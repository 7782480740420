import {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  PropsWithChildren,
} from "react";
import { Platform, StyleProp, View, ViewStyle } from "react-native";

import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import BottomSheetScrollView from "../BottomSheet/BottomSheetScrollView";
import Modal from "../Modal";
import { Props as ModalProps } from "../Modal/Modal";

import DialogCloseButton from "./DialogCloseButton";
import styles from "./styles";

/**
 * This dialog is developed to display any component inside.
 * @prop {react node} children - Content displayed inside.
 * @prop {string} closeButton - Show/hides the close button.
 * @prop {boolean} dismissable - Allow/prevents to dismiss the dialog without pressing on the action buttons.
 * @prop {boolean} mobileFullscreen - Open the dialog as fullscreen when on mobile viewport.
 * @prop {function} onDismiss - Action that changes the visibility of the dialog.
 * @prop {function} onPressCloseButton - Action triggered when the close action button is pressed.
 * @prop {boolean} overlay - Shows/hides the overlay.
 * @prop {object} style - Changes style of the container.
 * @prop {boolean} visible - Show/hides the dialog.
 */
const DialogCustom = ({
  children,
  closeButton: outerCloseButton,
  closeButtonStyle,
  desktopHeight,
  desktopWidth,
  dismissable = true,
  FooterComponent,
  FooterComponentStyle,
  hasOverlay,
  HeaderComponent,
  HeaderComponentStyle,
  internalScroll = true,
  internalScrollViewStyle,
  mobileFullscreen = false,
  onDismiss = () => {},
  onPressCloseButton,
  style: styleProp,
  visible,
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });
  const closeButtonRef = useRef<View>(null);
  const modalRef = useRef<View>(null);

  const closeButton = useMemo(() => {
    if (outerCloseButton !== undefined) {
      return outerCloseButton;
    }

    if (mobileFullscreen) {
      return true;
    }

    if (!isSmallScreen || !internalScroll) {
      return !!dismissable;
    }

    return false;
  }, [
    dismissable,
    internalScroll,
    isSmallScreen,
    mobileFullscreen,
    outerCloseButton,
  ]);

  const getFirstFocusableElement = useCallback(() => {
    const focusLocks = (
      modalRef.current as unknown as HTMLElement
    ).querySelectorAll("[data-focus-lock-disabled='false']");

    focusLocks.forEach((lock) => {
      const withAutofocus = lock.querySelector("[autofocus='true']");
      const tabbableEls = lock.querySelectorAll("[tabindex='0']");

      if (withAutofocus) {
        (withAutofocus as HTMLElement).focus();
      } else {
        if (closeButton && tabbableEls.length === 1) {
          (tabbableEls[0] as HTMLElement).focus();
        } else {
          (
            Array.from(tabbableEls).filter(
              (el) =>
                !(closeButtonRef.current as unknown as HTMLElement)?.contains(
                  el
                )
            )[0] as HTMLElement
          )?.focus();
        }
      }
    });
  }, [closeButton]);

  return (
    <Modal
      desktopHeight={desktopHeight}
      desktopWidth={desktopWidth}
      dismissable={dismissable}
      hasOverlay={hasOverlay}
      mobileFullscreen={mobileFullscreen}
      onDismiss={onDismiss}
      onFocusReady={
        Platform.OS === "web" ? getFirstFocusableElement : undefined
      }
      panEnabled={!!internalScroll && !closeButton && !!dismissable}
      ref={modalRef}
      style={styleProp}
      visible={visible}
    >
      {HeaderComponent || closeButton ? (
        <View style={[style.header, style.modalSideSpacing]}>
          <View style={[style.headerComponentContainer, HeaderComponentStyle]}>
            {HeaderComponent}
          </View>

          {closeButton && (
            <DialogCloseButton
              onPress={() =>
                onPressCloseButton ? onPressCloseButton() : onDismiss()
              }
              ref={closeButtonRef}
              style={[style.headerCloseButtonContainer, closeButtonStyle]}
            />
          )}
        </View>
      ) : undefined}

      {internalScroll ? (
        <BottomSheetScrollView
          fullscreen={mobileFullscreen}
          style={[style.modalSideSpacing, internalScrollViewStyle]}
        >
          {children}
        </BottomSheetScrollView>
      ) : (
        children
      )}

      {FooterComponent && (
        <View style={[style.modalSideSpacing, FooterComponentStyle]}>
          {FooterComponent}
        </View>
      )}
    </Modal>
  );
};

interface Props extends ModalProps {
  closeButton?: boolean;
  closeButtonStyle?: StyleProp<ViewStyle>;
  FooterComponent?: ReactNode;
  FooterComponentStyle?: StyleProp<ViewStyle>;
  HeaderComponent?: ReactNode;
  HeaderComponentStyle?: StyleProp<ViewStyle>;
  internalScroll?: boolean;
  internalScrollViewStyle?: StyleProp<ViewStyle>;
  onPressCloseButton?: () => void;
}

export default DialogCustom;
