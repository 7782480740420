import { ComponentProps, useCallback, useMemo, PropsWithChildren } from "react";
import { Image, Platform, View } from "react-native";

import { ActivityIndicator, Text } from "..";
import { useTheme } from "../../styles/themes/themeUtils";
import Animated from "../Animated";
import { IconComponent } from "../Icon";
import Heading from "../typography/Heading/Heading";

import styles, { getColor, getTextType, iconSizes } from "./styles";

const Button = ({
  additionalText,
  additionalTextColor,
  additionalTextPosition = "bottom",
  color = "neutral",
  disabled = false,
  dot = false,
  dotColor,
  Icon,
  iconAlign = "left",
  image,
  isLoading = false,
  hoverAnimatedValue,
  minTouchTarget = true,
  pressAnimatedValue,
  selected = false,
  size = "default",
  text,
  textAlign = "left",
  type = "filled",
  textHeadingLevel,

  // These default values resolve most use cases
  shape = text === undefined || size === "extraSmall" || size === "small"
    ? "round"
    : "default",
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(
    styles,
    useMemo(
      () => ({
        additionalText,
        additionalTextColor,
        additionalTextPosition,
        color,
        disabled,
        dotColor,
        minTouchTarget,
        selected,
        shape,
        size,
        text,
        textAlign,
        type,
      }),
      [
        additionalText,
        additionalTextColor,
        additionalTextPosition,
        color,
        disabled,
        dotColor,
        minTouchTarget,
        selected,
        shape,
        size,
        text,
        textAlign,
        type,
      ]
    )
  );
  const contentColor = getColor(color, disabled, selected, theme, type);
  const hasIconOrImage = Icon !== undefined || image !== undefined;
  const shouldOnlyDisplayActivityIndicator = isLoading && !hasIconOrImage;
  const canShowAdditionalText =
    additionalText && size !== "small" && size !== "extraSmall";

  const renderTexts = useCallback((): JSX.Element | null => {
    const sharedProps: Partial<React.ComponentProps<typeof Text>> = {
      align: textAlign,
      color: contentColor,
      selectable: false,
      style: shouldOnlyDisplayActivityIndicator && style.hiddenText,
      type: getTextType(size),
    };

    const renderText = (): JSX.Element => {
      if (textHeadingLevel) {
        return (
          <Heading {...sharedProps} level={textHeadingLevel}>
            {text}
          </Heading>
        );
      } else {
        return <Text {...sharedProps}>{text}</Text>;
      }
    };

    const renderAdditionalText = (): JSX.Element => (
      <Text
        align={textAlign}
        color={additionalTextColor || contentColor}
        selectable={false}
        style={shouldOnlyDisplayActivityIndicator && style.hiddenText}
        type="bodyExtraSmall"
      >
        {additionalText}
      </Text>
    );

    const renderBothTexts = (): JSX.Element => (
      <View
        style={[
          shouldOnlyDisplayActivityIndicator && style.hiddenText,
          additionalTextPosition === "top" && style.additionalTextTop,
        ]}
      >
        {renderText()}
        {renderAdditionalText()}
      </View>
    );

    if (!text && !canShowAdditionalText) {
      return null;
    } else if (text && !canShowAdditionalText) {
      return renderText();
    } else if (!text && canShowAdditionalText) {
      return renderAdditionalText();
    } else {
      return renderBothTexts();
    }
  }, [
    additionalText,
    additionalTextColor,
    additionalTextPosition,
    canShowAdditionalText,
    contentColor,
    shouldOnlyDisplayActivityIndicator,
    size,
    style.additionalTextTop,
    style.hiddenText,
    text,
    textAlign,
    textHeadingLevel,
  ]);

  return (
    <View
      /**
     style.selected was introduced because, when selected, the Button was losing its borderRadius on Android. Although the radius it's already specified in style.container, explicitly tying it with the selected prop fixes the issue!
     */
      style={[
        selected && Platform.OS === "android" ? style.selected : undefined,
        style.container,
      ]}
    >
      {hoverAnimatedValue && (
        <Animated.View
          style={[
            style.overlay,
            style.hoverOverlay,
            { opacity: hoverAnimatedValue },
          ]}
        />
      )}
      {pressAnimatedValue && (
        <Animated.View
          style={[
            style.overlay,
            style.pressOverlay,
            { opacity: pressAnimatedValue },
          ]}
        />
      )}
      <View
        style={[
          style.content,
          hasIconOrImage && text && iconAlign === "right"
            ? style.rightIconContent
            : undefined,
        ]}
      >
        {shouldOnlyDisplayActivityIndicator && (
          <ActivityIndicator
            colorCustom={contentColor}
            size={iconSizes[size]}
            style={style.activityIndicator}
          />
        )}
        {hasIconOrImage && (
          <View
            style={
              text
                ? iconAlign === "right"
                  ? style.rightIcon
                  : style.leftIcon
                : undefined
            }
          >
            {dot && <View style={style.dot} />}
            {isLoading ? (
              <ActivityIndicator
                colorCustom={contentColor}
                size={iconSizes[size]}
              />
            ) : (
              <>
                {image !== undefined && (
                  <Image
                    defaultSource={
                      Platform.OS === "ios" ? undefined : { uri: image }
                    }
                    source={{ uri: image }}
                    style={style.image}
                  />
                )}
                {Icon !== undefined && image === undefined && (
                  <Icon color={contentColor} size={iconSizes[size]} />
                )}
              </>
            )}
          </View>
        )}
        {renderTexts()}
      </View>
    </View>
  );
};

export type Color =
  | "black"
  | "blue"
  | "brand"
  | "green"
  | "grey"
  | "neutral"
  | "purple"
  | "serviceAmazonMusic"
  | "serviceAppleMusic"
  | "serviceFacebook"
  | "serviceInstagram"
  | "serviceSpotify"
  | "serviceTwitter"
  | "systemBlue"
  | "systemGreen"
  | "systemMagenta"
  | "systemRed"
  | "systemYellow"
  | "yellow"
  | "white";

type IconAlign = "left" | "right";

export type Shape = "default" | "pill" | "round";

export type Size = "default" | "extraSmall" | "large" | "small";

export type Type = "basic" | "filled" | "text";

type AdditionalTextPosition = "bottom" | "top";

type TextAlign = "center" | "left" | "right";

export interface Props {
  additionalText?: string;
  additionalTextColor?: string;
  additionalTextPosition?: AdditionalTextPosition;
  color?: Color;
  disabled?: boolean;
  dot?: boolean;
  dotColor?: string;
  Icon?: IconComponent;
  iconAlign?: IconAlign;
  image?: string;
  isLoading?: boolean;
  hoverAnimatedValue?: Animated.Value;
  minTouchTarget?: boolean;
  pressAnimatedValue?: Animated.Value;
  selected?: boolean;
  shape?: Shape;
  size?: Size;
  text?: string;
  textAlign?: TextAlign;
  type?: Type;
  textHeadingLevel?: ComponentProps<typeof Heading>["level"];
}

export default Button;
