import { global, Theme, viewStyles } from "../../../../styles/";

const styles = (theme: Theme, { baseColor }: Props) =>
  viewStyles({
    backgroundColor: {
      backgroundColor: baseColor,
    },
    container: {
      height: global.spacing * 6,
      paddingHorizontal: global.spacing * 3,
      paddingVertical: global.spacing,
    },
    radius: {
      borderRadius: global.radius.extraLarge,
      overflow: "hidden",
    },
  });

interface Props {
  baseColor: string;
}

export default styles;
