import { DataVersion, ResponseHandler } from "./types";

const handleResponseV0: ResponseHandler = (_httpResponse, jsonResponse) => {
  return {
    body: jsonResponse.message.body,
    header: jsonResponse.message.header,
  };
};

const handleResponseV1: ResponseHandler = (httpResponse, jsonResponse) => {
  return {
    body: jsonResponse.data,
    header: {
      hint: jsonResponse.message,
      status_code: httpResponse.status,
    },
  };
};

export const getDataVersion = (response: Record<string, any>): DataVersion => {
  if (
    response.hasOwnProperty("message") &&
    response.message.hasOwnProperty("header")
  ) {
    return "v0";
  }

  if (
    response.hasOwnProperty("message") &&
    typeof response.message === "string"
  ) {
    return "v1";
  }

  throw new Error("response does not match any supported data structure");
};

export const DATA_HANDLERS: Record<DataVersion, ResponseHandler> = {
  v0: handleResponseV0,
  v1: handleResponseV1,
};
