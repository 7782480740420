import { useUser } from "@mxmdev/podcasts-shared-native";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import { Linking } from "react-native";

export const useClaim = (): { handleClaim: () => void } => {
  const { signIn } = useAuth();
  const user = useUser();

  const handleClaim = (): void => {
    if (!user || !user.user?.user_id) {
      signIn();
    } else {
      Linking.openURL(
        `https://musixmatch.typeform.com/betaapplication#userid=${encodeURIComponent(
          user.user.user_id
        )}`
      );
    }
  };

  return {
    handleClaim,
  };
};
