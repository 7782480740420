import AsyncStorage from "@react-native-async-storage/async-storage";

import { PersistenceManager } from ".";

export const createAsyncPersistenceManager = (): PersistenceManager => {
  return {
    get: async (key: string): Promise<string | undefined> => {
      const value = await AsyncStorage.getItem(key);

      if (value !== null) {
        return Promise.resolve(value);
      } else {
        return Promise.resolve(undefined);
      }
    },
    remove: async (key: string): Promise<void> => {
      return AsyncStorage.removeItem(key);
    },
    set: async (key: string, value: string): Promise<void> => {
      return AsyncStorage.setItem(key, value);
    },
  };
};
