import { Path, Svg } from "react-native-svg";

const Core = ({ color }: Props): JSX.Element => (
  <Svg height="39" viewBox="0 0 50 39" width="50">
    <Path d="M25 0L0 19.5L25 39L50 19.5L25 0Z" fill={color} />
  </Svg>
);

type Props = {
  color: string;
};

export default Core;
