import { getPathnameFromURL, getURLSearchParams } from "./urlUtils";

export const AUTH_DEFAULT_CAPTCHA_URL: string =
  "https://apic.musixmatch.com/captcha.html";

export const AUTH_CALLBACK_CAPTCHA_PATH: string = "/captcha";

export const getCaptchaCallbackURL = (origin: string, redirectUrl?: string) => {
  const trimmedOrigin = origin.endsWith("/") ? origin.slice(0, -1) : origin;
  const url = new URL(trimmedOrigin + AUTH_CALLBACK_CAPTCHA_PATH);

  if (redirectUrl) {
    url.searchParams.append("redirectUrl", redirectUrl);
  }

  return url.toString();
};

export const isCaptchaURL = (url: string) => {
  return getPathnameFromURL(url) === AUTH_CALLBACK_CAPTCHA_PATH;
};

export const parseCaptchaResponse = (url: string) => {
  const { recaptcha_site_key: siteKey, response: captchaToken } =
    getURLSearchParams(url);

  return { captchaToken, siteKey };
};

export const parseRedirectUrl = (url: string) => {
  const { redirectUrl } = getURLSearchParams(url);

  return redirectUrl;
};
