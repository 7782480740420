import { useContext } from "react";

import { LocaleContext } from "./LocaleProvider";
import { LocaleInfo } from "./types";

export const useLocale = (): {
  localeInfo?: LocaleInfo;
  isLoading?: boolean;
  error?: unknown;
} => {
  const { state } = useContext(LocaleContext);

  if (state.status === "loaded") {
    return {
      localeInfo: {
        country: state.country,
        countryName: state.countryName,
        languages: state.languages,
      },
    };
  } else if (state.status === "loading") {
    return {
      isLoading: true,
    };
  } else if (state.status === "error") {
    return {
      error: state.error,
    };
  } else {
    return {};
  }
};
