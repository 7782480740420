import { APISpeaker, SpeakerCommon } from "../types";

export const generateSpeakerHashCommon = (speaker: SpeakerCommon): string => {
  if (speaker.type === "rich") {
    return `${speaker.type}-${speaker.wikidataId}`;
  } else if (speaker.type === "generic") {
    return "generic";
  }

  return `${speaker.type}-${speaker.name}`;
};

export const generateSpeakerHashApi = (speaker: APISpeaker): string => {
  return `${speaker.transcription_id}-${speaker.wikidata_id}-${speaker.name}`;
};

export const convertSpeakerIdToHumanLabel = (id: string): string => {
  const matches = /^spk_(\d+)$/.exec(id);

  if (matches) {
    const index = matches[1];

    let number = "";

    if (!isNaN(parseInt(index, 10))) {
      number = `${parseInt(index, 10) + 1}`;
    }

    return `Speaker ${number}`;
  }

  return "Speaker";
};
