const pad = (value: number, padding: number = 2): string => {
  return ("0".repeat(padding) + value).slice(-padding);
};

/**
 * Convert a given date to DD/MM/YYYY format
 * @param {string} inputDate - Date to convert in DD/MM/YYYY format.
 */
const getShortDate = (inputDate: string): string => {
  const date: Date = new Date(inputDate);
  const formattedDate: string = [
    pad(date.getDate()),
    pad(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");

  return formattedDate;
};

/**
 * Convert a given date string to the nearest milliseconds since epoch.
 *
 * @param {string} inputDate - Date to convert to milliseconds.
 * @returns {number } The converted date.
 */
const getTimeMilliseconds = (inputDate: string): number => {
  const date: Date = new Date(inputDate);

  return Math.round(date.getTime() / 1000);
};

export { getShortDate, getTimeMilliseconds, pad };
