import { PropsWithChildren } from "react";

import { ALIGN_TO_TEXT_STYLE } from "..";
import { useTheme } from "../../styles/themes/themeUtils";
import Animated from "../Animated";
import { Props as TextProps } from "../typography/Text/Text";

import styles from "./styles";

const TextLink = ({
  // No default values because it can
  // inherit styles from parent `Text`.
  align,

  children,
  color,
  hoverAnimatedValue,
  numberOfLines,
  onPress,
  style: styleProp,
  type,
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(styles, { align });

  return (
    <Animated.Text
      numberOfLines={numberOfLines}
      onPress={onPress}
      style={[
        align && style[ALIGN_TO_TEXT_STYLE[align]],
        { color: color || theme.contentPrimary },
        type && style[type],
        hoverAnimatedValue && {
          opacity: hoverAnimatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [1, 0.6],
          }),
        },
        styleProp,
      ]}
    >
      {children}
    </Animated.Text>
  );
};

export interface Props extends TextProps {
  hoverAnimatedValue?: Animated.Value;
}

export default TextLink;
