import {
  useMediaQuery,
  useTheme,
  ListItem,
  IconExternalLink,
} from "@mxmdev/react-universal-components";
import { ComponentProps } from "react";

import styles from "./styles";

const AccountMenuItem = ({
  children,
  externalLink = false,
  Icon,
  label,
  onPress,
  to,
}: Props) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });

  return externalLink ? (
    <ListItem
      containerStyle={style.item}
      divider={false}
      Icon={Icon}
      label={label}
      labelType="labelSmall"
      onPress={onPress}
      to={to}
    >
      <IconExternalLink size={20} />
    </ListItem>
  ) : (
    <ListItem
      children={children}
      containerStyle={style.item}
      divider={false}
      Icon={Icon}
      label={label}
      labelType="labelSmall"
      onPress={onPress}
      to={to}
    />
  );
};

interface Props {
  children?: ComponentProps<typeof ListItem>["children"];
  externalLink?: boolean;
  Icon?: ComponentProps<typeof ListItem>["Icon"];
  label: ComponentProps<typeof ListItem>["label"];
  onPress?: ComponentProps<typeof ListItem>["onPress"];
  to?: ComponentProps<typeof ListItem>["to"];
}

export default AccountMenuItem;
