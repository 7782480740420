import { gql, TypedDocumentNode } from "@apollo/client";

type Parameter = string | { name: string; type: string };

type RESTOptions = {
  name: string;
  path: string;
  parameters?: Parameter[];
  urlParameters?: Parameter[];
  endpoint?: string;
};

type RESTQueryOptions = RESTOptions;
type RESTMutationOptions = RESTOptions & {
  method?: "POST" | "PUT" | "DELETE" | "PATCH";
};

const DEFAULT_PARAMS: Parameter[] = ["app_id", "captcha_id", "usertoken"];

const compileTypeParams = (params: Parameter[]): string => {
  return params
    .map((nameOrParam) =>
      typeof nameOrParam === "string"
        ? `$${nameOrParam}: String`
        : `$${nameOrParam.name}: ${nameOrParam.type}`
    )
    .join(", ");
};

const compileParams = (params: Parameter[]): string => {
  return params
    .map((nameOrParam) =>
      typeof nameOrParam === "string"
        ? `${nameOrParam}: $${nameOrParam}`
        : `${nameOrParam.name}: $${nameOrParam.name}`
    )
    .join(", ");
};

export const createRESTQuery = <TData, TVars>({
  endpoint,
  name,
  parameters = [],
  path,
  urlParameters = [],
}: RESTQueryOptions): TypedDocumentNode<TData, TVars> => {
  const params = DEFAULT_PARAMS.concat(parameters);

  return gql`
    query ${name}(${compileTypeParams(params.concat(urlParameters))}) {
      ${name}(
        ${compileParams(urlParameters)}
        params: {
          ${compileParams(params)}
        }
      ) @rest(
        method: "GET"
        path: "${path}?{args.params}"
        ${endpoint ? `endpoint: "${endpoint}"` : ""}
      ) {
        body
        header
      }
    }
  `;
};

export const createRESTMutation = <TData, TVars>({
  endpoint,
  method = "POST",
  name,
  parameters = [],
  path,
  urlParameters = [],
}: RESTMutationOptions): TypedDocumentNode<TData, TVars> => {
  const params = DEFAULT_PARAMS.concat(parameters);

  return gql`
    mutation ${name}(${compileTypeParams(params.concat(urlParameters))}) {
      ${name}(
        input: $body
        ${compileParams(urlParameters)}
        params: {
          ${compileParams(params)}
        }
      ) @rest(
        method: "${method}"
        path: "${path}?{args.params}"
        ${endpoint ? `endpoint: "${endpoint}"` : ""}
      ) {
        body
        header
      }
    }
  `;
};
