import { global, viewStyles, Theme } from "@mxmdev/react-universal-components";

// This value is applied to the components that are
// on the edge of Header and are not buttons.
// It's necessary to keep the Header's padding consistent,
// since buttons have their own internal padding also
// (related to touch targets).
const sideItemSpacing = global.spacing * 2;
const anchorContainerMargin = {
  avatar: sideItemSpacing,
  more: 0,
};

type AnchorType = "avatar" | "more";

interface Props {
  anchorType: AnchorType;
  isSmallScreen: boolean;
}

const styles = (theme: Theme, { anchorType, isSmallScreen }: Props) => {
  const backgroundColor = theme.backgroundPrimaryElevated;
  const shadowColor = {
    shadowColor: theme.shadow,
  };
  // This is the padding value applied to the sides of Header.
  // NOTE: be careful to change this, because it affects
  // the layout of all our products.
  const headerPaddingHorizontal = isSmallScreen
    ? global.spacing * 2
    : global.spacing * 4;
  // Left and right areas must have the same flex value,
  // otherwise the content between them is not centered.
  const sideContentFlex = 1;

  return viewStyles({
    accountMenuAnchorContainerStyle: {
      alignItems: "center",
      flexDirection: "row",
      marginRight: sideItemSpacing,
    },
    anchorContainerStyle: {
      marginRight: anchorContainerMargin[anchorType],
    },
    anchorEnhancer: {
      marginHorizontal: global.spacing * 2,
    },
    headerText: {
      flex: 1,
      marginLeft: itemSpacing,
    },
    logoContainer: {
      marginLeft: sideItemSpacing,
    },
    primaryAction: {
      marginRight: itemSpacing,
    },
    stackedBarRow: {
      borderColor: theme.backgroundSecondary,
      borderTopWidth: 1,
    },
    tabsContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
    },
    topBar: {
      backgroundColor,
      borderBottomWidth: 1,
      borderColor: theme.backgroundSecondary,
      ...shadowColor,
      shadowOffset: { height: 8, width: 0 },
      shadowRadius: 80,
      zIndex: 2, // needs this to display shadows
    },
    topBarLeftContent: {
      alignItems: "center",
      flex: sideContentFlex,
      flexDirection: "row",
    },
    topBarRightContent: {
      alignItems: "center",
      flex: sideContentFlex,
      flexDirection: "row",
      justifyContent: "flex-end",
      zIndex: -1,
    },
    topBarRow: {
      alignItems: "center",
      flexDirection: "row",
      height: isSmallScreen ? 48 : 60,
      justifyContent: "center",
      paddingHorizontal: headerPaddingHorizontal,
    },
  });
};

export default styles;

// This value can be used to keep the same spacing
// between different UI components in the Header.
export const itemSpacing = global.spacing * 2;
