import { useCallback } from "react";

import { usePodcastsSettings } from "../settings";

export const useAudiogramDeveloperTools = () => {
  const {
    setSettings,
    settings: {
      areAudiogramDeveloperToolsEnabled:
        areAudiogramDeveloperToolsEnabledString,
    },
  } = usePodcastsSettings();

  const areAudiogramDeveloperToolsEnabled =
    areAudiogramDeveloperToolsEnabledString === "true";

  const toggleAudiogramDeveloperTools = useCallback(() => {
    setSettings(
      "areAudiogramDeveloperToolsEnabled",
      String(!areAudiogramDeveloperToolsEnabled)
    );
  }, [areAudiogramDeveloperToolsEnabled, setSettings]);

  return {
    areAudiogramDeveloperToolsEnabled,
    toggleAudiogramDeveloperTools,
  };
};
