import {
  ApolloError,
  EntitlementsGetData,
  EntitlementsGetVars,
  isResponseOk,
  queries,
  useQuery,
} from "@mxmdev/react-universal-core";
import { useCallback, useMemo, useState } from "react";

import { ForbiddenOperationError, PaymentsError } from "../errors";
import { ERROR_CODES } from "../errors/PaymentsError";
import { entitlementsTransformer } from "../transformers";
import { Entitlement } from "../types";

interface AdminOptions {
  admin: true;
  userId: string;
}

interface BaseOptions {
  admin?: false;
}

export type Options = AdminOptions | BaseOptions;

const usePaymentsEntitlements = (
  options: Options = {}
): {
  loading: boolean;
  error: ApolloError | PaymentsError | null;
  data: Entitlement[] | null;
  refetch: () => Promise<void>;
} => {
  const [error, setError] = useState<ApolloError | PaymentsError | null>(null);

  const { data, loading, refetch } = useQuery<
    EntitlementsGetData,
    EntitlementsGetVars
  >(
    options.admin
      ? queries.adminEntitlementsGet // Admin API returns entitlements for all products
      : queries.entitlementsGet,
    {
      onCompleted: (data) => {
        if (!isResponseOk(data?.entitlementsGet.header.status_code)) {
          if (data?.entitlementsGet.header.status_code === 403) {
            setError(new ForbiddenOperationError("entitlements"));

            return;
          }

          setError(
            new PaymentsError(
              data?.entitlementsGet.header.hint ?? "unknown error",
              ERROR_CODES.UNEXPECTED
            )
          );
        }
      },
      onError: (error) => {
        if (error.networkError && "statusCode" in error.networkError) {
          if (error.networkError.statusCode === 403) {
            setError(new ForbiddenOperationError("entitlements"));

            return;
          }
        }

        setError(error);
      },
      variables: {
        userId:
          "admin" in options && options.admin ? options.userId : undefined,
      },
    }
  );

  const transformedData = useMemo(() => {
    if (!data) {
      return null;
    }

    return entitlementsTransformer(data.entitlementsGet.body);
  }, [data]);

  const refetchFn = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return { data: transformedData, error, loading, refetch: refetchFn };
};

export default usePaymentsEntitlements;
