import { createContext } from "react";

export interface RefreshContextData {
  /**
   * Refreshes the application.
   *
   * @param loadingTimeMs NATIVE ONLY. How much time to show the loading component before returning to the application.
   */
  refresh: (loadingTimeMs?: number) => void;
}

const RefreshContext = createContext<RefreshContextData>({
  refresh: () => {},
});

export default RefreshContext;
