import { Episode } from "@mxmdev/podcasts-shared";

import { timeout } from "./util";

export const resolveEpisodeAfterRedirect = async (
  episode: Episode,
  fallbackFetcher?: (episode: Episode) => Promise<string>
): Promise<string> => {
  const controller = new AbortController();

  // First try to resolve the URL without relying on the backend service
  try {
    const response = await fetch(episode.audioTrackUrl, {
      method: "GET",
      signal: controller.signal,
    });

    // We don't want to download the whole mp3, we just need to resolve the
    // final URL after the redirects. Therefore, we abort the request
    // as soon as we resolved the redirects.
    controller.abort();

    return Promise.resolve(response.url);
  } catch (error) {
    console.warn("client-based url resolution failed: ", error);
  }

  // If the fetch method didn't work (for example, due to CORS errors) use the fallback
  if (fallbackFetcher !== undefined) {
    try {
      const response = await fallbackFetcher(episode);

      return Promise.resolve(response);
    } catch (error) {}
  }

  return Promise.reject(new Error("failed to resolve url"));
};

export const resolveEpisodeAfterRedirectWithRetries = async (
  episode: Episode,
  maxAttempts: number,
  fallbackFetcher?: (episode: Episode) => Promise<string>
): Promise<string> => {
  for (let i = 0; i < maxAttempts; i++) {
    try {
      const redirectUrl = await resolveEpisodeAfterRedirect(
        episode,
        fallbackFetcher
      );

      return Promise.resolve(redirectUrl);
    } catch (error) {}

    await timeout(1000);
  }

  return Promise.reject(new Error("max attempts reached"));
};
