import { useCallback, useEffect, PropsWithChildren } from "react";

import { useCaptcha, withCaptchaProviderSSR } from "../../captcha";
import { User } from "../../types";
import { storage } from "../../utils";
import {
  AUTH_ACCOUNT_MANAGE_PATH,
  AUTH_ACCOUNT_SUBSCRIPTIONS_PATH,
  AUTH_CONTRIBUTION_PREFERENCES_PATH,
} from "../constants";
import { getSignInURL, getSignOutURL, getTokenMigrateURL } from "../utils";

import AuthContext from "./AuthContext";
import { redirectTo } from "./redirects";
import { getUserToken, removeUserToken } from "./storage";
import { AccountLinks } from "./types";
import { getRedirectOrigin } from "./utils";

const AuthProvider = ({
  accountURL,
  appId,
  appScheme,
  children,
  ignoreTokenMigration,
  signInURL,
  signOutURL,
  tokenMigrateURL,
  user,
  userToken,
}: PropsWithChildren<Props>) => {
  const captcha = useCaptcha();

  const isLoggedIn = !!user;

  useEffect(() => {
    // If the user is logged via the "legacy" Authentication provider
    // try to migrate to the "shared" authentication mode, eventually deleting the old user token.
    (async () => {
      if (ignoreTokenMigration) {
        return;
      }

      const token = await getUserToken(storage);

      if (token) {
        await removeUserToken(storage);

        redirectTo(
          getTokenMigrateURL(
            tokenMigrateURL,
            appId,
            getRedirectOrigin(appScheme),
            token
          ).toString()
        );
      }
    })();
  }, [appId, appScheme, ignoreTokenMigration, tokenMigrateURL]);

  const signIn = useCallback(
    (redirectURL?: string) =>
      !isLoggedIn &&
      redirectTo(
        getSignInURL({
          appId,
          baseURL: signInURL,
          forceRedirect: true,
          origin: redirectURL ?? getRedirectOrigin(appScheme),
        }).toString()
      ),
    [appId, appScheme, isLoggedIn, signInURL]
  );

  const signOut = useCallback(
    (redirectURL?: string) => {
      if (isLoggedIn) {
        captcha.clear();

        redirectTo(
          getSignOutURL(
            signOutURL,
            appId,
            redirectURL ?? getRedirectOrigin(appScheme)
          ).toString()
        );
      }
    },
    [appId, appScheme, captcha, isLoggedIn, signOutURL]
  );

  const getLinks = useCallback((): AccountLinks => {
    return {
      contributionPreference: new URL(
        accountURL + AUTH_CONTRIBUTION_PREFERENCES_PATH
      ),
      manage: new URL(accountURL + AUTH_ACCOUNT_MANAGE_PATH),
      subscriptions: new URL(accountURL + AUTH_ACCOUNT_SUBSCRIPTIONS_PATH),
    };
  }, [accountURL]);

  return (
    <AuthContext.Provider
      value={{
        appId,
        getLinks,
        isLoggedIn,
        signIn,
        signOut,
        user,
        userToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface Props {
  accountURL: string;
  appScheme?: string;
  appId: string;
  ignoreTokenMigration?: boolean;
  signInURL: string;
  signOutURL: string;
  tokenMigrateURL: string;
  user: User | null;
  userToken: string | null;
}

export default withCaptchaProviderSSR(AuthProvider);
