import { Platform } from "react-native";

import { viewStyles } from "../../styles";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";

interface Props {
  mobileFullscreen: boolean;
  isSmallScreen: boolean;
}

const styles = (theme: Theme, { isSmallScreen, mobileFullscreen }: Props) => {
  return viewStyles({
    bottomSheet: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      maxHeight: "100%",
      overflow: "hidden",
      width: "100%",
    },
    bottomSheetScrollView: {
      paddingBottom: global.spacing * 12,
      paddingHorizontal: global.spacing * 4,
      paddingTop: global.spacing * 6,
    },
    container: {
      flex: 1,
      overflow: "hidden",
      paddingTop: isSmallScreen && !mobileFullscreen ? 40 : 0,
    },
    dropdown: {
      // Can't use the `global.sheetShadow.centered` token due to
      // a chrome-specific bug that prevents multiple box-shadows
      // from being displayed correctly on web
      ...Platform.select({
        android: {
          elevation: 40,
        },
        default: {
          shadowColor: theme.shadow,
          shadowOffset: {
            height: 0,
            width: 1,
          },
          shadowRadius: 60,
        },
      }),
      backgroundColor: theme.backgroundPrimaryElevated,
      borderRadius: global.radius.default,
      position: "absolute",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.6)",
      bottom: 0,
      // cursor type required, otherwise it won't show a pointer on the pressable area
      ...Platform.select({
        web: {
          cursor: "auto",
        },
      }),
      flex: 1,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
  });
};

export default styles;
