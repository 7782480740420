import { Path, Svg } from "react-native-svg";

const Side = ({ color }: Props): JSX.Element => (
  <Svg height="39" viewBox="0 0 25 39" width="25">
    <Path
      d="M25 19.5L0 0V39L25 19.5Z"
      fill={color}
      stroke={color}
      strokeWidth={0.5}
    />
  </Svg>
);

type Props = {
  color: string;
};

export default Side;
