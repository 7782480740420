import { Episode } from "@mxmdev/podcasts-shared";
import { Platform } from "react-native";

import { ResolvedEpisode } from "../../types";

import { createMobileAudioPlayer } from "./mobile-player";
import { createWebAudioPlayer } from "./web-player";

// Reference: http://react-native-track-player.js.org/documentation/

export interface AudioPlayer {
  initialize: () => Promise<void>;

  addStateUpdateListener: (listener: StateUpdateListener) => void;
  removeStateUpdateListener: (listener: StateUpdateListener) => void;

  addEventListener: (listener: PlayerEventListener) => void;
  removeEventListener: (listener: PlayerEventListener) => void;

  configureUrlResolveFallback: (
    fetcher: (episode: Episode) => Promise<string>
  ) => void;

  addEpisodes: (episodes: readonly Episode[]) => Promise<void>;
  reset: () => Promise<void>;

  loadCurrentEpisode: () => Promise<void>;
  play: () => Promise<void>;
  pause: () => Promise<void>;
  seek: (time: number) => Promise<void>;
  seekDelta: (deltaTime: number) => Promise<void>;
  next: () => Promise<void>;
  previous: () => Promise<void>;
  setIndex: (index: number) => Promise<void>;
  setPlaybackRate: (rate: number) => Promise<void>;
  replaceAudioTrack: (audioTrackUrl: string) => Promise<void>;

  destroy: () => Promise<void>;
}

export type StateUpdateListener = (state: AudioPlayerState) => void;

export type PlayerEvent = "play" | "pause" | "stop";

export type PlayerEventListener = {
  type: PlayerEvent;
  listener: (episode: ResolvedEpisode) => void;
};

export type AudioPlayerState = {
  canPlay: boolean;
  currentTime?: number;
  duration?: number;
  error?: unknown;
  isLoading: boolean;
  isPlaying: boolean;
  isSeeking: boolean;
  playbackRate: number;

  playlist: {
    episodes: readonly ResolvedEpisode[];
  };
  currentIndex: number | undefined;
};

export const initialAudioPlayerState: AudioPlayerState = {
  canPlay: false,
  currentIndex: undefined,
  isLoading: false,
  isPlaying: false,
  isSeeking: false,
  playbackRate: 1,
  playlist: {
    episodes: [],
  },
};

export const audioPlayer =
  typeof window !== "undefined"
    ? Platform.OS === "web"
      ? createWebAudioPlayer()
      : createMobileAudioPlayer()
    : undefined;
