import { useRef, useState, PropsWithChildren } from "react";

import { useTheme } from "../../styles";
import Animated from "../Animated";
import Hoverable from "../Hoverable";
import { Props as HoverableProps } from "../Hoverable/Hoverable";
import Pressable from "../Pressable";
import { Props as PressableProps } from "../Pressable/Pressable";

import TextLink, { Props as TextLinkProps } from "./TextLink";
import styles from "./styles";

const EnhancedTextLink = ({
  align,
  children,
  color,
  hasUnderlineOnHover = false,
  isHoverAnimationDisabled,
  numberOfLines,
  onHover,
  onPress,
  style: styleProp,
  to,
  type,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const hoverAnimatedValue = useRef(new Animated.Value(0)).current;
  const [isHovering, setIsHovering] = useState(false);
  const { style } = useTheme(styles, {});

  return (
    <Hoverable
      animatedValue={hoverAnimatedValue}
      isAnimationDisabled={
        isHoverAnimationDisabled
          ? isHoverAnimationDisabled
          : !(onHover || onPress || to)
      }
      onHover={onHover}
      onHoverIn={() => {
        setIsHovering(true);
      }}
      onHoverOut={() => {
        setIsHovering(false);
      }}
    >
      <Pressable {...otherProps} onPress={onPress} to={to}>
        <TextLink
          align={align}
          color={color}
          hoverAnimatedValue={hoverAnimatedValue}
          numberOfLines={numberOfLines}
          style={[
            hasUnderlineOnHover ? isHovering && style.textLink : style.textLink,
            styleProp,
          ]}
          type={type}
        >
          {children}
        </TextLink>
      </Pressable>
    </Hoverable>
  );
};

interface Props
  extends TextLinkProps,
    Omit<HoverableProps, "style">,
    Omit<PressableProps, "style"> {
  isHoverAnimationDisabled?: boolean;
  hasUnderlineOnHover?: boolean;
}

export default EnhancedTextLink;
