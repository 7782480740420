import { Episode } from "@mxmdev/podcasts-shared/src/types";

import { EpisodeAnalyticsCommon } from "../types";

export const episodeToAnalytics = (
  episode: Episode
): EpisodeAnalyticsCommon => {
  return {
    episodeId: episode.id,
    episodeLanguage: episode.language,
    episodeName: episode.name,
    podcastId: episode.podcastId,
    podcastName: episode.collection,
  };
};
