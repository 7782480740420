import { Episode } from "@mxmdev/podcasts-shared";
import { useAnalytics } from "@mxmdev/react-universal-core";
import { useCallback } from "react";

import { episodeToAnalytics } from "../analytics";
import { EpisodeAnalyticsCommon, formatEpisodeAnalytics } from "../types";

export const usePodcastsStudioAnalytics = () => {
  const { logEvent } = useAnalytics();

  const logStudioEvent = useCallback(
    (
      eventName: string,
      episode: EpisodeAnalyticsCommon,
      additionalParams?: object
    ) => {
      logEvent(eventName, {
        ...formatEpisodeAnalytics(episode),
        ...additionalParams,
      });
    },
    [logEvent]
  );

  const logAIContentCopyClicked = useCallback(
    (
      episode: Episode,
      type:
        | "summary"
        | "learnings"
        | "chapters"
        | "show_notes"
        | "newsletter"
        | "social_post"
    ) => {
      logStudioEvent(
        "studio_ai_content_copy_clicked",
        episodeToAnalytics(episode),
        { type }
      );
    },
    [logStudioEvent]
  );

  const logCopyEmbedCodeClicked = useCallback(
    (episode: EpisodeAnalyticsCommon) => {
      logStudioEvent("copy_embed_code_clicked", episode);
    },
    [logStudioEvent]
  );

  const logCopyTranscriptionUrlClicked = useCallback(
    (episode: EpisodeAnalyticsCommon) => {
      logStudioEvent("copy_transcription_url_clicked", episode);
    },
    [logStudioEvent]
  );

  const logDownloadTranscriptionClicked = useCallback(
    (
      episode: EpisodeAnalyticsCommon,
      transcriptionType: "srt" | "json" | "txt"
    ) => {
      logStudioEvent("download_transcription_clicked", episode, {
        transcription_type: transcriptionType,
      });
    },
    [logStudioEvent]
  );

  const logDownloadTranscriptionMenuClicked = useCallback(
    (episode: EpisodeAnalyticsCommon) => {
      logStudioEvent("download_transcription_menu_clicked", episode);
    },
    [logStudioEvent]
  );

  const logEmbedMenuClicked = useCallback(
    (episode: Episode) => {
      logStudioEvent("embed_menu_clicked", episodeToAnalytics(episode));
    },
    [logStudioEvent]
  );

  const logRequestTranscriptionClicked = useCallback(
    (episode: EpisodeAnalyticsCommon) => {
      logStudioEvent("request_transcription_clicked", episode);
    },
    [logStudioEvent]
  );

  const logTabAIContentClicked = useCallback(
    (episode: EpisodeAnalyticsCommon) => {
      logStudioEvent("tab_ai_content_clicked", episode);
    },
    [logStudioEvent]
  );

  const logTabVideoClicked = useCallback(
    (episode: EpisodeAnalyticsCommon) => {
      logStudioEvent("tab_video_clicked", episode);
    },
    [logStudioEvent]
  );

  const logTranscriptionRequest = useCallback(
    (episode: EpisodeAnalyticsCommon) => {
      logStudioEvent("transcription_request", episode, {
        combined_episode_info: `${episode.podcastId}-##$$##-${episode.episodeId}`,
      });
    },
    [logStudioEvent]
  );

  const logTranscriptionUrlMenuClicked = useCallback(
    (episode: Episode) => {
      logStudioEvent(
        "transcription_url_menu_clicked",
        episodeToAnalytics(episode)
      );
    },
    [logStudioEvent]
  );

  const logVideoTabDownloadClicked = useCallback(
    (episode: Episode, options: object, source: "preview" | "editor") => {
      logStudioEvent(
        "video_tab_download_clicked",
        episodeToAnalytics(episode),
        {
          options,
          source,
        }
      );
    },
    [logStudioEvent]
  );

  const logVideoTabChapterClicked = useCallback(
    (episode: Episode) => {
      logStudioEvent("video_tab_chapter_clicked", episodeToAnalytics(episode));
    },
    [logStudioEvent]
  );

  const logVideoTabEditClicked = useCallback(
    (episode: Episode) => {
      logStudioEvent("video_tab_edit_clicked", episodeToAnalytics(episode));
    },
    [logStudioEvent]
  );

  const logVideoTabTrimClicked = useCallback(
    (episode: Episode) => {
      logStudioEvent("video_tab_trim_clicked", episodeToAnalytics(episode));
    },
    [logStudioEvent]
  );

  return {
    logAIContentCopyClicked,
    logCopyEmbedCodeClicked,
    logCopyTranscriptionUrlClicked,
    logDownloadTranscriptionClicked,
    logDownloadTranscriptionMenuClicked,
    logEmbedMenuClicked,
    logRequestTranscriptionClicked,
    logTabAIContentClicked,
    logTabVideoClicked,
    logTranscriptionRequest,
    logTranscriptionUrlMenuClicked,
    logVideoTabChapterClicked,
    logVideoTabDownloadClicked,
    logVideoTabEditClicked,
    logVideoTabTrimClicked,
  };
};
