import {
  DialogCustom,
  viewStyles,
  global,
  Text,
  DialogComponent,
  Checkbox,
} from "@mxmdev/react-universal-components";
import { View } from "react-native";

import { useRequestTranscriptionType } from "../../hooks";
import { useAudiogramDeveloperTools } from "../../hooks/useAudiogramDeveloperTools";
import { useBackendCache } from "../../hooks/useBackendCache";
import { createStyles, useTheme } from "../../utils";

export const DeveloperToolsDialog: DialogComponent = (props) => {
  const { style } = useTheme(styles);

  const { isBackendCacheEnabled, toggleIsBackendCacheEnabled } =
    useBackendCache();

  const { requestTranscriptionType, toggleRequestTranscriptionType } =
    useRequestTranscriptionType();

  const { areAudiogramDeveloperToolsEnabled, toggleAudiogramDeveloperTools } =
    useAudiogramDeveloperTools();

  return (
    <DialogCustom desktopWidth={644} {...props}>
      <View style={style.container}>
        <Text accessibilityRole="header" numberOfLines={1} type="headingSmall">
          Developer Tools
        </Text>

        <View style={style.tab}>
          <Checkbox
            checked={!isBackendCacheEnabled}
            label="Disable backend cache"
            onChange={toggleIsBackendCacheEnabled}
          />
          <Checkbox
            checked={requestTranscriptionType === "amazon"}
            label="Use Amazon for transcriptions"
            onChange={toggleRequestTranscriptionType}
          />
          <Checkbox
            checked={areAudiogramDeveloperToolsEnabled}
            label="Enable audiogram's developer tools"
            onChange={toggleAudiogramDeveloperTools}
          />
        </View>
      </View>
    </DialogCustom>
  );
};

const styles = createStyles(() =>
  viewStyles({
    container: {
      alignItems: "stretch",
    },
    tab: {
      paddingTop: global.spacing * 5,
    },
  })
);
