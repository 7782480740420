import { viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";
import { bottomSheetPaddingHorizontal } from "../BottomSheet";
import { windowPaddingHorizontal } from "../Modal";

interface Props {
  inline: boolean;
  isSmallScreen: boolean;
  stacked: boolean;
}

const styles = (theme: Theme, { inline, isSmallScreen, stacked }: Props) => {
  const isVerticallyAligned = isSmallScreen && !inline;

  const modalSideSpacing = isSmallScreen
    ? bottomSheetPaddingHorizontal
    : windowPaddingHorizontal;

  return viewStyles({
    actionsContainer: {
      flexDirection: stacked || isVerticallyAligned ? "column" : "row-reverse",
      marginTop: stacked ? 0 : global.spacing * 6,
    },
    closeButtonContainer: {
      alignItems: "flex-end",
      marginBottom: global.spacing * 2,
    },
    description: {
      marginTop: global.spacing * 4,
    },
    dialogListChildrenContainer: {
      marginTop: global.spacing * 4,
    },
    dialogListItem: {
      marginLeft: modalSideSpacing,
      paddingRight: modalSideSpacing,
    },
    header: {
      alignItems: "center",
      flexDirection: "row",
      marginBottom: global.spacing * 2,
    },
    headerCloseButtonContainer: {
      marginBottom: 0,
    },
    headerComponentContainer: {
      flex: 1,
    },
    iconContainer: {
      marginBottom: global.spacing * 6,
    },
    modalSideSpacing: {
      paddingHorizontal: modalSideSpacing,
    },
    note: {
      marginTop: global.spacing * 4,
    },
    secondaryActionButton:
      stacked || isVerticallyAligned
        ? { marginTop: global.spacing * 4 }
        : { marginRight: global.spacing * 4 },
    textContainer: {
      marginBottom: global.spacing * 6,
    },
  });
};

export default styles;
