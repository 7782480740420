import { useState, useEffect } from "react";

import {
  audioPlayer,
  AudioPlayerState,
  initialAudioPlayerState,
  PlayerEventListener,
} from "../audio-player";

import useFallbackUrlResolveFetcher from "./useFallbackUrlResolveFetcher";

export const useAudioPlayer = (
  eventListeners?: PlayerEventListener[]
): {
  state: AudioPlayerState;
} => {
  const { fallbackUrlResolve } = useFallbackUrlResolveFetcher();
  const [state, setState] = useState<AudioPlayerState>(initialAudioPlayerState);

  useEffect(() => {
    const stateListener = (updatedState: AudioPlayerState): void => {
      setState(updatedState);
    };

    audioPlayer?.addStateUpdateListener(stateListener);
    audioPlayer?.configureUrlResolveFallback(fallbackUrlResolve);

    return (): void => audioPlayer?.removeStateUpdateListener(stateListener);
  }, [state, fallbackUrlResolve]);

  useEffect(() => {
    if (eventListeners) {
      eventListeners.forEach((listener) =>
        audioPlayer?.addEventListener(listener)
      );

      return (): void =>
        eventListeners.forEach((listener) =>
          audioPlayer?.removeEventListener(listener)
        );
    }
  }, [eventListeners]);

  return {
    state,
  };
};
