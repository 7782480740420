import { Platform } from "react-native";

import common from "../styles/themes/common-theme-colors";

// Types shared between components in the library (defined below)
export type ComponentSize = "default" | "large" | "small";

export default {
  animatedTimingDuration: 100,
  breakpoints: {
    extraLargeScreen: 1200,
    largeScreen: 1024,
    mediumScreen: 600,
  },
  minimumTouchTarget: 48,
  radius: {
    default: 8,
    extraLarge: 24,
    large: 16,
    small: 4,
  },
  sheetShadow: {
    below: {
      ...Platform.select({
        android: {
          elevation: 40,
        },
        default: {
          shadowColor: common.shadow,
          shadowOffset: {
            height: 4,
            width: 0,
          },
          shadowRadius: 60,
        },
      }),
    },
    centered: {
      ...Platform.select({
        android: {
          elevation: 40,
        },
        default: {
          shadowColor: common.shadow,
          shadowOffset: {
            height: 0,
            width: 0,
          },
          shadowRadius: 60,
        },
      }),
    },
  },
  size: {
    default: 48,
    extraSmall: 24,
    large: 56,
    small: 36,
  },
  spacing: 4,
};
