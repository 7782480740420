import { Platform } from "react-native";

import { Color } from "../components/Button/Button";
import { IconColor } from "../components/Icon";
import { isServer } from "../utils";

import { Theme } from "./themes";

export const getBasicColors = (color: Color, theme: Theme): string => {
  const basicColors = {
    black: theme.contentPrimary,
    blue: theme.contentPrimary,
    brand: theme.contentPrimary,
    green: theme.contentPrimary,
    grey: theme.contentPrimary,
    neutral: theme.contentPrimary,
    purple: theme.contentPrimary,
    serviceAmazonMusic: theme.contentPrimary,
    serviceAppleMusic: theme.contentPrimary,
    serviceFacebook: theme.contentPrimary,
    serviceInstagram: theme.contentPrimary,
    serviceSpotify: theme.contentPrimary,
    serviceTwitter: theme.contentPrimary,
    systemBlue: theme.contentPrimary,
    systemGreen: theme.contentPrimary,
    systemMagenta: theme.contentPrimary,
    systemRed: theme.contentPrimary,
    systemYellow: theme.contentPrimary,
    white: theme.contentPrimary,
    yellow: theme.contentPrimary,
  };

  return basicColors[color];
};

export const getFilledColors = (color: Color, theme: Theme): string => {
  const filledColors = {
    black: theme.contentPrimaryInvertedStatic,
    blue: theme.contentPrimaryInvertedStatic,
    brand: theme.contentPrimaryInvertedStatic,
    green: theme.contentPrimaryInvertedStatic,
    grey: theme.contentPrimaryInvertedStatic,
    neutral: theme.contentPrimaryInverted,
    purple: theme.contentPrimaryInvertedStatic,
    serviceAmazonMusic: theme.contentPrimaryInvertedStatic,
    serviceAppleMusic: theme.contentPrimaryInvertedStatic,
    serviceFacebook: theme.contentPrimaryInvertedStatic,
    serviceInstagram: theme.contentPrimaryInvertedStatic,
    serviceSpotify: theme.contentPrimaryInvertedStatic,
    serviceTwitter: theme.contentPrimaryInvertedStatic,
    systemBlue: theme.contentPrimaryInvertedStatic,
    systemGreen: theme.contentPrimaryInvertedStatic,
    systemMagenta: theme.contentPrimaryInvertedStatic,
    systemRed: theme.contentPrimaryInvertedStatic,
    systemYellow: theme.contentPrimaryInvertedStatic,
    white: theme.contentPrimaryStatic,
    yellow: theme.contentPrimaryInvertedStatic,
  };

  return filledColors[color];
};

export const getIconColor = (color: IconColor, theme: Theme): string => {
  const iconColors = {
    black: theme.contentPrimary,
    brand: theme.paletteBrand100,
    purple: theme.palettePurple100,
    systemBlue: theme.systemBlue100,
    systemGreen: theme.systemGreen100,
    systemRed: theme.systemRed100,
    systemYellow: theme.systemYellow100,
  };

  return iconColors[color];
};

export const getTextColors = (color: Color, theme: Theme): string => {
  const textColors = {
    black: theme.contentPrimaryStatic,
    blue: theme.paletteBlue100,
    brand: theme.paletteBrand100,
    green: theme.paletteGreen100,
    grey: theme.paletteGrey100,
    neutral: theme.contentPrimary,
    purple: theme.palettePurple100,
    serviceAmazonMusic: theme.serviceAmazonMusic,
    serviceAppleMusic: theme.serviceAppleMusic,
    serviceFacebook: theme.serviceFacebook,
    serviceInstagram: theme.serviceInstagram,
    serviceSpotify: theme.serviceSpotify2, // Always use this color for better accessibility
    serviceTwitter: theme.serviceTwitter,
    systemBlue: theme.systemBlue100,
    systemGreen: theme.systemGreen100,
    systemMagenta: theme.systemMagenta100,
    systemRed: theme.systemRed100,
    systemYellow: theme.systemYellow100,
    white: theme.contentPrimaryInvertedStatic,
    yellow: theme.paletteYellow100,
  };

  return textColors[color];
};

/**
 * Returns the color value of a CSS variable.
 *
 * @param colorCssVariable the `var(--mxm-colorVariable)`
 * @returns the resolved value from the browser. Eg: #131313
 */
export const getResolvedColorVariable = (colorCssVariable: string) => {
  if (
    colorCssVariable.startsWith("var(") &&
    Platform.OS === "web" &&
    !isServer()
  ) {
    const cssVariableName = colorCssVariable.slice(4, -1); // Removes 'var(' from the start and ')' from the end)
    const resolvedCSSValue = getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariableName)
      .trim();

    return resolvedCSSValue !== "" ? resolvedCSSValue : colorCssVariable;
  } else {
    return colorCssVariable;
  }
};
