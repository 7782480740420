import { userAgentIsBot } from "@mxmdev/react-universal-core";
import { GetServerSidePropsContext } from "next";
import { ReactNode } from "react";

import { DEPLOY_ENV } from "../../processEnv";

import { SEOProps } from "./types";

export const CANONICAL_HOME = "https://podcasts.musixmatch.com";

export const endsWithPunctuation: RegExp = /[?.!]$/;

export const HOME_PAGE_TITLE =
  "Musixmatch Podcasts: transcriptions for creators and listeners";

export const getTopicTitle = (title: string): string => {
  return `${title} on Musixmatch Podcasts`;
};

export const getTopicDescription = (title: string): string => {
  return `Find all the latest podcasts talking about ${title}. Discover curated episodes with synced transcriptions about all your favourite topics on Musixmatch Podcasts.`;
};

export const getPublisherTitle = (title: string): string => {
  return `${title} - Podcast Transcripts | Musixmatch`;
};

export const getPublisherDescription = (publisherName: string): string => {
  return `Check out all the podcasts published by ${publisherName}. Musixmatch Podcasts offers rich curated transcriptions and enhanced listening.`;
};

export const getSEOMetadata = ({
  canonical,
  description,
  height,
  image,
  keywords,
  noindex,
  ogUrl,
  schemas,
  title,
  twitterCard,
  width,
}: SEOProps): ReactNode[] => {
  const tags = [];

  if (noindex || DEPLOY_ENV === "pp") {
    tags.push(<meta content="noindex" key="noindex" name="robots" />);
  }

  tags.push(
    <meta content="website" key="og:type" property="og:type" />,
    <meta
      content={twitterCard || "summary"}
      key="twitter:card"
      name="twitter:card"
    />
  );

  if (title) {
    tags.push(
      <title key={title}>{title}</title>,
      <meta content={title} key="title" name="title" />,
      <meta content={title} key="og:title" property="og:title" />,
      <meta content={title} key="twitter:title" name="twitter:title" />
    );
  }

  if (description) {
    tags.push(
      <meta content={description} key="description" name="description" />,
      <meta
        content={description}
        key="og:description"
        property="og:description"
      />,
      <meta
        content={description}
        key="twitter:description"
        name="twitter:description"
      />
    );
  }

  if (image) {
    tags.push(
      <meta content={image} key="og:image" property="og:image" />,
      <meta content={image} key="twitter:image" name="twitter:image" />,
      width && (
        <meta content={width} key="og:image:width" property="og:image:width" />
      ),
      height && (
        <meta
          content={height}
          key="og:image:height"
          property="og:image:height"
        />
      )
    );
  }

  tags.push(
    canonical && <link href={canonical} key="canonical" rel="canonical" />,
    (canonical || ogUrl) && (
      <meta content={ogUrl ?? canonical} key="og:url" property="og:url" />
    )
  );

  if (keywords) {
    tags.push(<meta content={keywords} key="keywords" name="keywords" />);
  }

  return tags;
};

export const getUserAgentProps = (
  ctx: GetServerSidePropsContext
): { isBot: boolean; userAgent: string | undefined } => {
  const userAgent = ctx.req.headers["user-agent"];
  const isBot = userAgent ? userAgentIsBot(userAgent) : false;

  return {
    isBot,
    userAgent,
  };
};
