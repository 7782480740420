import { PropsWithChildren } from "react";
import { View } from "react-native";

import { Input } from "..";
import { useTheme } from "../..";

import styles from "./styles";

const MobileSearchHeader = ({
  onChangeText,
  value,
}: PropsWithChildren<Props>) => {
  const { style } = useTheme(styles, {});

  return (
    <View style={style.searchHeaderWrapper}>
      <Input
        background="secondary"
        clearable
        onChangeText={onChangeText}
        placeholder="Search"
        size="small"
        style={style.mobileSearchHeader}
        value={value}
      />
    </View>
  );
};

export default MobileSearchHeader;

interface Props {
  onChangeText: (text: string) => void;
  value?: string;
}
