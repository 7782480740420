export type UserType = "Apple" | "Facebook" | "Google" | "email";

/**
 * Returns a string representation of the given user type.
 * @param userType - The user type.
 * @returns `undefined` if `userType` is undefined or not mapped.
 */
export const getUserType = (userType?: string): UserType | undefined => {
  switch (userType) {
    case "apl":
      return "Apple";
    case "fb":
      return "Facebook";
    case "g2":
      return "Google";
    case "mxm":
      return "email";
    default:
  }
};
