import useSWR from "swr";

import { InvalidRequestError, NotFoundError } from "../../types/errors";

import { fetcher } from "./fetcher";
import { useSignURL } from "./useSignUrl";

type FetcherData<T> = {
  body: Readonly<T>;
  available?: number;
};

const useMXMSWR = <PayloadType>(
  api: string | undefined,
  params?: Record<string, unknown>,
  options?: {
    shouldRetryOnError?: boolean;
  }
) => {
  const { signedUrl } = useSignURL({ api, params });

  return useSWR<FetcherData<PayloadType>>(signedUrl, fetcher, {
    dedupingInterval: 1000000,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // Never retry on 404.
      if (
        error instanceof NotFoundError ||
        error instanceof InvalidRequestError
      ) {
        return;
      }

      // Only retry up to 5 times.
      if (retryCount >= 5) return;

      // Retry after 5 seconds.
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
};

export default useMXMSWR;
