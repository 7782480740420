import { gql } from "@apollo/client";

export const GQL_ADS_FINDER_INIT = gql`
  mutation MyMutation(
    $analysis_id: String!
    $app_id: String!
    $client_url: String!
    $fromMutation: String!
    $part: String!
    $podcast_itunes_episode_id: String
    $podcast_itunes_id: String
    $podcast_episode_id: String
    $podcast_id: String
    $timestamp: String!
    $usertoken: String
  ) {
    initAdsFinder(
      input: {
        analysis_id: $analysis_id
        app_id: $app_id
        client_url: $client_url
        fromMutation: $fromMutation
        part: $part
        podcast_itunes_episode_id: $podcast_itunes_episode_id
        podcast_itunes_id: $podcast_itunes_id
        podcast_episode_id: $podcast_episode_id
        podcast_id: $podcast_id
        timestamp: $timestamp
        usertoken: $usertoken
      }
    ) {
      timestamp
      message
      status_code
      success
    }
  }
`;

export const GQL_ADS_FINDER_STATUS_UPDATE = gql`
  subscription OnStatusUpdate($timestamp: String!) {
    onUpdateStatus(timestamp: $timestamp) {
      status_code
      success
      message
      timestamp
      status
    }
  }
`;
