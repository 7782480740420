import { useCallback } from "react";

import { usePodcastsSettings } from "../settings";

export const useRequestTranscriptionType = () => {
  const {
    setSettings,
    settings: { requestAmazonTranscription },
  } = usePodcastsSettings();

  const toggleRequestAmazonTranscription = useCallback(() => {
    setSettings(
      "requestAmazonTranscription",
      requestAmazonTranscription === "true" ? "false" : "true"
    );
  }, [requestAmazonTranscription, setSettings]);

  return {
    requestTranscriptionType:
      requestAmazonTranscription === "true"
        ? ("amazon" as const)
        : ("gladia" as const),
    toggleRequestTranscriptionType: toggleRequestAmazonTranscription,
  };
};
