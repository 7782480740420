import {
  SkeletonCircle,
  upperFirst,
  useMediaQuery,
  useTheme,
  global,
  Avatar,
  Button,
  CustomResponseDialogComponent,
  DialogActions,
  DialogCustom,
  DialogText,
  Text,
  useDialogContext,
  IconRepeat,
} from "@mxmdev/react-universal-components";
import {
  CrowdUserProfilePostData,
  CrowdUserProfilePostVars,
  formDataSerializer,
  isResponseOk,
  queries,
  useMutation,
} from "@mxmdev/react-universal-core";
import React, { useState } from "react";
import { View } from "react-native";

import GenericErrorDialog from "../../dialogs/GenericErrorDialog";

import AccountPictureDialogItem from "./AccountPictureDialogItem";
import styles from "./styles";

const COLORS = [
  "redpink",
  "blueyellow",
  "greenblue",
  "yellowgreen",
  "purplered",
];

const IMAGE_SET_LENGTH = 10;
const IMAGE_SET_HALF_LENGTH = IMAGE_SET_LENGTH / 2;

const getRandomColor = () => COLORS[Math.floor(Math.random() * COLORS.length)];

const getRandomImage = () => Math.floor(Math.random() * IMAGE_SET_LENGTH);

type Resolution = "error" | "saved";

const AccountPictureDialog: CustomResponseDialogComponent<Resolution, Props> =
  ({ t, userPicture, ...props }) => {
    const { isSmallScreen } = useMediaQuery();
    const { openDialog } = useDialogContext();
    const { style } = useTheme(styles, { isSmallScreen });
    const [error, setError] = useState(false);

    const userPictureRegex = userPicture?.match(
      /^https?:\/\/s\.mxmcdn\.net\/user\/mxm-avatar-(\d+)-([^/]+)\.png$/
    );

    const [selected, setSelected] = useState<{
      color: string;
      imageIndex: number;
    }>(() =>
      userPictureRegex
        ? {
            color: userPictureRegex[2],
            imageIndex:
              userPictureRegex[1] === "10"
                ? 9
                : parseInt(userPictureRegex[1], 10) - 1,
          }
        : {
            color: getRandomColor(),
            imageIndex: getRandomImage(),
          }
    );

    const [crowdUserProfilePost] = useMutation<
      CrowdUserProfilePostData,
      CrowdUserProfilePostVars
    >(queries.crowdUserProfilePost, { refetchQueries: ["crowdScoreGet"] });

    const [loading, setLoading] = useState<boolean>(false);

    const onPictureSave = () => {
      crowdUserProfilePost({
        variables: {
          body: {
            profile: {
              profile_avatar: `https://s.mxmcdn.net/user/mxm-avatar-${(
                selected.imageIndex + 1
              )
                .toString()
                .padStart(2, "0")}-${selected.color}.png`,
            },
          },
          formDataSerializer,
        },
      })
        .then(({ data }) => {
          if (!isResponseOk(data?.crowdUserProfilePost.header.status_code)) {
            setError(true);
          }
        })
        .catch((e) => {
          console.warn(e);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <DialogCustom
        {...props}
        FooterComponent={
          <DialogActions
            onPressPrimaryButton={() => {
              onPictureSave();

              if (error) {
                openDialog(GenericErrorDialog, {
                  onTryAgain: onPictureSave,
                  stackTop: true,
                });

                return;
              }

              props.onComplete("saved");
            }}
            primaryButtonIsLoading={loading}
            primaryButtonText={t ? upperFirst(t("save")) : "Save"}
            stacked
            style={style.button}
          />
        }
      >
        <DialogText
          style={style.title}
          title={t ? t("account_picture") : "Account Picture"}
          titleAlign="center"
        />
        <>
          <View style={style.container}>
            {loading ? (
              <SkeletonCircle style={style.picture} />
            ) : (
              <Avatar
                hasPlaceholder={false}
                shape="round"
                source={{
                  uri: `https://s.mxmcdn.net/user/mxm-avatar-${(
                    selected.imageIndex + 1
                  )
                    .toString()
                    .padStart(2, "0")}-${selected.color}.png`,
                }}
                style={style.picture}
              />
            )}
            <Button
              Icon={IconRepeat}
              onPress={() => {
                setSelected({
                  color: getRandomColor(),
                  imageIndex: getRandomImage(),
                });
              }}
              shape="pill"
              size="small"
              text={t ? upperFirst(t("random")) : "Random"}
              type="basic"
            />
          </View>
          <Text style={style.text} type="labelDefault">
            {t ? upperFirst(t("color")) : "Color"}
          </Text>
          <View style={style.colorsContainer}>
            {COLORS.map((color, index) => {
              return (
                <AccountPictureDialogItem
                  background={{
                    uri: `https://s.mxmcdn.net/user/mxm-avatar-color-${color}.png`,
                  }}
                  onPress={() => {
                    setSelected(({ imageIndex }) => ({
                      color,
                      imageIndex,
                    }));
                  }}
                  selected={selected.color === color}
                  style={{
                    marginLeft: index !== 0 ? global.spacing * 6 : undefined,
                  }}
                />
              );
            })}
          </View>
          <Text style={style.text} type="labelDefault">
            {t ? upperFirst(t("style")) : "Style"}
          </Text>
          <View style={style.imagesContainer}>
            {[...Array(IMAGE_SET_HALF_LENGTH)].map((_, index) => {
              return (
                <AccountPictureDialogItem
                  background={{
                    uri: `https://s.mxmcdn.net/user/mxm-avatar-${(index + 1)
                      .toString()
                      .padStart(2, "0")}-${selected.color}.png`,
                  }}
                  onPress={() =>
                    setSelected(({ color }) => ({
                      color,
                      imageIndex: index,
                    }))
                  }
                  selected={selected.imageIndex === index}
                  style={{
                    marginLeft: index !== 0 ? global.spacing * 6 : 0,
                  }}
                />
              );
            })}
          </View>
          <View style={style.imagesContainer}>
            {[...Array(IMAGE_SET_HALF_LENGTH)].map((_, index) => {
              return (
                <AccountPictureDialogItem
                  background={{
                    uri: `https://s.mxmcdn.net/user/mxm-avatar-${(
                      index +
                      IMAGE_SET_HALF_LENGTH +
                      1
                    )
                      .toString()
                      .padStart(2, "0")}-${selected.color}.png`,
                  }}
                  onPress={() =>
                    setSelected(({ color }) => ({
                      color,
                      imageIndex: index + IMAGE_SET_HALF_LENGTH,
                    }))
                  }
                  selected={
                    selected.imageIndex === index + IMAGE_SET_HALF_LENGTH
                  }
                  style={[
                    {
                      marginLeft: index !== 0 ? global.spacing * 6 : undefined,
                    },
                    style.imageView,
                  ]}
                />
              );
            })}
          </View>
        </>
      </DialogCustom>
    );
  };

interface Props {
  t?: (key: string, variable?: { name: string }) => string;
  userPicture?: string;
}

export default AccountPictureDialog;
