import { useMemo } from "react";
import { z } from "zod";

import { SyncError } from "../types/errors";
import { useEffectOnceCondition } from "../utils";

import useLogError from "./useLogError";

type TranformationResult<OutputType> = {
  data?: Readonly<OutputType>;
  error?: unknown;
};

const useTransformation = <InputType, OutputType>(
  parser: z.AnyZodObject,
  transform: (input: InputType) => OutputType,
  data?: Readonly<unknown>,
  context?: { name?: string; params?: Record<string, unknown> }
): TranformationResult<OutputType> => {
  const { logError } = useLogError();

  const transformedData = useMemo(() => {
    if (!data) {
      return {};
    }

    try {
      const validatedData = parser.parse(data);
      const transformedData = transform(validatedData as InputType);

      return { data: transformedData };
    } catch (error) {
      return { error };
    }
  }, [data, parser, transform]);

  useEffectOnceCondition(
    () => {
      const error = transformedData.error;

      console.error(
        "[useTransformation] validation error occurred during transformation: ",
        error
      );

      if (error instanceof SyncError) {
        logError(error, {
          extra: {
            validationError: String(error),
          },
        });
      } else {
        logError(
          new Error(
            `transformation error in context '${context?.name ?? "unknown"}'`
          ),
          {
            extra: {
              params: context?.params,
              validationError: String(error),
            },
          }
        );
      }
    },
    () => transformedData.error !== undefined,
    [context?.name, context?.params, logError, transformedData.error]
  );

  return transformedData;
};

export default useTransformation;
