import * as Sentry from "@sentry/nextjs";
import type { ErrorEvent, EventHint, Event } from "@sentry/types";

import { isKnownError } from "../errorTrackingUtils";

export type SentryClient = typeof Sentry;

type NextjsOptions = Parameters<SentryClient["init"]>[0];

const beforeSend = (
  event: ErrorEvent,
  hint: EventHint
): PromiseLike<Event | null> | Event | null => {
  const exception = hint?.originalException;

  if (
    (typeof exception === "string" || exception instanceof Error) &&
    isKnownError(exception)
  ) {
    return null;
  }

  return event;
};

const sentryClient = (() => {
  let client: SentryClient;

  const init = (options: NextjsOptions) => {
    Sentry.init(options);

    return Sentry;
  };

  return {
    get: (options: NextjsOptions): SentryClient => {
      if (client === undefined) {
        client = init(options);
      }

      return client;
    },
  };
})();

export { beforeSend, sentryClient };
