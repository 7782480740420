import { ServerError } from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";

import { handleUnauthorized } from "../../../utils";

import { DATA_HANDLERS, getDataVersion } from "./apolloRestUtils";
import { ApolloClientOptions } from "./types";

const isServerError = (
  error: NetworkError | undefined
): error is ServerError => {
  return error ? "response" in error && "statusCode" in error : false;
};

// Note Error Link callback is not called for internal API errors,
// i.e., when the HTTP response status code is 200,
// but the message.header.status_code is not 200 on the response object.
const errorLink = (options: ApolloClientOptions) =>
  onError(({ graphQLErrors, networkError, operation, response }) => {
    const { logError } = options;

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        logError(
          graphQLError,
          {
            contexts: {
              operation: { ...operation },
              response: { ...response },
            },
            tags: { namespace: "data-fetching" },
          },
          false
        );
      });
    }

    if (networkError) {
      if (isServerError(networkError)) {
        try {
          const dataVersion = getDataVersion(networkError.result);
          const dataHandler = DATA_HANDLERS[dataVersion];
          const dataResponse = dataHandler(
            networkError.response,
            networkError.result
          );

          if (dataResponse.header.status_code === 401) {
            handleUnauthorized(dataResponse.header.hint, options);

            return;
          }
        } catch {
          // Don't do anything, we cannot handle this error automatically
        }
      }

      logError(
        networkError,
        {
          contexts: { operation: { ...operation }, response: { ...response } },
          tags: { namespace: "data-fetching" },
        },
        false
      );
    }
  });

export default errorLink;
