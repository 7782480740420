import { ComponentProps, PropsWithChildren, ReactNode, useMemo } from "react";
import { StyleProp, View, ViewProps, ViewStyle } from "react-native";

import { IconComponent, Text } from "../..";
import global, { ComponentSize } from "../../../styles/global";
import { useTheme } from "../../../styles/themes/themeUtils";
import Animated from "../../Animated";
import Avatar, { Shape } from "../../Avatar/Avatar";
import ListItemDescription from "../ListItemDescription";
import ListItemLabel from "../ListItemLabel";

import styles from "./styles";

const ListItem = ({
  alignVertical = "center",
  children,
  condensed = false,
  containerStyle,
  description,
  descriptionColor,
  descriptionEndEnhancer,
  descriptionNumberOfLines,
  descriptionStartEnhancer,
  divider = true,
  hoverAnimatedValue,
  Icon,
  iconColor,
  iconContainerStyle,
  ignoreHorizontalSpacing = false,
  image,
  imageShape = "default",
  imageSize = "default",
  inverted = false,
  label,
  labelColor,
  labelEndEnhancer,
  labelExtra,
  labelExtraColor,
  labelExtraNumberOfLines,
  labelNumberOfLines,
  labelStartEnhancer,
  labelType,
  pressAnimatedValue,
  shape = "default",
  style: styleProp,
  wrapped = true,
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(
    styles,
    useMemo(() => ({ alignVertical, condensed }), [alignVertical, condensed])
  );

  const DescriptionComponent = !!description && (
    <ListItemDescription
      color={descriptionColor || theme.contentSecondary}
      endEnhancer={descriptionEndEnhancer}
      numberOfLines={descriptionNumberOfLines}
      startEnhancer={descriptionStartEnhancer}
      type={condensed ? "bodySmall" : undefined}
    >
      {description}
    </ListItemDescription>
  );

  const LabelComponent = (
    <ListItemLabel
      color={labelColor || theme.contentPrimary}
      endEnhancer={labelEndEnhancer}
      numberOfLines={labelNumberOfLines}
      startEnhancer={labelStartEnhancer}
      text={label}
      type={condensed ? "labelSmall" : labelType}
    />
  );

  return (
    <View
      style={[
        shape === "round" && style.round,
        style.container,
        ignoreHorizontalSpacing && style.ignoreHorizontalSpacing,
        containerStyle,
      ]}
    >
      {hoverAnimatedValue && (
        <Animated.View
          style={[
            style.overlay,
            style.hoverOverlay,
            shape === "round" && style.round,
            { opacity: hoverAnimatedValue },
          ]}
        />
      )}
      {pressAnimatedValue && (
        <Animated.View
          style={[
            style.overlay,
            style.pressOverlay,
            shape === "round" && style.round,
            { opacity: pressAnimatedValue },
          ]}
        />
      )}
      {image ? (
        <View style={style.leftContent}>
          <Avatar
            accessibilityLabel={typeof label === "string" ? label : ""}
            accessible
            shape={imageShape}
            size={imageSize}
            source={{ uri: image }}
          />
        </View>
      ) : Icon ? (
        <View style={style.leftContent}>
          <View style={[style.iconContainer, iconContainerStyle]}>
            <Icon color={iconColor} />
          </View>
        </View>
      ) : null}
      <View
        style={[
          style.rightContent,
          divider && style.divider,
          image || Icon ? null : { marginLeft: global.spacing * 4 },
          styleProp,
        ]}
      >
        <View style={[style.labelContainer, wrapped && style.wrapped]}>
          {inverted ? DescriptionComponent : LabelComponent}
          {!!labelExtra && (
            <Text
              color={labelExtraColor || theme.contentPrimary}
              numberOfLines={labelExtraNumberOfLines}
              type={condensed ? "bodySmall" : "bodyDefault"}
            >
              {labelExtra}
            </Text>
          )}
          {inverted ? LabelComponent : DescriptionComponent}
        </View>
        {children && <View style={style.actionsContainer}>{children}</View>}
      </View>
    </View>
  );
};

export type AlignVertical = "center" | "top";

interface Props extends ViewProps {
  alignVertical?: AlignVertical;
  condensed?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  description?: ReactNode;
  descriptionColor?: string;
  descriptionEndEnhancer?: ComponentProps<
    typeof ListItemDescription
  >["endEnhancer"];
  descriptionNumberOfLines?: number;
  descriptionStartEnhancer?: ComponentProps<
    typeof ListItemDescription
  >["startEnhancer"];
  divider?: boolean;
  hoverAnimatedValue?: Animated.Value;
  Icon?: IconComponent;
  iconColor?: string;
  iconContainerStyle?: StyleProp<ViewStyle>;
  ignoreHorizontalSpacing?: boolean;
  image?: string;
  imageShape?: Shape;
  imageSize?: ComponentSize;
  inverted?: boolean;
  label: ReactNode;
  labelEndEnhancer?: ComponentProps<typeof ListItemLabel>["endEnhancer"];
  labelStartEnhancer?: ComponentProps<typeof ListItemLabel>["startEnhancer"];
  labelType?: ComponentProps<typeof ListItemLabel>["type"];
  labelColor?: string;
  labelNumberOfLines?: number;
  labelExtra?: string;
  labelExtraColor?: string;
  labelExtraNumberOfLines?: number;
  pressAnimatedValue?: Animated.Value;
  shape?: Shape;
  wrapped?: boolean;
}

export default ListItem;
