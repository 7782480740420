import {
  createResponsiveStyles,
  useResponsiveTheme,
  useShareSectionDialog,
  SwitchView,
  usePodcastAnalytics,
} from "@mxmdev/podcasts-shared-native";
import {
  HeaderBackButton,
  Text,
  viewStyles,
  Button,
  global,
  Pressable,
  Header,
  LogoMusixmatchPodcastsShort,
  LogoMusixmatchPodcastsExtended,
  IconInfo,
  IconSearch,
  IconShare,
} from "@mxmdev/react-universal-components";
import { useState, useEffect, createRef, useRef } from "react";
import { View, TextInput } from "react-native";

import { useEpisode } from "../../data-fetching/hooks";
import {
  navigateBack,
  getBackAction,
  navigate,
  useRouteChange,
  getCurrentRouteParamsIfRoute,
  getCurrentRouteName,
} from "../../navigation/RootNavigation";
import {
  mapSearchParamsToAtom,
  mapAtomToSearchParams,
} from "../../navigation/mappers";
import { RootRoute, BackAction, DEFAULT_LABELS } from "../../navigation/types";
import { SearchAtom } from "../../types";
import { parseIdFromSlugURLComponent } from "../../util/urlUtils";
import SearchBar from "../SearchBar";

import CountrySelector from "./CountrySelector";
import MainHeaderMenu from "./MainHeaderMenu";

// We need to declare the reference globally to be able to call "focus" from other
// components outside the header. This is needed as in Safari, there is no way
// to focus a text input if the event wasn't generated by a click
const searchBarRef = createRef<TextInput>();

export const focusSearchBar = (): void => {
  searchBarRef.current?.focus();
};

const MainHeader = ({
  alternativeLeft,
  fallbackBackAction,
  showAlternativeLeft,
}: Props): JSX.Element => {
  const { isLargeScreen, isSmallScreen, style } = useResponsiveTheme(styles);
  const { logSearchUsed } = usePodcastAnalytics();

  const episodeParams = getCurrentRouteParamsIfRoute("Episode");

  const podcastId = episodeParams?.podcast_id
    ? parseIdFromSlugURLComponent(episodeParams?.podcast_id)
    : undefined;

  const episodeId = episodeParams?.episode_id
    ? parseIdFromSlugURLComponent(episodeParams?.episode_id)
    : undefined;

  const { episode } = useEpisode({ episodeId, podcastId });

  const { openShareSection } = useShareSectionDialog();

  const [isMobileSearchOpen, setMobileSearchOpen] = useState(false);

  const searchQuery = mapSearchParamsToAtom(
    getCurrentRouteParamsIfRoute("Search")
  );

  const previousQueryDebounceRef = useRef<number | undefined>(undefined);
  const onSearchQueryChange = (query: SearchAtom | undefined): void => {
    if (previousQueryDebounceRef.current !== undefined) {
      clearTimeout(previousQueryDebounceRef.current);
    }

    previousQueryDebounceRef.current = setTimeout((): void => {
      navigate("Search", mapAtomToSearchParams(query));
      previousQueryDebounceRef.current = undefined;
    }, 300);
  };

  const backAction = getBackAction() ?? fallbackBackAction;

  useEffect(() => {
    if (!isMobileSearchOpen && searchBarRef.current) {
      searchBarRef.current.clear();
    }
  }, [isMobileSearchOpen]);

  useRouteChange((route: RootRoute, isFirstRoute: boolean): void => {
    // Hide the search bar when the route changes, but only if this is not
    // the first route the user visits
    if (!isFirstRoute && route !== "Search") {
      setMobileSearchOpen(false);
    }

    if (route === "Search") {
      if (searchQuery === undefined) {
        // Focus the search bar when navigating to the zero-state search page
        focusSearchBar();
      }

      setMobileSearchOpen(true);
    }
  });

  const headerLeft = (): JSX.Element => {
    if (backAction) {
      const label =
        backAction.routeParams?._label_override ??
        DEFAULT_LABELS[backAction.route];

      const defaultLeft = (
        <Text numberOfLines={1} type="labelDefault">
          {label ?? ""}
        </Text>
      );

      return (
        <>
          <HeaderBackButton
            onPress={(): void => {
              if (backAction) {
                navigateBack(backAction);
              }

              setMobileSearchOpen(false);
            }}
            style={style.backBtn}
          />

          {!isMobileSearchOpen && (
            <>
              {alternativeLeft ? (
                <SwitchView
                  components={[defaultLeft, alternativeLeft]}
                  index={showAlternativeLeft ? 1 : 0}
                />
              ) : (
                defaultLeft
              )}
            </>
          )}
        </>
      );
    } else {
      return (
        <View style={{ width: isMobileSearchOpen ? 48 : undefined }}>
          <Pressable
            onPress={(): void => navigateBack({ route: "Home" })}
            style={style.logoContainer}
          >
            {isSmallScreen ? (
              <LogoMusixmatchPodcastsShort height={24} />
            ) : (
              <LogoMusixmatchPodcastsExtended height={28} />
            )}
          </Pressable>
        </View>
      );
    }
  };

  const headerCenter = (): JSX.Element => {
    const shouldSearchBarBeVisible =
      getCurrentRouteName() !== "Home" && getCurrentRouteName() !== undefined;

    // Because on safari we cannot focus the input text if it's not already on screen
    // we must "hide" it by move it offscreen.
    // See also: https://stackoverflow.com/questions/12204571/mobile-safari-javascript-focus-method-on-inputfield-only-works-with-click
    const hiddenStyle =
      !shouldSearchBarBeVisible || (!isLargeScreen && !isMobileSearchOpen)
        ? {
            left: -9999,
          }
        : {};

    return (
      <SearchBar
        initialQuery={searchQuery}
        onFocus={logSearchUsed}
        onQueryChange={onSearchQueryChange}
        ref={searchBarRef}
        size={isLargeScreen ? "default" : "small"}
        style={[style.searchBarWrapper, hiddenStyle]}
      />
    );
  };

  const headerRight = (): JSX.Element | undefined => {
    const isHomepage =
      getCurrentRouteName() === "Home" || getCurrentRouteName() === undefined;

    if (isHomepage) {
      return (
        <>
          <MainHeaderMenu style={style.mainHeaderMenu} />
          <CountrySelector style={style.countrySelector} />
          <Button
            Icon={IconInfo}
            size="small"
            style={style.aboutBtn}
            text={isSmallScreen ? undefined : "About"}
            to="https://about.musixmatch.com/podcasts"
            type="basic"
          />
        </>
      );
    }

    return (
      <>
        <MainHeaderMenu style={style.mainHeaderMenu} />

        {!isMobileSearchOpen && (
          <Button
            Icon={IconSearch}
            onPress={(): void => {
              setMobileSearchOpen(true);
              focusSearchBar();
            }}
            size="small"
            type="basic"
          />
        )}

        {episodeId !== undefined && episode && (
          <Button
            Icon={IconShare}
            onPress={(): void => {
              openShareSection({ episode });
            }}
            size="small"
            type="basic"
          />
        )}
      </>
    );
  };

  const centerAreaAdditionalStyle =
    !isLargeScreen && !isMobileSearchOpen && style.centerAreaSearchClosed;

  const leftAreaAdditionalStyle =
    !isLargeScreen && !isMobileSearchOpen && style.leftAreaSearchClosed;

  const renderHeader = (): JSX.Element => (
    <View style={style.container}>
      <View style={[style.leftArea, leftAreaAdditionalStyle]}>
        {headerLeft()}
      </View>
      <View style={[style.centerArea, centerAreaAdditionalStyle]}>
        {headerCenter()}
      </View>
      <View style={style.rightArea}>{headerRight()}</View>
    </View>
  );

  return <Header left={renderHeader} />;
};

const styles = createResponsiveStyles(
  (theme, { isLargeScreen, isSmallScreen }) =>
    viewStyles({
      aboutBtn: {
        marginRight: !isSmallScreen ? global.spacing * 4 : global.spacing,
      },
      backBtn: {
        marginRight: global.spacing * 2,
      },
      centerArea: {
        alignItems: "center",
        flex: isLargeScreen ? 2 : 1,
        flexDirection: "row",
        marginHorizontal: isLargeScreen ? global.spacing * 3 : 0,
      },
      centerAreaSearchClosed: {
        flex: 0,
        flexGrow: 0,
      },
      container: {
        alignItems: "stretch",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
      },
      countrySelector: {
        marginRight: !isSmallScreen ? global.spacing * 4 : global.spacing,
      },
      leftArea: {
        alignItems: "center",
        flex: isLargeScreen ? 1 : undefined,
        flexDirection: "row",
      },
      leftAreaSearchClosed: {
        flex: 1,
      },
      logoContainer: {
        marginLeft: global.spacing * 2,
      },
      mainHeaderMenu: {
        marginLeft: global.spacing * 2,
      },
      rightArea: {
        alignItems: "center",
        flex: isLargeScreen ? 1 : undefined,
        flexDirection: "row-reverse",
      },
      searchBarWrapper: {
        alignItems: "stretch",
        flex: 1,
        flexDirection: "row",
      },
    })
);

interface Props {
  alternativeLeft?: JSX.Element;
  fallbackBackAction?: BackAction<RootRoute>;
  showAlternativeLeft?: boolean;
}

export default MainHeader;
