import { ReactNode, useEffect, useState, PropsWithChildren } from "react";
import {
  Keyboard,
  KeyboardEvent,
  Platform,
  StyleProp,
  View,
  ViewStyle,
} from "react-native";
import { PanGestureHandler } from "react-native-gesture-handler";

import { SafeAreaView } from "../../safe-area";
import { useTheme } from "../../styles/themes/themeUtils";
import Animated from "../Animated";

import { useBottomSheetGestureContext } from "./BottomSheetGestureProvider";
import styles, { bottomSheetPaddingBottom } from "./styles";

const BottomSheet = ({
  bottomSheetRef,
  children,
  fullscreen,
  safeArea = true,
  style: styleProp,
  translateY,
}: PropsWithChildren<Props>) => {
  const defaultOffset: number = bottomSheetPaddingBottom;
  const [keyboardOffset, setKeyboardOffset] = useState<number>(defaultOffset);
  const gestureContext = useBottomSheetGestureContext();

  const { onHandlerStateChange, onSwipeDown } =
    gestureContext?.gestureHandlerProps || {};

  const isPanEnabled = gestureContext?.panEnabled && !fullscreen;
  const { style } = useTheme(styles, {
    fullscreen,
    isHandleVisible: isPanEnabled,
  });

  useEffect(() => {
    const keyboardDidShow = (e: KeyboardEvent) => {
      const keyboardHeight = Platform.select({
        android: defaultOffset,
        default: 0, // Not needed on other platforms
        ios: e.endCoordinates.height + defaultOffset,
      });

      setKeyboardOffset(keyboardHeight);
    };

    const keyboardDidHide = () => {
      setKeyboardOffset(defaultOffset);
    };

    // Temporary workaround to prevent bottomsheet from going
    // under the keyboard on mobile
    Keyboard.dismiss();

    const didShow = Keyboard.addListener("keyboardDidShow", keyboardDidShow);
    const didHide = Keyboard.addListener("keyboardDidHide", keyboardDidHide);

    return () => {
      didShow.remove();
      didHide.remove();
    };
  }, [defaultOffset]);

  return (
    <Animated.View
      pointerEvents="box-none"
      style={[
        style.animatedView,
        {
          transform: [
            {
              translateY,
            },
          ],
        },
      ]}
    >
      <View
        pointerEvents="box-none"
        ref={bottomSheetRef}
        style={style.container}
      >
        <View
          style={[
            style.card,
            styleProp,
            {
              paddingBottom: keyboardOffset,
            },
          ]}
        >
          {isPanEnabled && (
            <PanGestureHandler
              activeOffsetY={5}
              enabled={isPanEnabled}
              failOffsetY={-5}
              onGestureEvent={onSwipeDown?.(true)}
              onHandlerStateChange={onHandlerStateChange}
            >
              <View style={style.handleWrapper}>
                <View style={style.handle} />
              </View>
            </PanGestureHandler>
          )}
          {/* 
          TODO with RNGH v2 we will place the pangesturehandler here
          so that we can also avoid "ScrollView" and "FlatList" bottomsheet wrappers
          and remove those wrappers from Dialog, DialogList, DialogCustom, DatePicker, Select and Menu
          */}
          {fullscreen && safeArea ? (
            <SafeAreaView style={style.safeArea}>{children}</SafeAreaView>
          ) : (
            children
          )}
        </View>
      </View>
    </Animated.View>
  );
};

export interface Props {
  children: ReactNode;
  fullscreen?: boolean;
  style?: StyleProp<ViewStyle>;
  safeArea?: boolean;
  translateY: Animated.Value;
  bottomSheetRef: (node: View | null) => void;
}

export default BottomSheet;
