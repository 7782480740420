import {
  Episode,
  SyncedSectionWithoutTopics,
  UnsyncedSection,
} from "@mxmdev/podcasts-shared";
import {
  useConfiguration,
  usePodcastsPlayerAnalytics,
} from "@mxmdev/podcasts-shared-native";
import { useDebouncedCallback } from "@mxmdev/react-universal-core";
import {
  createContext,
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";

import { TranscriptionState, useTranscription } from "../data-fetching/hooks";
import { push } from "../navigation/RootNavigation";
import { findClosestSyncedSection } from "../util/transcriptionUtils";

import { AudioPlayer, audioPlayer } from "./audio-player";
import { useAudioPlayer } from "./hooks/useAudioPlayer";
import { useReferenceTrackUrl } from "./hooks/useReferenceTrackUrl";
import { useStopwatch } from "./hooks/useStopwatch";

export type PlayerProfile = "default" | "editor";

export type TargetSection = {
  section: UnsyncedSection | SyncedSectionWithoutTopics;
  episode: Episode;
};

export type PlayerContextData = {
  activeEpisodePageId?: string;
  currentTime?: number;
  currentEpisodeIndex?: number;
  duration?: number;
  currentEpisode?: Episode;
  error?: unknown;
  isLoading: boolean;
  isSeeking: boolean;
  isPlaying: boolean;
  keepTranscriptFocused: boolean;
  next: () => Promise<void>;
  playbackRate: number;
  pause: () => Promise<void>;
  play: () => Promise<void>;
  playerProfile: PlayerProfile;
  previous: () => Promise<void>;
  queue: readonly Episode[];
  replaceAudioTrack: (trackUrl: string) => Promise<void>;
  reset: () => Promise<void>;
  seek: (time: number) => Promise<void>;
  seekDelta: (deltaTime: number) => Promise<void>;
  seekToNextSection: () => Promise<void>;
  seekToPreviousSection: () => Promise<void>;
  setActiveEpisodePageId: (id: string | undefined) => void;
  setEpisode: (episode: Episode) => Promise<void>;
  setEpisodes: (episodes: readonly Episode[]) => Promise<void>;
  setKeepTranscriptFocused: (keep: boolean) => void;
  setPlaybackRate: (rate: number) => Promise<void>;
  setPlayerProfile: (profile: PlayerProfile) => void;
  setTargetReferenceSection: (section: TargetSection) => void;
  targetReferenceSection?: TargetSection;
  transcriptionRetry: () => void;
  transcriptionState: TranscriptionState;
};

const defaultPlayerProvider: PlayerContextData = {
  isLoading: false,
  isPlaying: false,
  isSeeking: false,
  keepTranscriptFocused: false,
  next: () => Promise.resolve(),
  pause: () => Promise.resolve(),
  play: () => Promise.resolve(),
  playbackRate: 1,
  playerProfile: "default",
  previous: () => Promise.resolve(),
  queue: [],
  replaceAudioTrack: () => Promise.resolve(),
  reset: () => Promise.resolve(),
  seek: () => Promise.resolve(),
  seekDelta: () => Promise.resolve(),
  seekToNextSection: () => Promise.resolve(),
  seekToPreviousSection: () => Promise.resolve(),
  setActiveEpisodePageId: () => {},
  setEpisode: () => Promise.resolve(),
  setEpisodes: () => Promise.resolve(),
  setKeepTranscriptFocused: (keep: boolean) => {},
  setPlaybackRate: () => Promise.resolve(),
  setPlayerProfile: () => {},
  setTargetReferenceSection: () => {},
  transcriptionRetry: () => {},
  transcriptionState: { status: "idle" },
};

export const PlayerContext = createContext<PlayerContextData>(
  defaultPlayerProvider
);

const EmptyPlayerProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <PlayerContext.Provider value={defaultPlayerProvider}>
      {children}
    </PlayerContext.Provider>
  );
};

const PlayerProvider = ({
  audioPlayer,
  children,
}: PropsWithChildren<{ audioPlayer: AudioPlayer }>) => {
  const [playerProfile, setPlayerProfile] = useState<PlayerProfile>("default");
  const { logPlayEnd, logVelocitySelected } = usePodcastsPlayerAnalytics();
  const logVelocitySelectedDebounced = useDebouncedCallback(
    logVelocitySelected,
    2500
  );
  const {
    pause: stopwatchPause,
    start: stopwatchStart,
    stop: stopwatchStop,
  } = useStopwatch();
  const { enableReferenceTrackFallback } = useConfiguration();

  const logListeningTime = useCallback(
    (episode: Episode): void => {
      const currentEpisodePlayTime = stopwatchStop();

      if (currentEpisodePlayTime > 0 && playerProfile === "default") {
        logPlayEnd(episode, currentEpisodePlayTime / 1000);
      }
    },
    [logPlayEnd, playerProfile, stopwatchStop]
  );

  const { state: audioPlayerState } = useAudioPlayer([
    { listener: stopwatchStart, type: "play" },
    { listener: stopwatchPause, type: "pause" },
    { listener: logListeningTime, type: "stop" },
  ]);

  const [activeEpisodePageId, setActiveEpisodePageId] = useState<
    string | undefined
  >(undefined);
  const [keepTranscriptFocused, setKeepTranscriptFocused] = useState(false);
  const [targetReferenceSection, setTargetReferenceSection] = useState<
    TargetSection | undefined
  >(undefined);

  const currentEpisode =
    audioPlayerState.currentIndex !== undefined
      ? audioPlayerState.playlist.episodes[audioPlayerState.currentIndex]
      : undefined;

  const referenceTrackUrl = useReferenceTrackUrl(currentEpisode);

  const { retry: transcriptionRetry, state: transcriptionState } =
    useTranscription({
      // Don't load the transcription when inside the editor
      episode: playerProfile !== "editor" ? currentEpisode : undefined,
    });

  const setEpisodes = useCallback(
    async (episodes: readonly Episode[]): Promise<void> => {
      await audioPlayer.reset();
      await audioPlayer.addEpisodes(episodes);
      await audioPlayer.loadCurrentEpisode();
    },
    [audioPlayer]
  );

  const setEpisode = useCallback(
    async (episode: Episode): Promise<void> => {
      const episodeIndex = audioPlayerState.playlist.episodes.findIndex(
        (playlistEpisode) => playlistEpisode.id === episode.id
      );

      if (episodeIndex >= 0) {
        await audioPlayer.setIndex(episodeIndex);
      } else {
        await setEpisodes([episode]);
      }
    },
    [audioPlayer, audioPlayerState.playlist.episodes, setEpisodes]
  );

  const replaceAudioTrack = useCallback(
    async (trackUrl: string): Promise<void> => {
      return await audioPlayer.replaceAudioTrack(trackUrl);
    },
    [audioPlayer]
  );

  const seek = useCallback(
    (time: number): Promise<void> => {
      return audioPlayer.seek(time);
    },
    [audioPlayer]
  );

  const seekDelta = useCallback(
    (deltaTime: number): Promise<void> => {
      return audioPlayer.seekDelta(deltaTime);
    },
    [audioPlayer]
  );

  const play = useCallback((): Promise<void> => {
    return audioPlayer.play();
  }, [audioPlayer]);

  const pause = useCallback((): Promise<void> => {
    return audioPlayer.pause();
  }, [audioPlayer]);

  const reset = useCallback((): Promise<void> => {
    return audioPlayer.reset();
  }, [audioPlayer]);

  const setPlaybackRate = useCallback(
    (rate: number): Promise<void> => {
      logVelocitySelectedDebounced(rate);

      return audioPlayer.setPlaybackRate(rate);
    },
    [audioPlayer, logVelocitySelectedDebounced]
  );

  const next = useCallback((): Promise<void> => {
    const nextEpisode =
      audioPlayerState.currentIndex !== undefined
        ? audioPlayerState.playlist.episodes[audioPlayerState.currentIndex + 1]
        : undefined;

    return audioPlayer.next().then(() => {
      if (keepTranscriptFocused && nextEpisode) {
        setTimeout(() => {
          push("Episode", {
            _label_override: nextEpisode.name,
            _scroll_to: "transcription",
            _slug_episode: nextEpisode.slug,
            _slug_podcast: nextEpisode.podcastSlug,
            episode_id: nextEpisode.id,
            podcast_id: nextEpisode.podcastId,
          });
        }, 500);
      }
    });
  }, [
    audioPlayer,
    audioPlayerState.playlist.episodes,
    audioPlayerState.currentIndex,
    keepTranscriptFocused,
  ]);

  const previous = useCallback((): Promise<void> => {
    const prevEpisode =
      audioPlayerState.currentIndex !== undefined
        ? audioPlayerState.playlist.episodes[audioPlayerState.currentIndex - 1]
        : undefined;

    return audioPlayer.previous().then(() => {
      if (keepTranscriptFocused && prevEpisode) {
        setTimeout(() => {
          push("Episode", {
            _label_override: prevEpisode.name,
            _scroll_to: "transcription",
            _slug_episode: prevEpisode.slug,
            _slug_podcast: prevEpisode.podcastSlug,
            episode_id: prevEpisode.id,
            podcast_id: prevEpisode.podcastId,
          });
        }, 500);
      }
    });
  }, [
    audioPlayer,
    audioPlayerState.playlist.episodes,
    audioPlayerState.currentIndex,
    keepTranscriptFocused,
  ]);

  const seekToNextSection = useCallback((): Promise<void> => {
    const currentTime = audioPlayerState.currentTime;

    if (
      transcriptionState.status !== "loaded" ||
      transcriptionState.syncState.status !== "loaded" ||
      currentTime === undefined
    ) {
      return Promise.resolve();
    }

    const nextSection =
      transcriptionState.syncState.transcription.syncedSections.find(
        (section) => section.startTime >= currentTime
      );

    if (!nextSection) {
      return Promise.resolve();
    }

    return audioPlayer.seek(nextSection.startTime + 0.01);
  }, [audioPlayer, transcriptionState, audioPlayerState.currentTime]);

  const seekToPreviousSection = useCallback((): Promise<void> => {
    const currentTime = audioPlayerState.currentTime;

    if (
      transcriptionState.status !== "loaded" ||
      transcriptionState.syncState.status !== "loaded" ||
      currentTime === undefined
    ) {
      return Promise.resolve();
    }

    const currentSectionIndex =
      transcriptionState.syncState.transcription.syncedSections.findIndex(
        (section) =>
          section.endTime >= currentTime && section.startTime <= currentTime
      );

    if (currentSectionIndex <= 0) {
      return Promise.resolve();
    }

    return audioPlayer.seek(
      transcriptionState.syncState.transcription.syncedSections[
        currentSectionIndex - 1
      ].startTime + 0.01
    );
  }, [audioPlayer, transcriptionState, audioPlayerState.currentTime]);

  // SIDE EFFECTS

  useEffect(() => {
    if (
      enableReferenceTrackFallback &&
      transcriptionState.status === "loaded" &&
      transcriptionState.syncState.status === "error" &&
      currentEpisode?.resolvedAudioTrackUrl &&
      referenceTrackUrl &&
      currentEpisode.resolvedAudioTrackUrl !== referenceTrackUrl
    ) {
      replaceAudioTrack(referenceTrackUrl);
    }
  }, [
    currentEpisode?.id,
    currentEpisode?.podcastId,
    currentEpisode?.resolvedAudioTrackUrl,
    enableReferenceTrackFallback,
    referenceTrackUrl,
    replaceAudioTrack,
    transcriptionState.status,
    // @ts-ignore: syncState exists only when status === "loaded"
    transcriptionState?.syncState?.status,
  ]);

  useEffect(() => {
    if (!targetReferenceSection) {
      return;
    }

    // We don't want to automatically start the audio once the sync is completed
    // when the target section is different.
    // This might happen when a user is listening to an episode, but
    // then clicks on the highlight of a search result.
    // In that case, the target section will refer to a different episode.
    if (targetReferenceSection.episode.id !== currentEpisode?.id) {
      return;
    }

    if (!audioPlayerState.canPlay) {
      return;
    }

    if (transcriptionState.status !== "loaded") {
      return;
    }

    if (transcriptionState.syncState.status === "loaded") {
      // Find the corresponding synced section
      const syncedSection =
        targetReferenceSection.section.variant === "unsynced"
          ? findClosestSyncedSection(
              targetReferenceSection.section,
              transcriptionState.syncState.transcription.syncedSections
            )
          : targetReferenceSection.section;

      if (
        audioPlayerState.currentTime !== undefined &&
        !audioPlayerState.isPlaying &&
        audioPlayerState.currentTime >= syncedSection.startTime &&
        audioPlayerState.currentTime <= syncedSection.endTime
      ) {
        play();
      }

      // Seek only if the user isn't already listening to that section
      if (
        audioPlayerState.currentTime === undefined ||
        audioPlayerState.currentTime < syncedSection.startTime ||
        audioPlayerState.currentTime > syncedSection.endTime
      ) {
        seek(syncedSection.startTime).then(() => play());
      }

      setTargetReferenceSection(undefined);
    } else if (transcriptionState.syncState.status === "error") {
      // Fallback behavior when sync fails
      const time =
        targetReferenceSection.section.variant === "unsynced"
          ? targetReferenceSection.section.referenceStartTime
          : targetReferenceSection.section.startTime;

      seek(time).then(() => play());
      setTargetReferenceSection(undefined);
    }
  }, [
    currentEpisode?.id,
    targetReferenceSection,
    transcriptionState,
    audioPlayerState.currentTime,
    play,
    seek,
    audioPlayerState.isPlaying,
    audioPlayerState.canPlay,
  ]);

  return (
    <PlayerContext.Provider
      value={{
        activeEpisodePageId,
        currentEpisode,
        currentEpisodeIndex: audioPlayerState.currentIndex,
        currentTime: audioPlayerState.currentTime,
        duration: audioPlayerState.duration,
        error: audioPlayerState.error,
        isLoading: audioPlayerState.isLoading,
        isPlaying: audioPlayerState.isPlaying,
        isSeeking: audioPlayerState.isSeeking,
        keepTranscriptFocused,
        next,
        pause,
        play,
        playbackRate: audioPlayerState.playbackRate,
        playerProfile,
        previous,
        queue: audioPlayerState.playlist.episodes,
        replaceAudioTrack,
        reset,
        seek,
        seekDelta,
        seekToNextSection,
        seekToPreviousSection,
        setActiveEpisodePageId,
        setEpisode,
        setEpisodes,
        setKeepTranscriptFocused,
        setPlaybackRate,
        setPlayerProfile,
        setTargetReferenceSection,
        targetReferenceSection,
        transcriptionRetry,
        transcriptionState,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

const PlayerProviderWrapper = ({ children }: PropsWithChildren<unknown>) => {
  return audioPlayer ? (
    <PlayerProvider audioPlayer={audioPlayer}>{children}</PlayerProvider>
  ) : (
    <EmptyPlayerProvider>{children}</EmptyPlayerProvider>
  );
};

export default PlayerProviderWrapper;
