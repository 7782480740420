import { encodeURL } from "../../utils";
import { MODE_SHARED } from "../constants";

const getSignOutURL = (baseURL: string, appId: string, origin: string) => {
  const url = new URL(baseURL);

  url.searchParams.append("mode", MODE_SHARED);
  url.searchParams.append("app_id", appId);
  url.searchParams.append("origin", encodeURL(origin));

  return url;
};

export default getSignOutURL;
