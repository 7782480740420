import {
  capitalize,
  clipString,
  useTheme,
  createStyles,
} from "@mxmdev/podcasts-shared-native";
import {
  viewStyles,
  global,
  IconSearch,
  IconClose,
} from "@mxmdev/react-universal-components";
import {
  forwardRef,
  useState,
  useEffect,
  useRef,
  MutableRefObject,
} from "react";
import {
  View,
  TextInput,
  Platform,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from "react-native";

import { SearchAtom } from "../../types";
import { useCombinedRefs } from "../../util/refUtils";
import Button from "../Button";

const SearchBar = (
  {
    initialQuery,
    onBlur,
    onFocus,
    onKeyPress,
    onQueryChange,
    size = "default",
    withSuggestions,
  }: Props,
  ref: MutableRefObject<TextInput>
): JSX.Element => {
  const { style, theme } = useTheme(styles);

  const innerTextRef = useRef(null);
  const combinedTextRef = useCombinedRefs(ref, innerTextRef);

  const [query, setQuery] = useState<SearchAtom | undefined>(initialQuery);

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  const handleQueryChange = (query: SearchAtom | undefined): void => {
    setQuery(query);
    onQueryChange(query);
  };

  const handleTopicChipPress = (): void => {
    if (query?.text) {
      setQuery({
        text: query?.text,
        type: "raw",
      });

      combinedTextRef.current?.focus();
    }
  };

  const handleKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ): void => {
    if (event.nativeEvent.key === "Backspace" && query?.type === "topic") {
      event.preventDefault();

      setQuery({
        text: query?.text,
        type: "raw",
      });
    }

    onKeyPress?.(event);
  };

  const borderStyle: ViewStyle = withSuggestions
    ? {
        borderTopLeftRadius: global.radius.default,
        borderTopRightRadius: global.radius.default,
      }
    : {
        borderRadius: global.radius.default,
      };

  const shouldShowPlaceholder = (query && query.text.length === 0) || !query;

  return (
    <View style={[style.container, borderStyle]}>
      <IconSearch
        color={theme.contentSecondary}
        size={size === "default" ? 24 : 16}
        style={style.icon}
      />
      <View style={style.queryContainer}>
        {query?.type === "topic" && (
          <View style={style.topicSection}>
            <Button
              color="brand"
              minTouchTarget={false}
              onPress={handleTopicChipPress}
              size="extraSmall"
              text={clipString(capitalize(query.text), 25)}
              type="filled"
            />
          </View>
        )}
        <TextInput
          onBlur={onBlur}
          onChangeText={(text: string): void => {
            handleQueryChange({
              text,
              type: "raw",
            });
          }}
          onFocus={onFocus}
          onKeyPress={handleKeyPress}
          placeholder={
            shouldShowPlaceholder ? "Search by people, things & places" : ""
          }
          ref={combinedTextRef}
          style={style.input}
          value={query?.type === "raw" ? query?.text ?? "" : ""}
        />
      </View>
      {query !== undefined && (
        <View style={style.clearSection}>
          <Button
            Icon={IconClose}
            minTouchTarget={false}
            onPress={(): void => handleQueryChange(undefined)}
            size="small"
            type="basic"
          />
        </View>
      )}
    </View>
  );
};

const styles = createStyles((theme) =>
  viewStyles({
    clearSection: {
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      alignItems: "stretch",
      backgroundColor: theme.backgroundSecondary,
      flex: 1,
      flexDirection: "row",
    },
    icon: {
      alignSelf: "center",
      marginHorizontal: global.spacing * 3,
    },
    input: {
      flex: 1,
      ...Platform.select({
        web: {
          outlineStyle: "none", // Remove outline on focus (web)
        },
      }),
    },
    queryContainer: {
      alignSelf: "stretch",
      flex: 1,
      flexDirection: "row",
    },
    topicSection: {
      alignItems: "center",
      flexDirection: "row",
      marginRight: global.spacing * 2,
    },
  })
);

interface Props {
  initialQuery?: SearchAtom;
  onBlur?: () => void;
  onFocus?: () => void;
  onQueryChange: (query: SearchAtom | undefined) => void;
  onKeyPress?: (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => void;
  withSuggestions?: boolean;
  size?: "default" | "small";
}

export default forwardRef(SearchBar);
