import { Platform } from "react-native";

import { global } from "../..";
import { viewStyles } from "../../styles/styleUtils";
import { Theme } from "../../styles/themes";

const styles = (theme: Theme, { isSmallScreen }: Options) => {
  const bottomElementsHeight = 48;
  const topElementsHeight = isSmallScreen ? 48 : 60;
  const spacing = isSmallScreen ? global.spacing * 4 : global.spacing * 6;

  const distanceFromBottom = spacing + bottomElementsHeight;
  const distanceFromTop = spacing + topElementsHeight;
  const distanceFromStart = spacing;
  const distanceFromEnd = spacing;

  return {
    ...viewStyles({
      customActionButton: { marginLeft: "auto" },
      startEnhancer: { marginLeft: global.spacing * 4 },
      toast: {
        alignItems: "center",
        backgroundColor: theme.backgroundPrimaryInverted,
        borderRadius: global.radius.default,
        flexDirection: "row",
        marginBottom: distanceFromBottom,
        marginLeft: distanceFromStart,
        marginRight: distanceFromEnd,
        marginTop: distanceFromTop,
        maxWidth: 450,
        position: Platform.OS === "web" ? ("fixed" as any) : "absolute",
      },
      toastText: {
        marginVertical: global.spacing * 4,
        paddingHorizontal: global.spacing * 4,
      },
    }),
  };
};

export default styles;

interface Options {
  isSmallScreen: boolean;
}
