import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import dynamic from "next/dynamic";
import { createContext, useCallback, useState, PropsWithChildren } from "react";

const ApolloLazy = dynamic(() => import("./ApolloLazy"));

export type ApolloContextData = {
  activateApollo: () => void;
  apolloClient: ApolloClient<NormalizedCacheObject> | null;
};

const apolloContextDefaultValue: ApolloContextData = {
  activateApollo: () => {
    throw new Error("Unimplemented");
  },
  apolloClient: null,
};

export const ApolloContext = createContext<ApolloContextData>(
  apolloContextDefaultValue
);

const ApolloProvider = ({
  apiKey,
  children,
  endpoint,
}: PropsWithChildren<Props>) => {
  const [apolloActive, setApolloActive] = useState(false);
  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null);

  const activateApollo = useCallback(() => {
    setApolloActive(true);
  }, []);

  const handleApolloReady = useCallback((apolloClient) => {
    setApolloClient(apolloClient);
  }, []);

  return (
    <ApolloContext.Provider value={{ activateApollo, apolloClient }}>
      {apolloActive && (
        <ApolloLazy
          apiKey={apiKey}
          endpoint={endpoint}
          onApolloReady={handleApolloReady}
        />
      )}

      {children}
    </ApolloContext.Provider>
  );
};

interface Props {
  apiKey: string;
  endpoint: string;
}

export default ApolloProvider;
