import { MutableRefObject, useEffect, useRef } from "react";

export const useCombinedRefs = <T>(
  ...refs: (MutableRefObject<T | null> | ((ref: T | null) => void))[]
): MutableRefObject<T | null> => {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};
