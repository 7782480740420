import { DeviceLocaleInfo, _countryNames } from ".";

// Extracted the relevant logic to easily test it.
export const _getLocaleInfoFromDeviceImpl = (
  locale: string
): DeviceLocaleInfo | undefined => {
  const langAndCountry = /^([a-zA-Z]{2})-([a-zA-Z]+)$/.exec(locale);
  const langOnly = /^([a-zA-Z]{2})$/.exec(locale);

  if (langAndCountry) {
    const country = langAndCountry[2].toUpperCase();
    const language = langAndCountry[1].toLowerCase();
    const countryName = getCountryName(country);

    if (!countryName) {
      return undefined;
    }

    return {
      country: country.toLowerCase(),
      countryName,
      language,
    };
  }

  if (langOnly) {
    const language = langOnly[1].toUpperCase();
    const countryName = getCountryName(language);

    return {
      country: countryName ? language.toLowerCase() : undefined,
      countryName,
      language: language.toLowerCase(),
    };
  }

  return undefined;
};

const getCountryName = (countryCode: string): string | undefined =>
  _countryNames[countryCode];
