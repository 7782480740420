import { useCallback } from "react";

import useLocalValidatedUpdate from "../../context/persistence/useLocalValidatedUpdate";
import { LocaleInfo } from "../../locale/types";

import { schema, reader, writer } from "./schema";

import { USER_PREFERRED_LOCALE_KEY } from ".";

const useSavePreferredLocale = (): {
  save: (locale: LocaleInfo) => Promise<void>;
} => {
  const { update } = useLocalValidatedUpdate(
    USER_PREFERRED_LOCALE_KEY,
    schema,
    reader,
    writer
  );

  const save = useCallback(
    async (locale: LocaleInfo) => {
      return update((): LocaleInfo => {
        return locale;
      });
    },
    [update]
  );

  return {
    save,
  };
};

export default useSavePreferredLocale;
