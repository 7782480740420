const limitTitleLength = (
  title: string,
  { extraLength, maxLength }: { extraLength: number; maxLength: number }
): string => {
  if (title.length <= maxLength) {
    return title;
  }

  const words = title.split("-");

  let limitedTitle = "";

  const addWord = (word: string) => {
    const hyphenPrefix = limitedTitle.length > 0 ? "-" : "";

    limitedTitle += hyphenPrefix + word;
  };

  for (const word of words) {
    const alreadyAddedAWord = limitedTitle.length !== 0;
    const nextWordSlugLength =
      limitedTitle.length + word.length + (alreadyAddedAWord ? 1 : 0);

    if (nextWordSlugLength <= maxLength + extraLength) {
      addWord(word);

      // If we used the extraLength, we break the loop
      if (nextWordSlugLength + 1 >= maxLength) {
        break;
      }
    } else if (!alreadyAddedAWord) {
      // If we haven't added a word yet, we truncate the first word
      addWord(word.slice(0, maxLength));
      break;
    } else {
      break;
    }
  }

  return limitedTitle;
};

const removeMultipleLines = (title: string) => {
  return title.replace(/\r\n|\n|\r/gm, " ");
};

const stripHyphens = (title: string): string => {
  const withoutConsecutiveHyphens = title.replace(/-{2,}/g, "-");

  return withoutConsecutiveHyphens.replace(/^-|-$/g, "");
};

const removeUnwantedCharacters = (title: string): string => {
  const charsToRemoveRegex =
    /!|\?|#|\*|%|\(|\)|\[|\]|\+|'|"|,|\.|\/|:|;|=|<|>|&|@|\\|\^|_|`|’|{|}|\||~|“|”|‒|–|‑|－/g;

  return title.replace(charsToRemoveRegex, "");
};

export type CreateSlugOptions = {
  extraLength: number;
  maxLength: number;
};

const createSlugDefaultOptions: CreateSlugOptions = {
  extraLength: 10,
  maxLength: 50,
};

export const createSlug = (
  title: string,
  { extraLength, maxLength } = createSlugDefaultOptions
) => {
  const withoutMultipleLines = removeMultipleLines(title);
  const lowercased = withoutMultipleLines.toLowerCase();
  const withoutUnwantedChars = removeUnwantedCharacters(lowercased);
  const withoutHyphens = withoutUnwantedChars.replaceAll("-", " ");
  const hyphened = withoutHyphens.replace(/[\s]+/g, "-");
  const limited = limitTitleLength(hyphened, { extraLength, maxLength });
  const strippedHyphens = stripHyphens(limited);

  return encodeURIComponent(strippedHyphens);
};
