import {
  viewStyles,
  useTheme,
  Theme,
} from "@mxmdev/react-universal-components";
import { useEffect, useRef, useState } from "react";
import { View, ViewStyle, StyleProp } from "react-native";

const DEFAULT_ANIMATION_DURATION = 100;

export const SwitchView = ({
  animationDuration = DEFAULT_ANIMATION_DURATION,
  components,
  index,
  style: styleProp,
}: Props): JSX.Element => {
  const { style } = useTheme(styles, { animationDuration });

  // This represent the component's index that is currently visible on the screen
  // and accounts for the animation
  const [activeIndex, setActiveIndex] = useState(index);
  const direction = useRef(1);
  const isFirstAppearance = useRef(true);
  const animationTimer = useRef<number | undefined>(undefined);
  const previousContent = useRef<JSX.Element | undefined>(undefined);

  useEffect(() => {
    if (isFirstAppearance.current) {
      return;
    }

    if (animationTimer.current) {
      clearTimeout(animationTimer.current);
    }

    direction.current = activeIndex > index ? 1 : -1;

    // @ts-ignore
    animationTimer.current = setTimeout((): void => {
      setActiveIndex(index);
    }, animationDuration);

    return (): void => {
      clearTimeout(animationTimer.current);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [index]);

  useEffect(() => {
    isFirstAppearance.current = false;
  }, [activeIndex]);

  const component =
    activeIndex !== index ? previousContent.current : components[activeIndex];

  useEffect(() => {
    previousContent.current = components[activeIndex];
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [component]);

  const animationStyle = ((): ViewStyle | undefined => {
    if (isFirstAppearance.current) {
      return undefined;
    } else if (direction.current < 0) {
      if (activeIndex === index) {
        return style.fadeInUp;
      } else {
        return style.fadeOutUp;
      }
    } else {
      if (activeIndex === index) {
        return style.fadeInDown;
      } else {
        return style.fadeOutDown;
      }
    }
  })();

  return (
    <>
      <View style={[style.container, styleProp, animationStyle]}>
        {component}
      </View>
    </>
  );
};

const styles = (
  theme: Theme,
  {
    animationDuration,
    direction,
  }: { animationDuration: number; direction: number }
): {
  container: ViewStyle;
  fadeInUp: ViewStyle;
  fadeOutUp: ViewStyle;
  fadeInDown: ViewStyle;
  fadeOutDown: ViewStyle;
} =>
  viewStyles({
    container: {
      alignItems: "stretch",
      flex: 1,
    },
    fadeInDown: {
      // @ts-ignore
      animationDuration: `${animationDuration}ms`,
      animationFillMode: "both",
      animationIterationCount: 1,
      animationKeyframes: [
        {
          from: {
            opacity: 0,
            transform: [{ translateY: -10 }],
          },
          to: { opacity: 1, transform: [{ translateY: 0 }] },
        },
      ],
    },
    fadeInUp: {
      // @ts-ignore
      animationDuration: `${animationDuration}ms`,
      animationFillMode: "both",
      animationIterationCount: 1,
      animationKeyframes: [
        {
          from: {
            opacity: 0,
            transform: [{ translateY: 10 }],
          },
          to: { opacity: 1, transform: [{ translateY: 0 }] },
        },
      ],
    },
    fadeOutDown: {
      // @ts-ignore
      animationDuration: `${animationDuration}ms`,
      animationFillMode: "both",
      animationIterationCount: 1,
      animationKeyframes: [
        {
          from: { opacity: 1, transform: [{ translateY: 0 }] },
          to: {
            opacity: 0,
            transform: [{ translateY: -10 }],
          },
        },
      ],
    },
    fadeOutUp: {
      // @ts-ignore
      animationDuration: `${animationDuration}ms`,
      animationFillMode: "both",
      animationIterationCount: 1,
      animationKeyframes: [
        {
          from: { opacity: 1, transform: [{ translateY: 0 }] },
          to: {
            opacity: 0,
            transform: [{ translateY: 10 }],
          },
        },
      ],
    },
  });

type Props = {
  animationDuration?: number;
  index: number;
  components: JSX.Element[];
  style?: StyleProp<ViewStyle>;
};
