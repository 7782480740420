import {
  getEpisodeApiParams,
  nlpBaseGetSchema,
  TranscriptionStatus,
  UnsyncedTranscription,
  unsyncedTranscriptionGetTransformer,
} from "@mxmdev/podcasts-shared";
import {
  useValidatedFetcher,
  useValidatedQuery,
  ApiResponse,
} from "@mxmdev/podcasts-shared-native";

import { useSSRData } from "../useSSRData";

type Params = {
  episodeId: string | undefined;
  podcastId: string | undefined;
  transcriptionStatus: TranscriptionStatus | undefined;
};
const API_ENDPOINT = "podcast.episode.nlp.base.get";

const getAPIParams = (
  params?: Omit<Params, "transcriptionStatus">
): Record<string, unknown> => ({
  ...(params?.episodeId &&
    params?.podcastId &&
    getEpisodeApiParams(params.podcastId, params.episodeId)),
});

const useUnsyncedTranscription = ({
  episodeId,
  podcastId,
  transcriptionStatus,
}: Params): ApiResponse<UnsyncedTranscription, "transcription"> => {
  const isEpisodeTranscribed = transcriptionStatus !== "not_found";

  const ssrTranscription = useSSRData(
    episodeId,
    "episode",
    "unsyncedTranscription"
  );

  const { data, error, isLoading, retry } = useValidatedQuery(
    episodeId && podcastId && !ssrTranscription && isEpisodeTranscribed
      ? API_ENDPOINT
      : undefined,
    getAPIParams({
      episodeId,
      podcastId,
    }),
    nlpBaseGetSchema,
    unsyncedTranscriptionGetTransformer
  );

  return {
    error,
    isLoading: ssrTranscription || !isEpisodeTranscribed ? false : isLoading,
    retry,
    transcription: ssrTranscription ? ssrTranscription : data,
  };
};

export const useUnsyncedTranscriptionFetcher = (): {
  fetchUnsyncedTranscription: (
    episodeId: string,
    podcastId: string
  ) => Promise<UnsyncedTranscription | undefined>;
} => {
  const { fetcher } = useValidatedFetcher(
    API_ENDPOINT,
    getAPIParams(),
    nlpBaseGetSchema,
    unsyncedTranscriptionGetTransformer
  );

  const scopedFetcher = (
    episodeId: string,
    podcastId: string
  ): Promise<UnsyncedTranscription | undefined> => {
    const params = getEpisodeApiParams(podcastId, episodeId);

    return fetcher(params);
  };

  return {
    fetchUnsyncedTranscription: scopedFetcher,
  };
};

export default useUnsyncedTranscription;
