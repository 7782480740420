import { forwardRef, FunctionComponent, Ref, PropsWithChildren } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { IconClose } from "..";
import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import Button from "../Button";

import styles from "./styles";

const DialogCloseButton: FunctionComponent<PropsWithChildren<Props>> =
  forwardRef(({ onPress, style: styleProp }, ref) => {
    const { isSmallScreen } = useMediaQuery();
    const { style } = useTheme(styles, { isSmallScreen });

    return (
      <View ref={ref} style={[style.closeButtonContainer, styleProp]}>
        <Button
          Icon={IconClose}
          onPress={onPress}
          shape="round"
          size="small"
          type="basic"
        />
      </View>
    );
  });

interface Props {
  onPress: () => void;
  ref?: Ref<View>;
  style?: StyleProp<ViewStyle>;
}

export default DialogCloseButton;
