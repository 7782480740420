import AsyncStorageFactory from "@react-native-community/async-storage";
import WebStorage from "@react-native-community/async-storage-backend-web";

import { Model } from "./storageUtils";

// @ts-ignore
// It seems we can't pass "idb" to WebStorage according to its types, but
// when porting the types we noticed that it was used.
// We don't want to break anything, so we keep it 💥
const webStorage = new WebStorage("idb");

const storage = AsyncStorageFactory.create<Model>(webStorage);

export { storage };
