import { ComponentProps } from "react";
import { FlexStyle, ViewStyle } from "react-native";

import { Theme, viewStyles } from "../../../styles";
import global from "../../../styles/global";

import ListItem, { AlignVertical } from "./ListItem";

const styles = (
  theme: Theme,
  {
    alignVertical = "center",
    condensed = false,
  }: Pick<ComponentProps<typeof ListItem>, "alignVertical" | "condensed">
) => {
  const sideContentVerticalAlign: Record<
    AlignVertical,
    ViewStyle["justifyContent"] & ViewStyle["alignItems"]
  > = { center: "center", top: "flex-start" };

  const leftContentPadding: Record<
    AlignVertical,
    FlexStyle["paddingVertical"]
  > = { center: global.spacing * 2, top: global.spacing * 5 };

  const actionsContainerPadding: Record<
    AlignVertical,
    FlexStyle["paddingTop"]
  > = { center: undefined, top: global.spacing * 5 };

  return viewStyles({
    actionsContainer: {
      alignItems: "center",
      flexDirection: "row",
      marginLeft: global.spacing * 4,
      paddingTop: actionsContainerPadding[alignVertical],
    },
    container: { flexDirection: "row" },
    divider: {
      borderBottomWidth: 1,
      borderColor: theme.backgroundSecondary,
    },
    hoverOverlay: { backgroundColor: theme.backgroundHover },
    iconContainer: {
      alignItems: "center",
      justifyContent: "center",
    },
    ignoreHorizontalSpacing: { marginHorizontal: -global.spacing * 4 },
    labelContainer: {
      flexGrow: 1,
      paddingVertical: global.spacing * (condensed ? 3 : 5),
    },
    leftContent: {
      alignItems: "center",
      justifyContent: sideContentVerticalAlign[alignVertical],
      minWidth: 64,
      paddingHorizontal: global.spacing * 4,
      paddingVertical: leftContentPadding[alignVertical],
    },
    noDataMessage: { marginBottom: global.spacing * 2 },
    overlay: {
      bottom: 0,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    pagination: { marginTop: global.spacing * 6 },
    pressOverlay: { backgroundColor: theme.backgroundPress },
    rightContent: {
      alignItems: sideContentVerticalAlign[alignVertical],
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      minHeight: condensed ? 40 : 60,
      paddingRight: global.spacing * 4,
    },
    round: { borderRadius: global.radius.large },
    wrapped: {
      width: 0, // NOTE: this prevents text overflow
    },
  });
};

export default styles;
