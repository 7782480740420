import { viewStyles, imageStyles } from "../../styles/";
import {
  getBasicColors,
  getFilledColors,
  getTextColors,
} from "../../styles/colorUtils";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";

import { Color, Shape, Size, Type } from "./Button";

const shapes = {
  default: global.radius.default,
  pill: global.radius.large,
  round: null,
};

const sizes = {
  default: global.size.default,
  extraSmall: global.size.extraSmall,
  large: global.size.large,
  small: global.size.small,
};

interface Props {
  color: Color;
  disabled: boolean;
  dotColor: string;
  minTouchTarget: boolean;
  selected: boolean;
  shape: Shape;
  size: Size;
  text?: string;
  type: Type;
}

const styles = (
  theme: Theme,
  {
    color,
    disabled,
    dotColor,
    minTouchTarget,
    selected,
    shape,
    size,
    text,
    type,
  }: Props
) => {
  const basicBackgrounds: Record<Color, string> = {
    black: theme.backgroundSecondary,
    blue: theme.backgroundSecondary,
    brand: theme.backgroundSecondary,
    green: theme.backgroundSecondary,
    grey: theme.backgroundSecondary,
    neutral: theme.backgroundSecondary,
    purple: theme.backgroundSecondary,
    serviceAmazonMusic: theme.backgroundSecondary,
    serviceAppleMusic: theme.backgroundSecondary,
    serviceFacebook: theme.backgroundSecondary,
    serviceInstagram: theme.backgroundSecondary,
    serviceSpotify: theme.backgroundSecondary,
    serviceTwitter: theme.backgroundSecondary,
    systemBlue: theme.backgroundSecondary,
    systemGreen: theme.backgroundSecondary,
    systemMagenta: theme.backgroundSecondary,
    systemRed: theme.backgroundSecondary,
    systemYellow: theme.backgroundSecondary,
    white: theme.backgroundSecondary,
    yellow: theme.backgroundSecondary,
  };
  const dotBorderWidth = 2;
  const dotSizes = {
    // Add this because the border is rendered inside the dot.
    default: 8 + dotBorderWidth * 2,
    extraSmall: 4 + dotBorderWidth * 2,
    large: 8 + dotBorderWidth * 2,
    small: 6 + dotBorderWidth * 2,
  };
  const iconMargin = {
    default: global.spacing * 2,
    extraSmall: global.spacing,
    large: global.spacing * 2,
    small: global.spacing * 2,
  };
  const filledBackgrounds = {
    black: theme.contentPrimaryStatic,
    blue: theme.paletteBlue100,
    brand: theme.paletteBrand100,
    green: theme.paletteGreen100,
    grey: theme.paletteGrey100,
    neutral: theme.backgroundPrimaryInverted,
    purple: theme.palettePurple100,
    serviceAmazonMusic: theme.serviceAmazonMusic,
    serviceAppleMusic: theme.serviceAppleMusic,
    serviceFacebook: theme.serviceFacebook,
    serviceInstagram: theme.serviceInstagram,
    serviceSpotify: theme.serviceSpotify2, // Always use this color for better accessibility
    serviceTwitter: theme.serviceTwitter,
    systemBlue: theme.systemBlue100,
    systemGreen: theme.systemGreen100,
    systemMagenta: theme.systemMagenta100,
    systemRed: theme.systemRed100,
    systemYellow: theme.systemYellow100,
    white: theme.contentPrimaryInvertedStatic,
    yellow: theme.paletteYellow100,
  };
  const hoverBackgrounds = {
    basic: theme.backgroundHover,
    filled: theme.backgroundHoverInverted,
    text: theme.backgroundHover,
  };
  const paddingValues = {
    default: global.spacing * 6,
    extraSmall: global.spacing * 3,
    large: global.spacing * 6,
    small: global.spacing * 4,
  };
  const pressBackgrounds = {
    basic: theme.backgroundPress,
    filled: theme.backgroundPressInverted,
    text: theme.backgroundPress,
  };
  const backgroundColors = {
    basic: basicBackgrounds[color],
    filled: filledBackgrounds[color],
    text: undefined,
  };
  const dotBorderColors = {
    // These borders must have the same color defined
    // for the background of the button (`backgroundColors`).
    // If it doesn't exist (e.g. "text" type) we apply a generic
    // primary background.
    basic: basicBackgrounds[color],
    filled: filledBackgrounds[color],
    text: theme.backgroundPrimary,
  };
  const selectedBackgroundColors = {
    basic: filledBackgrounds[color],
    filled: filledBackgrounds[color],
    text: basicBackgrounds[color],
  };
  const borderRadius =
    // NOTE: button height is used to have rounded shape
    shape === "round" ? sizes[size] : shapes[shape];
  const getBackground = (
    color: string,
    disabled: boolean,
    selected: boolean,
    theme: Theme,
    type: Type
  ) => {
    if (disabled && type !== "text") {
      return theme.backgroundSecondary;
    }

    if (selected) {
      return selectedBackgroundColors[type];
    }

    return backgroundColors[type];
  };
  const getDotBorderColors = (
    disabled: boolean,
    selected: boolean,
    theme: Theme,
    type: Type
  ) => {
    if (disabled) {
      return theme.backgroundSecondary;
    }

    if (selected) {
      return selectedBackgroundColors[type];
    }

    return dotBorderColors[type];
  };
  const getHoverBackground = (color: Color, type: Type) => {
    // The hovered background color for black and white
    // buttons doesn't change in relation to their type
    if (type === "filled") {
      if (color === "black") {
        return theme.backgroundPressInvertedStatic;
      }

      if (color === "white") {
        return theme.backgroundHoverStatic;
      }
    }

    if (type === "text") {
      if (color === "black") {
        return theme.backgroundHoverStatic;
      }

      if (color === "white") {
        return theme.backgroundHoverInvertedStatic;
      }
    }

    return hoverBackgrounds[type];
  };
  const getPressBackground = (color: Color, type: Type) => {
    // The pressed background color for black and white
    // buttons doesn't change in relation to their type
    if (type === "filled") {
      if (color === "black") {
        return theme.backgroundPressInvertedStatic;
      }

      if (color === "white") {
        return theme.backgroundPressStatic;
      }
    }

    if (type === "text") {
      if (color === "black") {
        return theme.backgroundPressStatic;
      }

      if (color === "white") {
        return theme.backgroundPressInvertedStatic;
      }
    }

    return pressBackgrounds[type];
  };

  return {
    ...viewStyles({
      activityIndicator: {
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
      },
      additionalTextTop: {
        flexDirection: "column-reverse",
      },
      container: {
        alignItems: "center",
        backgroundColor: getBackground(color, disabled, selected, theme, type),
        borderRadius,
        flexDirection: "row",
        height: sizes[size],
        justifyContent: "center",
        // Set horizontal touch target
        // NOTE:
        // our assumption here is that `text` strings make the Button longer
        // than the minimum touch target value for most cases. In the future
        // we could make this feature more accurate by calculating the
        // component width dynamically regardless of its content.
        marginHorizontal:
          minTouchTarget && !text && sizes[size] < global.minimumTouchTarget
            ? (global.minimumTouchTarget - sizes[size]) / 2
            : 0,
        // Set vertical touch target
        marginVertical:
          minTouchTarget && sizes[size] < global.minimumTouchTarget
            ? (global.minimumTouchTarget - sizes[size]) / 2
            : 0,
        // NOTE: aspect ratio is 1:1 when there are icons without text
        paddingHorizontal: !text
          ? (sizes[size] - iconSizes[size]) / 2
          : paddingValues[size],
      },
      content: {
        alignItems: "center",
        flexDirection: "row",
      },
      dot: {
        backgroundColor: dotColor ? dotColor : theme.systemRed100,
        borderColor: getDotBorderColors(disabled, selected, theme, type),
        borderRadius: dotSizes[size],
        borderWidth: dotBorderWidth,
        height: dotSizes[size],
        position: "absolute",
        right: 0,
        top: -dotBorderWidth,
        width: dotSizes[size],
        zIndex: 1, // Required on native platforms
      },
      hiddenText: {
        opacity: 0,
      },
      hoverOverlay: {
        backgroundColor: getHoverBackground(color, type),
      },
      leftIcon: {
        marginRight: iconMargin[size],
      },
      overlay: {
        borderRadius,
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
      },
      pressOverlay: {
        backgroundColor: getPressBackground(color, type),
      },
      rightIcon: {
        marginLeft: iconMargin[size],
      },
      rightIconContent: {
        flexDirection: "row-reverse",
      },
      selected: {
        borderRadius,
      },
    }),
    ...imageStyles({
      image: {
        borderRadius: iconSizes[size],
        height: iconSizes[size],
        width: iconSizes[size],
      },
    }),
  };
};

export default styles;

export const getColor = (
  color: Color,
  disabled: boolean,
  selected: boolean,
  theme: Theme,
  type: Type
): string => {
  const contentColors = {
    basic: getBasicColors(color, theme),
    filled: getFilledColors(color, theme),
    text: getTextColors(color, theme),
  };

  const selectedColors = {
    basic: getFilledColors(color, theme),
    filled: getFilledColors(color, theme),
    text: getBasicColors(color, theme),
  };

  if (disabled) {
    return theme.contentTertiary;
  }

  if (selected) {
    return selectedColors[type];
  }

  return contentColors[type];
};

export const getTextType = (size: string) => {
  if (size === "extraSmall") {
    return "bodySmall";
  }

  if (size === "small") {
    return "labelSmall";
  }

  return "labelDefault";
};

export const iconSizes = {
  default: 24,
  extraSmall: 16,
  large: 24,
  small: 20,
};
