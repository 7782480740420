import { viewStyles } from "../../styles";
import { Theme } from "../../styles/themes";

import { IconColor } from "./IconArtwork";

interface Props {
  iconColor: IconColor;
}

export const iconArtworkStyle = {
  borderRadius: 64,
  height: 64,
  width: 64,
};

const styles = (theme: Theme, { iconColor }: Props) => {
  const iconBackgroundColors = {
    black: theme.backgroundSecondary,
    brand: theme.paletteBrand10,
    purple: theme.palettePurple10,
    systemBlue: theme.systemBlue10,
    systemGreen: theme.systemGreen10,
    systemRed: theme.systemRed10,
    systemYellow: theme.systemYellow10,
  };

  return viewStyles({
    iconContainer: {
      ...iconArtworkStyle,
      alignItems: "center",
      backgroundColor: iconBackgroundColors[iconColor],
      justifyContent: "center",
    },
  });
};

export default styles;
