import "../polyfills/match-media";

import {
  useCallback,
  useRef,
  useState,
  createContext,
  ReactNode,
  useContext,
} from "react";
import {
  ImageStyle,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
  Platform,
  useWindowDimensions,
} from "react-native";
import { useMediaQuery as useResponsiveMediaQuery } from "react-responsive";

import { screenIndent } from "../components/Popover/constants";
import { useIsomorphicEffect } from "../hooks/useIsomorphicEffect";
import { isServer } from "../utils";

import global from "./global";

export type ServerScreenSize = "large" | "medium" | "small";

const ServerScreenSizeContext = createContext<ServerScreenSize | null>(null);

export const ServerScreenSizeProvider = ({
  children,
  screenSize,
}: {
  screenSize?: ServerScreenSize;
  children: ReactNode;
}) => {
  return (
    <ServerScreenSizeContext.Provider value={screenSize ?? null}>
      {children}
    </ServerScreenSizeContext.Provider>
  );
};

export interface Measures {
  height: number;
  width: number;
  x: number;
  y: number;
}

// Utilities to type style objects correctly
const asTypedStyle =
  <X extends ImageStyle | TextStyle | ViewStyle>() =>
  <T,>(s: { [key in keyof T]: X }) =>
    StyleSheet.create<{ [key in keyof T]: X }>(s);

const imageStyles = asTypedStyle<ImageStyle>();
const textStyles = asTypedStyle<TextStyle>();
const viewStyles = asTypedStyle<ViewStyle>();

const useLayoutMeasures = () => {
  const [node, setNode] = useState<View | null>(null);
  const [measures, setMeasures] = useState<Measures | null>(null);

  const ref = useCallback((node: View | null) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  useIsomorphicEffect(() => {
    node?.measureInWindow((x, y, width, height) => {
      if (
        (height !== undefined && height !== measures?.height) ||
        (width !== undefined && width !== measures?.width) ||
        (x !== undefined && x !== measures?.x) ||
        (y !== undefined && y !== measures?.y)
      ) {
        setMeasures({ height, width, x, y });
      }
    });
  });

  return [measures, ref] as const;
};

const useLayoutMeasuresResponsive = () => {
  const measures = useRef<Measures | null>(null);

  const ref = (node: View | null) => {
    if (node !== null) {
      node?.measureInWindow((x, y, width, height) => {
        measures.current = { height, width, x, y };
      });
    }
  };

  return [measures, ref] as const;
};

const useMediaQuery = () => {
  const serverScreenSize = useContext(ServerScreenSizeContext);
  const isLargeScreen: boolean = useResponsiveMediaQuery({
    minWidth: global.breakpoints.largeScreen,
  });

  const isMediumScreen: boolean = useResponsiveMediaQuery({
    maxWidth: global.breakpoints.largeScreen - 1,
    minWidth: global.breakpoints.mediumScreen,
  });

  const isSmallScreen: boolean = useResponsiveMediaQuery({
    maxWidth: global.breakpoints.mediumScreen - 1,
  });

  if (isServer()) {
    return {
      isLargeScreen: serverScreenSize === "large",
      isMediumScreen: serverScreenSize === "medium",
      isSmallScreen: serverScreenSize === "small",
    };
  }

  return { isLargeScreen, isMediumScreen, isSmallScreen };
};

declare global {
  interface Window {
    chrome?: unknown | null;
    opr?: unknown;
  }
}

const useDistanceFromEdges = ({
  element,
  includeMargin = false,
}: {
  element: Measures | null;
  includeMargin: boolean;
}) => {
  const window = useWindowDimensions();
  const margin = includeMargin ? screenIndent : 0;

  if (!element) {
    return {
      fromBottom: 0,
      fromLeft: 0,
      fromRight: 0,
      fromTop: 0,
    };
  }

  const xStart = element.x;
  const yStart = element.y;
  const height = element.height;
  const width = element.width;

  const yEnd = yStart + height;
  const xEnd = xStart + width;

  return {
    fromBottom: window.height - yEnd - margin,
    fromLeft: xStart - margin,
    fromRight: window.width - xEnd - margin,
    fromTop: yStart - margin,
  };
};

export {
  imageStyles,
  textStyles,
  useLayoutMeasures,
  useLayoutMeasuresResponsive,
  useMediaQuery,
  viewStyles,
  useDistanceFromEdges,
};
