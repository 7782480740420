import { PropsWithChildren } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { getIconColor } from "../../styles/colorUtils";
import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";

import styles from "./styles";

import { IconComponent } from ".";

const IconArtwork = ({
  Icon,
  iconAlign,
  iconColor = "brand",
  style: styleProp,
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style, theme } = useTheme(styles, { iconColor, isSmallScreen });

  return (
    <View style={[style.iconContainer, { alignSelf: iconAlign }, styleProp]}>
      <Icon color={getIconColor(iconColor, theme)} size={36} />
    </View>
  );
};

export type IconAlign = "center" | "flex-end" | "flex-start";

export type IconColor =
  | "black"
  | "brand"
  | "purple"
  | "systemBlue"
  | "systemGreen"
  | "systemRed"
  | "systemYellow";

interface Props {
  Icon: IconComponent;
  iconAlign?: IconAlign;
  iconColor?: IconColor;
  style?: StyleProp<ViewStyle>;
}

export default IconArtwork;
