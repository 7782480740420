import {
  Children,
  cloneElement,
  ComponentProps,
  isValidElement,
  PropsWithChildren,
} from "react";

import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import BottomSheetFlatList from "../BottomSheet/BottomSheetFlatList";
import Button from "../Button";
import FocusLock from "../FocusLock";
import Popover from "../Popover";
import { Props as DropdownProps } from "../Popover/Popover";

import styles from "./styles";

const Menu = ({
  anchor,
  anchorContainerStyle,
  children,
  dropdownWidth,
  keepInDOM = false,
  onDismiss,
  visible,
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });

  return (
    <Popover
      anchor={cloneElement(anchor, {
        selected: anchor.type === Button && visible,
      })}
      anchorContainerStyle={anchorContainerStyle}
      keepInDOM={keepInDOM}
      onDismiss={onDismiss}
      style={style.dropdown}
      visible={visible}
    >
      <FocusLock
        lockProps={{
          style: { display: "flex", maxHeight: "inherit" },
        }}
      >
        <BottomSheetFlatList
          data={Children.toArray(children)}
          renderItem={({ item: child }) => {
            if (!isValidElement(child)) {
              return null;
            }

            const props = {
              // Wrap content on dropdown when a fixed width is added.
              // The bottomsheet on small screens must always wrap content.
              wrapped: isSmallScreen ? true : !!dropdownWidth,
            };

            return cloneElement(child, props);
          }}
          style={[
            style.menu,
            // Add a fixed width to dropdown (and not to the bottomsheet rendered
            // on small screens, which always fits 100% of the window width)
            !isSmallScreen && dropdownWidth
              ? { width: dropdownWidth }
              : undefined,
          ]}
        />
      </FocusLock>
    </Popover>
  );
};

export interface Props extends Omit<DropdownProps, "onDismiss" | "style"> {
  dropdownWidth?: number;
  onDismiss: () => void;
  keepInDOM?: ComponentProps<typeof Popover>["keepInDOM"];
}

export default Menu;
