import { Path } from "react-native-svg";

import createIcon from "../createIcon";

export const IconClose = createIcon(
  <Path
    d="M18.295,7.115L13.41,12l4.885,4.885c0.389,0.389,0.389,1.021,0,1.41s-1.021,0.389-1.41,0L12,13.41l-4.885,4.885
  c-0.389,0.389-1.021,0.389-1.41,0c-0.389-0.389-0.389-1.021,0-1.41L10.59,12L5.705,7.115c-0.389-0.389-0.389-1.021,0-1.41
  c0.389-0.389,1.021-0.389,1.41,0L12,10.59l4.885-4.885c0.389-0.389,1.021-0.389,1.41,0C18.684,6.094,18.684,6.726,18.295,7.115z"
  />
);
