import { Env } from "@mxmdev/podcasts-shared-native";
import {
  GenericError,
  RitmoProvider,
} from "@mxmdev/react-universal-components";
import { withAuthApp } from "@mxmdev/react-universal-core";
import { useLink } from "@mxmdev/react-universal-navigation";
import { AppProps } from "next/app";
import Head from "next/head";

import App from "../App";
import {
  ACCOUNT_URL,
  API_ORIGIN,
  API_STREAM,
  API_URL_SIGNATURE_ENABLED,
  APP_ID,
  APP_SYNC_API_KEY,
  APP_SYNC_URL,
  APP_URL,
  APP_VERSION,
  AUTH_SHARED_SIGN_IN_ENABLED,
  AUTH_SIGN_IN_URL,
  AUTH_SIGN_OUT_URL,
  AUTH_TOKEN_MIGRATE_URL,
  BUILD_NUMBER,
  CLIENT_KEY,
  DEPLOY_ENV,
  // Feature flags
  FF_DISABLE_ADS_FINDER,
  FF_DISABLE_PODCAST_CLAIM,
  FF_DISABLE_RELATED_PODCASTS,
  FF_DISABLE_SEARCH_BAR,
  FF_ENABLE_AUDIOGRAM_DOWNLOAD,
  FF_ENABLE_MXM_ACCOUNTS,
  FF_ENABLE_REFERENCE_TRACK_FALLBACK,
  FF_ENABLE_SSR,
  FF_ENABLE_TEXT2SYNC,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET_ID,
  PR_NUMBER,
  SENTRY_DSN,
  SENTRY_RELEASE_VERSION,
  STRIPE_PRICE_ID,
} from "../processEnv";
import { MainHeaderProvider } from "../src/components/MainHeader";
import { getSEOMetadata, SEOProps } from "../src/seo";
import { SSRProps } from "../src/ssr/types";

/**
 * This is unused right now (i.e: `pageProps` will still be `any`).
 * Will be fixed by Next 12
 */
type PodcastsAppProps = {
  isBot: boolean;
  ssr: SSRProps;
  seo: SEOProps;
  userAgent: string;
};

const NextApp = ({
  Component,
  pageProps,
}: AppProps<PodcastsAppProps>): JSX.Element => {
  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          name="viewport"
        />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="yes" name="mobile-web-app-capable" />
        {pageProps.seo ? getSEOMetadata(pageProps.seo) : null}
      </Head>
      <App
        accountURL={ACCOUNT_URL!}
        analyticsConfig={{
          firebase: {
            apiKey: FIREBASE_API_KEY,
            appId: FIREBASE_APP_ID,
            authDomain: FIREBASE_AUTH_DOMAIN,
            measurementId: FIREBASE_MEASUREMENT_ID,
            messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
            projectId: FIREBASE_PROJECT_ID,
            storageBucket: FIREBASE_STORAGE_BUCKET_ID,
          },
        }}
        apiOrigin={API_ORIGIN as string}
        apiStream={API_STREAM as string}
        apiURLSignatureEnabled={API_URL_SIGNATURE_ENABLED === "true"}
        appId={APP_ID as string}
        appSyncApiKey={APP_SYNC_API_KEY as string}
        appSyncUrl={APP_SYNC_URL as string}
        appUrl={APP_URL as string}
        authSharedSignInEnabled={AUTH_SHARED_SIGN_IN_ENABLED as string}
        authSignInUrl={AUTH_SIGN_IN_URL as string}
        authSignOutUrl={AUTH_SIGN_OUT_URL as string}
        authTokensSetUrl={AUTH_TOKEN_MIGRATE_URL as string}
        buildNumber={
          (DEPLOY_ENV === "prod"
            ? APP_VERSION
            : `${APP_VERSION} (${BUILD_NUMBER})`) as string
        }
        clientKey={CLIENT_KEY as string}
        env={DEPLOY_ENV as Env}
        errorTrackingConfig={{
          url: SENTRY_DSN,
          version: SENTRY_RELEASE_VERSION,
        }}
        featureFlags={{
          disableAdsFinder: FF_DISABLE_ADS_FINDER === "true",
          disablePodcastClaim: FF_DISABLE_PODCAST_CLAIM === "true",
          disableRelatedPodcasts: FF_DISABLE_RELATED_PODCASTS === "true",
          disableSearchBar: FF_DISABLE_SEARCH_BAR === "true",
          enableAudiogramDownload: FF_ENABLE_AUDIOGRAM_DOWNLOAD === "true",
          enableMxmAccounts: FF_ENABLE_MXM_ACCOUNTS === "true",
          enableReferenceTrackFallback:
            FF_ENABLE_REFERENCE_TRACK_FALLBACK === "true",
          enableSSR: FF_ENABLE_SSR === "true",
          enableText2Sync: FF_ENABLE_TEXT2SYNC === "true",
        }}
        isBot={pageProps.isBot}
        prNumber={PR_NUMBER ? parseInt(PR_NUMBER as string) : undefined}
        ssrProps={pageProps.ssr}
        stripePriceId={STRIPE_PRICE_ID}
        userAgent={pageProps.userAgent}
      >
        <MainHeaderProvider>
          <Component {...pageProps} />
        </MainHeaderProvider>
      </App>
    </>
  );
};

const Error = (): JSX.Element => (
  <RitmoProvider useLink={useLink}>
    <GenericError />
  </RitmoProvider>
);

export default AUTH_SHARED_SIGN_IN_ENABLED === "true"
  ? withAuthApp(NextApp, Error, {
      accountURL: ACCOUNT_URL!,
      apiOrigin: API_ORIGIN!,
      appId: APP_ID!,
      clientKey: CLIENT_KEY!,
      signInURL: AUTH_SIGN_IN_URL!,
      signOutURL: AUTH_SIGN_OUT_URL!,
      tokenMigrateURL: AUTH_TOKEN_MIGRATE_URL!,
    })
  : NextApp;
