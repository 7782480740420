import * as queryString from "query-string";
import { Linking, Platform } from "react-native";

import { canUseDOM } from "./platformUtils";

interface ParsedQuery {
  [key: string]: string | null;
}

interface URLSearchParams {
  [key: string]: string | undefined;
}

/**
 * Get the string representation of a given URL with search parameters.
 * @param baseUrl - Base URL.
 * @param params - Search parameters.
 * @returns
 */
export const getUrl = (baseUrl: string, params: URLSearchParams): string => {
  const url = new URL(baseUrl);

  Object.keys(params).forEach((name) => {
    if (params[name]) {
      url.searchParams.append(name, params[name]!);
    }
  });

  return url.toString();
};

export const getURLSearchParams = (url: string): URLSearchParams => {
  const parsedQuery = queryString.parseUrl(url).query as ParsedQuery;

  const searchParams: URLSearchParams = {};

  Object.entries(parsedQuery).forEach(([key, value]) => {
    searchParams[key] = value === null ? "null" : value;
  });

  return searchParams;
};

/**
 * This function is not meant to substitute browsers' URL implementation but
 * it should be enough to retrieve the pathname from an URL.
 *
 * WARNING: the following function is going to work fine ONLY for
 * fully and well-formed URLs.
 */
export const getPathnameFromURL = (url: string): string => {
  const protocolRE = /^[^:]+(?=:\/\/)/;
  const protocolMatches = url.match(protocolRE);

  if (!protocolMatches || protocolMatches.length !== 1) {
    return "/";
  }

  const protocol = protocolMatches[0];

  url = url.substr((protocol + "://").length);

  if (protocol.startsWith("http")) {
    const firstSlash = url.indexOf("/");

    if (firstSlash !== -1) {
      url = url.substr(firstSlash);
    } else {
      url = "";
    }
  }

  if (url.length === 0) {
    return "/";
  }

  const firstParamIndex = url.indexOf("?");

  if (firstParamIndex !== -1) {
    url = url.substr(0, firstParamIndex);
  }

  if (!url.startsWith("/")) {
    url = "/" + url;
  }

  return url;
};

/**
 * Opens an URL with a given browsing context (if provided) on web,
 * otherwise uses React Native's default Linking module.
 * @param {string} url - The URL to open.
 * @param {Target} webTarget - The browsing context to use.
 */
export const openUrl = (
  url: string,
  webTarget?: "_blank" | "_self" | "_parent" | "_top" // type of Target from Pressable
): void => {
  if (Platform.OS === "web") {
    window.open(url, webTarget ?? "_blank");
  } else {
    Linking.openURL(url);
  }
};

export const encodeURL = (url: string): string => {
  // TODO: explicitly declare 'buffer' dependency (added by Webpack?).

  return Buffer.from(url)
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/g, "");
};

export const decodeURL = (encoded: string): string => {
  return Buffer.from(encoded, "base64").toString("ascii");
};

export const getOrigin = (appScheme?: string): string => {
  if (canUseDOM) {
    return window.location.origin;
  }

  if (!appScheme) {
    throw new Error(
      "cannot build native origin URL, `appScheme` not specified"
    );
  }

  return `${appScheme}://`;
};
