import { useTranslation } from "react-i18next";

/**
 * @deprecated Please refer to the `react-universal-i18n` package.
 */
const useI18n = () => {
  const { ready, t } = useTranslation<any, any>();

  return { ready, t };
};

export { useI18n };
