import { ReactElement, useMemo, PropsWithChildren } from "react";
import { Platform } from "react-native";

import {
  ErrorTrackingContext,
  ErrorTrackingContextType,
  isKnownError,
} from "./errorTrackingUtils";
import type { SentryClient as SentryNativeClient } from "./providers/sentry";
import { sentryClient as sentryNativeClient } from "./providers/sentry";
import {
  beforeSend,
  sentryClient as sentryWebClient,
} from "./providers/sentry.web";
import type { SentryClient as SentryWebClient } from "./providers/sentry.web";

const ErrorTrackingProvider = ({
  children,
  config,
  fallback,
}: PropsWithChildren<Props>) => {
  let client: SentryWebClient | SentryNativeClient;

  if (Platform.OS === "web") {
    client = sentryWebClient.get({
      beforeSend,
      debug: __DEV__,
      dsn: config.url,
      release: config.version,
      sampleRate: config.sampleRate,
      ...(config.tracingSampleRate && {
        tracesSampleRate: config.tracingSampleRate,
      }),
    });
  } else {
    client = sentryNativeClient.get({
      // TODO: import from ./providers/sentry.web once @sentry/react-native has been upgraded.
      beforeSend: (event, hint) => {
        const exception = hint?.originalException;

        if (
          (typeof exception === "string" || exception instanceof Error) &&
          isKnownError(exception)
        ) {
          return null;
        }

        return event;
      },
      debug: __DEV__,
      dsn: config.url,
      release: config.version,
      sampleRate: config.sampleRate,
      tracesSampleRate: config.tracingSampleRate,
    });
  }

  const contextValue = useMemo<ErrorTrackingContextType>(
    () => ({
      clearUser: () => {
        client.configureScope((scope) => scope.setUser(null));
      },
      logError: (error, data, shouldTrack = true) => {
        if (shouldTrack) {
          client.captureException(
            typeof error === "string" ? new Error(error) : error,
            data
          );
        }

        console.error(
          `[${data?.tags?.namespace ?? "unknown"}] ${error}`,
          JSON.stringify(data)
        );
      },
      setContext: (name, context) => {
        client.setContext(name, context);
      },
      setUser: (id: string) => {
        client.setUser({ id });
      },
    }),
    [client]
  );

  return (
    <ErrorTrackingContext.Provider value={contextValue}>
      <client.ErrorBoundary fallback={fallback}>
        {children}
      </client.ErrorBoundary>
    </ErrorTrackingContext.Provider>
  );
};

export interface Props {
  fallback?: ReactElement;
  config: {
    tracingSampleRate?: number;
    sampleRate?: number;
    url?: string;
    version?: string;
  };
}

export default ErrorTrackingProvider;
