import { createContext, useContext } from "react";

type TrackingContext = Record<string, any> & { type?: never };

export interface ErrorTrackingContextType {
  clearUser: () => void;
  logError: (
    error: Error | string,
    data?: {
      contexts?: Record<string, Record<string, unknown>>;
      tags?: {
        [key: string]: string;
      };
      extra?: Record<string, unknown>;
    },
    shouldTrack?: boolean
  ) => void;
  setContext: (name: string, context: TrackingContext) => void;
  setUser: (id: string) => void;
}

const ErrorTrackingContext = createContext<ErrorTrackingContextType>({
  clearUser: () => {},
  logError: (error, data) => {
    console.error(
      `UNTRACKED ERROR [${data?.tags?.namespace ?? "unknown"}] ${error}`,
      JSON.stringify(data)
    );
  },
  setContext: () => {},
  setUser: () => {},
} as ErrorTrackingContextType);

const isNavigationBackError = (error: Error) => {
  return (
    error.message === "Cannot read property 'indexOf' of undefined" &&
    error.name === "TypeError" &&
    error.stack?.includes("onPopState")
  );
};

const isKnownError = (error: Error | string) => {
  if (error instanceof Error) {
    return isNavigationBackError(error);
  } else if (typeof error === "string") {
    return false;
  }
};

const useErrorTracking = () => useContext(ErrorTrackingContext);

export { ErrorTrackingContext, isKnownError, useErrorTracking };
