import { ReactNode, PropsWithChildren } from "react";
import { View } from "react-native";

import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";

import styles from "./styles";

const Header = ({
  center,
  condensed,
  left,
  right,
  stacked,
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });

  return (
    <View style={style.topBar}>
      <View style={style.topBarRow}>
        {left && <View style={style.topBarLeftContent}>{left()}</View>}
        {condensed
          ? center && <View style={style.tabsContainer}>{center()}</View>
          : center &&
            !isSmallScreen &&
            !stacked && <View style={style.tabsContainer}>{center()}</View>}
        {right && <View style={style.topBarRightContent}>{right()}</View>}
      </View>
      {center && (isSmallScreen || stacked) && !condensed && (
        <View style={[style.stackedBarRow, style.topBarRow]}>
          <View style={style.tabsContainer}>{center()}</View>
        </View>
      )}
    </View>
  );
};

export interface Props {
  center?: (props?: any) => ReactNode;
  condensed?: boolean;
  left?: (props?: any) => ReactNode;
  right?: (props?: any) => ReactNode;
  stacked?: boolean;
}

export default Header;
