import { useTheme, createStyles } from "@mxmdev/podcasts-shared-native";
import { viewStyles, global } from "@mxmdev/react-universal-components";
import { Ref, forwardRef } from "react";
import { View, TextInput, StyleProp, ViewStyle } from "react-native";

import { SearchAtom } from "../../types";

import SearchBar from "./SearchBar";

const SearchBarContainer = (
  {
    initialQuery,
    onBlur,
    onFocus,
    onQueryChange,
    size = "default",
    style: styleProp,
  }: Props,
  ref: Ref<TextInput>
): JSX.Element => {
  const { style } = useTheme(styles);
  const height = size === "default" ? global.size.default : global.size.small;
  const sizeStyle = {
    height,
  };

  return (
    <View style={[style.container, sizeStyle, styleProp]}>
      <SearchBar
        initialQuery={initialQuery}
        onFocus={onFocus}
        onQueryChange={onQueryChange}
        ref={ref}
        size={size}
      />
    </View>
  );
};

const styles = createStyles((theme) =>
  viewStyles({
    container: {
      flex: 1,
    },
  })
);

interface Props {
  initialQuery?: SearchAtom;
  onBlur?: () => void;
  onFocus?: () => void;
  onQueryChange: (query: SearchAtom | undefined) => void;
  size?: "default" | "small";
  style?: StyleProp<ViewStyle>;
}

export default forwardRef(SearchBarContainer);
