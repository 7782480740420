import { ComponentProps } from "react";
import { ViewStyle } from "react-native";

import { viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";
import { Avatar } from "../Avatar";
import Card from "../cards/Card";

import { Align } from "./EnhancedSkeleton";

interface Props {
  align: Align;
  columnGap: number;
  hasAvatar: boolean;
  hasPrimaryAction: boolean;
  height: ViewStyle["height"];
  horizontalScrollWidth: number;
  ignoreHorizontalSpacing: boolean;
  imageAspectRatio: ComponentProps<typeof Card>["imageAspectRatio"];
  imageShape: ComponentProps<typeof Avatar>["shape"];
  percentageWidth: string | number;
  rowGap: string | number;
}

export const styles = (
  theme: Theme,
  {
    align,
    columnGap,
    hasAvatar,
    hasPrimaryAction,
    height,
    horizontalScrollWidth,
    ignoreHorizontalSpacing,
    imageAspectRatio,
    imageShape,
    percentageWidth,
    rowGap,
  }: Props
) => {
  const spacing = global.spacing * 4;

  return viewStyles({
    actions: {
      alignItems: "flex-end",
      paddingBottom: spacing,
      paddingRight: spacing,
      paddingTop: global.spacing * 8,
    },
    avatar: { paddingRight: spacing },
    cardImage: {
      aspectRatio: imageAspectRatio,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: global.radius.large,
      borderTopRightRadius: global.radius.large,
      marginBottom: spacing,
    },
    cardText: {
      paddingBottom: hasPrimaryAction ? undefined : spacing,
      paddingHorizontal: spacing,
    },
    container: {
      flexDirection: align === "horizontal" ? "row" : "column",
    },
    contentContainer: {
      flex: 1,
      marginHorizontal: columnGap > 0 ? -(columnGap / 2) : 0,
      overflow: "hidden",
    },
    icon: {
      marginBottom: spacing,
      paddingLeft: spacing,
      paddingTop: spacing,
    },
    itemContainer: {
      marginTop: rowGap,
      paddingHorizontal: columnGap > 0 ? columnGap / 2 : 0,
      width: percentageWidth,
    },
    itemOnFirstRow: {
      marginTop: 0,
    },
    listActions: {
      alignItems: "flex-end",
      flexGrow: 1,
      flexShrink: 0,
    },
    listContainer: {
      maxWidth: "100%",
      paddingHorizontal: ignoreHorizontalSpacing
        ? undefined
        : global.spacing * 4,
      width: "100%",
    },
    pagination: {
      marginTop: global.spacing * 6,
    },
    scrollView: {
      maxWidth: "100%",
    },
    scrollViewContainer: {
      width: horizontalScrollWidth ? horizontalScrollWidth : "100%",
    },
    skeleton: {
      ...(height === undefined
        ? { minHeight: global.size.default }
        : { height }),
      borderRadius: global.radius.default,
    },
    skeletonAction: {
      marginTop: global.spacing * 3,
      width: "100%",
    },
    skeletonArtwork: {
      aspectRatio: imageAspectRatio,
      borderRadius: imageShape === "default" ? global.radius.default : 1000,
      height: undefined,
      width: "100%",
    },
    skeletonCard: {
      ...global.sheetShadow.centered,
      backgroundColor: theme.backgroundPrimaryElevated,
      borderRadius: global.radius.large,
    },
    skeletonItem: {
      paddingTop: global.spacing * 4,
    },
    skeletonList: {
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    skeletonSpacing: {
      paddingVertical: global.spacing * 5,
    },
    skeletonTable: {
      width: global.breakpoints.largeScreen,
    },
    skeletonTableHeader: {
      backgroundColor: theme.backgroundPrimary,
    },
    skeletonTableRow: {
      alignItems: "center",
      flexDirection: "row",
    },
    skeletonText: { width: "100%" },
    skeletonTextContainer: {
      justifyContent: align === "horizontal" ? "center" : undefined,
      width: align === "horizontal" ? undefined : "100%",
    },
    text: {
      flexGrow: 4,
      maxWidth: 400,
      paddingLeft: hasAvatar ? spacing : 0,
    },
    textContainer: {
      marginTop: global.spacing * 3,
    },
  });
};
