import { useCallback, useEffect, useRef } from "react";

/**
 * Web browsers emulate mouse events (and hover states) after touch events.
 * This code infers when the currently-in-use modality supports hover
 * (including for multi-modality devices) and considers "hover" to be enabled
 * if a mouse movement occurs more than 1 second after the last touch event.
 * This threshold is long enough to account for longer delays between the
 * browser firing touch and mouse events on low-powered devices.
 */
const HOVER_THRESHOLD_MS = 1000;

export const useIsHoverEnabled = () => {
  const enabled = useRef<boolean>(false);
  const lastTouchTimestamp = useRef<number>(0);

  useEffect(() => {
    const enableHover = () => {
      if (
        enabled.current ||
        Date.now() - lastTouchTimestamp.current < HOVER_THRESHOLD_MS
      ) {
        return;
      }

      enabled.current = true;
    };

    const disableHover = () => {
      lastTouchTimestamp.current = Date.now();

      if (enabled.current) {
        enabled.current = false;
      }
    };

    document.addEventListener("touchstart", disableHover, true);
    document.addEventListener("touchmove", disableHover, true);
    document.addEventListener("mousemove", enableHover, true);

    return () => {
      document.removeEventListener("touchstart", disableHover, true);
      document.removeEventListener("touchmove", disableHover, true);
      document.removeEventListener("mousemove", enableHover, true);
    };
  }, []);

  return useCallback(() => enabled.current, []);
};
