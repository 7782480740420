import { useTheme, Pressable } from "@mxmdev/react-universal-components";
import React from "react";
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  View,
} from "react-native";

import styles from "./styles";

const AccountPictureDialogItem = ({
  background,
  onPress,
  selected,
  style: styleProp,
}: Props) => {
  const { style } = useTheme(styles, { selected });

  return (
    <View style={[styleProp, style.itemContainer]}>
      <Pressable onPress={onPress}>
        <Image resizeMode="cover" source={background} style={[style.item]} />
        <View
          style={[style.borderView, selected ? style.selected : undefined]}
        />
      </Pressable>
    </View>
  );
};

interface Props {
  background: ImageSourcePropType;
  onPress: () => void;
  selected: boolean;
  style?: StyleProp<ImageStyle>;
}

export default AccountPictureDialogItem;
