import { textAlignStyles, textTypeStyles } from "..";
import { textStyles } from "../../styles/styleUtils";
import { Theme } from "../../styles/themes";

const styles = (theme: Theme) => ({
  ...textAlignStyles,
  ...textTypeStyles,
  ...textStyles({
    textLink: {
      textDecorationLine: "underline",
    },
  }),
});

export default styles;
