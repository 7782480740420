import { PropsWithChildren } from "react";
import { StyleProp, ViewStyle } from "react-native";

import Hoverable from "../Hoverable";
import { Props as HoverableProps } from "../Hoverable/Hoverable";
import Pressable from "../Pressable";
import { Props as PressableProps } from "../Pressable/Pressable";

import AvatarBox, { Props as AvatarBoxProps } from "./AvatarBox";

const EnhancedAvatarBox = ({
  align,
  caption,
  captionLink,
  captionNumberOfLines,
  captionStyle,
  captionType,
  hasPlaceholder,
  Icon,
  label,
  labelLink,
  labelNumberOfLines,
  maxWidth,
  onHover,
  onPress,
  onPressCaption,
  onPressLabel,
  shape,
  size,
  source,
  style,
  ...otherProps
}: PropsWithChildren<Props>) => {
  return (
    <Hoverable onHover={onHover} style={style}>
      <Pressable {...otherProps} onPress={onPress}>
        <AvatarBox
          align={align}
          caption={caption}
          captionLink={captionLink}
          captionNumberOfLines={captionNumberOfLines}
          captionStyle={captionStyle}
          captionType={captionType}
          hasPlaceholder={hasPlaceholder}
          Icon={Icon}
          label={label}
          labelLink={labelLink}
          labelNumberOfLines={labelNumberOfLines}
          maxWidth={maxWidth}
          onPressCaption={onPressCaption}
          onPressLabel={onPressLabel}
          shape={shape}
          size={size}
          source={source}
        />
      </Pressable>
    </Hoverable>
  );
};

export interface Props extends AvatarBoxProps, HoverableProps, PressableProps {
  style?: StyleProp<ViewStyle>;
}

export default EnhancedAvatarBox;
