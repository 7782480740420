import { z, TypeOf } from "zod";

import { ApiResponse } from "../types";

import { useValidatedQuery } from ".";

type Params = {
  episodeId?: string;
  podcastId?: string;
  time?: number;
};

const parser = z.object({
  previewUrl: z.string(),
});

const transformer = (input: TypeOf<typeof parser>): string => {
  return input.previewUrl;
};

const useEpisodePreviewUrl = ({
  episodeId,
  podcastId,
  time,
}: Params): ApiResponse<string, "previewUrl"> => {
  const params: Params = { episodeId, podcastId };

  if (time) {
    params.time = time;
  }

  const { data, error, isLoading, retry } = useValidatedQuery(
    episodeId !== undefined && podcastId !== undefined
      ? "podcast.episode.preview.image.get"
      : undefined,
    params,
    parser,
    transformer
  );

  return {
    error,
    isLoading,
    previewUrl: data,
    retry,
  };
};

export default useEpisodePreviewUrl;
