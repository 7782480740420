import { useCallback } from "react";
import { z } from "zod";

import useFetcher from "./useFetcher";

export declare type TypeOf<T extends z.ZodType<unknown>> = T["_output"];

const DEFAULT_OPTIONS = {};

/**
 * Wrapper around useFetcher to automatically handle:
 * - Fetching
 * - Parsing/Validation (with zod)
 * - Transformation
 * - Authentication
 */
const useValidatedFetcher = <T extends z.ZodRawShape, OutputType>(
  api: string,
  params: Record<string, unknown>,
  apiSchema: z.ZodObject<T>,
  transform: (input: TypeOf<z.ZodObject<T>>) => OutputType,
  options: RequestInit = DEFAULT_OPTIONS
): {
  fetcher: (
    queryParams?: Record<string, unknown>,
    body?: Record<string, unknown>
  ) => Promise<Readonly<OutputType>>;
} => {
  const { fetcher } = useFetcher(api, params);

  const scopedFetcher = useCallback(
    (
      queryParams: Record<string, unknown> = {},
      body?: Record<string, unknown>
    ): Promise<Readonly<OutputType>> => {
      const bodyString = body ? JSON.stringify(body) : undefined;

      return fetcher(queryParams, { ...options, body: bodyString })
        .then((data) => apiSchema.parse(data.body))
        .then((parsed) => transform(parsed))
        .catch((error) => {
          console.error("fetcher experienced error: ", error);
          throw error;
        });
    },
    [fetcher, options, apiSchema, transform]
  );

  return {
    fetcher: scopedFetcher,
  };
};

export default useValidatedFetcher;
