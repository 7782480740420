import { ComponentProps } from "react";
import {
  Edge,
  initialWindowMetrics,
  Metrics,
  SafeAreaProvider as RNSafeAreaProvider,
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";

const initialMetrics: Metrics = {
  frame: { height: 0, width: 0, x: 0, y: 0 },
  insets: { bottom: 0, left: 0, right: 0, top: 0 },
};

const SafeAreaProvider = (props: ComponentProps<typeof RNSafeAreaProvider>) => (
  <RNSafeAreaProvider
    initialMetrics={initialWindowMetrics || initialMetrics}
    {...props}
  />
);

export type { Edge };
export { SafeAreaView, SafeAreaProvider, useSafeAreaInsets };
