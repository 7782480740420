import { ComponentProps, useMemo, useState, PropsWithChildren } from "react";
import { StyleProp, TextStyle, View } from "react-native";

import { IconComponent, Text } from "..";
import { ComponentSize } from "../../styles/global";
import { useTheme } from "../../styles/themes/themeUtils";
import TextLink from "../TextLink";

import Avatar, { Shape } from "./Avatar";
import styles from "./styles";

const AvatarBox = ({
  align = "left",
  caption,
  captionLink,
  captionNumberOfLines,
  captionStyle,
  captionType,
  hasPlaceholder,
  Icon,
  label,
  labelLink,
  labelNumberOfLines,
  maxWidth,
  onPressCaption,
  onPressLabel,
  shape = "round",
  size = "default",
  source,
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(
    styles,
    useMemo(() => ({ align, maxWidth }), [align, maxWidth])
  );

  const [isLabelHovering, setIsLabelHovering] = useState(false);
  const [isCaptionHovering, setIsCaptionHovering] = useState(false);

  const Label = useMemo(
    () => (onPressLabel || labelLink ? TextLink : Text),
    [onPressLabel, labelLink]
  );
  const Caption = useMemo(
    () => (onPressCaption || captionLink ? TextLink : Text),
    [onPressCaption, captionLink]
  );

  return (
    <View style={style.boxContainer}>
      <Avatar
        accessibilityLabel={label}
        accessible
        hasPlaceholder={hasPlaceholder}
        PlaceholderIcon={Icon}
        shape={shape}
        size={size}
        source={{ uri: source }}
      />
      <View style={style.boxTextContainer}>
        {label ? (
          <Label
            align={align === "right" ? "right" : "left"}
            color={theme.contentPrimary}
            isHoverAnimationDisabled
            numberOfLines={labelNumberOfLines}
            onHoverIn={() => setIsLabelHovering(true)}
            onHoverOut={() => setIsLabelHovering(false)}
            onPress={onPressLabel}
            style={[
              style.boxText,
              { textDecorationLine: isLabelHovering ? "underline" : "none" },
            ]}
            to={labelLink}
            type="labelDefault"
          >
            {label}
          </Label>
        ) : null}
        {caption ? (
          <Caption
            align={align === "right" ? "right" : "left"}
            color={theme.contentSecondary}
            isHoverAnimationDisabled
            numberOfLines={captionNumberOfLines}
            onHoverIn={() => setIsCaptionHovering(true)}
            onHoverOut={() => setIsCaptionHovering(false)}
            onPress={onPressCaption}
            style={[
              style.boxText,
              { textDecorationLine: isCaptionHovering ? "underline" : "none" },
              captionStyle,
            ]}
            to={captionLink}
            type={captionType || "bodyDefault"}
          >
            {caption}
          </Caption>
        ) : null}
      </View>
    </View>
  );
};

type Align = "left" | "right";

export interface Props {
  align?: Align;
  caption?: string;
  captionLink?: string;
  captionNumberOfLines?: number;
  captionStyle?: StyleProp<TextStyle>;
  captionType?: ComponentProps<typeof Text>["type"];
  hasPlaceholder?: boolean;
  Icon?: IconComponent;
  label?: string;
  labelLink?: string;
  labelNumberOfLines?: ComponentProps<typeof Text>["numberOfLines"];
  maxWidth?: number;
  onPressCaption?: () => void;
  onPressLabel?: () => void;
  size?: ComponentSize;
  shape?: Shape;
  source: string;
}

export default AvatarBox;
