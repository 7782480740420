import { Path } from "react-native-svg";

import createIcon from "../createIcon";

export const IconCheck = createIcon(
  <Path
    d="M18.686,6.025c-0.357,0.015-0.702,0.134-0.96,0.39L9.023,15.09l-2.674-2.645c-0.528-0.523-1.387-0.521-1.916,0
  c-0.261,0.258-0.405,0.603-0.404,0.969c0,0.367,0.144,0.711,0.404,0.969l3.625,3.584c0.279,0.277,0.604,0.417,0.966,0.417
  c0.362,0,0.687-0.14,0.966-0.417l9.625-9.662c0.517-0.517,0.525-1.337,0.021-1.867C19.386,6.176,19.048,6.029,18.686,6.025z"
  />
);
