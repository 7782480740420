import { isResponseOk } from "../../../data-fetching";
import { storage } from "../../../utils";
import { AuthCaptchaError } from "../errors";
import {
  getCaptchaId,
  getGUID,
  getUserToken as getStoredUserToken,
  setUserToken,
} from "../storage";
import { AuthStorage, TokenFetcher } from "../types";

interface Result {
  isNew: boolean;
  userToken: string;
}

const getOrFetchToken = async (
  fetcher: TokenFetcher,
  storage: AuthStorage,
  appId: string,
  guid: string
): Promise<Result> => {
  const storedToken = await getStoredUserToken(storage);

  if (storedToken) {
    return {
      isNew: false,
      userToken: storedToken,
    };
  }

  const captchaId = await getCaptchaId(storage);

  const { data } = await fetcher({
    app_id: appId,
    guid,
    ...(captchaId && { captcha_id: captchaId }),
  });

  if (!isResponseOk(data.tokenGet.header.status_code)) {
    if (data.tokenGet.header.hint === "captcha") {
      throw new AuthCaptchaError();
    } else {
      throw new Error(
        `request returned a non-successful code ${data.tokenGet.header.status_code}`
      );
    }
  }

  const userToken = data.tokenGet.body?.user_token;

  if (!userToken) {
    throw new Error("could not get nor fetch user token");
  }

  await setUserToken(storage, userToken);

  return {
    isNew: true,
    userToken,
  };
};

export const getUserToken = async (
  tokenFetcher: TokenFetcher,
  appId: string
): Promise<Result> => {
  const guid = await getGUID(storage);

  return getOrFetchToken(tokenFetcher, storage, appId, guid);
};
