import { useCallback } from "react";
import { Animated } from "react-native";

import { global } from "../..";

const usePressableAnimations = ({
  animatedValue,
  isAnimationDisabled,
}: Props) => {
  const onPressInAnimation = useCallback(() => {
    if (!isAnimationDisabled && animatedValue) {
      Animated.timing(animatedValue, {
        duration: global.animatedTimingDuration,
        toValue: 1,
        useNativeDriver: true,
      }).start();
    }
  }, [animatedValue, isAnimationDisabled]);

  const onPressOutAnimation = useCallback(() => {
    if (!isAnimationDisabled && animatedValue) {
      Animated.timing(animatedValue, {
        duration: global.animatedTimingDuration,
        toValue: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [animatedValue, isAnimationDisabled]);

  return { onPressInAnimation, onPressOutAnimation };
};

export default usePressableAnimations;

interface Props {
  isAnimationDisabled?: boolean;
  animatedValue?: Animated.Value;
}
