import { Animated, viewStyles } from "@mxmdev/react-universal-components";
import { useRef } from "react";
import { View, Easing } from "react-native";

import { useEffectOnce } from "../../utils/hookUtils";

import Arrow from "./elements/Arrow";
import Core from "./elements/Core";
import Side from "./elements/Side";

const ASSEMBLE_DURATION = 400;

export const LoaderAnimation = (): JSX.Element => {
  const coreOpacity = useRef(new Animated.Value(0)).current;
  const arrowUpOpacity = useRef(new Animated.Value(0)).current;
  const arrowUpTranslateY = useRef(new Animated.Value(20)).current;
  const arrowDownOpacity = useRef(new Animated.Value(0)).current;
  const arrowDownTranslateY = useRef(new Animated.Value(-20)).current;
  const sideLeftOpacity = useRef(new Animated.Value(0)).current;
  const sideLeftTranslateX = useRef(new Animated.Value(-20)).current;
  const sideRightOpacity = useRef(new Animated.Value(0)).current;
  const sideRightTranslateX = useRef(new Animated.Value(20)).current;

  useEffectOnce(() => {
    const animation = assembleAnimation(
      coreOpacity,
      arrowUpOpacity,
      arrowUpTranslateY,
      arrowDownOpacity,
      arrowDownTranslateY,
      sideLeftOpacity,
      sideLeftTranslateX,
      sideRightOpacity,
      sideRightTranslateX
    );

    animation.start();

    return (): void => {
      animation.stop();
    };
  });

  return (
    <View style={styles.root}>
      <Animated.View
        style={[
          styles.arrowUp,
          {
            opacity: arrowUpOpacity,
            transform: [
              { translateY: arrowUpTranslateY },
              { rotate: "180deg" },
            ],
          },
        ]}
      >
        <Arrow color="#FDBDBD" />
      </Animated.View>
      <Animated.View
        style={[
          styles.arrowDown,
          {
            opacity: arrowDownOpacity,
            transform: [{ translateY: arrowDownTranslateY }],
          },
        ]}
      >
        <Arrow color="#FF5353" />
      </Animated.View>
      <Animated.View
        style={[
          styles.sideLeft,
          {
            opacity: sideLeftOpacity,
            transform: [{ translateX: sideLeftTranslateX }],
          },
        ]}
      >
        <Side color="#FF8B8B" />
      </Animated.View>
      <Animated.View
        style={[
          styles.sideRight,
          {
            opacity: sideRightOpacity,
            transform: [
              { translateX: sideRightTranslateX },
              { rotate: "180deg" },
            ],
          },
        ]}
      >
        <Side color="#FF8B8B" />
      </Animated.View>
      <Animated.View style={[styles.core, { opacity: coreOpacity }]}>
        <Core color="#FF8B8B" />
      </Animated.View>
    </View>
  );
};

const styles = viewStyles({
  arrowDown: {
    position: "absolute",
  },
  arrowUp: {
    position: "absolute",
    top: 28,
    transform: [{ rotate: "180deg" }],
  },
  core: {
    left: 25,
    position: "absolute",
    top: 28,
  },
  root: {
    height: 100,
    transform: [{ scale: 0.5 }],
    width: 100,
  },
  sideLeft: {
    position: "absolute",
    top: 28,
  },
  sideRight: {
    position: "absolute",
    right: 0,
    top: 28,
    transform: [{ rotate: "180deg" }],
  },
});

const assembleAnimation = (
  coreOpacity: Animated.Value,
  arrowUpOpacity: Animated.Value,
  arrowUpTranslateY: Animated.Value,
  arrowDownOpacity: Animated.Value,
  arrowDownTranslateY: Animated.Value,
  sideLeftOpacity: Animated.Value,
  sideLeftTranslateX: Animated.Value,
  sideRightOpacity: Animated.Value,
  sideRightTranslateX: Animated.Value
): Animated.CompositeAnimation =>
  Animated.sequence([
    Animated.delay(500),

    Animated.loop(
      Animated.sequence([
        Animated.parallel([
          inArrowAnimation(arrowDownOpacity, arrowDownTranslateY, 0),
          inArrowAnimation(arrowUpOpacity, arrowUpTranslateY, 100),
          inSideAnimation(sideLeftOpacity, sideLeftTranslateX, 200),
          inSideAnimation(sideRightOpacity, sideRightTranslateX, 300),
          inCoreAnimation(coreOpacity),
        ]),
        Animated.delay(500),
        Animated.parallel([
          outCoreAnimation(coreOpacity),
          outArrowAnimation(arrowDownOpacity, arrowDownTranslateY, 1),
          outArrowAnimation(arrowUpOpacity, arrowUpTranslateY, -1),
          outSideAnimation(sideLeftOpacity, sideLeftTranslateX, 1),
          outSideAnimation(sideRightOpacity, sideRightTranslateX, -1),
        ]),
      ])
    ),
  ]);
const inCoreAnimation = (
  opacity: Animated.Value
): Animated.CompositeAnimation =>
  Animated.timing(opacity, {
    duration: ASSEMBLE_DURATION,
    toValue: 1,
    useNativeDriver: true,
  });

const inArrowAnimation = (
  opacity: Animated.Value,
  translateY: Animated.Value,
  delay: number
): Animated.CompositeAnimation =>
  Animated.sequence([
    Animated.delay(delay),

    Animated.parallel([
      Animated.timing(opacity, {
        duration: ASSEMBLE_DURATION,
        toValue: 1,
        useNativeDriver: true,
      }),
      Animated.timing(translateY, {
        duration: ASSEMBLE_DURATION,
        easing: Easing.out(Easing.ease),
        toValue: 0,
        useNativeDriver: true,
      }),
    ]),
  ]);

const inSideAnimation = (
  opacity: Animated.Value,
  translateX: Animated.Value,
  delay: number
): Animated.CompositeAnimation =>
  Animated.sequence([
    Animated.delay(delay),
    Animated.parallel([
      Animated.timing(opacity, {
        duration: ASSEMBLE_DURATION,
        toValue: 1,
        useNativeDriver: true,
      }),
      Animated.timing(translateX, {
        duration: ASSEMBLE_DURATION,
        easing: Easing.out(Easing.ease),
        toValue: 0,
        useNativeDriver: true,
      }),
    ]),
  ]);

const outCoreAnimation = (
  opacity: Animated.Value
): Animated.CompositeAnimation =>
  Animated.timing(opacity, {
    duration: ASSEMBLE_DURATION,
    toValue: 0,
    useNativeDriver: true,
  });

const outArrowAnimation = (
  opacity: Animated.Value,
  translateY: Animated.Value,
  direction: number
): Animated.CompositeAnimation =>
  Animated.parallel([
    Animated.timing(opacity, {
      duration: ASSEMBLE_DURATION,
      toValue: 0,
      useNativeDriver: true,
    }),
    Animated.timing(translateY, {
      duration: ASSEMBLE_DURATION,
      easing: Easing.in(Easing.ease),
      toValue: direction * 20,
      useNativeDriver: true,
    }),
  ]);

const outSideAnimation = (
  opacity: Animated.Value,
  translateX: Animated.Value,
  direction: number
): Animated.CompositeAnimation =>
  Animated.parallel([
    Animated.timing(opacity, {
      duration: ASSEMBLE_DURATION,
      toValue: 0,
      useNativeDriver: true,
    }),
    Animated.timing(translateX, {
      duration: ASSEMBLE_DURATION,
      easing: Easing.in(Easing.ease),
      toValue: direction * 20,
      useNativeDriver: true,
    }),
  ]);
