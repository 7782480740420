import {
  RootRoute,
  RootStackParamList,
  StudioRoute,
} from "../navigation/types";

type ParseUrlConfig<T extends keyof RootStackParamList> = Partial<
  Record<keyof RootStackParamList[T], (id: string) => string>
>;

type StringifyUrlConfig<T extends keyof RootStackParamList> = Partial<
  Record<keyof RootStackParamList[T], (id: string) => string>
>;

type RouteName = keyof RootStackParamList;

type URLComponentName<T extends RouteName> = keyof RootStackParamList[T];

type URLConfiguration<T extends RouteName> = {
  parse: ParseUrlConfig<T>;
  stringify: StringifyUrlConfig<T>;
};

export const parseUnderscoredURLComponent = (urlComponent?: string): string => {
  if (!urlComponent) {
    return "";
  }

  return decodeURIComponent(urlComponent.replace(new RegExp("_", "g"), " "));
};

export const stringifyUnderscoredURLComponent = (
  urlComponent?: string
): string => {
  return urlComponent?.replace(new RegExp(" ", "g"), "_") ?? "";
};

export const createURLConfigurationForUnderscores = <T extends RouteName>(
  ...urlComponents: URLComponentName<T>[]
): URLConfiguration<T> => {
  const config: URLConfiguration<T> = {
    parse: {},
    stringify: {},
  };

  for (const urlComponent of urlComponents) {
    config.parse[urlComponent] = parseUnderscoredURLComponent;
    config.stringify[urlComponent] = stringifyUnderscoredURLComponent;
  }

  return config;
};

const parseSlugURLComponent = (slug?: string): string => {
  return slug?.toUpperCase() ?? "";
};

const stringifySlugURLComponent = (slug?: string): string => {
  if (!slug) {
    return "";
  }

  return decodeURIComponent(slug.toLowerCase());
};

export const createURLConfigurationForSlugs = <T extends RouteName>(
  ...urlComponents: URLComponentName<T>[]
): URLConfiguration<T> => {
  const config: URLConfiguration<T> = {
    parse: {},
    stringify: {},
  };

  for (const urlComponent of urlComponents) {
    config.parse[urlComponent] = parseSlugURLComponent;
    config.stringify[urlComponent] = stringifySlugURLComponent;
  }

  return config;
};

export const parseIdFromSlugURLComponent = (urlComponent: string): string => {
  return urlComponent.replace(/.*-/g, "");
};

export const isStudioRoute = (route: RootRoute): route is StudioRoute => {
  return (
    route === "Editor" ||
    route === "StudioEpisodeAIContent" ||
    route === "StudioEpisodeOverview" ||
    route === "StudioEpisodeVideo" ||
    route === "StudioAudiogramPreview" ||
    route === "StudioAudiogramEdit" ||
    route === "StudioAudiogramTrim"
  );
};
