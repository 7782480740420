import { useCallback, useContext } from "react";

import { DataFetchingContext } from "../../context/DataFetchingProvider";

import { fetcher } from "./fetcher";

/**
 * This hook can be used to generate a `fetcher` function to make API requests.
 * The provided fetcher will automatically take care of passing the authentication
 * tokens when appropriate.
 * Parameters can be passed in two ways: if you provide the `params` object to the
 * `useFetcher` method, those will define the "fixed" parameter that will be included
 * inside each API call. The provided fetcher method also accepts a `params` object as
 * parameters, and those will define the "variable" parameters that could be different
 * between the different calls.
 */
const useFetcher = (
  api: string,
  params?: Record<string, unknown>
): {
  fetcher: (
    params?: Record<string, unknown>,
    options?: RequestInit
  ) => ReturnType<typeof fetcher>;
} => {
  const { createRequestUrl } = useContext(DataFetchingContext);

  const scopedFetcher = useCallback(
    async (callParams?: Record<string, unknown>, options?: RequestInit) => {
      const allParams = {
        ...params,
        ...callParams,
      };

      const url = await createRequestUrl(api, allParams);

      return fetcher(url.toString(), options);
    },
    [api, createRequestUrl, params]
  );

  return { fetcher: scopedFetcher };
};

export default useFetcher;
