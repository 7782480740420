import {
  ConfigurationProvider,
  createAsyncPersistenceManager,
  DataFetchingProvider,
  Env,
  ErrorPanel,
  FeatureFlags,
  Loader,
  LocaleProvider,
  LocalPersistenceProvider,
} from "@mxmdev/podcasts-shared-native";
import { podcastsDefaultSettings } from "@mxmdev/podcasts-shared-native/src/settings/settings";
import { RitmoProvider } from "@mxmdev/react-universal-components";
import {
  AnalyticsProvider,
  canUseDOM,
  DataFetchingProvider as CoreDataFetchingProvider,
  ErrorTrackingProvider,
  SettingsProvider,
} from "@mxmdev/react-universal-core";
import { NativeAuthProvider } from "@mxmdev/react-universal-core/auth-ssr/client";
import { useLink } from "@mxmdev/react-universal-navigation";
import { PaymentProvider } from "@mxmdev/react-universal-payments";
import * as React from "react";
import { ComponentProps, useRef, PropsWithChildren } from "react";
import { Platform } from "react-native";

import usePodcastsAnalyticsPlatforms from "./src/analytics/usePodcastsAnalyticsPlaforms";
import ApolloProvider from "./src/apollo/ApolloProvider";
import { PlayerProvider } from "./src/player";
import { useInitializeAudioPlayer } from "./src/player/audio-player/useInitializeAudioPlayer";
import SSRProvider from "./src/ssr/SSRProvider";
import { SSRProps } from "./src/ssr/types";
import useSliderFix from "./src/util/useSliderFix";
import { useSmoothScrollPolyfill } from "./src/util/useSmoothScrollPolyfill";

const App = (props: React.PropsWithChildren<AppProps>): JSX.Element => {
  // Workaround to fix an invalid Slider component behavior.
  // See the definition for an explanation.
  useSliderFix();

  // Polyfill to enable smooth scrolling on Safari
  useSmoothScrollPolyfill();

  const { playerInitialized } = useInitializeAudioPlayer();

  // TODO: show another loading message and show error message if player can't be initialized
  if (!playerInitialized && typeof window !== "undefined") {
    return <Loader />;
  }

  return (
    <RitmoProvider
      colorScheme="light"
      PrePortalComponent={
        <PrePortalComponent
          accountURL={props.accountURL}
          analyticsConfig={props.analyticsConfig}
          apiOrigin={props.apiOrigin}
          apiStream={props.apiStream}
          apiURLSignatureEnabled={props.apiURLSignatureEnabled}
          appId={props.appId}
          appSyncApiKey={props.appSyncApiKey}
          appSyncUrl={props.appSyncUrl}
          authSharedSignInEnabled={props.authSharedSignInEnabled}
          authSignInUrl={props.authSignInUrl}
          buildNumber={props.buildNumber}
          clientKey={props.clientKey}
          env={props.env}
          errorTrackingConfig={props.errorTrackingConfig}
          featureFlags={props.featureFlags}
          prNumber={props.prNumber}
          ssrProps={props.ssrProps}
          stripePriceId={props.stripePriceId}
        />
      }
      useLink={useLink}
    >
      <AppContent
        isBot={props.isBot}
        isSSREnabled={!!props.featureFlags.enableSSR}
      >
        {props.children}
      </AppContent>
    </RitmoProvider>
  );
};

const PrePortalComponent = ({
  accountURL,
  analyticsConfig,
  apiOrigin,
  apiStream,
  apiURLSignatureEnabled,
  appId,
  appSyncApiKey,
  appSyncUrl,
  authSharedSignInEnabled,
  authSignInUrl,
  buildNumber,
  children,
  clientKey,
  env,
  errorTrackingConfig,
  featureFlags,
  prNumber,
  signOutURL,
  ssrProps,
  stripePriceId,
}: PropsWithChildren<PrePortalProps>) => {
  const persistenceManager = useRef(createAsyncPersistenceManager()).current;

  const AuthProviderComponent =
    Platform.OS !== "web" || authSharedSignInEnabled !== "true"
      ? NativeAuthProvider
      : React.Fragment;

  const analyticsPlatforms = usePodcastsAnalyticsPlatforms();

  return (
    <SettingsProvider
      appId="podcasts"
      defaultSettings={podcastsDefaultSettings}
    >
      <ErrorTrackingProvider
        config={errorTrackingConfig}
        fallback={
          <ErrorPanel description="We couldn't load the page, sorry! Our team is already working on it. Please try refreshing the page and contact us if the problem persists." />
        }
      >
        <AnalyticsProvider
          config={analyticsConfig}
          platforms={analyticsPlatforms}
        >
          <SSRProvider ssrProps={ssrProps}>
            <CoreDataFetchingProvider
              apiOrigin={apiOrigin}
              apiURLSignatureEnabled={apiURLSignatureEnabled}
              // TODO: To be implemented in native
              // appScheme={appScheme}
              appId={appId}
              clientKey={clientKey}
              loadingComponent={null}
              signOutURL={signOutURL}
            >
              <PaymentProvider credentials={{}}>
                <AuthProviderComponent
                  accountURL={accountURL}
                  appId={appId}
                  automaticSignIn={false}
                  loadingComponent={null}
                  signInURL={authSignInUrl}
                >
                  <ConfigurationProvider
                    apiStream={apiStream}
                    buildNumber={buildNumber}
                    env={env}
                    featureFlags={featureFlags}
                    prNumber={prNumber}
                    stripePriceId={stripePriceId}
                  >
                    <LocalPersistenceProvider
                      persistenceManager={persistenceManager}
                    >
                      <DataFetchingProvider
                        apiOrigin={apiOrigin}
                        apiURLSignatureEnabled={apiURLSignatureEnabled}
                        appId={appId}
                        clientKey={clientKey}
                      >
                        <ApolloProvider
                          apiKey={appSyncApiKey}
                          endpoint={appSyncUrl}
                        >
                          {children}
                        </ApolloProvider>
                      </DataFetchingProvider>
                    </LocalPersistenceProvider>
                  </ConfigurationProvider>
                </AuthProviderComponent>
              </PaymentProvider>
            </CoreDataFetchingProvider>
          </SSRProvider>
        </AnalyticsProvider>
      </ErrorTrackingProvider>
    </SettingsProvider>
  );
};

const AppContent = ({
  children,
  isBot,
  isSSREnabled,
}: React.PropsWithChildren<AppContentProps>): JSX.Element => {
  // We are blocking server side rendering until we have a solution for
  // responsive styles. That said, we are allowing SSR when the request is from
  // a crawler bot.
  if (!canUseDOM && (!isBot || !isSSREnabled)) {
    return <Loader />;
  }

  return (
    <LocaleProvider>
      <PlayerProvider>{children}</PlayerProvider>
    </LocaleProvider>
  );
};

interface AppContentProps {
  isBot: boolean;
  isSSREnabled: boolean;
}

export type AppProps = {
  accountURL: string;
  analyticsConfig: ComponentProps<typeof AnalyticsProvider>["config"];
  apiOrigin: string;
  apiStream: string;
  apiURLSignatureEnabled: boolean;
  appId: string;
  appSyncApiKey: string;
  appSyncUrl: string;
  appUrl: string;
  authSharedSignInEnabled: string;
  authSignInUrl: string;
  authSignOutUrl: string;
  authTokensSetUrl: string;
  buildNumber: string;
  clientKey: string;
  env: Env;
  errorTrackingConfig: ComponentProps<typeof ErrorTrackingProvider>["config"];
  featureFlags: Partial<FeatureFlags>;
  isBot: boolean;
  prNumber?: number;
  signOutURL?: string;
  ssrProps: SSRProps | null;
  userAgent: string | undefined;
  stripePriceId?: string;
};

type PrePortalProps = Pick<
  AppProps,
  | "accountURL"
  | "analyticsConfig"
  | "apiOrigin"
  | "apiStream"
  | "apiURLSignatureEnabled"
  | "appId"
  | "appSyncApiKey"
  | "appSyncUrl"
  | "authSharedSignInEnabled"
  | "authSignInUrl"
  | "buildNumber"
  | "clientKey"
  | "env"
  | "errorTrackingConfig"
  | "featureFlags"
  | "prNumber"
  | "signOutURL"
  | "ssrProps"
  | "stripePriceId"
>;

export default App;
