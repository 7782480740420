import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { InAppBrowser } from "react-native-inappbrowser-reborn";

import { useCaptcha, withCaptchaProvider } from "../../captcha";
import {
  queries,
  useClientQuery,
  useDataFetchingClient,
  useMutation,
} from "../../data-fetching";
import { useErrorTracking } from "../../error-tracking";
import {
  CredentialPostData,
  CredentialPostVars,
  CrowdScoreGetData,
  CrowdScoreGetVars,
  TokenGetData,
  TokenGetVars,
  User,
  UserGetData,
  UserGetVars,
} from "../../types";
import { encodeURL, getSignInURL, storage } from "../../utils";
import {
  AUTH_ACCOUNT_MANAGE_PATH,
  AUTH_ACCOUNT_SUBSCRIPTIONS_PATH,
  AUTH_CONTRIBUTION_PREFERENCES_PATH,
  AUTH_DEFAULT_SIGN_IN_URL,
} from "../constants";
import { getAccountURLWithAuth } from "../utils";

import AuthContext, { ContextType } from "./AuthContext";
import { redirectTo, redirectToSignInPage } from "./redirects";
import {
  getUserToken as getStoredUserToken,
  removeUserToken,
  removeRedirectUrl,
} from "./storage";
import { AccountLinks } from "./types";
import { fetchUser, getRedirectOrigin, getUserToken } from "./utils";

const NativeAuthProvider = ({
  accountURL,
  appId,
  appScheme,
  automaticSignIn = false,
  children,
  defaultAuthState = undefined,
  inApp,
  loadingComponent,
  renderError,
  signInURL,
}: PropsWithChildren<Props>) => {
  const dataFetchingClient = useDataFetchingClient();
  const errorTracking = useErrorTracking();
  const captcha = useCaptcha();

  const [state, setState] = useState<AuthState | undefined>(defaultAuthState);
  const [error, setError] = useState<Error | string | unknown | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { query: tokenFetcher } = useClientQuery<TokenGetData, TokenGetVars>(
    queries.tokenGet
  );

  const { query: userFetcher } = useClientQuery<UserGetData, UserGetVars>(
    queries.userGet
  );

  const { query: userPictureFetcher } = useClientQuery<
    CrowdScoreGetData,
    CrowdScoreGetVars
  >(queries.crowdScoreGet);

  const [credentialPost] = useMutation<CredentialPostData, CredentialPostVars>(
    queries.credentialPost
  );

  const onSignIn = useCallback(
    (user: User, userToken: string) => {
      errorTracking.setUser(user.user_id);

      setState({
        isLoggedIn: true,
        user,
        userToken,
      });
    },
    [errorTracking]
  );

  const onReset = useCallback(() => {
    removeUserToken(storage);
    removeRedirectUrl(storage);

    errorTracking.clearUser();

    setState({ isLoggedIn: false, user: null, userToken: null });
  }, [errorTracking]);

  const newSignIn = useCallback(
    async (userToken: string, redirectURL?: string) => {
      const inAppAuthentication = inApp && (await InAppBrowser.isAvailable());

      if (inAppAuthentication) {
        inAppSignIn(userToken);
      } else {
        redirectToSignInPage(
          appId,
          userToken,
          signInURL || AUTH_DEFAULT_SIGN_IN_URL,
          redirectURL ?? getRedirectOrigin(appScheme)
        );
      }
    },
    [appId, appScheme, inApp, signInURL]
  );

  const signIn = useCallback(
    async (redirectURL?: string) => {
      if (!state?.isLoggedIn) {
        setLoading(true);

        try {
          const { isNew, userToken } = await getUserToken(tokenFetcher, appId);

          if (isNew) {
            newSignIn(userToken, redirectURL);
          } else {
            const user = await fetchUser({
              appId,
              userFetcher,
              userPictureFetcher,
              userToken,
            });

            if (user) {
              removeRedirectUrl(storage);

              errorTracking.setUser(user.user_id);

              setLoading(false);

              onSignIn(user, userToken);
            } else {
              removeUserToken(storage);

              signIn();
            }
          }
        } catch {
          // Captcha redirect is handled inside `authUtils.ts`
          setLoading(false);
          setError("could not sign in");
        }
      }
    },
    [
      appId,
      errorTracking,
      newSignIn,
      onSignIn,
      state?.isLoggedIn,
      tokenFetcher,
      userFetcher,
      userPictureFetcher,
    ]
  );

  const signOut = useCallback(
    async (redirectURL?: string) => {
      if (state?.isLoggedIn) {
        setLoading(true);

        await credentialPost({
          variables: {
            app_id: appId,
            body: {
              credential_list: [],
            },
            usertoken: state.userToken || undefined,
          },
        });

        captcha.clear();

        onReset();

        dataFetchingClient.clearStore();
        setLoading(false);

        if (redirectURL) {
          redirectTo(redirectURL);
        }
      }
    },
    [
      appId,
      captcha,
      credentialPost,
      dataFetchingClient,
      onReset,
      state?.isLoggedIn,
      state?.userToken,
    ]
  );

  const getSignedInData = useCallback(async () => {
    const userToken = await getStoredUserToken(storage);

    if (!userToken) {
      return undefined;
    }

    const user = await fetchUser({
      appId,
      userFetcher,
      userPictureFetcher,
      userToken,
    });

    if (!user) {
      return undefined;
    }

    return {
      user,
      userToken,
    };
  }, [appId, userPictureFetcher, userFetcher]);

  const initialize = useCallback(async () => {
    if (automaticSignIn) {
      signIn();
    } else {
      const signedInData = await getSignedInData();

      if (signedInData !== undefined) {
        onSignIn(signedInData.user, signedInData.userToken);
      } else {
        onReset();
      }

      setLoading(false);
    }
  }, [automaticSignIn, signIn, getSignedInData, onSignIn, onReset]);

  const inAppSignIn = useCallback(
    async (userToken: string) => {
      const url = getSignInURL(
        appId,
        userToken,
        encodeURL(getRedirectOrigin(appScheme)),
        signInURL
      );

      try {
        const result = await InAppBrowser.openAuth(
          url,
          getRedirectOrigin(appScheme),
          {
            forceCloseOnRedirection: true,
          }
        );

        if (result.type === "cancel" || result.type === "dismiss") {
          setLoading(false);

          return onReset();
        }

        initialize();
      } catch (error) {
        console.error("[NativeAuthProvider] InAppBrowser error:", error);
      }
    },
    [appId, appScheme, initialize, onReset, signInURL]
  );

  const getLinks = useCallback((): AccountLinks => {
    return {
      contributionPreference: state?.userToken
        ? getAccountURLWithAuth(
            accountURL + AUTH_CONTRIBUTION_PREFERENCES_PATH,
            appId,
            state.userToken
          )
        : null,
      manage: state?.userToken
        ? getAccountURLWithAuth(
            accountURL + AUTH_ACCOUNT_MANAGE_PATH,
            appId,
            state.userToken
          )
        : null,
      subscriptions: state?.userToken
        ? getAccountURLWithAuth(
            accountURL + AUTH_ACCOUNT_SUBSCRIPTIONS_PATH,
            appId,
            state.userToken
          )
        : null,
    };
  }, [accountURL, appId, state?.userToken]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (error !== undefined) {
    return renderError ? renderError(error) : null;
  }

  if (!state || loading) {
    return loadingComponent;
  }

  return (
    <AuthContext.Provider
      value={{
        appId,
        getLinks,
        isLoggedIn: state.isLoggedIn,
        signIn,
        signOut,
        user: state.user,
        userToken: state.userToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

type AuthState = Pick<ContextType, "isLoggedIn" | "user" | "userToken">;

interface Props {
  accountURL: string;
  appId: string;
  appScheme?: string;
  automaticSignIn: boolean;
  inApp?: boolean;
  loadingComponent: ReactElement<any, any> | null;
  renderError?: (error: unknown) => ReactElement<any, any> | null;
  signInURL?: string;
  defaultAuthState?: AuthState;
}

export default withCaptchaProvider(NativeAuthProvider);
