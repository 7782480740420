import { useLinkProps } from "./react-navigation/native";
import { UseLink, UseLinkProps } from "./types";

export const useReactNavigationLink = ({ to }: UseLinkProps): UseLink => {
  const { href, onPress } = useLinkProps({ to });

  return {
    href,
    onPress,
  };
};
