import {
  Episode,
  SyncedSection,
  UnsyncedSection,
} from "@mxmdev/podcasts-shared";
import { useDialogContext } from "@mxmdev/react-universal-components";
import { OpenDialogConfig } from "@mxmdev/react-universal-components/components/dialogs/DialogProvider/types";
import { useCallback, useState } from "react";

import { ShareSectionDialog } from "./ShareSectionDialog";

const useShareSectionDialog = (): {
  isOpen: boolean;
  openShareSection: ({
    episode,
    initialTab,
    section,
    stackTop,
    withOverlay,
    withTabs,
  }: Props) => Promise<void>;
} => {
  const { openDialog } = useDialogContext();
  const [isOpen, setIsOpen] = useState(false);

  const openShareSection = useCallback(
    async ({
      episode,
      initialTab = "image",
      section,
      stackTop,
      withOverlay = true,
      withTabs,
    }: Props) => {
      setIsOpen(true);
      await openDialog(ShareSectionDialog, {
        episode,
        initialTab,
        section,
        stackTop,
        withOverlay,
        withTabs,
      });
      setIsOpen(false);
    },
    [openDialog]
  );

  return {
    isOpen,
    openShareSection,
  };
};

type Props = {
  episode: Pick<Episode, "id" | "podcastId" | "collection" | "name">;
  initialTab?: "image" | "embed";
  section?: UnsyncedSection | SyncedSection;
  withTabs?: boolean;
  withOverlay?: boolean;
} & Pick<OpenDialogConfig, "stackTop">;

export default useShareSectionDialog;
