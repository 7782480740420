import { ComponentProps, useEffect, useRef } from "react";

import { Popover } from "../components";

type Props = Pick<ComponentProps<typeof Popover>, "visible" | "onDismiss">;

const useEscapeListener = ({ onDismiss, visible }: Props) => {
  const dismiss = useRef(onDismiss);

  dismiss.current = onDismiss;

  useEffect(() => {
    const handleKeypress = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        dismiss.current?.();
      }
    };

    const attachListeners = () => {
      document.addEventListener("keyup", handleKeypress);
    };

    const removeListeners = () => {
      document.removeEventListener("keyup", handleKeypress);
    };

    if (visible) {
      attachListeners();
    } else {
      removeListeners();
    }

    return () => {
      removeListeners();
    };
  }, [visible]);
};

export default useEscapeListener;
