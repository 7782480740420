import { Linking } from "react-native";

import { canUseDOM, encodeURL, isNativePlatform } from "../../utils";
import { MODE_LEGACY } from "../constants";

export const redirectTo = (url: string) => {
  if (canUseDOM) {
    window.location.replace(url);
  }

  if (isNativePlatform()) {
    Linking.openURL(url);
  }
};

export const redirectToCaptchaPage = (
  captchaURL: string,
  callbackURL: string
) => {
  redirectTo(`${captchaURL}?callback_url=${callbackURL}`);
};

export const redirectToSignInPage = (
  appId: string,
  userToken: string,
  signInURL: string,
  origin: string
) => {
  redirectTo(
    `${signInURL}?mode=${MODE_LEGACY}&app_id=${appId}&usertoken=${userToken}&origin=${encodeURL(
      origin
    )}`
  );
};
