import { z } from "zod";

import { entitySchema } from "../common/entity";

export const speakerSchema = z
  .object({
    generic: z.optional(z.boolean()),
    name: z.optional(z.string()),
    slug: z.optional(z.string()),
    thumbnail_url: z.optional(z.string()),
    transcription_id: z.string(),
    wikidata_id: z.optional(z.string()),
  })
  .passthrough();

export const speakerQuoteSchema = z.object({
  end_char: z.number(),
  end_time: z.number(),
  explicit: z.optional(z.boolean()),
  podcast: z.object({
    artworkUrl: z.optional(z.string()),
    collectionName: z.string(),
    mxmShowId: z.string(),
    slug: z.string(),
  }),
  podcast_episode: z.object({
    artworkUrl: z.optional(z.string()),
    isoDate: z.string(),
    mxmEpisodeId: z.string(),
    releaseDate: z.string(),
    slug: z.string(),
    trackName: z.string(),
  }),
  quote_reason: z.string(),
  quote_text: z.string(),
  start_char: z.number(),
  start_time: z.number(),
});

export const speakerGetSchema = z.object({
  podcast_speaker: speakerSchema
    .extend({
      facebook_id: z.optional(z.string()),
      instagram_id: z.optional(z.string()),
      itunes_performer_id: z.optional(z.string()),
      mxm_performer_id: z.optional(z.string()),
      mxmSpeakerId: z.string(),
      quote_extraction: z.optional(z.array(speakerQuoteSchema)),
      spotify_performer_id: z.optional(z.string()),
      tiktok_username: z.optional(z.string()),
      twitter_username: z.optional(z.string()),
      website: z.optional(z.string()),
      wikidata: entitySchema,
      youtube_channel_id: z.optional(z.string()),
    })
    .omit({ transcription_id: true }),
});
