import {
  Button,
  DialogComponent,
  DialogCustom,
  IconClose,
  useMediaQuery,
  useTheme,
} from "@mxmdev/react-universal-components";
import { isNativePlatform } from "@mxmdev/react-universal-core";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import { useState } from "react";
import { LayoutRectangle, View } from "react-native";

import { CustomWebView } from "../../components";

import styles from "./styles";

const AccountWebView: DialogComponent<Props> = ({ onSignOut, ...props }) => {
  const [containerLayout, setContainerLayout] = useState<LayoutRectangle>();
  const { style } = useTheme(styles, {
    webViewHeight: containerLayout?.height,
    webViewWidth: containerLayout?.width,
  });
  const { isSmallScreen } = useMediaQuery();
  const { getLinks } = useAuth();
  const { manage: manageAccountURL } = getLinks();

  return (
    <DialogCustom
      {...props}
      closeButton={false}
      internalScroll={false}
      mobileFullscreen
      // workaround to prevent the dialog shrinking on tablets bug
      style={!isSmallScreen && !!isNativePlatform ? style.dialog : undefined}
    >
      <Button
        Icon={IconClose}
        minTouchTarget={false}
        onPress={props.onDismiss}
        size="small"
        style={style.closeButton}
        type="basic"
      />
      <View
        onLayout={(event) => {
          setContainerLayout(event.nativeEvent.layout);
        }}
        style={style.webViewContainer}
      >
        <CustomWebView
          handleNavigationStateChanged={(navigation) => {
            if (navigation.url === "https://www.musixmatch.com/") {
              props.onDismiss?.();
              onSignOut?.();
            }
          }}
          style={style.webView}
          urlToLoad={manageAccountURL?.href ?? ""}
        />
      </View>
    </DialogCustom>
  );
};

interface Props {
  onSignOut?: () => void;
}

export default AccountWebView;
