import { useMemo, PropsWithChildren } from "react";
import {
  ActivityIndicator as BasicIndicator,
  ActivityIndicatorProps,
} from "react-native";

import { useTheme } from "../../styles/themes";

import styles from "./styles";

const useActivityColor = (color: Color): string => {
  const { theme } = useTheme();
  const colors = useMemo((): Record<Color, string> => {
    return {
      black: theme.backgroundPrimaryInverted,
      brand: theme.paletteBrand100,
      white: theme.contentPrimaryInvertedStatic,
    };
  }, [theme]);

  return colors[color];
};

const ActivityIndicator = ({
  color: colorProp,
  colorCustom,
  size = "large",
  style: styleProp,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const { config, style } = useTheme(styles);
  const color = useActivityColor(
    colorProp || config?.activityIndicatorColor || "brand"
  );

  return (
    <BasicIndicator
      {...otherProps}
      color={colorCustom || color}
      size={size}
      style={[style.default, styleProp]}
    />
  );
};

export type Color = "black" | "brand" | "white";

interface Props extends ActivityIndicatorProps {
  color?: Color;
  colorCustom?: string;
}

export default ActivityIndicator;
