import {
  AbsolutePositionedTopicOccurrence,
  SyncedSection,
  SyncedSectionWithoutTopics,
  SyncedTranscription,
  UnsyncedSection,
  UnsyncedSectionWithTopics,
  UnsyncedTranscription,
} from "@mxmdev/podcasts-shared";

export const findClosestSyncedSection = (
  target: UnsyncedSection,
  syncedSections: readonly SyncedSection[]
): SyncedSection => {
  let closestSection = undefined;
  let closestDistance = 100000000;

  for (const syncedSection of syncedSections) {
    const distance = Math.abs(
      syncedSection.startPosition - target.startPosition
    );

    if (distance < closestDistance) {
      closestDistance = distance;
      closestSection = syncedSection;
    }
  }

  if (!closestSection) {
    throw new Error("could not find closest section");
  }

  return closestSection;
};

export const getSectionStartTime = (
  section: UnsyncedSection | SyncedSectionWithoutTopics
): number => {
  return section.variant === "synced"
    ? section.startTime
    : section.referenceStartTime;
};

export const extractAbsolutePositionedTopicOccurrencesFromSections = (
  sections: readonly UnsyncedSectionWithTopics[]
): AbsolutePositionedTopicOccurrence[] => {
  return sections.flatMap((section) =>
    section.topics.map((topic) => {
      const { endPosition, startPosition, ...topicWithoutRelativePositions } =
        topic;

      return {
        ...topicWithoutRelativePositions,
        // Convert to absolute positions
        absoluteEndPosition: section.startPosition + topic.endPosition,
        absoluteStartPosition: section.startPosition + topic.startPosition,
      };
    })
  );
};

export const findSectionAtTime = (
  sections: readonly UnsyncedSection[],
  time: number
): UnsyncedSection | undefined => {
  return sections.find(
    (s) => time >= s.referenceStartTime && time < s.referenceEndTime
  );
};

export const unsyncedTranscriptionToSynced = (
  unsyncedTranscription: UnsyncedTranscription
): SyncedTranscription => {
  return {
    ...unsyncedTranscription,
    syncedSections: unsyncedTranscription.sections.map((section) => ({
      ...section,
      endTime: section.referenceEndTime,
      startTime: section.referenceStartTime,
      variant: "synced",
    })),
  };
};

export const getUnsyncedTranscriptionGroupsText = (
  unsyncedTranscription: UnsyncedTranscription
): string[] => {
  return unsyncedTranscription.sections
    .filter((section) => !section.isAds)
    .map((section) => {
      const speaker = unsyncedTranscription.speakers.find(
        (speaker) => speaker.id === section.speakerId
      );

      const speakerName =
        speaker && speaker.type !== "generic"
          ? speaker.name.toUpperCase()
          : null;

      const text = section.transcript;

      return speakerName ? `${speakerName}: ${text}` : text;
    });
};
