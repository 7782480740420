import {
  PodcastsColor,
  PodcastsPalette,
  podcastsLightPalette,
} from "../../theme";
import {
  APISpeaker,
  BasicSpeaker,
  GenericSpeaker,
  NamedSpeaker,
  PlaceholderSpeaker,
  RichSpeaker,
  Speaker,
  SpeakerCommon,
} from "../../types";
import { convertSpeakerIdToHumanLabel, thumbnailOfSize } from "../../utils";

export const mapAPISpeakerToSpeakerCommon = (
  speaker: APISpeaker
): SpeakerCommon => {
  if (speaker.generic) {
    return {
      type: "generic",
    };
  } else if (speaker.wikidata_id && speaker.name) {
    return {
      mxmSpeakerId: speaker.mxmSpeakerId,
      name: speaker.name,
      thumbnailUrl: speaker.thumbnail_url,
      type: "rich",
      wikidataId: speaker.wikidata_id,
    };
  } else if (speaker.name) {
    return {
      name: speaker.name,
      type: "basic",
    };
  } else {
    return {
      // Convert to `Speaker 1` format in case we don't have the name
      name: convertSpeakerIdToHumanLabel(speaker.transcription_id),
      type: "placeholder",
    };
  }
};

type UnrefinedSpeaker =
  | Omit<RichSpeaker, "colors">
  | Omit<BasicSpeaker, "colors">
  | Omit<GenericSpeaker, "colors">
  | Omit<PlaceholderSpeaker, "colors">;

export const mapAPISpeakersToSpeakers = (
  apiSpeakers: readonly APISpeaker[]
): Speaker[] => {
  const speakersWithoutColors = apiSpeakers.map(
    mapAPISpeakerToUnrefinedSpeaker
  );
  const sortedSpeakersWithoutColors = sortSpeakersByType(speakersWithoutColors);
  const speakers: Speaker[] = sortedSpeakersWithoutColors.map(refineSpeaker);

  return speakers;
};

/**
 * The backend is returning the same speaker multiple times with
 * different ids, so we need to deduplicate them by name.
 *
 * We don't handle this in the transformer to avoid interfering with
 * the editor logic.
 */
export const getShowableSpeakers = (
  speakers: readonly Speaker[]
): NamedSpeaker[] => {
  const addedNames: Set<string> = new Set();
  const namedSpeakers = speakers.filter(speakerIsNamed);

  return namedSpeakers.filter((speaker) => {
    if (addedNames.has(speaker.name)) {
      return false;
    }

    addedNames.add(speaker.name);

    return true;
  });
};

const mapAPISpeakerToUnrefinedSpeaker = (
  speakerCommon: APISpeaker
): UnrefinedSpeaker => {
  return {
    ...mapAPISpeakerToSpeakerCommon(speakerCommon),
    id: speakerCommon.transcription_id,
  };
};

const refineSpeaker = (speaker: UnrefinedSpeaker, idx: number): Speaker => {
  const colors = speakerColors(
    podcastsLightPalette,
    idx,
    speaker.type === "generic"
  );

  if (speaker.type === "basic" && !speaker.name) {
    return {
      ...speaker,
      colors,
      name: convertSpeakerIdToHumanLabel(speaker.id),
      type: "placeholder",
    };
  }

  if (speaker.type === "rich") {
    return {
      ...speaker,
      colors,
      thumbnailUrl: speaker.thumbnailUrl
        ? thumbnailOfSize(speaker.thumbnailUrl, 100)
        : undefined,
    };
  }

  return {
    ...speaker,
    colors,
  };
};

/**
 * Sorts the rich and basic speakers to be before the generic and placeholder
 * ones.
 *
 * The rich and basic speakers are sorted in the order they appear in the
 * original array.
 */
const sortSpeakersByType = (
  speakers: readonly UnrefinedSpeaker[]
): UnrefinedSpeaker[] => {
  return [...speakers].sort((s1, s2) => {
    const isGenericS1 = s1.type === "generic";
    const isGenericS2 = s2.type === "generic";

    if (isGenericS1 && isGenericS2) {
      return 0;
    } else if (isGenericS1 && !isGenericS2) {
      return 1;
    } else if (!isGenericS1 && isGenericS2) {
      return -1;
    } else {
      // !isGenericS1 && !isGenericS2
      return 0;
    }
  });
};

export const mapSpeakerLabelToPlaceholderSpeaker = (
  speakerLabel: string,
  idx: number | undefined
): PlaceholderSpeaker => {
  const index = idx ?? Number(speakerLabel.match(/\d+/)?.[0]);

  const base: Omit<PlaceholderSpeaker, "colors"> = {
    id: speakerLabel,
    name: convertSpeakerIdToHumanLabel(speakerLabel),
    type: "placeholder",
  };

  return {
    ...base,
    colors: speakerColors(podcastsLightPalette, index, false),
  };
};

export const speakerColors = (
  theme: PodcastsPalette,
  speakerIdx: number,
  isGeneric: boolean
): PodcastsColor => {
  if (isGeneric) {
    return theme.grey;
  }

  switch (speakerIdx % 8) {
    case 0:
      return theme.blue;
    case 1:
      return theme.teal;
    case 2:
      return theme.yellow;
    case 3:
      return theme.pink;
    case 4:
      return theme.cyan;
    case 5:
      return theme.green;
    case 6:
      return theme.orange;
    case 7:
      return theme.purple;
    default:
      return theme.grey;
  }
};

export const speakerIsNamed = (speaker: Speaker): speaker is NamedSpeaker => {
  return speaker.type === "basic" || speaker.type === "rich";
};
