import { ImageResizeFormat } from "../types";

export const getResizedImageUrl = (
  url: string,
  width: number,
  height: number,
  format: ImageResizeFormat = "auto"
) => {
  const encodedUrl = encodeURIComponent(url);

  return `https://s.mxmcdn.net/dynamic-images/image/${width}/${height}/${format}/${encodedUrl}`;
};
