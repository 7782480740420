import { Path } from "react-native-svg";

import createIcon from "../createIcon";

export const IconExternalLink = createIcon(
  <>
    <Path d="M22 3.6v16.8c0 .882-.718 1.6-1.6 1.6H8a1 1 0 1 1 0-2h12V4H4v12a1 1 0 1 1-2 0V3.6C2 2.718 2.718 2 3.6 2h16.8c.882 0 1.6.718 1.6 1.6z" />
    <Path d="M11 14.414l-6.793 6.793a.997.997 0 0 1-1.414 0 .997.997 0 0 1 0-1.414L9.586 13H8a1 1 0 0 1 0-2h4.003a.988.988 0 0 1 .704.293 1.009 1.009 0 0 1 .293.704V16a1 1 0 1 1-2 0v-1.586z" />
  </>
);
