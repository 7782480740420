import { HttpLink, HttpOptions } from "@apollo/client";
import { Platform } from "react-native";

import { canUseDOM } from "../../../utils";

import { ApolloClientOptions } from "./types";
import { encodeURL, signURL } from "./utils";

export const createProxiedHttpLink =
  (uri: string, linkOptions?: HttpOptions) =>
  ({
    apiOrigin,
    apiURLSignatureEnabled,
    clientKey,
  }: ApolloClientOptions): HttpLink =>
    new HttpLink({
      ...(Platform.OS === "web" && {
        fetch: async (url, config) =>
          fetch(
            apiURLSignatureEnabled && clientKey
              ? await signURL(encodeURL(url as string), clientKey)
              : url,
            config
          ),
      }),
      uri: (op) => {
        const params = op.getContext().params ?? {};

        const url = new URL(
          `${canUseDOM ? window.location.origin : apiOrigin}/${uri}`
        );

        Object.keys(params).forEach((name) => {
          if (params[name] !== undefined) {
            url.searchParams.append(name, params[name]);
          }
        });

        return url.toString();
      },
      ...linkOptions,
    });
