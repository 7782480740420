import { viewStyles, imageStyles, textStyles } from "../../styles/";
import global, { ComponentSize } from "../../styles/global";
import { Theme } from "../../styles/themes";

import { Shape } from "./Avatar";

const sizes = {
  default: global.size.default,
  large: global.size.large,
  small: global.size.small,
};

const shapes = {
  default: global.radius.default,
  round: null,
};

interface Props {
  hasBackground?: boolean;
  align: string;
  borderColor: string;
  maxWidth: number;
  shape: Shape;
  size: ComponentSize;
}

const styles = (
  theme: Theme,
  { align, borderColor, hasBackground, maxWidth, shape, size }: Props
) => {
  const radius = shape === "round" ? sizes[size] : shapes[shape];

  const borderWith = 2;

  const avatarGroupSize = (): number => {
    if (size === "large") {
      return global.size.large + borderWith * 2;
    }

    if (size === "small") {
      return global.size.small + borderWith * 2;
    }

    return global.size.default + borderWith * 2;
  };

  return {
    ...imageStyles({
      avatar: {
        // NOTE: button height is used to have rounded shape
        borderRadius: radius,
        height: "100%",
        width: "100%",
      },
    }),
    ...viewStyles({
      avatarContainer: {
        backgroundColor: borderColor ?? theme.backgroundPrimary,
        borderRadius: avatarGroupSize(),
        flexDirection: "row",
        height: avatarGroupSize(),
        padding: borderWith,
        width: avatarGroupSize(),
      },
      avatarIcon: {
        height: "100%",
        width: "100%",
      },
      boxContainer: {
        alignItems: "center",
        flexDirection: align === "right" ? "row-reverse" : "row",
        maxWidth: maxWidth ? maxWidth : undefined,
      },
      boxText: {
        marginHorizontal: global.spacing * 4,
      },
      boxTextContainer: {
        // NOTE: this prevents text overflow
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: "center",
      },
      container: {
        alignItems: "center",
        backgroundColor: hasBackground ? theme.backgroundSecondary : undefined,
        borderRadius: radius,
        height: sizes[size],
        justifyContent: "center",
        overflow: "hidden",
        width: sizes[size],
      },
      groupContainer: {
        flexDirection: "row",
      },
      groupCounter: {
        alignSelf: "center",
        borderColor: borderColor ?? theme.backgroundPrimary,
        borderRadius: avatarGroupSize(),
        borderWidth: borderWith,
        width: avatarGroupSize(),
      },
      icon: { position: "absolute" },
      iconContainer: {
        backgroundColor: borderColor ?? "transparent",
        borderColor: borderColor ?? "transparent",
        borderRadius: avatarGroupSize(),
        borderWidth: borderWith,
        flexDirection: "row",
        height: avatarGroupSize(),
        padding: borderWith,
        width: avatarGroupSize(),
      },
      repositioned: {
        marginLeft: size === "small" ? -global.spacing : -global.spacing * 2,
      },
    }),
    ...textStyles({
      counterText: {
        textAlign: "center",
      },
    }),
  };
};

export default styles;
