import { Episode } from "@mxmdev/podcasts-shared";

import { ResolvedEpisode } from "../../types";

import { AudioPlayer, PlayerEventListener, StateUpdateListener } from ".";

// This is currently a mockup
class MobileAudioPlayer implements AudioPlayer {
  initialize = (): Promise<void> => {
    return Promise.resolve();
  };

  addEpisodes = (episodes: readonly ResolvedEpisode[]): Promise<void> => {
    return Promise.resolve();
  };

  reset = (): Promise<void> => {
    return Promise.resolve();
  };

  loadCurrentEpisode = async (): Promise<void> => {
    return Promise.resolve();
  };

  play = async (): Promise<void> => {
    return Promise.resolve();
  };

  pause = (): Promise<void> => {
    return Promise.resolve();
  };

  seek = (time: number): Promise<void> => {
    return Promise.resolve();
  };

  seekDelta = (deltaTime: number): Promise<void> => {
    return Promise.resolve();
  };

  next = async (): Promise<void> => {
    return Promise.resolve();
  };

  previous = async (): Promise<void> => {
    return Promise.resolve();
  };

  setPlaybackRate = (rate: number): Promise<void> => {
    return Promise.resolve();
  };

  replaceAudioTrack = (trackUrl: string): Promise<void> => {
    return Promise.resolve();
  };

  setIndex = async (index: number): Promise<void> => {
    return Promise.resolve();
  };

  addStateUpdateListener = (listener: StateUpdateListener): void => {};

  removeStateUpdateListener = (listener: StateUpdateListener): void => {};

  addEventListener = (listener: PlayerEventListener): void => {};

  removeEventListener = (listener: PlayerEventListener): void => {};

  configureUrlResolveFallback = (
    fetcher: (episode: Episode) => Promise<string>
  ): void => {
    // TODO
  };

  destroy = (): Promise<void> => {
    return Promise.resolve();
  };
}

export const createMobileAudioPlayer = (): AudioPlayer => {
  return new MobileAudioPlayer();
};
