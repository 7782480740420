import { podcastsLightPalette, PodcastsPalette } from "@mxmdev/podcasts-shared";
import {
  Theme,
  useTheme as useUnsafeTheme,
  useMediaQuery,
  global,
} from "@mxmdev/react-universal-components";
import { useMemo } from "react";
import { StyleProp } from "react-native";
import { useMediaQuery as useResponsiveMediaQuery } from "react-responsive";

import customGlobal from "../styles/customGlobal";

// This is a Typesafe wrapper around Ritmo's useTheme
export const useTheme = <StyleSheet>(
  styles: Styles<undefined, StyleSheet>
): UseThemeComponents<StyleSheet> => {
  const { style, theme } = useUnsafeTheme(styles);

  return { palette: podcastsLightPalette, style, theme };
};

export type UseThemeComponents<StyleType> = {
  style: StyleType;
  theme: Theme;
  palette: PodcastsPalette;
};

type StyleValue = {
  [key: string]: boolean | null | number | string | undefined;
};

export type Styles<StyleProps extends StyleValue | undefined, StyleSheet> = (
  theme: Theme,
  styleProps: StyleProps
) => StyleProp<StyleSheet>;

export const createStyles = <
  TParams extends StyleValue | undefined = undefined,
  TStyleSheet = object
>(
  stylesBuilder: Styles<TParams, TStyleSheet>
): Styles<TParams, TStyleSheet> => {
  return stylesBuilder;
};

/// Responsive versions

export const useResponsiveTheme = <StyleSheet>(
  styles: Styles<MediaQueryProps, StyleSheet>
): UseResponsiveThemeComponents<StyleSheet> => {
  const { isLargeScreen, isMediumScreen, isSmallScreen } = useMediaQuery();

  const isExtraLargeScreen = useResponsiveMediaQuery({
    minWidth: customGlobal.breakpoints.extraLargeScreen,
  });

  const mediaProps = useMemo(() => {
    const targetMaxWidth = calculateTargetMaxWidth(
      isLargeScreen,
      isExtraLargeScreen
    );

    return {
      isExtraLargeScreen,
      isLargeScreen,
      isMediumScreen,
      isSmallScreen,
      targetMaxWidth,
    };
  }, [isExtraLargeScreen, isLargeScreen, isMediumScreen, isSmallScreen]);

  const { style, theme } = useUnsafeTheme(styles, mediaProps);

  return {
    palette: podcastsLightPalette,
    style,
    theme,
    ...mediaProps,
  };
};

export type MediaQueryProps = {
  isExtraLargeScreen: boolean;
  isLargeScreen: boolean;
  isSmallScreen: boolean;

  targetMaxWidth?: number;
};

export type UseResponsiveThemeComponents<StyleSheet> = MediaQueryProps &
  UseThemeComponents<StyleSheet>;

export type ResponsiveStyles<StyleProps extends MediaQueryProps, StyleSheet> = (
  theme: Theme,
  styleProps: StyleProps
) => StyleProp<StyleSheet>;

export const createResponsiveStyles = <
  TParams extends StyleValue = MediaQueryProps,
  TStyleSheet = object
>(
  stylesBuilder: Styles<TParams, TStyleSheet>
): Styles<TParams, TStyleSheet> => {
  return stylesBuilder;
};

const calculateTargetMaxWidth = (
  isLargeScreen: boolean,
  isExtraLargeScreen: boolean
): number | undefined => {
  if (isExtraLargeScreen) {
    return customGlobal.breakpoints.extraLargeScreen;
  }

  if (isLargeScreen) {
    return global.breakpoints.largeScreen;
  }

  return undefined;
};
