import { useMemo, PropsWithChildren } from "react";

import { AnalyticsContext } from "./analyticsUtils";
import {
  logEvent as logCustomerIOEvent,
  setUser as setCustomerIOUser,
} from "./providers/customerio";
import {
  analytics as firebaseAnalytics,
  FirebaseConfig,
} from "./providers/firebase";
import {
  analytics as intercomAnalytics,
  IntercomConfig,
} from "./providers/intercom";
import { logEvent as logMetaEvent } from "./providers/meta";
import {
  analytics as mixpanelAnalytics,
  MixpanelConfig,
} from "./providers/mixpanel";
import { AnalyticsContextValue, AnalyticsPlatform } from "./types";

interface Props {
  config?: {
    firebase: FirebaseConfig;
    intercom?: IntercomConfig;
    mixpanel?: MixpanelConfig;
  };
  platforms?: AnalyticsPlatform[];
}

export const DEFAULT_PROVIDER_PLATFORMS: AnalyticsPlatform[] = [
  "firebase",
  "customerio",
];

const AnalyticsProvider = ({
  children,
  config,
  platforms: providerPlatforms = DEFAULT_PROVIDER_PLATFORMS,
}: PropsWithChildren<Props>) => {
  const contextValue = useMemo((): AnalyticsContextValue => {
    const logFirebaseEvent = firebaseAnalytics.get(config?.firebase).logEvent;
    const {
      clearUser: resetIntercomUser,
      logEvent: logIntercomEvent,
      setUser: setIntercomUser,
    } = intercomAnalytics.get(config?.intercom);
    const {
      clearUser: resetMixpanelUser,
      logEvent: logMixpanelEvent,
      setUser: setMixpanelUser,
    } = mixpanelAnalytics.get(config?.mixpanel);

    return {
      clearUser: (platforms = ["mixpanel"]) => {
        platforms.forEach((platform) => {
          switch (platform) {
            case "intercom":
              resetIntercomUser();
              break;
            case "mixpanel":
              resetMixpanelUser();
              break;
            default:
              console.warn(
                `[Analytics] User not reset, because platform is unknown: ${platform}.`
              );
              break;
          }
        });
      },
      logEvent: (eventName, eventParams, platforms = providerPlatforms) => {
        platforms.forEach((platform) => {
          switch (platform) {
            case "customerio":
              logCustomerIOEvent(eventName, eventParams);
              break;
            case "firebase":
              logFirebaseEvent(eventName, eventParams);
              break;
            case "intercom":
              logIntercomEvent(eventName, eventParams);
              break;
            case "meta":
              logMetaEvent(eventName, eventParams);
              break;
            case "mixpanel":
              logMixpanelEvent(eventName, eventParams);
              break;
            default:
              console.warn(
                `[Analytics] Event '${eventName}' (params: ${JSON.stringify(
                  eventParams
                )}) dropped, because platform is unknown: ${platform}.`
              );
              break;
          }
        });
      },
      setUser: (user, userParams, platforms = DEFAULT_PROVIDER_PLATFORMS) => {
        platforms.forEach((platform) => {
          switch (platform) {
            case "customerio":
              setCustomerIOUser(user, userParams);
              break;
            case "firebase":
              // FIXME: this is a debug event and should be removed as soon as we figure out the underlying issue.
              // https://musixmatch.atlassian.net/browse/PLATHELP-389
              logFirebaseEvent("customerio_identify", {
                hasEmail: !!user.profile.email,
                hasId: !!user.profile.user_id,
                userType: user.profile.user_type,
              });
              break;
            case "intercom":
              setIntercomUser(user);
              break;
            case "mixpanel":
              setMixpanelUser(user);
              break;
            default:
              console.warn(
                `[Analytics] User with id '${
                  user.user_id
                }' (params: ${JSON.stringify(
                  userParams
                )}) not set, because platform is unknown: ${platform}.`
              );
              break;
          }
        });
      },
    };
  }, [config, providerPlatforms]);

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
