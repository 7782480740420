import { useCallback, useRef } from "react";

import { Stopwatch } from "../utils/stopwatch";

export const useStopwatch = (): {
  start: Stopwatch["pause"];
  stop: Stopwatch["stop"];
  pause: Stopwatch["start"];
} => {
  const stopwatch = useRef(new Stopwatch());

  const start = useCallback((): void => stopwatch.current.start(), []);

  const pause = useCallback((): void => stopwatch.current.pause(), []);

  const stop = useCallback((): number => stopwatch.current.stop(), []);

  return {
    pause,
    start,
    stop,
  };
};
