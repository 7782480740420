import { RestLink } from "apollo-link-rest";
import { Platform } from "react-native";

import { isResponseOk } from "../..";
import {
  ChartTracksGetData,
  LanguagesGetData,
  Response as MxmResponse,
  TerritoryGet,
  TerritoryPostResult,
  TrackSearchData,
  TracksGetData,
  WorkTerritoryListData,
  WorkTerritoryPostData,
} from "../../../types";
import { canUseDOM, handleUnauthorized, isServer } from "../../../utils";

import { DATA_HANDLERS, getDataVersion } from "./apolloRestUtils";
import { ApolloClientOptions } from "./types";
import { encodeURL, signURL } from "./utils";

const restLink = (options: ApolloClientOptions) => {
  const {
    apiOrigin,
    apiURLSignatureEnabled,
    clientKey,
    endpoints: apolloEndpoints,
    failOnMissingUserID,
    logError,
  } = options;

  let canHandleUnauthorized = true;

  const baseURL = canUseDOM ? window.location.origin : apiOrigin;

  const endpoints = apolloEndpoints
    ? Object.keys(apolloEndpoints).reduce((prev, curr) => {
        const value = apolloEndpoints[curr];

        if (typeof value === "string") {
          return {
            ...prev,
            [curr]: value.startsWith("http")
              ? value
              : new URL(value, baseURL).toString(),
          };
        }

        const { responseTransformer, uri } = value;

        return {
          ...prev,
          [curr]: {
            responseTransformer,
            uri: uri.startsWith("http")
              ? uri
              : new URL(uri, baseURL).toString(),
          },
        };
      }, {} as RestLink.Endpoints)
    : undefined;

  const uri = `${baseURL}/ws/1.1/`;

  const endpointsSignatureEnabled = apolloEndpoints
    ? Object.keys(apolloEndpoints).reduce(
        (prev, curr) => {
          const value = apolloEndpoints[curr];

          if (typeof value === "string") {
            const url = value.startsWith("http")
              ? value
              : new URL(value, baseURL).toString();

            return { ...prev, [url]: apiURLSignatureEnabled };
          }

          const { signatureEnabled, uri: apiURI } = value;

          const url = apiURI.startsWith("http")
            ? apiURI
            : new URL(apiURI, baseURL).toString();

          return {
            ...prev,
            [url]: signatureEnabled ?? apiURLSignatureEnabled,
          };
        },
        {
          [uri]: apiURLSignatureEnabled,
        }
      )
    : { [uri]: apiURLSignatureEnabled };

  const signatureKeys = Object.keys(endpointsSignatureEnabled);

  const isAPIURLSignatureEnabled = (url: string) => {
    let enabled = apiURLSignatureEnabled;

    signatureKeys.forEach((signatureKey) => {
      if (url.startsWith(signatureKey)) {
        enabled = endpointsSignatureEnabled[signatureKey];
      }
    });

    return enabled;
  };

  return new RestLink({
    ...(Platform.OS === "web" && {
      customFetch: async (url, config) =>
        fetch(
          isAPIURLSignatureEnabled(url as string) && clientKey
            ? await signURL(encodeURL(url as string), clientKey)
            : url,
          config
        ),
    }),
    endpoints,
    responseTransformer: async (
      response: Response,
      typeName
    ): Promise<MxmResponse<any>> => {
      if (!response) {
        logError(
          "Empty response.",
          {
            tags: { namespace: "data-fetching" },
          },
          false
        );

        return Promise.reject(new Error("empty response"));
      }

      const XMxmUserId = response.headers.get("x-mxm-user-id");

      if (XMxmUserId === "" && failOnMissingUserID) {
        console.warn("[apollo] Empty 'x-mxm-user-id' response header.");

        if (canHandleUnauthorized) {
          await handleUnauthorized("not_authorized", options);
          canHandleUnauthorized = false;
        }
      }

      return response
        .json()
        .then((data) => {
          const version = getDataVersion(data);
          const result = DATA_HANDLERS[version](response, data);

          const { body, header } = result;

          if (!isResponseOk(header.status_code)) {
            logError(
              "Response status KO.",
              {
                contexts: {
                  response: { header },
                },
                tags: { namespace: "data-fetching" },
              },
              false
            );

            if (header.status_code === 401) {
              if (canHandleUnauthorized && !isServer()) {
                handleUnauthorized(header.hint, options);
                canHandleUnauthorized = false;
              }

              if (isServer()) {
                console.warn(
                  "[apollo] 401 is not handled by apollo, make sure you handle it in getServerSideProps"
                );
              }
            }
          }

          if (!body) {
            console.warn("[apollo] Missing response body:", body);
          }

          return { body, header };
        })
        .catch((error) => {
          logError(error, { tags: { namespace: "data-fetching" } }, false);

          return Promise.reject(new Error("not a json response"));
        });
    },
    typePatcher: {
      ChartTracksGet: (data: ChartTracksGetData["chartTracksGet"]) => {
        if (data?.body?.track_list) {
          data.body.track_list = data.body.track_list.map((track) => ({
            track: {
              __typename: "Track",
              ...track.track,
            },
          }));
        }

        return data;
      },
      LanguagesGet: (data: LanguagesGetData["languagesGet"]) => {
        if (data?.body?.language_list) {
          data.body.language_list = data.body.language_list.map((language) => ({
            language: {
              __typename: "Language",
              ...language.language,
            },
          }));
        }

        return data;
      },
      TrackSearch: (data: TrackSearchData["trackSearch"]) => {
        if (data?.body?.track_list) {
          data.body.track_list = data.body.track_list.map((track) => ({
            track: {
              __typename: "Track",
              ...track.track,
            },
          }));
        }

        return data;
      },
      TracksGet: (data: TracksGetData["tracksGet"]) => {
        if (data?.body?.track_list) {
          data.body.track_list = data.body.track_list.map((track) => ({
            track: {
              __typename: "Track",
              ...track.track,
            },
          }));
        }

        return data;
      },
      WorkTerritoryList: (data: WorkTerritoryListData["workTerritoryList"]) => {
        if (data?.body) {
          const territoryList: Record<string, TerritoryGet> = {};

          Object.entries(data.body).forEach(([key, value]) => {
            territoryList[key] = {
              __typename: "Territory",
              ...value,
            } as TerritoryGet;
          });

          data.body = territoryList;
        }

        return data;
      },
      WorkTerritoryPost: (data: WorkTerritoryPostData["workTerritoryPost"]) => {
        if (data?.body) {
          data.body = {
            __typename: "Territory",
            ...data.body,
          } as TerritoryPostResult;
        }

        return data;
      },
    },
    uri,
  });
};

export default restLink;
