import {
  createResponsiveStyles,
  useResponsiveTheme,
} from "@mxmdev/podcasts-shared-native";
import { viewStyles } from "@mxmdev/react-universal-components";
import { createContext, useCallback, useState, PropsWithChildren } from "react";
import { View } from "react-native";

import { useRouteChange } from "../../navigation/RootNavigation";
import { RootRoute, BackAction } from "../../navigation/types";
import { isStudioRoute } from "../../util/urlUtils";

import BannerBeta from "./BannerBeta";
import MainHeader from "./MainHeader";
import StudioHeader from "./StudioHeader";
import { useBannerBeta } from "./useBannerBeta";

export type MainHeaderContextData = {
  setAlternativeLeft: (element: JSX.Element | undefined) => void;
  setFallbackBackAction: (
    fallbackBackAction: BackAction<RootRoute> | undefined
  ) => void;
  setShowAlternativeLeft: (show: boolean) => void;
  setShowMainHeader: (show: boolean) => void;
};

export const MainHeaderContext = createContext<MainHeaderContextData>({
  setAlternativeLeft: () => {
    throw new Error("calling setAlternativeLeft on an uninitialized context");
  },
  setFallbackBackAction: () => {
    throw new Error(
      "calling setFallbackBackAction on an uninitialized context"
    );
  },
  setShowAlternativeLeft: () => {
    throw new Error(
      "calling setShowAlternativeLeft on an uninitialized context"
    );
  },
  setShowMainHeader: () => {
    throw new Error("calling setShowMainHeader on an uninitialized context");
  },
});

const MainHeaderProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { isLargeScreen, isSmallScreen, style } = useResponsiveTheme(styles);

  const [fallbackBackAction, setFallbackBackAction] = useState<
    BackAction<RootRoute> | undefined
  >(undefined);

  const [showAlternativeLeft, setShowAlternativeLeft] =
    useState<boolean>(false);

  const [alternativeLeft, setAlternativeLeft] = useState<
    JSX.Element | undefined
  >(undefined);

  const [route, setRoute] = useState<RootRoute>();

  const isStudioScreen = route && isStudioRoute(route);

  const [showMainHeader, setShowMainHeader] = useState(true);

  const {
    dismissBanner,
    height: bannerBetaHeight,
    showBanner,
  } = useBannerBeta();

  useRouteChange(setRoute);

  const getIsSubheaderVisible = useCallback((): boolean => {
    // The subheader is visible only in the Studio
    if (!isStudioScreen) {
      return false;
    }

    // In the other pages we always show the subheader in non-large screens
    return !isLargeScreen;
  }, [isLargeScreen, isStudioScreen]);

  const mainHeaderHeight = isSmallScreen ? 48 : 60;

  const isSubheaderVisible = getIsSubheaderVisible();

  const subHeaderHeight = isSubheaderVisible ? (isSmallScreen ? 48 : 60) : 0;

  // Compensate for the header
  const marginStyle = showMainHeader
    ? { marginTop: mainHeaderHeight + subHeaderHeight + bannerBetaHeight }
    : undefined;

  return (
    <MainHeaderContext.Provider
      value={{
        setAlternativeLeft,
        setFallbackBackAction,
        setShowAlternativeLeft,
        setShowMainHeader,
      }}
    >
      <View style={[style.content, marginStyle]}>{children}</View>

      <View style={style.fixed}>
        {showBanner && showMainHeader && (
          <BannerBeta onHideBanner={dismissBanner} />
        )}

        <View style={bannerBetaHeight ? { top: bannerBetaHeight } : undefined}>
          {showMainHeader && (
            <>
              {isStudioScreen ? (
                <StudioHeader />
              ) : (
                <MainHeader
                  alternativeLeft={alternativeLeft}
                  fallbackBackAction={fallbackBackAction}
                  showAlternativeLeft={showAlternativeLeft}
                />
              )}
            </>
          )}
        </View>
      </View>
    </MainHeaderContext.Provider>
  );
};

const styles = createResponsiveStyles((theme) =>
  viewStyles({
    content: {
      flex: 1,
    },
    fixed: {
      backgroundColor: theme.backgroundPrimary,
      left: 0,
      // @ts-ignore React web only
      position: "fixed",
      right: 0,
      top: 0,
    },
  })
);

export default MainHeaderProvider;
