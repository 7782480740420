import {
  SyncedSection,
  SyncedSectionWithoutTopics,
  UnsyncedSectionWithTopics,
} from "@mxmdev/podcasts-shared";

export const remapTopicsToSyncedTranscription = (
  unsyncedSections: readonly UnsyncedSectionWithTopics[],
  syncedSections: readonly SyncedSectionWithoutTopics[]
): SyncedSection[] => {
  const allTopics = unsyncedSections.flatMap((section) =>
    section.topics.map((topic) => ({
      absolutePositions: {
        end: section.startPosition + topic.endPosition,
        start: section.startPosition + topic.startPosition,
      },
      topic,
    }))
  );

  const adsFinderHasSynced = syncedSections.some(
    (section) => section.referenceStartTime !== undefined
  );

  const syncedSectionsWithTopics: SyncedSection[] = syncedSections.map(
    (section, idx) => ({
      ...section,
      referenceEndTime: adsFinderHasSynced
        ? section.referenceEndTime
        : unsyncedSections[idx].referenceEndTime,
      referenceStartTime: adsFinderHasSynced
        ? section.referenceStartTime
        : unsyncedSections[idx].referenceStartTime,
      topics: [],
    })
  );

  let currentTopicIndex = 0;

  for (let i = 0; i < syncedSectionsWithTopics.length; i++) {
    const currentSection = syncedSectionsWithTopics[i];

    while (currentTopicIndex < allTopics.length) {
      const currentTopic = allTopics[currentTopicIndex];

      if (
        currentTopic.absolutePositions.start <= currentSection.endPosition &&
        currentTopic.absolutePositions.end >= currentSection.startPosition
      ) {
        currentSection.topics.push({
          ...currentTopic.topic,
          endPosition:
            currentTopic.absolutePositions.end - currentSection.startPosition,
          startPosition:
            currentTopic.absolutePositions.start - currentSection.startPosition,
        });
        currentTopicIndex++;
      } else {
        break;
      }
    }
  }

  return syncedSectionsWithTopics;
};

export const remapUnsyncedToSyncedSection = (
  unsyncedSections: readonly UnsyncedSectionWithTopics[]
): SyncedSectionWithoutTopics[] => {
  return unsyncedSections.map((section) => ({
    ...section,
    endTime: section.referenceEndTime,
    startTime: section.referenceStartTime,
    variant: "synced",
  }));
};
