import {
  getAnalytics,
  isSupported,
  logEvent as firebaseLogEvent,
} from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";

import { EventQueue } from "../../types";

import { FirebaseAnalytics, FirebaseConfig } from "./types";

const createEventQueue = (): {
  eventQueue: EventQueue;
  logEvent: FirebaseAnalytics["logEvent"];
} => {
  const eventQueue: EventQueue = [];

  return {
    eventQueue,
    logEvent: (eventName, eventParams) => {
      eventQueue.push({ eventName, eventParams });
    },
  };
};

const analytics = (() => {
  let client: FirebaseAnalytics;
  let apps: FirebaseApp[];

  const init = async (config?: FirebaseConfig): Promise<FirebaseAnalytics> => {
    const isMultiProperty = config && Array.isArray(config);

    const analyticsSupported = await isSupported();

    if (!analyticsSupported) {
      console.warn(
        `[Analytics] Firebase Analytics is not supported in this environment.`
      );

      return {
        logEvent: (eventName, eventParams) => {
          console.warn(
            `[Analytics] Firebase event '${eventName}' (params: ${JSON.stringify(
              eventParams
            )}) dropped because of unsupported environment for analytics.`
          );
        },
      };
    }

    if (
      !config ||
      (isMultiProperty && config.length === 0) ||
      (isMultiProperty ? config : [config]).some((config) =>
        Object.values(config).some(
          (value) =>
            value === undefined || value === null || value.trim() === ""
        )
      )
    ) {
      console.warn(
        `[Analytics] Firebase: Not able to initialize due to missing/incomplete config:`,
        config
      );

      return {
        logEvent: (eventName, eventParams) => {
          console.warn(
            `[Analytics] Firebase event '${eventName}' (params: ${JSON.stringify(
              eventParams
            )}) dropped, because analytics has not been properly initialized.`
          );
        },
      };
    }

    if (typeof window === "undefined") {
      return {
        logEvent: (eventName, eventParams) => {
          console.warn(
            `[Analytics] Firebase event '${eventName}' (params: ${JSON.stringify(
              eventParams
            )}) dropped, because analytics are not available in SSR`
          );
        },
      };
    }

    if (isMultiProperty) {
      apps = config.map((config, index) =>
        initializeApp(
          config,
          `firebase-${
            config.projectId
              ?.replace(/[^0-9a-zA-Z-]/g, "")
              .concat(`-${index}`) ?? index
          }`
        )
      );
    } else {
      apps = [initializeApp(config)];
    }

    const analyticsInstances = apps.map((app) => getAnalytics(app));

    return {
      logEvent: (eventName, eventParams) => {
        analyticsInstances.forEach((analytics) => {
          firebaseLogEvent(analytics, eventName, eventParams);
        });
      },
    };
  };

  const { eventQueue, logEvent } = createEventQueue();

  return {
    get: (config?: FirebaseConfig): FirebaseAnalytics => {
      if (!client) {
        let initializedLogEvent: FirebaseAnalytics["logEvent"];

        client = {
          logEvent: (eventName, eventParams) => {
            const logFn = initializedLogEvent ?? logEvent;

            logFn(eventName, eventParams);
          },
        };

        init(config).then(({ logEvent: newLogEvent }) => {
          initializedLogEvent = newLogEvent;

          eventQueue.forEach(({ eventName, eventParams }) => {
            newLogEvent(eventName, eventParams);
          });
        });
      }

      return client;
    },
  };
})();

export { analytics };
