import { EmbedCodeType } from "@mxmdev/podcasts-shared/src/types";
import { getEmbedCode } from "@mxmdev/podcasts-shared/src/utils";
import {
  Input,
  viewStyles,
  global,
  Button,
  Text,
  useToast,
  IconInfo,
} from "@mxmdev/react-universal-components";
import { useClipboard } from "@mxmdev/react-universal-core";
import { useCallback, useState } from "react";
import { View } from "react-native";

import { usePodcastsStudioAnalytics } from "../../hooks";
import { useEmbedCode } from "../../hooks/useEmbedCode";
import { createStyles, useTheme } from "../../utils";

import EmbedCode from "./EmbedCode";

export const EmbedTab = ({ episode }: Props) => {
  const { id, podcastId } = episode;

  const { style } = useTheme(styles);

  const {
    embedCode,
    fetchEmbedCode,
    isLoading: embedIdIsLoading,
  } = useEmbedCode({ episodeId: id, podcastId });
  const { showToast } = useToast();
  const [, copyValue] = useClipboard();

  const { logCopyEmbedCodeClicked } = usePodcastsStudioAnalytics();

  const [embedder, setEmbedder] = useState("");
  const [confirmedEmbedder, setConfirmedEmbedder] = useState("");
  const [embedCodeType, setEmbedCodeType] = useState<EmbedCodeType>("iframe");

  const handleFetchEmbedId = useCallback(
    (embedder: string) => {
      setConfirmedEmbedder(embedder);
      fetchEmbedCode([embedder]);
    },
    [fetchEmbedCode]
  );

  const handleCopyToClipboard = useCallback(
    (embedId: string) => {
      copyValue(getEmbedCode(embedCodeType, embedId));

      logCopyEmbedCodeClicked({
        episodeId: episode.id,
        episodeLanguage: episode.language,
        episodeName: episode.name,
        podcastId: episode.podcastId,
        podcastName: episode.collection,
      });

      showToast("Embed code copied");
    },
    [copyValue, episode, logCopyEmbedCodeClicked, embedCodeType, showToast]
  );

  return (
    <>
      <Text type="labelDefault">Enter your domain</Text>

      <View style={style.inputDomainRow}>
        <View style={style.inputDomainContainer}>
          <Input
            autoFocus
            onChange={(e): void => setEmbedder(e.nativeEvent.text)}
            placeholder="https://www.example.com"
            value={embedder}
          />
        </View>

        <Button
          color="brand"
          disabled={!embedder || confirmedEmbedder === embedder}
          isLoading={embedIdIsLoading}
          onPress={embedder ? () => handleFetchEmbedId(embedder) : undefined}
          text="Confirm"
        />
      </View>

      <View style={style.domainDescriptionRow}>
        <IconInfo style={style.infoIcon} />

        <Text style={style.description} type="bodyLarge">
          Insert the domain of the website that will contain the widget. Please
          note that the widget will work only on the provided domain.
        </Text>
      </View>

      {embedCode?.id ? (
        <EmbedCode
          embedId={embedCode.id}
          onEmbedCodeChange={setEmbedCodeType}
          selectedEmbedType={embedCodeType}
          style={style.embedCode}
        />
      ) : null}

      <View style={style.actionSection}>
        <Button
          color="brand"
          disabled={!embedCode?.id}
          onPress={
            embedCode?.id
              ? () => handleCopyToClipboard(embedCode.id)
              : undefined
          }
          text="Copy embed code"
          type="filled"
        />
      </View>
    </>
  );
};

const styles = createStyles(() =>
  viewStyles({
    actionSection: {
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: global.spacing * 7,
    },
    description: {
      marginLeft: global.spacing * 4,
    },
    domainDescriptionRow: {
      alignItems: "center",
      flexDirection: "row",
      marginTop: global.spacing * 3,
    },
    embedCode: {
      marginTop: global.spacing * 5,
    },
    hidden: {
      opacity: 0,
    },
    infoIcon: {
      flexShrink: 0,
    },
    inputDomainContainer: {
      flex: 1,
      marginRight: global.spacing * 2,
    },
    inputDomainRow: {
      alignItems: "center",
      flexDirection: "row",
      marginTop: global.spacing * 5,
    },
    label: {
      marginVertical: global.spacing * 6,
    },
  })
);

interface Props {
  episode: {
    id: string;
    podcastId: string;
    collection: string;
    name: string;
    language?: string;
  };
}
