import { canUseDOM } from "@mxmdev/react-universal-core";
import { useEffect } from "react";

export const useSmoothScrollPolyfill = (): void => {
  useEffect(() => {
    if (canUseDOM && !window.CSS.supports("scroll-behavior", "smooth")) {
      import("seamless-scroll-polyfill").then(({ polyfill }) => {
        polyfill();
      });
    }
  }, []);
};
