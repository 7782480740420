import { Path, Svg } from "react-native-svg";

const Arrow = ({ color }: Props): JSX.Element => (
  <Svg height="67" viewBox="0 0 100 67" width="100">
    <Path
      d="M90.3071 6.78201e-06C95.2769 7.21649e-06 100 3.77077 100 9L100 28L50 67L3.40949e-06 28L5.07052e-06 8.99999C5.52771e-06 3.77032 5.02954 -6.73187e-07 10 -2.38655e-07C12.1391 -5.16494e-08 14.3274 0.657913 16.2073 2.12138C16.2073 2.12138 33 15 50 28L84 2.12139C85.8801 0.657492 88.1673 6.59495e-06 90.3071 6.78201e-06Z"
      fill={color}
    />
  </Svg>
);

type Props = {
  color: string;
};

export default Arrow;
