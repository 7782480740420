import { z } from "zod";

import { speakerSchema } from "./speaker";

const sentenceSchema = z.object({
  end_char: z.number(),
  end_time: z.number(),
  is_ads: z.optional(z.boolean()),
  speaker_label: z.string(),
  start_char: z.number(),
  start_time: z.number(),
  text: z.string(),
});

const nerSchema = z.object({
  end_char: z.number(),
  family_type: z.string(),
  name: z.string(),
  start_char: z.number(),
  wikidata_id: z.optional(z.string()),
});

export const nlpBaseSchema = z
  .object({
    curation: z.optional(z.any()),
    transcription: z.object({
      group_of_sentences: z.array(sentenceSchema),
      ner: z.array(nerSchema).default([]),
      speakers: z.optional(z.array(speakerSchema)),
      text: z.string(),
    }),
  })
  .passthrough();
