/**
 * This file is created to quickly show which colors
 * don't change between different color themes.
 */

export default {
  backgroundHoverInvertedStatic: "rgba(255,255,255,0.15)",
  backgroundHoverStatic: "rgba(0,0,0,0.05)",
  backgroundPressInvertedStatic: "rgba(255,255,255,0.15)",
  backgroundPressStatic: "rgba(0,0,0,0.05)",
  contentPrimaryInvertedStatic: "#FFFFFF",
  contentPrimaryStatic: "#131313",
  contentSecondaryInvertedStatic: "#E0E0E0",
  contentSecondaryStatic: "#777777",
  contentTertiaryInvertedStatic: "#828282",
  contentTertiaryStatic: "#BDBDBD",
  paletteBlue100: "#5677FC",
  paletteBlue200: "#3859D7",
  paletteBlue300: "#2433B6",
  paletteBrand100: "#FC532E",
  paletteBrand200: "#A02208",
  paletteBrand300: "#661300",
  paletteGreen100: "#00CC99",
  paletteGreen200: "#00AE7B",
  paletteGreen300: "#008666",
  paletteGrey100: "#828282",
  palettePurple100: "#9013FE",
  palettePurple200: "#6F05D6",
  palettePurple300: "#5709A3",
  paletteYellow100: "#F5B800",
  paletteYellow200: "#EB920C",
  paletteYellow300: "#D06503",
  serviceAmazonMusic: "#46C3D0",
  serviceAppleMusic: "#FF2D55",
  serviceFacebook: "#1877F2",
  serviceInstagram: "#E1306C",
  serviceSpotify1: "#1ED760",
  serviceSpotify2: "#1DB954",
  serviceVimeo: "#18D5FF",
  serviceYoutube: "#FF0000",
  shadow: "rgba(19, 19, 19, 0.08)",
  systemBlue100: "#2979FF",
  systemBlue200: "#0039CB",
  systemGreen100: "#4CAF50",
  systemGreen200: "#388E3C",
  systemMagenta100: "#FF0E83",
  systemMagenta200: "#AD0959",
  systemRed100: "#F54B40",
  systemRed200: "#D32F2F",
  systemYellow100: "#F0A83B",
  systemYellow200: "#B37922",
};
