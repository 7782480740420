import {
  ReactElement,
  useState,
  useRef,
  useMemo,
  ComponentProps,
  PropsWithChildren,
} from "react";
import {
  StyleProp,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
  ViewStyle,
  StyleSheet,
} from "react-native";

import useAnimatedOverlays from "../../hooks/useAnimatedOverlays";
import useEscapeListener from "../../hooks/useEscapeListener";
import {
  useLayoutMeasures,
  useMediaQuery,
  useDistanceFromEdges,
} from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import Animated from "../Animated";
import BottomSheet from "../BottomSheet";
import BottomSheetGestureProvider from "../BottomSheet/BottomSheetGestureProvider";
import { FullscreenPortal } from "../FullscreenPortal";

import {
  anchorDropdownGap,
  isBrowser,
  minHeight as minHeightConstant,
  screenIndent,
} from "./constants";
import styles from "./styles";

const Popover = ({
  anchor,
  anchorContainerStyle,
  children,
  hasOverlay = true,
  keepInDOM = false,
  maxDropdownHeight,
  minDropdownHeight: minHeight = minHeightConstant,
  mobileFullscreen = false,
  onDismiss = () => {},
  panEnabled = true,
  style: styleProp,
  visible,
}: PropsWithChildren<Props>) => {
  const dropdownOpacity = useRef(new Animated.Value(0)).current;
  const overlayOpacity = useRef(new Animated.Value(0)).current;

  const { isSmallScreen } = useMediaQuery();
  const renderedState = useState(visible);
  const [rendered] = renderedState;
  const [anchorLayout, anchorRef] = useLayoutMeasures();
  const [containerLayout, containerRef] = useLayoutMeasures();
  const [bottomSheetLayout, bottomSheetRef] = useLayoutMeasures();
  const [dropdownLayout, dropdownRef] = useLayoutMeasures();
  const distance = useDistanceFromEdges({
    element: anchorLayout,
    includeMargin: true,
  });
  const window = useWindowDimensions();

  useEscapeListener({
    onDismiss,
    visible,
  });

  const { style } = useTheme(styles, {
    isSmallScreen,
    mobileFullscreen,
  });

  const noVerticalSpace = useMemo(
    () => distance.fromTop < minHeight && distance.fromBottom < minHeight,
    [distance.fromBottom, distance.fromTop, minHeight]
  );

  const positioningStyle = useMemo(() => {
    if (
      anchorLayout === null ||
      dropdownLayout === null ||
      containerLayout === null
    ) {
      return undefined;
    }

    // In case there is a scrollView at the root of a page
    // we can calculate how much pixels we scrolled checking
    // the negative y position of the absolute positioned container overlay
    // summing this value to the others we obtain the correct top value to assign
    const viewportOffset = Math.abs(containerLayout.y);

    // By default the dropdown appears BELOW the anchor:
    // top at anchor's Y + anchor's height
    // fixated maxHeight
    const style: ViewStyle = {
      maxHeight: maxDropdownHeight,
      transform: [
        {
          translateX:
            anchorLayout.x +
            (distance.fromRight < dropdownLayout.width
              ? anchorLayout.width - dropdownLayout.width
              : 0),
        },
        {
          translateY:
            viewportOffset +
            anchorLayout.y +
            anchorLayout.height +
            anchorDropdownGap,
        },
      ],
    };

    // If a maxDropdownHeight is not set, we set the entire viewport height
    // it's like setting it to 100% but allows us to do calculations with it
    if (!maxDropdownHeight) {
      maxDropdownHeight = window.height;
    }

    // If BELOW the dropdown there is enough space (> maxDropdownHeight)
    // default styles are ok, we don't wanna proceed
    // WARNING we can't use >= otherwise would conflict in case of maxDropdownHeight = dropdownLayout.height
    if (distance.fromBottom > maxDropdownHeight) {
      return style;
    }

    // If the minimum available space (arbitrary value)
    // is insufficient both ABOVE and BELOW the anchor
    // OR the select is outside the viewport (both BELOW or ABOVE)
    // then we show it fixed and in full height
    if (noVerticalSpace) {
      // We force the top to go auto (removing the translateY)
      // so that we can set the container to center its children
      delete style.transform?.[1];
      style.justifyContent = "center";
      style.maxHeight = window.height - screenIndent;

      if (isBrowser) {
        // @ts-ignore
        // might not be needed on native large viewports such as tablets
        // because you don't have the ability to resize a window
        style.position = "fixed";
      }

      return style;
    }

    // At this point of the code we're sure that BELOW the anchor
    // there is not enough space for a maxDropdownHeight
    // but if it's enough for a minHeight
    // then we show the dropdown till the end of the window's bottom border
    if (distance.fromBottom > minHeight) {
      style.maxHeight = distance.fromBottom;

      return style;
    }

    // At this point of the code we're sure that BELOW the anchor
    // there is not enough space even for a minHeight
    // in this case we check if there is enough space ABOVE the anchor for a minHeight
    // if so, we show it ABOVE the anchor
    // (might be a useless check here for the above branch)
    if (distance.fromTop > minHeight) {
      //@ts-ignore TODO
      style.transform[1] = {
        translateY:
          viewportOffset +
          anchorLayout.y -
          dropdownLayout.height -
          anchorDropdownGap,
      };

      // But to set the maxHeight we check if ABOVE the anchor
      // there is not enough space for a maxDropdownHeight
      // and in that case we show the dropdown till the end of the window's top border
      if (distance.fromTop < maxDropdownHeight) {
        style.maxHeight = distance.fromTop;
      }
    }

    return style;
  }, [
    maxDropdownHeight,
    anchorLayout,
    containerLayout,
    dropdownLayout,
    distance.fromTop,
    distance.fromBottom,
  ]);

  // We use this value to postpone the appearance of the popover's content
  // when we actually have at least the X value and Y where to display it
  const isPositionCalculated = useMemo(
    () =>
      isSmallScreen ||
      (noVerticalSpace
        ? !!positioningStyle?.transform?.[0]
        : !!(
            positioningStyle?.transform?.[0] && positioningStyle?.transform?.[1]
          )),
    [isSmallScreen, noVerticalSpace, positioningStyle?.transform]
  );

  const { gestureHandlerProps, scrollViewProps, translateY } =
    useAnimatedOverlays({
      bottomSheetLayout,
      fullscreen: mobileFullscreen,
      isPositionCalculated,
      onDismiss,
      overlayOpacity,
      rendered: renderedState,
      visible,
      windowOpacity: dropdownOpacity,
    });

  return (
    <>
      <View ref={anchorRef} style={anchorContainerStyle}>
        {anchor}
      </View>

      <FullscreenPortal
        keepInDOM={keepInDOM}
        ref={containerRef}
        style={[
          style.container,
          // Used only when there is no minHeight both BELOW and ABOVE
          // the anchor to vertically center the dropdown
          { justifyContent: positioningStyle?.justifyContent },
          isSmallScreen && {
            alignItems: "center",
            justifyContent: "flex-end",
          },
        ]}
        visible={rendered}
      >
        {isSmallScreen ? (
          <>
            <TouchableWithoutFeedback onPress={onDismiss}>
              {hasOverlay ? (
                <Animated.View
                  style={[style.overlay, { opacity: overlayOpacity }]}
                />
              ) : (
                <View style={StyleSheet.absoluteFill} />
              )}
            </TouchableWithoutFeedback>

            <BottomSheetGestureProvider
              value={{
                gestureHandlerProps,
                panEnabled,
                scrollViewProps,
              }}
            >
              <View pointerEvents="box-none" style={{ flex: 1, width: "100%" }}>
                <BottomSheet
                  bottomSheetRef={bottomSheetRef}
                  fullscreen={mobileFullscreen}
                  style={styleProp}
                  translateY={translateY}
                >
                  {children}
                </BottomSheet>
              </View>
            </BottomSheetGestureProvider>
          </>
        ) : (
          <>
            <TouchableWithoutFeedback onPress={onDismiss}>
              <View style={StyleSheet.absoluteFill} />
            </TouchableWithoutFeedback>
            <Animated.View
              ref={dropdownRef}
              style={[
                style.dropdown,
                { opacity: dropdownOpacity },
                positioningStyle,
                styleProp,
              ]}
            >
              {children}
            </Animated.View>
          </>
        )}
      </FullscreenPortal>
    </>
  );
};

export interface Props {
  anchor: ReactElement;
  anchorContainerStyle?: StyleProp<ViewStyle>;
  hasOverlay?: boolean;
  mobileFullscreen?: boolean;
  onDismiss?: () => void;
  style?: StyleProp<ViewStyle>;
  visible: boolean;
  maxDropdownHeight?: number;
  minDropdownHeight?: number;
  panEnabled?: boolean;
  keepInDOM?: ComponentProps<typeof FullscreenPortal>["keepInDOM"];
}

export default Popover;
