import { User } from "../../../types";
import { storage } from "../../../utils";
import { getCaptchaId } from "../storage";
import { UserFetcher, UserPictureFetcher } from "../types";

interface FetchOptions {
  userFetcher: UserFetcher;
  userPictureFetcher: UserPictureFetcher;
  appId: string;
  userToken: string;
}

export const fetchUser = async ({
  appId,
  userFetcher,
  userPictureFetcher,
  userToken,
}: FetchOptions): Promise<User | undefined> => {
  try {
    const captchaId = await getCaptchaId(storage);

    const { data: userData } = await userFetcher({
      app_id: appId,
      usertoken: userToken,
      ...(captchaId && { captcha_id: captchaId }),
    });

    const userProfilePicture = await userPictureFetcher({
      app_id: appId,
      part: "profile",
      usertoken: userToken,
      ...(captchaId && { captcha_id: captchaId }),
    })
      .then(
        ({ data }) => data.crowdScoreGet.body?.user.user_profile_photo ?? ""
      )
      .catch(() => "");

    if (!userData.userGet.body) {
      return undefined;
    }

    return {
      ...userData.userGet.body.user,
      profile: {
        ...userData.userGet.body.user.profile,
        user_picture: userProfilePicture,
      },
    };
  } catch {
    return undefined;
  }
};
