/**
 * Platform utils.
 * See also https://reactnative.dev/docs/platform-specific-code.html and
 *
 * type PlatformOSType = "ios" | "android" | "macos" | "windows" | "web"
 * (from TypeScript definitions for react-native 0.61.5)
 */
import { Platform } from "react-native";

const canUseDOM: boolean =
  typeof window !== "undefined" && window.document?.createElement !== undefined;

const userAgentIsBot = (userAgent: string) =>
  /bot|crawler|spider|crawling/i.test(userAgent);

/**
 * @deprecated Use "isNativePlatform" instead to handle either a deep link or a web url
 */
const getLocationURI = (): string | undefined => {
  return isNativePlatform() ? undefined : window.location.href;
};

const isNativePlatform = (): boolean => {
  return Platform.OS === "ios" || Platform.OS === "android";
};

const isServer = (): boolean =>
  Platform.OS === "web" && typeof window === "undefined";

export {
  canUseDOM,
  userAgentIsBot,
  getLocationURI,
  isNativePlatform,
  isServer,
};
