import { getResizedImageUrl } from "@mxmdev/dynamic-images-shared";
import { TypeOf, z } from "zod";

import { Episode } from "../../types";
import { createSlug } from "../../utils";
import { idSchema } from "../parsing/id";
import { speakerSchema } from "../parsing/speaker";

import { mapAPISpeakersToSpeakers } from "./speaker";

export const curationSchema = z.object({
  fields: z.optional(z.array(z.string())),
});

export const episodeSchema = z.object({
  artworkUrl: z.optional(z.string().url()).transform((url) => url ?? ""),
  author: z.optional(z.string()),
  collectionId: idSchema.optional(),
  collectionName: z.string(),
  created_at: z.optional(z.string()),
  curation: z.optional(curationSchema),
  description: z.optional(z.string()),
  description_decoded: z.optional(z.string()),
  episodeUrl: z.string(),
  explicit: z.optional(z.string()),
  language: z.optional(z.string()),
  last_updated: z.optional(z.string()),
  mxmEpisodeId: z.optional(z.string()),
  mxmShowId: z.optional(z.string()),
  pubDate: z.optional(z.string()),
  releaseDate: z.optional(z.string()),
  shortDescription: z.optional(z.string()),
  slug: z.optional(z.string()),
  speakers: z.optional(z.array(speakerSchema)),
  trackId: idSchema.optional(),
  trackName: z.string(),
  trackTimeMillis: z.optional(z.number()),
  trackViewUrl: z.optional(z.string()),
  transcription: z.optional(z.string()),
  transcription_chapterization: z.optional(
    z.array(
      z.object({
        chapter_id: z.number(),
        end_time: z.number(),
        start_time: z.number(),
        title: z.string(),
      })
    )
  ),
  transcription_question_answering: z.optional(
    z.array(z.object({ answer: z.string(), question: z.string() }))
  ),
  transcription_short_summarization: z.optional(z.string()),
  transcription_summarization: z.optional(z.string()),
  transcription_timestamp: z.optional(z.string()),
});

export const parseEpisode = (
  input: TypeOf<typeof episodeSchema>
): Episode | undefined => {
  const id = input.mxmEpisodeId || input.trackId;

  if (!id) {
    return undefined;
  }

  const podcastId = input.mxmShowId || input.collectionId;

  if (!podcastId) {
    return undefined;
  }

  const releaseDate = input.releaseDate ?? input.pubDate;

  if (!releaseDate) {
    return undefined;
  }

  const lastChapterEndTime = input.transcription_chapterization
    ? input.transcription_chapterization[
        input.transcription_chapterization.length - 1
      ].end_time
    : undefined;

  const transcriptionStatus = input.curation?.fields?.includes("transcription")
    ? "verified"
    : input.transcription_timestamp
    ? "transcribed"
    : "not_found";

  return {
    aiLearnings: input.transcription_question_answering?.map((qa) => ({
      answer: qa.answer,
      question: qa.question,
    })),
    aiSummary: input.transcription_summarization,
    artworkSmallUrl: getResizedImageUrl(
      input.artworkUrl,
      160 * 2,
      160 * 2,
      "webp"
    ),
    artworkUrl: getResizedImageUrl(input.artworkUrl, 286 * 2, 286 * 2, "webp"),
    artworkUrlOriginal: input.artworkUrl,
    audioTrackUrl: input.episodeUrl,
    chapters: input.transcription_chapterization?.map((chapter) => ({
      endTime: chapter.end_time,
      id: chapter.chapter_id,
      startTime: chapter.start_time,
      title: chapter.title,
    })),
    collection: input.collectionName,
    creationDate: input.created_at,
    curation: input.curation,
    description: input.description_decoded ?? input.description ?? "",
    duration: input.trackTimeMillis
      ? input.trackTimeMillis / 1000
      : lastChapterEndTime,
    explicit: input.explicit === "Yes",
    id,
    itunesUrl: input.trackViewUrl,
    language: input.language,
    lastUpdateDate: input.last_updated,
    mxmId: input.mxmEpisodeId,
    mxmPodcastId: input.mxmShowId,
    name: input.trackName,
    podcastId,
    podcastSlug: createSlug(input.collectionName),
    publisher: input.author,
    releaseDate,
    shortSummarization: input.transcription_short_summarization,
    slug: input.slug ?? createSlug(input.trackName),
    speakers: input.speakers
      ? mapAPISpeakersToSpeakers(input.speakers)
      : undefined,
    transcription: input.transcription,
    transcriptionStatus,
  };
};

export const episodeGetAIParts = [
  "transcription_chapterization",
  "transcription_question_answering",
  "transcription_summarization",
  "transcription_short_summarization",
].join(",");
