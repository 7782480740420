export type PodcastsSettings = {
  areAudiogramDeveloperToolsEnabled: string;
  freeTranscriptionRequestsRemaining: string;
  hasSeenPremiumDialogInStudioOverview: "true" | "false";
  requestAmazonTranscription: "true" | "false";
};

export const podcastsDefaultSettings: PodcastsSettings = {
  areAudiogramDeveloperToolsEnabled: "false",
  freeTranscriptionRequestsRemaining: "4",
  hasSeenPremiumDialogInStudioOverview: "false",
  requestAmazonTranscription: "false",
};
