import {
  Dialog,
  DialogComponent,
  IconAlert,
} from "@mxmdev/react-universal-components";
import { useI18n } from "@mxmdev/react-universal-core";

const GenericErrorDialog: DialogComponent<Props> = ({
  onTryAgain,
  ...props
}) => {
  const { t } = useI18n();

  return (
    <Dialog
      {...props}
      description={t("generic_error_message")}
      Icon={IconAlert}
      iconColor="systemRed"
      onPressPrimaryButton={onTryAgain}
      primaryButtonColor="systemRed"
      primaryButtonText={t("try_again")}
      title={t("something_went_wrong")}
    />
  );
};

interface Props {
  onTryAgain: () => void;
}

export default GenericErrorDialog;
