import { Episode } from "@mxmdev/podcasts-shared";
import produce from "immer";

import { ResolvedEpisode } from "../../types";

import { resolveEpisodeAfterRedirectWithRetries } from "./urlResolve";

import {
  AudioPlayer,
  AudioPlayerState,
  initialAudioPlayerState,
  PlayerEvent,
  PlayerEventListener,
  StateUpdateListener,
} from ".";

const PLAYBACK_RATE_PREFERENCE_KEY = "playback-rate";

class WebAudioPlayer implements AudioPlayer {
  audio: HTMLAudioElement;
  state: Readonly<AudioPlayerState>;

  stateListeners: StateUpdateListener[];
  eventListeners: PlayerEventListener[];

  urlResolveFallback: ((episode: Episode) => Promise<string>) | undefined;

  _shouldIgnoreNextPauseEvent: boolean;
  _lastTimeUpdateTime: number;
  _playbackRatePreference: number = 1;

  // TODO MediaSession-related APIs: https://googlechrome.github.io/samples/media-session/audio.html
  //      https://developer.mozilla.org/en-US/docs/Web/API/MediaMetadata

  constructor() {
    this.audio = new Audio();
    this.state = initialAudioPlayerState;
    this.stateListeners = [];
    this.eventListeners = [];
    this.urlResolveFallback = undefined;
    this._shouldIgnoreNextPauseEvent = false;
    this._lastTimeUpdateTime = new Date().getTime();
    this.loadPlaybackRatePreference();
  }

  initialize = (): Promise<void> => {
    this.audio.addEventListener("canplaythrough", this.onCanPlayThrough);
    this.audio.addEventListener("loadstart", this.onLoadStart);
    this.audio.addEventListener("play", this.onPlay);
    this.audio.addEventListener("pause", this.onPause);
    this.audio.addEventListener("ended", this.onEnded);
    this.audio.addEventListener("error", this.onError);
    this.audio.addEventListener("ratechange", this.onRateChange);
    this.audio.addEventListener("timeupdate", this.onTimeUpdate);
    this.audio.addEventListener("seeked", this.onSeeked);
    this.audio.addEventListener("seeking", this.onSeeking);

    return Promise.resolve();
  };

  // Internal

  updateState = (updater: (state: AudioPlayerState) => void): void => {
    const newState = produce(this.state, (draft) => {
      updater(draft);
    });

    const oldState = this.state;

    this.state = newState;

    this.checkEpisodeChange(oldState, newState);

    this.stateListeners.forEach((listener) => listener(newState));
  };

  checkEpisodeChange = (
    oldState: Readonly<AudioPlayerState>,
    newState: Readonly<AudioPlayerState>
  ): void => {
    if (
      newState.currentIndex !== oldState.currentIndex ||
      newState.playlist !== oldState.playlist
    ) {
      const previousEpisode =
        oldState.currentIndex !== undefined
          ? oldState.playlist.episodes[oldState.currentIndex]
          : null;

      if (previousEpisode) {
        this.notifyEventListeners("stop", previousEpisode);
      }

      if (newState.currentIndex === undefined) {
        this.audio.removeAttribute("src");
        this.audio.load();
      } else {
        const currentEpisode =
          newState.playlist.episodes[newState.currentIndex];

        if (currentEpisode) {
          // Make sure the episode is a different one before triggering a change
          const isDifferent = previousEpisode?.id !== currentEpisode.id;

          if (isDifferent) {
            this.handleEpisodeChange(currentEpisode);
          }
        } else {
          throw new Error(
            `invalid episode index detected: ${newState.currentIndex}, but playlist has ${newState.playlist.episodes.length} elements`
          );
        }
      }
    }
  };

  handleEpisodeChange = (currentEpisode: ResolvedEpisode): void => {
    if (this.state.isPlaying) {
      this.play();
    }

    this.audio.setAttribute(
      "title",
      `${currentEpisode.name} - ${currentEpisode.collection}`
    );

    // TODO: handle media session
    // navigator.mediaSession.metadata = new MediaMetadata({
    //   title: episode.name,
    //   artist: episode.collection,
    //   artwork: [
    //     {
    //       src: episode.artworkUrl,
    //       sizes: "512x512",
    //       type: "image/png",
    //     },
    //   ],
    // });
  };

  // Handlers

  onCanPlayThrough = (): void => {
    this.updateState((state) => {
      state.isLoading = false;
      state.duration = this.audio.duration;
      state.error = undefined;
      state.canPlay = true;
    });
  };

  onPlay = (): void => {
    this.updateState((state) => {
      state.isPlaying = true;
    });
  };

  onSeeking = (): void => {
    this.updateState((state) => {
      state.isSeeking = true;
    });
  };

  onSeeked = (): void => {
    this.updateState((state) => {
      state.isSeeking = false;
    });
  };

  onPause = (): void => {
    // We want to pause the player only if the onPause event was explicitly
    // requested by the user, and not if it occurred at the end of an episode.
    if (!this.audio.ended && !this._shouldIgnoreNextPauseEvent) {
      this.updateState((state) => {
        state.isPlaying = false;
      });
    }

    this._shouldIgnoreNextPauseEvent = false;
  };

  onEnded = (): void => {
    if (
      this.state.currentIndex === undefined ||
      this.state.playlist.episodes.length === 0
    ) {
      return;
    }

    const newIndex = this.state.currentIndex + 1;

    if (newIndex < this.state.playlist.episodes.length) {
      this.next();
    } else {
      // TODO: if we want endless listening, here we generate the following playlist
      // this.updateState((state) => {
      //   state.isPlaying = false;
      // });
    }
  };

  onError = (): void => {
    this.updateState((state) => {
      state.currentTime = undefined;
      state.duration = undefined;
      state.error = new Error("error loading audio track");
      state.isLoading = false;
    });
  };

  onLoadStart = (): void => {
    this.updateState((state) => {
      state.isLoading = true;
    });
  };

  onRateChange = (): void => {
    this.updateState((state) => {
      state.playbackRate = this.audio.playbackRate;
    });
  };

  onTimeUpdate = (): void => {
    // We don't want to update the state too often, as
    // that causes the UI to update as well.
    const now = new Date().getTime();
    const elapsed = now - this._lastTimeUpdateTime;

    if (elapsed >= 1000) {
      this.updateState((state) => {
        state.currentTime = this.audio.currentTime;
      });
      this._lastTimeUpdateTime = now;
    }
  };

  addEpisodes = (episodes: readonly ResolvedEpisode[]): Promise<void> => {
    if (episodes.length === 0) {
      return Promise.resolve();
    }

    this.updateState((state) => {
      state.playlist.episodes = [...state.playlist.episodes, ...episodes];

      if (state.currentIndex === undefined) {
        state.currentIndex = 0;
      }
    });

    return Promise.resolve();
  };

  reset = (): Promise<void> => {
    this.audio.pause();
    this.audio.removeAttribute("src");
    this.audio.removeAttribute("title");
    this.audio.load();

    this.updateState((state) => {
      Object.assign(state, initialAudioPlayerState);
    });

    return Promise.resolve();
  };

  loadCurrentEpisode = async (): Promise<void> => {
    const currentEpisode = this.getCurrentEpisode();

    if (!currentEpisode) {
      return Promise.resolve();
    }

    // Already loaded
    if (
      this.audio.src &&
      currentEpisode.resolvedAudioTrackUrl === this.audio.currentSrc
    ) {
      return Promise.resolve();
    }

    try {
      let resolvedUrl = currentEpisode.resolvedAudioTrackUrl;

      // We need to resolve the URL AFTER the redirect

      this.updateState((state) => {
        state.isLoading = true;
      });

      if (resolvedUrl === undefined) {
        // Resolve the URL first
        resolvedUrl = await resolveEpisodeAfterRedirectWithRetries(
          currentEpisode,
          2,
          this.urlResolveFallback
        );

        this.updateState((state) => {
          state.playlist.episodes = state.playlist.episodes.map((episode) => {
            if (episode.id === currentEpisode.id) {
              return {
                ...episode,
                resolvedAudioTrackUrl: resolvedUrl,
              };
            } else {
              return episode;
            }
          });
        });
      }

      // Make sure the current episode didn't change in the meanwhile
      if (
        this.state.currentIndex === undefined ||
        this.state.playlist.episodes[this.state.currentIndex].id !==
          currentEpisode.id
      ) {
        // Episode changed in the meanwhile
        return Promise.resolve();
      }

      this.audio.src = resolvedUrl;
      this.audio.load();
      this._shouldIgnoreNextPauseEvent = false;
      // Changing the audio causes the playback rate to being reset
      this.audio.playbackRate = this._playbackRatePreference;
    } catch (error) {
      this.updateState((state) => {
        state.error = error;
        state.isLoading = false;
      });
    }

    return Promise.resolve();
  };

  play = async (): Promise<void> => {
    const currentEpisode = this.getCurrentEpisode();

    if (!currentEpisode) {
      return Promise.resolve();
    }

    // Already loaded
    if (
      currentEpisode.resolvedAudioTrackUrl === this.audio.currentSrc ||
      currentEpisode.resolvedAudioTrackUrl === this.audio.src
    ) {
      this._shouldIgnoreNextPauseEvent = false;

      try {
        await this.audio.play();
        this.notifyEventListeners("play", currentEpisode);
      } catch (error) {
        console.log(error);
      }
    }

    return Promise.resolve();
  };

  pause = (): Promise<void> => {
    this.audio.pause();

    const currentEpisode = this.getCurrentEpisode();

    if (currentEpisode) {
      this.notifyEventListeners("pause", currentEpisode);
    }

    return Promise.resolve();
  };

  seek = async (time: number): Promise<void> => {
    if (!this.state.canPlay) {
      await this.canPlay();
    }

    if (this.audio.currentTime !== undefined && this.audio.duration >= time) {
      this.audio.currentTime = time;
    }

    // Without this state update, seeking to a particular
    // time will feel laggy
    this.updateState((state) => {
      state.currentTime = time;
    });

    return Promise.resolve();
  };

  seekDelta = (deltaTime: number): Promise<void> => {
    if (
      this.audio.currentSrc === undefined ||
      this.audio.duration === undefined ||
      this.audio.currentTime === undefined
    ) {
      return Promise.resolve();
    }

    let targetTime = this.audio.currentTime + deltaTime;

    if (targetTime > this.audio.duration) {
      targetTime = this.audio.duration;
    } else if (targetTime < 0) {
      targetTime = 0;
    }

    this.audio.currentTime = targetTime;

    return Promise.resolve();
  };

  next = async (): Promise<void> => {
    if (this.state.currentIndex !== undefined) {
      const newIndex = this.state.currentIndex + 1;

      if (newIndex < this.state.playlist.episodes.length) {
        await this.pauseWithoutStateUpdate();
        this.updateState((state) => {
          state.currentIndex = newIndex;
        });
      }
    }

    return Promise.resolve();
  };

  previous = async (): Promise<void> => {
    if (this.state.currentIndex !== undefined) {
      const newIndex = this.state.currentIndex - 1;

      if (newIndex >= 0 && this.state.playlist.episodes.length > 0) {
        await this.pauseWithoutStateUpdate();
        this.updateState((state) => {
          state.currentIndex = newIndex;
        });
      }
    }

    return Promise.resolve();
  };

  pauseWithoutStateUpdate = async (): Promise<void> => {
    this._shouldIgnoreNextPauseEvent = true;
    await this.audio.pause();
  };

  setPlaybackRate = (rate: number): Promise<void> => {
    this.audio.playbackRate = rate;
    this.savePlaybackRatePreference(rate);

    return Promise.resolve();
  };

  replaceAudioTrack = async (trackUrl: string): Promise<void> => {
    const previousTime = this.audio.currentTime;

    this.audio.src = trackUrl;
    this.audio.load();

    if (this.state.isPlaying) {
      this.audio.currentTime = previousTime;
      this.audio.play();
    }

    const currentEpisode = this.getCurrentEpisode();

    this._shouldIgnoreNextPauseEvent = false;
    // Changing the audio causes the playback rate to being reset
    this.audio.playbackRate = this._playbackRatePreference;

    this.updateState((state) => {
      state.canPlay = false;
      state.playlist.episodes = state.playlist.episodes.map((episode) => {
        if (episode.id === currentEpisode?.id) {
          return {
            ...episode,
            resolvedAudioTrackUrl: trackUrl,
          };
        } else {
          return episode;
        }
      });
    });

    return Promise.resolve();
  };

  setIndex = async (index: number): Promise<void> => {
    if (
      this.state.currentIndex !== index &&
      this.state.playlist.episodes.length > 0 &&
      this.state.playlist.episodes.length > index
    ) {
      await this.pauseWithoutStateUpdate();
      this.updateState((state): void => {
        state.currentIndex = index;
      });
    }

    return Promise.resolve();
  };

  addStateUpdateListener = (listener: StateUpdateListener): void => {
    this.stateListeners.push(listener);
  };

  removeStateUpdateListener = (listener: StateUpdateListener): void => {
    this.stateListeners.splice(this.stateListeners.indexOf(listener), 1);
  };

  addEventListener = (listener: PlayerEventListener): void => {
    this.eventListeners.push(listener);
  };

  removeEventListener = (listener: PlayerEventListener): void => {
    this.eventListeners.splice(this.eventListeners.indexOf(listener), 1);
  };

  configureUrlResolveFallback = (
    fetcher: (episode: Episode) => Promise<string>
  ): void => {
    this.urlResolveFallback = fetcher;
  };

  destroy = (): Promise<void> => {
    this.audio.src = "";

    this.audio.removeEventListener("canplaythrough", this.onCanPlayThrough);
    this.audio.removeEventListener("play", this.onPlay);
    this.audio.removeEventListener("pause", this.onPause);
    this.audio.removeEventListener("ended", this.onEnded);
    this.audio.removeEventListener("error", this.onError);
    this.audio.removeEventListener("ratechange", this.onRateChange);
    this.audio.removeEventListener("timeupdate", this.onTimeUpdate);
    this.audio.removeEventListener("seeking", this.onSeeking);
    this.audio.removeEventListener("seeked", this.onSeeked);

    return Promise.resolve();
  };

  private notifyEventListeners = (
    type: PlayerEvent,
    episode: ResolvedEpisode
  ): void => {
    this.eventListeners
      .filter((eventListener) => eventListener.type === type)
      .forEach(({ listener }) => {
        listener(episode);
      });
  };

  private savePlaybackRatePreference = (playbackRate: number): void => {
    this._playbackRatePreference = playbackRate;

    localStorage.setItem(
      PLAYBACK_RATE_PREFERENCE_KEY,
      JSON.stringify(playbackRate)
    );
  };

  private loadPlaybackRatePreference = (): void => {
    const preference = localStorage.getItem(PLAYBACK_RATE_PREFERENCE_KEY);

    if (preference) {
      try {
        const rate = JSON.parse(preference);

        if (typeof rate === "number") {
          this._playbackRatePreference = rate;
        }
      } catch {
        console.error("invalid playback rate preference: ", preference);
      }
    }
  };

  private getCurrentEpisode = (): ResolvedEpisode | null => {
    return this.state.currentIndex !== undefined
      ? this.state.playlist.episodes[this.state.currentIndex]
      : null;
  };

  private canPlay = (): Promise<void> => {
    return new Promise((resolve) => {
      const onPlay = (): void => {
        resolve();

        this.audio.removeEventListener("canplaythrough", onPlay);
      };

      this.audio.addEventListener("canplaythrough", onPlay);
    });
  };
}

export const createWebAudioPlayer = (): AudioPlayer => {
  return new WebAudioPlayer();
};
