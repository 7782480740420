export type EpisodeAnalyticsCommon = {
  episodeId: string;
  episodeName: string;
  podcastId: string;
  podcastName: string;
  episodeLanguage: string | undefined;
};

export const formatEpisodeAnalytics = ({
  episodeId,
  episodeLanguage,
  episodeName,
  podcastId,
  podcastName,
}: EpisodeAnalyticsCommon) => {
  return {
    episode_id: episodeId,
    episode_language: episodeLanguage,
    episode_title: episodeName,
    podcast_id: podcastId,
    podcast_title: podcastName,
  };
};
