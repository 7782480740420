import { z, TypeOf } from "zod";

import { ApiResponse } from "../../types/api";

import useValidatedQuery from "./useValidatedQuery";

type UserInfo = {
  curator: boolean;
  profile?: {
    country?: string;
    countryName?: string;
    nativeLanguages?: string[];
    spokenLanguages?: string[];
  };
};

const parser = z.object({
  user: z.optional(
    z.object({
      admin: z.optional(z.boolean()),
      moderator: z.optional(z.boolean()),
      profile: z.object({
        country: z.optional(z.string()),
        country_name: z.optional(z.string()),
        native_languages: z.optional(z.array(z.string())),
        spoken_languages: z.optional(z.array(z.string())),
      }),
    })
  ),
});

const transformer = (input: TypeOf<typeof parser>): UserInfo => {
  return {
    curator: !!(input.user?.moderator || input.user?.admin),
    profile: input.user?.profile
      ? {
          country: input.user.profile.country?.toLowerCase(),
          countryName: input.user.profile.country_name,
          nativeLanguages: input.user.profile.native_languages,
          spokenLanguages: input.user.profile.spoken_languages,
        }
      : undefined,
  };
};

const useUserInfo = (): ApiResponse<Readonly<UserInfo>, "userInfo"> => {
  const { data, error, isLoading, retry } = useValidatedQuery(
    "crowd.score.get",
    { format: "json", part: "profile" },
    parser,
    transformer,
    {
      requireAuth: true,
    }
  );

  return {
    error,
    isLoading,
    retry,
    userInfo: data,
  };
};

export default useUserInfo;
