import { AuthStorage } from "../types";

const USER_TOKEN_STORAGE_KEY = "userToken";

export const getUserToken = (storage: AuthStorage) =>
  storage.get(USER_TOKEN_STORAGE_KEY);

export const setUserToken = (storage: AuthStorage, userToken: string) =>
  storage.set(USER_TOKEN_STORAGE_KEY, userToken);

export const removeUserToken = (storage: AuthStorage) =>
  storage.remove(USER_TOKEN_STORAGE_KEY);
