import { AuthStorage } from "../types";

const CAPTCHA_ID_STORAGE_TOKEN = "captchaId";

export const getCaptchaId = (storage: AuthStorage) =>
  storage.get(CAPTCHA_ID_STORAGE_TOKEN);

export const setCaptchaId = (storage: AuthStorage, captchaId: string) =>
  storage.set(CAPTCHA_ID_STORAGE_TOKEN, captchaId);

export const removeCaptchaId = (storage: AuthStorage) =>
  storage.remove(CAPTCHA_ID_STORAGE_TOKEN);
