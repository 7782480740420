import { ComponentProps, PropsWithChildren } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { IconComponent } from "..";
import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import Button from "../Button";

import styles from "./styles";

const DialogActions = ({
  disabledPrimaryButton,
  disabledSecondaryButton,
  inline,
  onPressPrimaryButton,
  onPressSecondaryButton,
  primaryButtonColor = "neutral",
  PrimaryButtonIcon,
  primaryButtonIsLoading,
  primaryButtonLink,
  primaryButtonText,
  SecondaryButtonIcon,
  secondaryButtonIsLoading,
  secondaryButtonLink,
  secondaryButtonText,
  stacked,
  style: styleProp,
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { inline, isSmallScreen, stacked });

  if (!onPressPrimaryButton && !primaryButtonLink) {
    console.warn(
      "[DialogActions] The primary button should have at least an action (`onPressPrimaryButton`) or a link (`primaryButtonLink`)."
    );
  }

  if (secondaryButtonText && !onPressSecondaryButton && !secondaryButtonLink) {
    console.warn(
      "[DialogActions] The secondary button should have at least an action (`onPressSecondaryButton`) or a link (`secondaryButtonLink`)."
    );
  }

  return (
    <View style={[style.actionsContainer, styleProp]}>
      <Button
        color={primaryButtonColor}
        disabled={disabledPrimaryButton}
        Icon={PrimaryButtonIcon}
        isLoading={primaryButtonIsLoading}
        onPress={onPressPrimaryButton}
        text={primaryButtonText}
        to={primaryButtonLink}
      />
      {secondaryButtonText && (
        <Button
          disabled={disabledSecondaryButton}
          Icon={SecondaryButtonIcon}
          isLoading={secondaryButtonIsLoading}
          onPress={onPressSecondaryButton}
          style={style.secondaryActionButton}
          text={secondaryButtonText}
          to={secondaryButtonLink}
          type="basic"
        />
      )}
    </View>
  );
};

interface Props {
  disabledPrimaryButton?: boolean;
  disabledSecondaryButton?: boolean;
  inline?: boolean;
  onPressPrimaryButton?: () => void;
  onPressSecondaryButton?: () => void;
  primaryButtonColor?: Extract<
    ComponentProps<typeof Button>["color"],
    "neutral" | "systemRed" | "purple"
  >;
  PrimaryButtonIcon?: IconComponent;
  primaryButtonIsLoading?: boolean;
  primaryButtonLink?: string;
  primaryButtonText: string;
  SecondaryButtonIcon?: IconComponent;
  secondaryButtonIsLoading?: boolean;
  secondaryButtonLink?: string;
  secondaryButtonText?: string;
  stacked?: boolean;
  style?: StyleProp<ViewStyle>;
}

export default DialogActions;
