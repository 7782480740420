import { ComponentProps, ReactNode, PropsWithChildren } from "react";
import { View } from "react-native";

import { Text } from "..";
import { useTheme } from "../../styles";
import EnhancedTextLink from "../TextLink";

import styles from "./styles";

const ItemLabel = ({
  align,
  color,
  endEnhancer,
  numberOfLines,
  onHover,
  onPress,
  startEnhancer,
  text,
  to,
  type = "labelDefault",
}: PropsWithChildren<Props>) => {
  const { style } = useTheme(styles, { align });

  const Label = onPress || to ? EnhancedTextLink : Text;

  return (
    <View style={style.container}>
      {startEnhancer && (
        <View style={style.startEnhancer}>{startEnhancer}</View>
      )}
      <View style={style.textContainer}>
        <Label
          align={align}
          color={color}
          numberOfLines={numberOfLines}
          onHover={onHover}
          onPress={onPress}
          style={onPress || to ? style.link : undefined}
          to={to}
          type={type}
        >
          {text}
        </Label>
      </View>
      {endEnhancer && <View style={style.endEnhancer}>{endEnhancer}</View>}
    </View>
  );
};

export interface Props
  extends Pick<
    ComponentProps<typeof Text>,
    "align" | "color" | "numberOfLines" | "type"
  > {
  endEnhancer?: ReactNode;
  onHover?: ComponentProps<typeof EnhancedTextLink>["onHover"];
  onPress?: ComponentProps<typeof EnhancedTextLink>["onPress"];
  startEnhancer?: ReactNode;
  text: ReactNode;
  to?: ComponentProps<typeof EnhancedTextLink>["to"];
}

export default ItemLabel;
