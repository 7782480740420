import { viewStyles } from "../../styles/";
import { Theme } from "../../styles/themes";

const styles = (theme: Theme) =>
  viewStyles({
    default: {
      flex: 1,
    },
    modal: {
      backgroundColor: "rgba(0,0,0,0.6)",
      flex: 1,
    },
  });

export default styles;
