import { Platform } from "react-native";

import { viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";

interface Props {
  desktopHeight?: number;
  desktopWidth?: number;
  dismissable?: boolean;
  isSmallScreen: boolean;
  mobileFullscreen?: boolean;
}

const styles = (
  theme: Theme,
  {
    desktopHeight,
    desktopWidth,
    dismissable,
    isSmallScreen,
    mobileFullscreen,
  }: Props
) => {
  // Minimum distance of the component from the window.
  // If the window is too small the component becomes scrollable inside.
  const containerPadding = 40;

  return viewStyles({
    container: {
      alignItems: "center",
      flex: 1,
      overflow: "hidden",
      paddingBottom: isSmallScreen ? 0 : containerPadding,
      paddingHorizontal: isSmallScreen ? 0 : containerPadding,
      paddingTop: mobileFullscreen && isSmallScreen ? 0 : containerPadding,
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.6)",
      bottom: 0,
      // cursor type required, otherwise it won't show a pointer on the pressable area
      ...Platform.select({
        web: {
          cursor: dismissable ? "pointer" : "auto",
        },
      }),
      flex: 1,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    window: {
      // cursor type required, otherwise it shows a pointer on the whole card area
      ...Platform.select({
        web: {
          cursor: "auto",
        },
      }),
      backgroundColor: theme.backgroundPrimaryElevated,
      borderRadius: global.radius.large,
      height: desktopHeight,
      maxHeight: "100%",
      maxWidth: "100%",
      paddingVertical: windowPaddingHorizontal,
      width: desktopWidth || 500,
      ...global.sheetShadow.below,
    },
    windowContainer: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
  });
};

export default styles;

// This value can be used by other components that include Modal
export const windowPaddingHorizontal = global.spacing * 6;
