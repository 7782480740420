import { ComponentProps, ComponentType, PropsWithChildren } from "react";

import { AuthProvider } from "../client";

import { AuthProps, NextAppWithAuth, NextPageWithAuth } from "./types";

type Config = Pick<
  ComponentProps<typeof AuthProvider>,
  | "accountURL"
  | "apiOrigin"
  | "appId"
  | "clientKey"
  | "ignoreTokenMigration"
  | "signInURL"
  | "signOutURL"
  | "tokenMigrateURL"
>;

type ServerAuthProviderProps = {
  auth: AuthProps;
  ErrorComponent: ComponentType<PropsWithChildren<unknown>>;
  config: Config;
};

const ServerAuthProvider = ({
  auth,
  children,
  config,
  ErrorComponent,
}: PropsWithChildren<ServerAuthProviderProps>) => {
  if (auth.hasError) {
    return <ErrorComponent />;
  }

  const {
    accountURL,
    apiOrigin,
    clientKey,
    ignoreTokenMigration,
    signInURL,
    signOutURL,
    tokenMigrateURL,
  } = config;

  const appId = auth.appId ?? config.appId;

  return (
    <AuthProvider
      accountURL={accountURL}
      apiOrigin={apiOrigin}
      appId={appId}
      clientKey={clientKey}
      ignoreTokenMigration={ignoreTokenMigration}
      loadingComponent={null}
      renderError={() => <ErrorComponent />}
      signInURL={signInURL}
      signOutURL={signOutURL}
      tokenMigrateURL={tokenMigrateURL}
      user={auth.user}
      userToken={auth.userToken}
    >
      {children}
    </AuthProvider>
  );
};

export const withAuthApp =
  <P extends object>(
    WrappedApp: NextAppWithAuth<P>,
    ErrorComponent: ComponentType<PropsWithChildren<unknown>>,
    config: Config
  ): NextAppWithAuth<P> =>
  (props) => {
    if (!props.pageProps.auth) {
      return <WrappedApp {...props} />;
    }

    return (
      <ServerAuthProvider
        auth={props.pageProps.auth}
        config={config}
        ErrorComponent={ErrorComponent}
      >
        <WrappedApp {...props} />
      </ServerAuthProvider>
    );
  };

export const withAuthPage =
  <P extends object>(
    WrappedPage: NextPageWithAuth<P>,
    ErrorComponent: ComponentType<PropsWithChildren<unknown>>,
    config: Config
  ): NextPageWithAuth<P> =>
  (props) => {
    return (
      <ServerAuthProvider
        auth={props.auth}
        config={config}
        ErrorComponent={ErrorComponent}
      >
        <WrappedPage {...props} />
      </ServerAuthProvider>
    );
  };
