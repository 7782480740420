import { StackActionType } from "@mxmdev/react-universal-navigation/native";

import { Highlight } from "../types";

type RootStackParamListWithoutCommonParams = {
  AudiogramDownload: {
    request_id: string;
  };
  Editor: {
    episode_id: string;
    podcast_id: string;
    _slug_episode: string;
    _slug_podcast: string;
  };
  Episode: {
    episode_id: string;
    podcast_id: string;
    time?: number;
    _highlight?: Highlight;
    _scroll_to?: EpisodePageSection;
    _slug_episode: string;
    _slug_podcast: string;
  };
  StudioEpisodeOverview: {
    episode_id: string;
    podcast_id: string;
    _slug_episode: string;
    _slug_podcast: string;
    show_upgrade_plan?: string;
  };
  StudioEpisodeAIContent: {
    episode_id: string;
    podcast_id: string;
    _slug_episode: string;
    _slug_podcast: string;
  };
  StudioEpisodeVideo: {
    episode_id: string;
    podcast_id: string;
    _slug_episode: string;
    _slug_podcast: string;
  };
  StudioAudiogramPreview: {
    episode_id: string;
    podcast_id: string;
    start_time: number;
    end_time: number;
    _slug_episode: string;
    _slug_podcast: string;
  };
  StudioAudiogramEdit: {
    episode_id: string;
    podcast_id: string;
    start_time: number;
    end_time: number;
    chapter_id: number;
    is_edited?: string;
    _slug_episode: string;
    _slug_podcast: string;
  };
  StudioAudiogramTrim: {
    episode_id: string;
    podcast_id: string;
    start_time: number;
    end_time: number;
    selected_start?: number;
    selected_end?: number;
    _slug_episode: string;
    _slug_podcast: string;
  };
  Home: object;
  Search: {
    q?: string;
    type?: "raw" | "topic";
    id?: string;
  };
  Podcast: {
    podcast_id: string;
    _slug_podcast: string;
  };
  Publisher: {
    publisher_id: string;
  };
  PurchasePremium: {
    canceled?: boolean;
    item_id: string;
    ref?: string;
  };
  Queue: object;
  Studio: object;
  TranscribeReferral: {
    episodeGuid: string;
    feedUrl: string;
    k: string;
    signature: string;
    signature_protocol: string;
    trustee: string;
  };
  Video: {
    episode_id: string;
    podcast_id: string;
    _slug_episode: string;
    _slug_podcast: string;
  };
  Speaker: {
    speaker_id: string;
    _slug_speaker: string;
  };
};

type RootStackParamListCommonParams = {
  _label_override?: string;
  purchase_premium_feature_ref?: string;
  purchase_premium_price_id?: string;
  purchase_premium_success?: string;
};

export type RootStackParamList = {
  [K in keyof RootStackParamListWithoutCommonParams]: RootStackParamListWithoutCommonParams[K] &
    RootStackParamListCommonParams;
};

export const DEFAULT_LABELS: Record<RootRoute, string> = {
  AudiogramDownload: "Clip Download",
  Editor: "Editor",
  Episode: "Episode",
  Home: "Home",
  Podcast: "Podcast",
  Publisher: "Publisher",
  PurchasePremium: "Premium",
  Queue: "Queue",
  Search: "Search",
  Speaker: "Speaker",
  Studio: "Podcasts Studio",
  StudioAudiogramEdit: "Clip Edit",
  StudioAudiogramPreview: "Clip Preview",
  StudioAudiogramTrim: "Clip Trim",
  StudioEpisodeAIContent: "AI Content",
  StudioEpisodeOverview: "Studio",
  StudioEpisodeVideo: "Video",
  TranscribeReferral: "Transcribe Referral",
  Video: "Video",
};

export type EpisodePageSection = "transcription";

export type RootRoute = keyof RootStackParamList;
export type CommonRouteParams = RootStackParamList[keyof RootStackParamList];
export type StudioRoute = keyof Pick<
  RootStackParamList,
  | "Editor"
  | "StudioEpisodeAIContent"
  | "StudioEpisodeOverview"
  | "StudioEpisodeVideo"
  | "StudioAudiogramPreview"
  | "StudioAudiogramEdit"
  | "StudioAudiogramTrim"
>;

export type BackAction<T extends RootRoute> = {
  route: T;
  routeParams?: RootStackParamList[T];
};

export type PodcastsLinkBuilder = {
  getBackAction: () => BackAction<RootRoute>;
  getLink: (type: "push" | "replace") => {
    action: StackActionType;
    to?: string;
  };
  navigate: () => void;
  push: () => void;
  replace: () => void;
};
