import { useEffect, useRef, PropsWithChildren } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { useTheme } from "../..";
import Animated from "../Animated";

import EnhancedSkeleton, { SharedSkeletonProps } from "./EnhancedSkeleton";
import { styles } from "./styles";

const Skeleton = ({
  children,
  height,
  style: styleProp,
  width = "100%",
}: PropsWithChildren<Props>) => {
  const animatedValue = useRef(new Animated.Value(0)).current;
  const { style, theme } = useTheme(styles, { height });
  const endColor = theme.backgroundTertiary;
  const startColor = theme.backgroundSecondary;

  const animation = (
    animatedValue: Animated.Value
  ): Animated.CompositeAnimation =>
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedValue, {
          duration: 1000,
          toValue: 1,
          useNativeDriver: false,
        }),
        Animated.timing(animatedValue, {
          duration: 1000,
          toValue: 0,
          useNativeDriver: false,
        }),
      ])
    );

  useEffect(() => {
    const colorAnimation = animation(animatedValue);

    colorAnimation.start();

    return () => {
      colorAnimation.stop();
    };
  }, []);

  return (
    <Animated.View
      style={[
        {
          backgroundColor: animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [startColor, endColor],
          }),

          width,
        },
        style.skeleton,
        styleProp,
      ]}
    >
      <View style={{ opacity: 0 }}>{children}</View>
    </Animated.View>
  );
};

const _EnhancedSkeleton = (
  props: PropsWithChildren<Props & SharedSkeletonProps>
) => <EnhancedSkeleton SkeletonComponent={Skeleton} {...props} />;

interface Props {
  height?: ViewStyle["height"];
  style?: StyleProp<ViewStyle>;
  width?: ViewStyle["width"];
}

export default _EnhancedSkeleton;
