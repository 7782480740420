import { ComponentProps } from "react";
import { View } from "react-native";

import { IconComponent, Text } from "..";
import { useTheme } from "../..";
import Pressable from "../Pressable";

import { BackgroundWrapper } from "./components";
import styles from "./styles";

const PlanIndicator = ({ colors, Icon, label, onPress, to }: Props) => {
  const { style, theme } = useTheme(styles);

  return (
    <Pressable onPress={onPress} style={style.minTouchTarget} to={to}>
      <BackgroundWrapper colors={colors}>
        <View style={style.content}>
          {!!Icon && (
            <Icon
              color={theme.contentPrimaryInvertedStatic}
              size={16}
              style={style.icon}
            />
          )}
          <Text
            color={theme.contentPrimaryInvertedStatic}
            style={style.text}
            type="bodySmall"
          >
            {label.toUpperCase()}
          </Text>
        </View>
      </BackgroundWrapper>
    </Pressable>
  );
};

interface Props
  extends Pick<ComponentProps<typeof Pressable>, "onPress" | "to"> {
  colors: string[];
  Icon?: IconComponent;
  label: string;
}

export default PlanIndicator;
