import { useLink } from "solito/link";

import { UseLink, UseLinkProps } from "./types";

export const useSolitoLink = ({ to }: UseLinkProps): UseLink => {
  const { href, onPress } = useLink({
    href: to,
  });

  return {
    href,
    onPress,
  };
};
