const getTokenMigrateURL = (
  baseURL: string,
  appId: string,
  callbackURL: string,
  userToken: string
) => {
  const url = new URL(baseURL);

  url.searchParams.append("app_id", appId);
  url.searchParams.append("callback_url", callbackURL);
  url.searchParams.append("usertoken", userToken);

  return url;
};

export default getTokenMigrateURL;
