import { z } from "zod";

import { nlpBaseSchema } from "./nlp-base";

export const nlpBaseGetSchema = z.object({
  podcast_episode_analysis: z.object({
    musixmatch: z
      .object({
        nlp: nlpBaseSchema,
        part: z.string(),
      })
      .nullish(),
  }),
});
