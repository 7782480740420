import { TypeOf } from "zod";

import { Episode } from "../../types";
import { parseEpisode } from "../common";
import { episodeGetSchema } from "../parsing";

export const episodeGetTransformer = (
  input: TypeOf<typeof episodeGetSchema>
): Episode | undefined => {
  if (!input.podcast_episode) {
    return undefined;
  }

  return parseEpisode(input.podcast_episode);
};
