import { useEffect, useRef, PropsWithChildren } from "react";
import { GestureResponderEvent, Platform, View } from "react-native";

import Animated from "../Animated";
import Hoverable from "../Hoverable";
import { Props as HoverableProps } from "../Hoverable/Hoverable";
import Pressable from "../Pressable";
import { Props as PressableProps } from "../Pressable/Pressable";

import Checkbox, { Props as CheckboxProps } from "./Checkbox";

const EnhancedCheckbox = ({
  checked,
  disabled,
  error,
  label,
  labelAlign,
  labelType,
  onBlur,
  onChange = () => {},
  onFocus,
  onHover,
  style,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const hoverAnimatedValue = useRef(new Animated.Value(0)).current;
  const pressAnimatedValue = useRef(new Animated.Value(0)).current;
  const ref = useRef<View>(null);

  useEffect(() => {
    const viewRef = ref.current;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        e.stopPropagation();
      }

      if (e.code === "Space") {
        onChange(!checked, e);
      }
    };

    if (Platform.OS === "web") {
      (viewRef as unknown as HTMLElement)?.addEventListener(
        "keydown",
        handleKeyDown
      );

      return () => {
        (viewRef as unknown as HTMLElement)?.removeEventListener(
          "keydown",
          handleKeyDown
        );
      };
    }
  }, [checked, onChange]);

  return (
    <View ref={ref}>
      <Hoverable
        animatedValue={hoverAnimatedValue}
        isAnimationDisabled={disabled}
        onHover={onHover}
        style={style}
      >
        <Pressable
          {...otherProps}
          animatedValue={pressAnimatedValue}
          disabled={disabled}
          onPress={(event: GestureResponderEvent) => {
            onChange(!checked, event);
            onBlur?.();
          }}
        >
          <Checkbox
            checked={checked}
            disabled={disabled}
            error={error}
            hoverAnimatedValue={hoverAnimatedValue}
            label={label}
            labelAlign={labelAlign}
            labelType={labelType}
            pressAnimatedValue={pressAnimatedValue}
          />
        </Pressable>
      </Hoverable>
    </View>
  );
};

export interface Props
  extends CheckboxProps,
    HoverableProps,
    Omit<PressableProps, "onBlur" | "onPress" | "to"> {
  onBlur?: () => void;
  onChange?: (
    checked: boolean,
    event: GestureResponderEvent | KeyboardEvent
  ) => void;
}

export default EnhancedCheckbox;
