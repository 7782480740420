import { ComponentProps, FunctionComponent, PropsWithChildren } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { global, useTheme } from "../../styles";

import Skeleton from "./Skeleton";
import { styles } from "./styles";

const EnhancedSkeleton = ({
  align = "vertical",
  children,
  containerStyle,
  count = 1,
  gapSize,
  isLoading = true,
  SkeletonComponent,
  style: styleProp,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const { style } = useTheme(styles, { align });

  if (!isLoading) {
    return <>{children}</>;
  }

  if (count === 1) {
    return (
      <SkeletonComponent {...otherProps} style={styleProp}>
        {children}
      </SkeletonComponent>
    );
  }

  return (
    <View
      accessibilityLabel="skeleton loader"
      accessibilityRole="progressbar"
      accessibilityState={{ busy: true }}
      accessibilityValue={{ text: "Content loading..." }}
      style={[containerStyle, style.container]}
    >
      {Array.from(Array(count).keys()).map((_, index) => {
        return (
          <SkeletonComponent
            key={index}
            style={[
              {
                marginBottom:
                  align === "vertical" && index !== count - 1 && count > 1
                    ? gapSize || global.spacing * 4
                    : undefined,
                marginRight:
                  align === "horizontal" && index !== count - 1 && count > 1
                    ? gapSize || global.spacing * 4
                    : undefined,
              },
              styleProp,
            ]}
            {...otherProps}
          />
        );
      })}
    </View>
  );
};

export type Align = "horizontal" | "vertical";

export interface SharedSkeletonProps {
  align?: Align;
  count?: number;
  gapSize?: number;
  containerStyle?: StyleProp<ViewStyle>;
  isLoading?: boolean;
  style?: StyleProp<ViewStyle>;
}

interface Props extends SharedSkeletonProps {
  SkeletonComponent: FunctionComponent<
    PropsWithChildren<ComponentProps<typeof Skeleton>>
  >;
}

export default EnhancedSkeleton;
