import { memo } from "react";
import { ImageStyle, Image as RNImage } from "react-native";

import { ImageProps } from "./types";

// Can't use Stylesheet.create() here because the
// outline props gets stripped away
const styleOutline: ImageStyle = {
  // @ts-ignore React web only
  outline: "solid 1px rgba(0, 0, 0, 0.1)",
  outlineOffset: -1,
};

const Image = ({
  onError,
  onLoadEnd,
  outline = true,
  src,
  style: styleProp,
}: ImageProps) => {
  return (
    <RNImage
      onError={onError}
      onLoadEnd={onLoadEnd}
      source={{ uri: src }}
      style={[outline ? styleOutline : undefined, styleProp]}
    />
  );
};

export default memo(Image);
