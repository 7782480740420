import { encodeURL } from "../../utils";
import { MODE_SHARED } from "../constants";

interface Options {
  appId: string;
  baseURL: string;
  forceRedirect?: boolean;
  origin: string;
}

const getSignInURL = ({ appId, baseURL, forceRedirect, origin }: Options) => {
  const url = new URL(baseURL);

  url.searchParams.append("mode", MODE_SHARED);
  url.searchParams.append("app_id", appId);
  url.searchParams.append("origin", encodeURL(origin));

  if (forceRedirect) {
    url.searchParams.append("force_app_redirect", "true");
  }

  return url;
};

export default getSignInURL;
