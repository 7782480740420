import { useMemo, PropsWithChildren } from "react";
import {
  Image,
  ImageProps,
  ImageStyle,
  ImageURISource,
  Platform,
  View,
} from "react-native";

import { ComponentSize } from "../../styles/global";
import { useTheme } from "../../styles/themes/themeUtils";
import { IconComponent, IconProfile } from "../Icon";

import styles from "./styles";

const Avatar = ({
  hasBackground = true,
  hasPlaceholder = false,
  PlaceholderIcon = IconProfile,
  shape = "default",
  size = "default",
  source,
  style: styleProp,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(
    styles,
    useMemo(
      () => ({ hasBackground, shape, size }),
      [shape, hasBackground, size]
    )
  );

  return (
    <View style={[style.container, styleProp]}>
      {hasPlaceholder && (
        <PlaceholderIcon
          color={theme.contentTertiary}
          size="50%"
          style={style.icon}
        />
      )}
      <Image
        {...otherProps}
        defaultSource={Platform.OS === "ios" ? undefined : source}
        source={source}
        style={style.avatar}
      />
    </View>
  );
};

export type Shape = "default" | "round";

interface Props extends ImageProps {
  hasPlaceholder?: boolean;
  hasBackground?: boolean;
  PlaceholderIcon?: IconComponent;
  shape?: Shape;
  size?: ComponentSize;
  source: ImageURISource;
  style?: ImageStyle;
}

export default Avatar;
