import { PropsWithChildren } from "react";

import ItemLabel, { Props } from "../shared/ItemLabel";

const ListItemLabel = ({
  color,
  endEnhancer,
  numberOfLines,
  startEnhancer,
  text,
  type,
}: PropsWithChildren<Props>) => {
  return (
    <ItemLabel
      color={color}
      endEnhancer={endEnhancer}
      numberOfLines={numberOfLines}
      startEnhancer={startEnhancer}
      text={text}
      type={type}
    />
  );
};

export default ListItemLabel;
