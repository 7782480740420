import { Easing } from "react-native";

import { viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";

interface Props {
  fullscreen?: boolean;
  isHandleVisible?: boolean;
}

const handleHeight = 6;
const handleWidth = 50;

const styles = (theme: Theme, { fullscreen, isHandleVisible }: Props = {}) => {
  // NOTE:
  // borderRadius must be added to all the views for native support
  const radius = {
    borderTopLeftRadius: global.radius.large,
    borderTopRightRadius: global.radius.large,
  };

  return viewStyles({
    animatedView: {
      ...(!fullscreen && radius),
      ...(fullscreen && { height: "100%" }),
      flex: 1,
      maxHeight: "100%",
      width: "100%",
    },
    card: {
      ...global.sheetShadow.centered,
      ...(!fullscreen && radius),
      backgroundColor: theme.backgroundPrimaryElevated,
      ...(fullscreen && { height: "100%" }),
      maxHeight: "100%",
      paddingTop: isHandleVisible ? 0 : bottomSheetPaddingTop,
      width: "100%",
    },
    container: {
      ...(!fullscreen && radius),
      ...(fullscreen && { height: "100%" }),
      flex: 1,
      justifyContent: "flex-end",
      maxHeight: "100%",
    },
    handle: {
      alignSelf: "center",
      backgroundColor: theme.backgroundTertiary,
      borderRadius: handleHeight,
      height: handleHeight,
      width: handleWidth,
    },
    handleWrapper: {
      paddingVertical: bottomSheetHandlePaddingTop,
      width: "100%",
    },
    safeArea: {
      flex: 1,
    },
  });
};

export default styles;

// This values can be used by other components that include BottomSheet
export const bottomSheetDuration = 250;
export const bottomSheetEasing = Easing.bezier(0.25, 0.46, 0.45, 0.94);
export const bottomSheetPaddingBottom = global.spacing * 12;
export const bottomSheetPaddingHorizontal = global.spacing * 4;
export const bottomSheetPaddingTop = global.spacing * 6;
export const bottomSheetHandlePaddingTop = global.spacing * 2;
