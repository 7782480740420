import { useBackendCache } from "@mxmdev/podcasts-shared-native";

import { useSSRProvider } from "../../../ssr/SSRProvider";
import { SSRProps, SsrPages } from "../../../ssr/types";

export const useSSRData = <
  PageT extends keyof SsrPages,
  PageKeyT extends Exclude<keyof SsrPages[PageT], "id" | "tag">
>(
  id: SSRProps["id"] | undefined,
  tag: PageT,
  selector: PageKeyT
): SsrPages[PageT][PageKeyT] | undefined => {
  const ssrProps = useSSRProvider();
  const { isBackendCacheEnabled } = useBackendCache();

  // If we invalidate the backend cache from the developer tools, we want to
  // call every API on the client, so we don't use the SSRData which would
  // skip some API calls.
  if (!isBackendCacheEnabled) {
    return undefined;
  }

  if (!ssrProps) {
    return undefined;
  }

  if (id === undefined || ssrProps.id !== id) {
    return undefined;
  }

  if (tag !== ssrProps.tag) {
    return undefined;
  }

  // Checked with the previous if
  const typedSSRProps = ssrProps as SsrPages[PageT];

  return typedSSRProps[selector];
};
