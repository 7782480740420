import { canUseDOM } from "@apollo/client/utilities";
import { ReactElement, useCallback, useState, PropsWithChildren } from "react";
import { Platform } from "react-native";

import RefreshContext from "./RefreshContext";

const MIN_LOADING_TIME_MS: number = 300; // Ensures a minimal delay for the best UX
const DEFAULT_LOADING_TIME_MS: number = 1000; // 1 second

const RefreshProvider = ({
  children,
  loadingComponent,
}: PropsWithChildren<Props>) => {
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * The refresh acts differently depending on the platform.
   * On Web, we simply force a refresh of the web page.
   * On native, we need to trick the application to re-render its entire stack,
   * we do so by rendering a loading animation instead of the application.
   */
  const refresh = useCallback((loadingTimeMs?: number) => {
    if (Platform.OS === "web" && canUseDOM) {
      return window.location.reload();
    }

    setLoading(true);
    setTimeout(
      () => setLoading(false),
      Math.max(MIN_LOADING_TIME_MS, loadingTimeMs ?? DEFAULT_LOADING_TIME_MS)
    );
  }, []);

  if (loading) {
    return loadingComponent;
  }

  return (
    <RefreshContext.Provider value={{ refresh }}>
      {children}
    </RefreshContext.Provider>
  );
};

interface Props {
  loadingComponent: ReactElement<any, any> | null;
}

export default RefreshProvider;
