import { createContext, PropsWithChildren } from "react";

import { PersistenceManager } from ".";

export type LocalPersistenceContextData = {
  persistenceManager: PersistenceManager | undefined;
};

const localPersistenceContextDefaultValue: LocalPersistenceContextData = {
  persistenceManager: undefined,
};

export const LocalPersistenceContext =
  createContext<LocalPersistenceContextData>(
    localPersistenceContextDefaultValue
  );

const LocalPersistenceProvider = ({
  children,
  persistenceManager,
}: PropsWithChildren<Props>) => {
  return (
    <LocalPersistenceContext.Provider value={{ persistenceManager }}>
      {children}
    </LocalPersistenceContext.Provider>
  );
};

interface Props {
  persistenceManager: PersistenceManager;
}

export default LocalPersistenceProvider;
