import {
  Avatar as RitmoAvatar,
  useMediaQuery,
  useTheme,
} from "@mxmdev/react-universal-components";
import { ComponentProps, PropsWithChildren } from "react";
import { Animated } from "react-native";

import styles from "./styles";

const Avatar = ({
  hoverAnimatedValue,
  pressAnimatedValue,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });

  return (
    <>
      <RitmoAvatar {...otherProps} />

      {hoverAnimatedValue && (
        <Animated.View
          style={[
            style.overlay,
            style.hoverOverlay,
            { opacity: hoverAnimatedValue },
          ]}
        />
      )}

      {pressAnimatedValue && (
        <Animated.View
          style={[
            style.overlay,
            style.pressOverlay,
            { opacity: pressAnimatedValue },
          ]}
        />
      )}
    </>
  );
};

export interface Props extends ComponentProps<typeof RitmoAvatar> {
  hoverAnimatedValue?: Animated.Value;
  pressAnimatedValue?: Animated.Value;
}

export default Avatar;
