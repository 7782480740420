import { ApolloProvider } from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { ComponentProps, useMemo, PropsWithChildren } from "react";

import { isServer } from "..";
import { useErrorTracking } from "../error-tracking";
import { RefreshProvider, useRefresh } from "../refresh";

import { apolloClient } from "./providers/apollo";

const DataFetchingProvider = ({
  apiOrigin,
  apiURLSignatureEnabled,
  appId,
  appScheme,
  appVersion,
  captchaChallengeURL,
  children,
  clientKey,
  datoApiHeaders,
  datoApiToken,
  endpoints,
  failOnMissingUserID = true,
  loadingComponent,
  signOutURL,
  ssrMode,
}: PropsWithChildren<Props>) => {
  const errorTracking = useErrorTracking();
  const refresh = useRefresh();
  const client = useMemo(() => {
    const initOptions = {
      apiOrigin,
      apiURLSignatureEnabled,
      appId,
      appScheme,
      appVersion,
      captchaChallengeURL,
      clientKey,
      datoApiHeaders,
      datoApiToken,
      endpoints,
      failOnMissingUserID,
      forceRefresh: refresh,
      logError: errorTracking.logError,
      signOutURL,
      ssrMode,
    };

    if (isServer()) {
      return apolloClient.getNew(initOptions);
    } else {
      return apolloClient.get(initOptions);
    }
  }, [
    apiOrigin,
    apiURLSignatureEnabled,
    appId,
    appScheme,
    appVersion,
    captchaChallengeURL,
    clientKey,
    datoApiHeaders,
    datoApiToken,
    endpoints,
    errorTracking.logError,
    failOnMissingUserID,
    refresh,
    signOutURL,
    ssrMode,
  ]);

  return (
    <RefreshProvider loadingComponent={loadingComponent}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </RefreshProvider>
  );
};

type DatoApiHeader = "X-Environment" | "X-Exclude-Invalid" | "X-Include-Drafts";

type DatoApiHeaders = Partial<Record<DatoApiHeader, string>>;

interface RestLinkEndpoint extends RestLink.EndpointOptions {
  signatureEnabled?: boolean;
}

interface RestLinkEndpoints {
  [key: string]: string | RestLinkEndpoint;
}
interface Props {
  apiOrigin: string;
  apiURLSignatureEnabled: boolean;
  appId: string;
  appScheme?: string;
  appVersion?: string;
  captchaChallengeURL?: string;
  clientKey: string;
  datoApiHeaders?: DatoApiHeaders;
  datoApiToken?: string;
  endpoints?: RestLinkEndpoints;
  failOnMissingUserID?: boolean;
  loadingComponent: ComponentProps<typeof RefreshProvider>["loadingComponent"];
  signOutURL: string | undefined | null;
  ssrMode?: boolean;
}

export default DataFetchingProvider;
