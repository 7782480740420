const API_ALLOW_GUEST_ACCESS = process.env.NEXT_PUBLIC_API_ALLOW_GUEST_ACCESS;
const API_ORIGIN = process.env.NEXT_PUBLIC_API_ORIGIN;
const API_STREAM = process.env.NEXT_PUBLIC_API_STREAM;
const API_URL_SIGNATURE_ENABLED =
  process.env.NEXT_PUBLIC_API_URL_SIGNATURE_ENABLED;
const APP_ID = process.env.NEXT_PUBLIC_APP_ID;

const APP_SYNC_API_KEY = process.env.NEXT_PUBLIC_APP_SYNC_API_KEY;
const APP_SYNC_URL = process.env.NEXT_PUBLIC_APP_SYNC_URL;
const APP_URL = process.env.NEXT_PUBLIC_APP_URL;

const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

const AUTH_CAPTCHA_URL = process.env.NEXT_PUBLIC_CAPTCHA_URL;
const AUTH_SHARED_SIGN_IN_ENABLED =
  process.env.NEXT_PUBLIC_AUTH_SHARED_SIGN_IN_ENABLED;
const AUTH_SIGN_IN_URL = process.env.NEXT_PUBLIC_AUTH_SIGN_IN_URL;
const AUTH_SIGN_OUT_URL = process.env.NEXT_PUBLIC_AUTH_SIGN_OUT_URL;
const AUTH_TOKEN_MIGRATE_URL = process.env.NEXT_PUBLIC_AUTH_TOKEN_MIGRATE_URL;

const BUILD_NUMBER = process.env.NEXT_PUBLIC_BUILD_NUMBER;
const CLIENT_KEY = process.env.NEXT_PUBLIC_CLIENT_KEY;
const DEFAULT_BOT_TOKEN = process.env.API_DEFAULT_BOT_TOKEN;
const DEFAULT_USER_TOKEN = process.env.API_DEFAULT_USER_TOKEN;
const DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV;

const ACCOUNT_URL = process.env.NEXT_PUBLIC_ACCOUNT_URL;

const FF_DISABLE_ADS_FINDER = process.env.NEXT_PUBLIC_FF_DISABLE_ADS_FINDER;
const FF_DISABLE_PODCAST_CLAIM =
  process.env.NEXT_PUBLIC_FF_DISABLE_PODCAST_CLAIM;
const FF_DISABLE_RELATED_PODCASTS =
  process.env.NEXT_PUBLIC_FF_DISABLE_RELATED_PODCASTS;
const FF_DISABLE_SEARCH_BAR = process.env.NEXT_PUBLIC_FF_DISABLE_SEARCH_BAR;
const FF_ENABLE_ITUNES_REDIRECTS =
  process.env.NEXT_PUBLIC_FF_ENABLE_ITUNES_REDIRECTS;
const FF_ENABLE_MXM_ACCOUNTS = process.env.NEXT_PUBLIC_FF_ENABLE_MXM_ACCOUNTS;
const FF_ENABLE_REFERENCE_TRACK_FALLBACK =
  process.env.NEXT_PUBLIC_FF_ENABLE_REFERENCE_TRACK_FALLBACK;
const FF_ENABLE_SEO = process.env.NEXT_PUBLIC_FF_ENABLE_SEO;
const FF_ENABLE_SSR = process.env.NEXT_PUBLIC_FF_ENABLE_SSR;
const FF_ENABLE_TEXT2SYNC = process.env.NEXT_PUBLIC_FF_ENABLE_TEXT2SYNC;
const FF_ENABLE_AUDIOGRAM_DOWNLOAD =
  process.env.NEXT_PUBLIC_FF_ENABLE_AUDIOGRAM_DOWNLOAD;

const STRIPE_PRICE_ID = process.env.NEXT_PUBLIC_STRIPE_PRICE_ID;

const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
const FIREBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
const FIREBASE_MEASUREMENT_ID = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;
const FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET_ID =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET_ID;

const PR_NUMBER = process.env.NEXT_PUBLIC_PR_NUMBER;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_RELEASE_VERSION = process.env.NEXT_PUBLIC_SENTRY_RELEASE_VERSION;

module.exports = {
  ACCOUNT_URL,
  API_ALLOW_GUEST_ACCESS,
  API_ORIGIN,
  API_STREAM,
  API_URL_SIGNATURE_ENABLED,
  APP_ID,
  APP_SYNC_API_KEY,
  APP_SYNC_URL,
  APP_URL,
  APP_VERSION,
  AUTH_CAPTCHA_URL,
  AUTH_SHARED_SIGN_IN_ENABLED,
  AUTH_SIGN_IN_URL,
  AUTH_SIGN_OUT_URL,
  AUTH_TOKEN_MIGRATE_URL,
  BUILD_NUMBER,
  CLIENT_KEY,
  DEFAULT_BOT_TOKEN,
  DEFAULT_USER_TOKEN,
  DEPLOY_ENV,
  FF_DISABLE_ADS_FINDER,
  FF_DISABLE_PODCAST_CLAIM,
  FF_DISABLE_RELATED_PODCASTS,
  FF_DISABLE_SEARCH_BAR,
  FF_ENABLE_AUDIOGRAM_DOWNLOAD,
  FF_ENABLE_ITUNES_REDIRECTS,
  FF_ENABLE_MXM_ACCOUNTS,
  FF_ENABLE_REFERENCE_TRACK_FALLBACK,
  FF_ENABLE_SEO,
  FF_ENABLE_SSR,
  FF_ENABLE_TEXT2SYNC,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET_ID,
  PR_NUMBER,
  SENTRY_DSN,
  SENTRY_RELEASE_VERSION,
  STRIPE_PRICE_ID,
};
