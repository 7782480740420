import {
  Text,
  useMediaQuery,
  useTheme,
  Avatar as RitmoAvatar,
  BottomSheetScrollView,
  Button,
  FocusLock,
  Popover,
  useDialogContext,
  useToast,
  IconComponent,
  IconCheck,
  PlanIndicator,
} from "@mxmdev/react-universal-components";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import {
  cloneElement,
  ComponentProps,
  ReactElement,
  useCallback,
  PropsWithChildren,
} from "react";
import { GestureResponderEvent, View } from "react-native";

import { USER_EMPTY_PIC_BE, USER_EMPTY_PIC_PROXY } from "../../constants";
import { AccountEditableAvatar } from "../AccountEditableAvatar";
import AccountPictureDialog from "../AccountPictureDialog";

import AccountMenuItem from "./AccountMenuItem";
import styles from "./styles";

const AccountMenu = ({
  anchor,
  anchorContainerStyle,
  appData,
  hasManageAccountButton = true,
  image,
  isLoading,
  manageAccountLink,
  onDismiss,
  onSignOut,
  service,
  subscriptionColors,
  subscriptionIcon,
  subscriptionName,
  t,
  userName,
  visible,
}: PropsWithChildren<Props>) => {
  const { appInfo, appOptions } = appData || {};

  const { isSmallScreen } = useMediaQuery();
  const { openCustomResponseDialog } = useDialogContext();
  const { showToast } = useToast();
  const { style, theme } = useTheme(styles, {
    appData: appInfo?.name,
    isSmallScreen,
  });

  const auth = useAuth();

  const isObsoletePlaceholder =
    image?.includes(USER_EMPTY_PIC_PROXY) || image?.includes(USER_EMPTY_PIC_BE);

  const profileImage = isObsoletePlaceholder ? "" : image;

  const user = auth.user?.profile;

  const openEditPictureDialog = useCallback(async () => {
    const { resolution } = await openCustomResponseDialog(
      AccountPictureDialog,
      {
        userPicture: profileImage,
      }
    );

    if (resolution === "error") {
      return;
    }

    if (resolution === "saved") {
      setTimeout(
        () => showToast("Account Picture updated", { Icon: IconCheck }),
        1000
      );
    }
  }, [profileImage, openCustomResponseDialog, showToast]);

  return (
    <Popover
      anchor={anchor}
      anchorContainerStyle={anchorContainerStyle}
      onDismiss={onDismiss}
      style={style.dropDown}
      visible={visible}
    >
      <FocusLock
        lockProps={{
          style: { display: "flex", maxHeight: "inherit" },
        }}
      >
        <BottomSheetScrollView contentContainerStyle={style.container}>
          {user?.user_type === "mxm" ? (
            <AccountEditableAvatar
              hasPlaceholder={!profileImage || isLoading}
              isLoading={isLoading}
              onPress={openEditPictureDialog}
              source={{
                uri: profileImage,
              }}
              style={style.profilePic}
            />
          ) : (
            <RitmoAvatar
              hasPlaceholder={!profileImage || isLoading}
              source={{
                uri: profileImage,
              }}
              style={style.profilePic}
            />
          )}
          <Text
            align="center"
            color={theme.contentPrimary}
            style={style.username}
            type="labelDefault"
          >
            {userName}
          </Text>
          <Text
            align="center"
            color={theme.contentSecondary}
            type="bodyDefault"
          >
            {t
              ? t("logged_in", { name: service })
              : `Logged in with ${service}`}
          </Text>
          {hasManageAccountButton && (
            <Button
              minTouchTarget
              onPress={manageAccountLink}
              size="small"
              style={style.accountButton}
              text={t ? t("manage_account") : "Manage Account"}
              type="basic"
            />
          )}
          {appInfo?.name && (
            <View style={style.appDataBox}>
              <View style={style.appInfoContainer}>
                <View>
                  <Text type="headingSmall">{appInfo.name}</Text>
                  {appInfo.userRole && (
                    <Text
                      color={theme.contentSecondary}
                      style={style.appInfoRole}
                      type="bodyDefault"
                    >
                      {appInfo.userRole}
                    </Text>
                  )}
                </View>

                {subscriptionName && (
                  <View style={style.planIndicator}>
                    <PlanIndicator
                      colors={subscriptionColors || [theme.paletteBrand100]}
                      Icon={subscriptionIcon}
                      label={subscriptionName}
                    />
                  </View>
                )}
              </View>

              {appOptions?.map((options, index) => {
                if (options.component !== undefined) {
                  return cloneElement(options.component, {
                    key: index,
                    onPress: (event: GestureResponderEvent) => {
                      if (options.component?.props.onPress) {
                        options.component.props.onPress(event);
                      }

                      onDismiss();
                    },
                  });
                }

                return (
                  <AccountMenuItem
                    externalLink={options.externalLink}
                    Icon={options.StartIcon}
                    key={index}
                    label={options.label ?? ""}
                    onPress={(event: GestureResponderEvent) => {
                      options.onPress?.(event);
                      onDismiss();
                    }}
                    to={options.url}
                  />
                );
              })}

              {(appInfo.version || appInfo.build) && (
                <View style={style.versionInfo}>
                  {appInfo.version ? (
                    <Text color={theme.contentSecondary} type="bodySmall">
                      {`Version ${appInfo.version}`}
                    </Text>
                  ) : undefined}
                  {appInfo.build ? (
                    <Text color={theme.contentSecondary} type="bodySmall">
                      {`Build: ${appInfo.build}`}
                    </Text>
                  ) : undefined}
                </View>
              )}
            </View>
          )}
          <View style={style.signOut}>
            <Button
              onPress={() => onSignOut()}
              size="small"
              text={t ? t("sign_out") : "Sign out"}
              type="text"
            />
          </View>
        </BottomSheetScrollView>
      </FocusLock>
    </Popover>
  );
};

export interface AppData {
  appInfo: {
    build?: string;
    name: string;
    userRole?: string;
    version?: string;
  };
  appOptions: AppOptions[];
}

export interface AppOptions {
  component?: ReactElement;
  externalLink?: boolean;
  label?: string;
  onPress?: ComponentProps<typeof Button>["onPress"];
  StartIcon?: IconComponent;
  url?: string;
}

export interface Props {
  anchor: ComponentProps<typeof Popover>["anchor"];
  anchorContainerStyle: ComponentProps<typeof Popover>["anchorContainerStyle"];
  appData?: AppData;
  hasManageAccountButton?: boolean;
  manageAccountLink: ComponentProps<typeof Button>["onPress"];
  image?: string;
  isLoading?: boolean;
  onDismiss: () => void;
  onSignOut: () => void;
  service: string;
  subscriptionIcon?: IconComponent;
  subscriptionColors?: string[];
  subscriptionName?: string;
  t?: (key: string, variable?: { name: string }) => string;
  userName: string;
  visible: ComponentProps<typeof Popover>["visible"];
}

export default AccountMenu;
