import { Platform } from "react-native";

import { viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";

interface Props {
  isSmallScreen: boolean;
}

const styles = (theme: Theme, { isSmallScreen }: Props) => {
  return viewStyles({
    dropdown: {
      paddingHorizontal: 0,
    },
    menu: {
      minWidth: 220,
      overflow: "hidden",
      paddingVertical: isSmallScreen ? 0 : global.spacing * 2,
    },
    menuItem: {
      ...Platform.select({
        web: {
          // NOTE:
          // Due to the internal structure of `ListItem`,
          // this height override works only on web.
          // To solve this in the future, replace it with
          // a custom one (just for `MenuItem`) or change
          // its internal structure.
          height: global.size.default,
          minHeight: 0,
        },
      }),
    },
  });
};

export default styles;
