import {
  IconAi,
  IconComponent,
  IconList,
  IconTranscript,
  IconVideocam,
} from "@mxmdev/react-universal-components";

import { StudioRoute } from "./types";

export const STUDIO_TABS: {
  Icon?: IconComponent;
  name: string;
  value: StudioRoute;
}[] = [
  {
    Icon: IconList,
    name: "Overview",
    value: "StudioEpisodeOverview",
  },
  {
    Icon: IconTranscript,
    name: "Transcription",
    value: "Editor",
  },
  {
    Icon: IconVideocam,
    name: "Clips",
    value: "StudioEpisodeVideo",
  },
  {
    Icon: IconAi,
    name: "AI Content",
    value: "StudioEpisodeAIContent",
  },
];
