import { createContext } from "react";
import { Platform } from "react-native";

import { useAuth } from "../auth-ssr/client";
import { redirectToCaptchaPage } from "../auth-ssr/client/redirects";
import { setRedirectUrl } from "../auth-ssr/client/storage";
import { getRedirectOrigin } from "../auth-ssr/client/utils";
import { AUTH_DEFAULT_SIGN_IN_URL, MODE_LEGACY } from "../auth-ssr/constants";
import { getSignOutURL } from "../auth-ssr/utils";
import { queries, useQuery } from "../data-fetching";
import { ApolloClientOptions } from "../data-fetching/providers/apollo";
import { CrowdScoreGetData, CrowdScoreGetVars, User } from "../types";
import {
  AUTH_DEFAULT_CAPTCHA_URL,
  canUseDOM,
  encodeURL,
  getCaptchaCallbackURL,
  getOrigin,
  storage,
  openUrl,
} from "../utils";

export const CAPTCHA_PATH: string = "/captcha";

export const getSignInURL = (
  appId: string,
  userToken: string,
  origin: string,
  baseURL: string = AUTH_DEFAULT_SIGN_IN_URL
): string => {
  return `${baseURL}?mode=${MODE_LEGACY}&app_id=${appId}&usertoken=${userToken}&origin=${origin}`;
};

export const handleUnauthorized = async (
  hint: string | undefined,
  options: ApolloClientOptions
) => {
  const { appId, appScheme, captchaChallengeURL, signOutURL } = options;

  const refreshToken = async () => {
    console.warn("[AuthUtils] Refreshing user token...");

    await storage.remove("userToken");

    if (canUseDOM && signOutURL) {
      openUrl(
        getSignOutURL(signOutURL, appId, location.href).toString(),
        "_self"
      );
    } else {
      options.forceRefresh();
    }
  };

  switch (hint) {
    case "captcha":
      await setRedirectUrl(storage, getRedirectOrigin(appScheme));

      redirectToCaptchaPage(
        captchaChallengeURL || AUTH_DEFAULT_CAPTCHA_URL,
        getCaptchaCallbackURL(getOrigin(appScheme))
      );
      break;

    default:
      await refreshToken();
      break;
  }
};

export const signIn = async (
  appId: string,
  userToken: string,
  signInURL?: string
) => {
  if (!canUseDOM) {
    console.warn(`[AuthUtils] signIn unimplemented on ${Platform.OS}`);

    return;
  }

  const getSignInOrigin = (): string => {
    const pathName: string = new URL(window.location.href).pathname;
    let origin: string = window.location.href;

    if (pathName === CAPTCHA_PATH) {
      origin = window.location.origin;
    }

    return encodeURL(origin);
  };

  return Promise.resolve(
    window.location.replace(
      getSignInURL(appId, userToken, getSignInOrigin(), signInURL)
    )
  );
};

export const usePublishingAuth = () => {
  const { data, loading: isLoadingPermissions } = useQuery<
    CrowdScoreGetData,
    CrowdScoreGetVars
  >(queries.crowdScoreGet, { variables: { part: "auth" } });

  const permissions =
    data?.crowdScoreGet.body?.user.env_authorization?.["mxm-pro"];
  const editorPolicy = permissions?.["publisher_editor"];
  const managerPolicy = permissions?.["publisher_manager"];
  const readerPolicy = permissions?.["publisher_viewer"];
  const editorResources = managerPolicy
    ? Object.keys(managerPolicy)
    : editorPolicy
    ? Object.keys(editorPolicy)
    : undefined;
  const readerResources = readerPolicy && Object.keys(readerPolicy);

  const isUserAuthorized =
    permissions !== undefined ? Object.keys(permissions).length > 0 : false;
  const isUserEditor = editorPolicy !== undefined;
  const isUserManager = managerPolicy !== undefined;
  const isUserViewer = readerPolicy !== undefined;
  const userPublishingSources = editorResources
    ? editorResources
    : readerResources;

  return {
    isLoadingPermissions,
    isUserAuthorized,
    isUserEditor,
    isUserManager,
    isUserViewer,
    permissions,
    userPublishingSources,
  };
};

export const useUserId = (): string | undefined => {
  const auth = useAuth();

  return auth.user?.user_id;
};

interface AuthContextType {
  appId: string;
  captchaId?: string | null;
  signOut: () => void;
  user: User;
  userToken: string;
}

export const AuthContext = createContext<AuthContextType | null>(null);
