import {
  Children,
  cloneElement,
  ComponentProps,
  isValidElement,
  ReactElement,
  useMemo,
  useRef,
  PropsWithChildren,
} from "react";
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  ScrollView,
  StyleProp,
  ViewStyle,
} from "react-native";

import { global, useMediaQuery } from "../../styles";
import { useTheme } from "../../styles/themes/themeUtils";
import Button from "../Button";
import { Props as ButtonProps } from "../Button/EnhancedButton";

import styles from "./styles";

const ButtonGroup = ({
  align = "left",
  children,
  color,
  contentContainerStyle: contentContainerStyleProp,
  disabled,
  horizontalScrollAnimation = true,
  isScrollbarVisible = true,
  minTouchTarget = true,
  onPress,
  selected: selectedProp,
  shape,
  size,

  // These default values resolve most use cases
  spacing = size === "small" || size === "extraSmall" ? "small" : "default",

  style: styleProp,
  type,
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(
    styles,
    useMemo(
      () => ({
        align,
        spacing,
      }),
      [align, spacing]
    )
  );

  const childrenCoordinates = useRef<number[]>([]);
  const scrollViewRef = useRef<ScrollView | null>(null);

  const selectElement = (
    selected: number | number[] | undefined,
    index: number
  ) => {
    if (Array.isArray(selected)) {
      return selected.includes(index);
    }

    return selected === index;
  };

  const renderChildren = () => {
    return (
      <>
        {Children.map(children, (child, index) => {
          if (!isValidElement(child)) {
            return null;
          }

          const scrollTo = (x: number) => {
            scrollViewRef.current?.scrollTo({
              // We are forced to use an animated scroll, otherwise the coordinates of all the items succeeding the selected one (computed in `onLayout`) will be wrongly calculated.
              animated: true,
              x: x - global.spacing * (isSmallScreen ? 4 : 6),
              y: 0,
            });
          };

          const selected = selectElement(selectedProp, index);

          return cloneElement(
            child as ReactElement<ComponentProps<typeof Button>>,
            {
              color: child.props.color ?? color,
              disabled: child.props.disabled ?? disabled,
              minTouchTarget,
              onLayout: (event: LayoutChangeEvent) => {
                // @ts-ignore-next
                const coordinate = event.nativeEvent.layout.left;

                childrenCoordinates.current.push(coordinate);

                if (horizontalScrollAnimation && selected) {
                  scrollTo(coordinate);
                }
              },
              onPress: (event: GestureResponderEvent) => {
                if (horizontalScrollAnimation) {
                  scrollTo(childrenCoordinates.current[index]);
                }

                if (child.props.onPress) {
                  child.props.onPress(event);
                }

                if (onPress) {
                  onPress(event, index);
                }
              },
              selected,
              shape: child.props.shape ?? shape,
              size,
              style: index !== 0 ? style.childSpacing : undefined,
              type: child.props.type ?? type,
            }
          );
        })}
      </>
    );
  };

  return (
    <ScrollView
      contentContainerStyle={[
        contentContainerStyleProp,
        style.contentContainerStyle,
        style.alignment,
      ]}
      horizontal
      ref={scrollViewRef}
      showsHorizontalScrollIndicator={isScrollbarVisible}
      style={[style.scrollViewStyle, styleProp]}
    >
      {renderChildren()}
    </ScrollView>
  );
};

export type Align = "center" | "left" | "right";

export type Spacing = "default" | "small";

interface Props
  extends Omit<ButtonProps, "onHover" | "onPress" | "selected" | "style"> {
  align?: Align;
  contentContainerStyle?: StyleProp<ViewStyle>;
  horizontalScrollAnimation?: boolean;
  isScrollbarVisible?: boolean;
  onPress?: (event: GestureResponderEvent, index: number) => void;
  selected?: number | number[];
  spacing?: Spacing;
  style?: StyleProp<ViewStyle>;
}

export default ButtonGroup;
