import { TypeOf } from "zod";

import {
  Speaker,
  TopicOccurrence,
  UnsyncedSection,
  UnsyncedSectionWithTopics,
  UnsyncedTranscription,
} from "../../types";
import {
  collapseAdsSections,
  notUndefined,
  thumbnailOfSize,
} from "../../utils";
import {
  mapAPISpeakersToSpeakers,
  mapSpeakerLabelToPlaceholderSpeaker,
} from "../common";
import { nlpBaseGetSchema } from "../parsing";

// Ner family types that will be filtered out when loading the topics
const EXCLUDED_NER_FAMILY_TYPES = ["NUMBERS", "DATETIME"];

export const unsyncedTranscriptionGetTransformer = (
  input: TypeOf<typeof nlpBaseGetSchema>
): UnsyncedTranscription | undefined => {
  if (!input.podcast_episode_analysis) {
    return undefined;
  }

  const nlp_base = input.podcast_episode_analysis.musixmatch?.nlp;

  const verified =
    input.podcast_episode_analysis.musixmatch?.part === "nlp_base_curated";

  if (!nlp_base?.transcription) {
    return undefined;
  }

  const transcription = nlp_base.transcription.text;

  const rawUnsyncedSections = nlp_base.transcription.group_of_sentences.map(
    (sentence): UnsyncedSection => ({
      endPosition: sentence.end_char,
      isAds: sentence.is_ads === true,
      referenceEndTime: sentence.end_time,
      referenceStartTime: sentence.start_time,
      speakerId: sentence.speaker_label,
      startPosition: sentence.start_char,
      transcript: sentence.text,
      variant: "unsynced",
    })
  );

  const unsyncedSections = collapseAdsSections(rawUnsyncedSections);

  const topics =
    nlp_base.transcription.ner !== undefined
      ? nlp_base.transcription.ner
          .map((entity): TopicOccurrence | undefined => {
            if (EXCLUDED_NER_FAMILY_TYPES.includes(entity.family_type)) {
              return undefined;
            }

            if (entity.wikidata_id) {
              return {
                endPosition: entity.end_char,
                id: entity.wikidata_id,
                name: entity.name,
                startPosition: entity.start_char,
                text: transcription.slice(entity.start_char, entity.end_char),
                type: "rich",
              };
            } else {
              return {
                category: entity.family_type,
                endPosition: entity.end_char,
                name: entity.name,
                startPosition: entity.start_char,
                text: transcription.slice(entity.start_char, entity.end_char),
                type: "raw",
              };
            }
          })
          .filter(notUndefined)
          .filter((topic) =>
            // The topics gets inserted inside the sections assuming every topic
            // is contained in a section.
            unsyncedSections.some(
              (section) =>
                topic.startPosition >= section.startPosition &&
                topic.endPosition <= section.endPosition
            )
          )
      : [];

  topics.sort((a, b) => a.startPosition - b.startPosition);

  const sections: UnsyncedSectionWithTopics[] = unsyncedSections.map(
    (section) => ({
      ...section,
      topics: [],
    })
  );

  let currentSectionIndex = 0;

  for (const topic of topics) {
    while (currentSectionIndex < sections.length) {
      const currentSection = sections[currentSectionIndex];

      if (
        topic.startPosition >= currentSection.startPosition &&
        topic.endPosition <= currentSection.endPosition
      ) {
        currentSection.topics.push({
          ...topic,
          endPosition: topic.endPosition - currentSection.startPosition,
          startPosition: topic.startPosition - currentSection.startPosition,
        });
        break;
      } else {
        currentSectionIndex++;
      }
    }
  }

  let speakers: Speaker[];

  if ("speakers" in nlp_base.transcription && nlp_base.transcription.speakers) {
    const baseSpeakers = mapAPISpeakersToSpeakers(
      nlp_base.transcription.speakers
    );
    const resizedThumbnailSpeakers = baseSpeakers.map((speaker) => {
      return speaker.type === "rich"
        ? {
            ...speaker,
            thumbnailUrl: speaker.thumbnailUrl
              ? thumbnailOfSize(speaker.thumbnailUrl, 80)
              : undefined,
          }
        : speaker;
    });

    speakers = resizedThumbnailSpeakers;
  } else {
    const speakerLabelsSet = new Set(
      nlp_base.transcription.group_of_sentences.map(
        (sentence) => sentence.speaker_label
      )
    );
    const uniqueLabels = Array.from(speakerLabelsSet.values());

    speakers = uniqueLabels.map(mapSpeakerLabelToPlaceholderSpeaker);
  }

  return {
    sections,
    speakers: speakers ?? [],
    text: nlp_base.transcription.text,
    verified,
  };
};
