import { User } from "@mxmdev/react-universal-core";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import { useEffect } from "react";
import { useSWRConfig } from "swr";
import { TypeOf, z } from "zod";

import { useValidatedQuery } from "../hooks/data-fetching";

type UseUserOptions = {
  noCache?: boolean;
};

const ENDPOINT = "user.get";

const parser = z.object({
  user: z.any(),
});

const transformer = (input: TypeOf<typeof parser>): User => {
  return input.user;
};

export const useUser = ({ noCache }: UseUserOptions = {}): {
  user: User | undefined;
} => {
  const { cache, mutate } = useSWRConfig();

  const { user } = useAuth();

  // Invalidate the cache of SWR for this endpoint
  useEffect(() => {
    // Will never enter here unless we change the cache implementation of swr
    if (!(cache instanceof Map)) {
      throw new Error(
        "matchMutate requires the cache provider to be a Map instance"
      );
    }

    if (noCache) {
      for (const key of Array.from(cache.keys())) {
        if (key.includes(ENDPOINT)) {
          mutate(key, undefined);
        }
      }
    }
  }, [cache, mutate, noCache]);

  const { data } = useValidatedQuery(
    noCache ? ENDPOINT : undefined,
    {},
    parser,
    transformer
  );

  return { user: noCache ? data : user || undefined };
};
