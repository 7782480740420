import { useState, useEffect, useCallback } from "react";

import {
  subscribeForChangeEvents,
  unsubscribeForChangeEvents,
} from "./localUpdateNotifier";
import { useLocalPersistence } from "./useLocalPersistence";

const useLocalRawData = (
  key: string | undefined
): {
  error?: unknown;
  isLoading: boolean;
  data?: Readonly<unknown>;
  retry: () => void;
} => {
  const persistenceManager = useLocalPersistence();

  const [isLoading, setLoading] = useState<boolean>(key !== undefined);
  const [data, setData] = useState<Readonly<unknown> | undefined>(undefined);
  const [error, setError] = useState<unknown | undefined>(undefined);

  const loadData = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      setError(undefined);
      setData(undefined);

      if (key !== undefined) {
        const value = await persistenceManager.get(key);

        if (value !== undefined) {
          setData(JSON.parse(value));
        }
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [key, persistenceManager]);

  useEffect((): void => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    const handler = (): void => {
      loadData();
    };

    if (key !== undefined) {
      subscribeForChangeEvents(key, handler);
    }

    return (): void => {
      if (key !== undefined) {
        unsubscribeForChangeEvents(key, handler);
      }
    };
  }, [loadData, key]);

  return {
    data,
    error,
    isLoading,
    retry: loadData,
  };
};

export default useLocalRawData;
