import { canUseDOM } from "@mxmdev/react-universal-core";
import { createContext, useMemo, useCallback, PropsWithChildren } from "react";

import ErrorPanel from "../components/ErrorPanel";
import { Loader } from "../components/Loader";
import { useUserInfo } from "../hooks";
import { usePreferredLocale, useSavePreferredLocale } from "../hooks/locale";
import { DeviceLocaleInfo, getLocaleInfoFromDevice } from "../utils/locale";

import { supportedCountries } from "./countries";
import { LocaleInfo, LocaleState } from "./types";

export type LocaleContextData = {
  setLocalePreference: (locale: LocaleInfo) => void;
  state: LocaleState;
};

const localeContextDefaultValue: LocaleContextData = {
  setLocalePreference: () => {},
  state: { status: "not_initialized" },
};

export const LocaleContext = createContext<LocaleContextData>(
  localeContextDefaultValue
);

const LocaleProvider = ({ children }: PropsWithChildren<Props>) => {
  const {
    error: profileError,
    isLoading: isLoadingProfile,
    userInfo: { profile } = {},
  } = useUserInfo();

  const deviceInfo: DeviceLocaleInfo | undefined = useMemo(
    () => getLocaleInfoFromDevice(),
    []
  );

  const {
    isLoading: isLoadingUserLocalePreference,
    locale: userLocalePreference,
  } = usePreferredLocale();

  const { save: saveUserPreferredLocale } = useSavePreferredLocale();

  const handleSetLocalePreference = useCallback(
    (locale: LocaleInfo) => {
      saveUserPreferredLocale(locale);
    },
    [saveUserPreferredLocale]
  );

  const state = useMemo((): LocaleState => {
    let country = profile?.country ?? deviceInfo?.country ?? "us";
    let countryName =
      profile?.countryName ?? deviceInfo?.countryName ?? "United States";
    let languages: string[] = [];

    if (userLocalePreference) {
      country = userLocalePreference.country;
      countryName = userLocalePreference.countryName;
      languages = userLocalePreference.languages;
    } else if (profile?.nativeLanguages || profile?.spokenLanguages) {
      profile?.nativeLanguages?.forEach((lang) => {
        // If the user selects no languages, the backend returns "none" instead of an empty array...
        if (!languages.includes(lang) && lang !== "none") {
          languages.push(lang);
        }
      });

      profile?.spokenLanguages?.forEach((lang) => {
        if (!languages.includes(lang) && lang !== "none") {
          languages.push(lang);
        }
      });
    } else if (deviceInfo?.language) {
      languages = [deviceInfo.language];
    }

    // All users should also have english as language
    if (!languages.includes("en")) {
      languages.push("en");
    }

    // We only support a subset of countries right now, so override in case
    // the user is located in an unsupported country
    if (
      !supportedCountries.find(
        (supportedCountry) => supportedCountry.country === country
      )
    ) {
      country = "us";
      countryName = "United States";
      languages = ["en"];
    }

    if ((isLoadingProfile || isLoadingUserLocalePreference) && !profileError) {
      return {
        status: "loading",
      };
    } else if (country && countryName && languages) {
      return {
        country,
        countryName,
        languages,
        status: "loaded",
      };
    } else {
      return {
        status: "error",
      };
    }
  }, [
    deviceInfo,
    isLoadingProfile,
    isLoadingUserLocalePreference,
    profileError,
    profile,
    userLocalePreference,
  ]);

  if (
    canUseDOM &&
    (state.status === "loading" || state.status === "not_initialized")
  ) {
    return <Loader />;
  }

  if (state.status === "error") {
    return (
      <ErrorPanel
        description="We couldn't load your language, but don't worry! Please refresh the page and let us know if the problem persists"
        onRetry={(): void => {
          if (canUseDOM) {
            window.location.reload();
          }
        }}
      />
    );
  }

  return (
    <LocaleContext.Provider
      value={{ setLocalePreference: handleSetLocalePreference, state }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

interface Props {}

export default LocaleProvider;
