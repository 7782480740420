import { useState } from "react";

import { useEffectOnce } from "../utils/hookUtils";

/**
 * Returns the given data after the specified delay has passed.
 * This Hook acts as proxy that "delays" the given data for a while.
 *
 * The reason behind this hook is that sometimes, we'd like to defer
 * rendering a component for a while to distribute the rendering overhead
 * and make animations more fluid.
 * For example, if we are rendering a large amount of data in a component
 * that includes a fade-in animation, delaying the data for a few milliseconds
 * will prevent the fade-in animation from stuttering.
 */
const useDelayed = <T>(
  data: T,
  delay: number = 0
): {
  data: T | undefined;
} => {
  const [delayElapsed, setDelayElapsed] = useState(delay === 0);

  useEffectOnce(() => {
    if (delay === 0) {
      return;
    }

    const timeout = setTimeout((): void => {
      setDelayElapsed(true);
    }, delay);

    return (): void => clearTimeout(timeout);
  });

  return {
    data: delayElapsed ? data : undefined,
  };
};

export default useDelayed;
