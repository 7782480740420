import { canUseDOM } from "../../../utils";

const logEvent = (eventName: string, eventParams?: Record<string, any>) => {
  const { eventID, ...othersEventParams } = eventParams || {};

  if (!canUseDOM || !window.fbq) {
    console.warn(
      `[Analytics] Meta event '${eventName}' (params: ${JSON.stringify(
        eventParams
      )}) dropped, because analytics has not been properly initialized.`
    );

    return;
  }

  window.fbq(
    "track",
    eventName,
    othersEventParams,
    eventID ? { eventID } : undefined
  );
};

export { logEvent };
