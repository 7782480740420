import {
  Animated,
  viewStyles,
  global,
} from "@mxmdev/react-universal-components";
import { useRef } from "react";
import { View } from "react-native";

import { useEffectOnce } from "../../utils/hookUtils";
import { createStyles, useTheme } from "../../utils/themeUtils";

const LoaderBar = (): JSX.Element => {
  const { style } = useTheme(styles);

  const scale = useRef(new Animated.Value(0)).current;

  useEffectOnce(() => {
    const animation = progressAnimation(scale);

    animation.start();

    return (): void => {
      animation.stop();
    };
  });

  const progress = scale.interpolate({
    inputRange: [0, 1],
    outputRange: ["0%", "100%"],
  });

  return (
    <View style={style.container}>
      <Animated.View
        style={[
          style.bar,
          {
            width: progress,
          },
        ]}
      />
    </View>
  );
};

const styles = createStyles((theme) =>
  viewStyles({
    bar: {
      backgroundColor: theme.paletteBrand100,
      height: global.spacing,
    },
    container: {
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
  })
);

const progressAnimation = (
  scale: Animated.Value
): Animated.CompositeAnimation =>
  Animated.sequence([
    Animated.timing(scale, {
      duration: 2000,
      toValue: 0.5,
      useNativeDriver: false,
    }),
    Animated.timing(scale, {
      duration: 2000,
      toValue: 0.75,
      useNativeDriver: false,
    }),
    Animated.timing(scale, {
      duration: 4000,
      toValue: 0.85,
      useNativeDriver: false,
    }),
    Animated.timing(scale, {
      duration: 60000,
      toValue: 1,
      useNativeDriver: false,
    }),
  ]);

export default LoaderBar;
