import { ComponentProps, PropsWithChildren } from "react";
import { TextStyle, View } from "react-native";

import { Text } from "..";
import { useTheme } from "../../styles/themes/themeUtils";
import Animated from "../Animated";

import styles from "./styles";

const Checkbox = ({
  checked = false,
  disabled = false,
  error = false,
  hoverAnimatedValue,
  label,
  labelAlign = "left",
  labelType = "bodyLarge",
  pressAnimatedValue,
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(styles, { labelType });

  return (
    <View
      style={[
        style.container,
        labelAlign === "right" && style.rightLabelContainer,
      ]}
    >
      <View style={style.checkmarkContainer}>
        {hoverAnimatedValue && (
          <Animated.View
            style={[
              style.overlay,
              style.hoverOverlay,
              { opacity: hoverAnimatedValue },
            ]}
          />
        )}
        {pressAnimatedValue && (
          <Animated.View
            style={[
              style.overlay,
              style.pressOverlay,
              { opacity: pressAnimatedValue },
            ]}
          />
        )}
        <View
          style={[
            style.checkmark,
            error && style.checkmarkError,
            disabled && style.checkmarkDisabled,
          ]}
        >
          {checked && (
            <View
              style={[
                style.checkmarkFilled,
                disabled && style.checkmarkFilledDisabled,
              ]}
            />
          )}
        </View>
      </View>
      {label && (
        <View style={style.labelContainer}>
          <Text
            color={disabled ? theme.contentTertiary : theme.contentPrimary}
            style={
              [
                style.label,
                labelAlign === "right" && style.rightLabel,
              ] as TextStyle
            }
            type={labelType}
          >
            {label}
          </Text>
        </View>
      )}
    </View>
  );
};

type LabelAlign = "left" | "right";

export interface Props {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  hoverAnimatedValue?: Animated.Value;
  label?: string;
  labelAlign?: LabelAlign;
  labelType?: ComponentProps<typeof Text>["type"];
  pressAnimatedValue?: Animated.Value;
}

export default Checkbox;
