import { useRef, PropsWithChildren } from "react";

import Animated from "../Animated";
import Hoverable from "../Hoverable";
import { Props as HoverableProps } from "../Hoverable/Hoverable";
import Pressable from "../Pressable";
import { Props as PressableProps } from "../Pressable/Pressable";

import Button, { Props as ButtonProps } from "./Button";

/**
 * Component that provides an hoverable and pressable button.
 * @prop {function} onPress - Action triggered when the button is pressed.
 * @prop {function} onHover - Action triggered when the button is hovered.
 * @prop {boolean} isAnimationDisabled - Value that disables the button's animations.
 * @prop {boolean} disabled - Value that disables the button's onPress action and animation and onHover animation.
 * @prop {boolean} selected - Value that disables the button's onHover and onPress animations.
 */
const EnhancedButton = ({
  additionalText,
  additionalTextColor,
  additionalTextPosition,
  color,
  disabled,
  dot,
  dotColor,
  Icon,
  iconAlign,
  image,
  isLoading,
  onPress,
  onHover,
  selected,
  shape,
  size,
  style,
  text,
  textAlign,
  to,
  type,
  minTouchTarget = !!(onPress || to),
  textHeadingLevel,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const hoverAnimatedValue = useRef(new Animated.Value(0)).current;
  const pressAnimatedValue = useRef(new Animated.Value(0)).current;

  return (
    <Hoverable
      animatedValue={hoverAnimatedValue}
      isAnimationDisabled={disabled}
      onHover={onHover}
      style={style}
    >
      <Pressable
        {...otherProps}
        animatedValue={pressAnimatedValue}
        disabled={disabled || isLoading}
        isAnimationDisabled={disabled || selected || isLoading}
        onPress={onPress}
        to={to}
      >
        <Button
          additionalText={additionalText}
          additionalTextColor={additionalTextColor}
          additionalTextPosition={additionalTextPosition}
          color={color}
          disabled={disabled}
          dot={dot}
          dotColor={dotColor}
          hoverAnimatedValue={
            (onHover && !selected) ||
            (onPress && !selected) ||
            (to && !selected)
              ? hoverAnimatedValue
              : undefined
          }
          Icon={Icon}
          iconAlign={iconAlign}
          image={image}
          isLoading={isLoading}
          minTouchTarget={minTouchTarget}
          pressAnimatedValue={onPress || to ? pressAnimatedValue : undefined}
          selected={selected}
          shape={shape}
          size={size}
          text={text}
          textAlign={textAlign}
          /// Can be rendered as an heading `h1` only if the `to` prop is defined as it will render the button as an `<a>` tag with an `<h1>` child.
          /// Otherwise the button will render as a `div` (in future `<button>`) thus the `<h1>` as a child is not supported by HTML specs.
          textHeadingLevel={to ? textHeadingLevel : undefined}
          type={type}
        />
      </Pressable>
    </Hoverable>
  );
};

export interface Props extends ButtonProps, HoverableProps, PressableProps {}

export default EnhancedButton;
