import { Platform, TextStyle } from "react-native";

const systemFontFamily = Platform.select({
  // Prevents an issue using no-system fonts on Android:
  // https://github.com/facebook/react-native/issues/15114
  // NOTE:
  // Web and iOS platforms don't need this now, they can
  // continue to fallback to their system font
  android: {
    fontFamily: "",
  },
});
const gorditaFontFamily = Platform.select({
  default: {
    fontFamily: "gordita-bold",
  },
  web: {
    fontFamily: "gordita-bold, sans-serif",
  },
});
const labelFontWeight = Platform.select({
  // Numerical values don't work on Android:
  // https://github.com/facebook/react-native/issues/26193
  android: { fontWeight: "bold" } as TextStyle,
  default: { fontWeight: "600" as "bold" },
});

export default {
  bodyDefault: {
    ...systemFontFamily,
    fontSize: 15,
    lineHeight: 20,
  },
  bodyExtraLarge: {
    ...systemFontFamily,
    fontSize: 20,
    lineHeight: 28,
  },
  bodyExtraSmall: {
    ...systemFontFamily,
    fontSize: 10,
    lineHeight: 12,
  },
  bodyLarge: {
    ...systemFontFamily,
    fontSize: 17,
    lineHeight: 24,
  },
  bodySmall: {
    ...systemFontFamily,
    fontSize: 13,
    lineHeight: 16,
  },
  displayDefault: {
    ...gorditaFontFamily,
    fontSize: 34,
    lineHeight: 44,
  },
  headingDefault: {
    ...gorditaFontFamily,
    fontSize: 22,
    lineHeight: 28,
  },
  headingLarge: {
    ...gorditaFontFamily,
    fontSize: 28,
    lineHeight: 32,
  },
  headingSmall: {
    ...gorditaFontFamily,
    fontSize: 20,
    lineHeight: 24,
  },
  labelDefault: {
    ...systemFontFamily,
    ...labelFontWeight,
    fontSize: 17,
    lineHeight: 20,
  },
  labelSmall: {
    ...systemFontFamily,
    ...labelFontWeight,
    fontSize: 15,
    lineHeight: 16,
  },
};
