import { PropsWithChildren } from "react";
import {
  StyleProp,
  Text as BasicText,
  TextProps,
  TextStyle,
} from "react-native";

import { useTheme } from "../../../styles/themes/themeUtils";

import styles from "./styles";

const Text = ({
  align = "left",
  children,
  color,
  style: styleProp,
  type = "bodyDefault",
  ...otherProps
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(styles, { align });

  return (
    <BasicText
      {...otherProps}
      style={[
        { color: color || theme.contentPrimary },
        style[ALIGN_TO_TEXT_STYLE[align]],
        style[type],
        styleProp,
      ]}
    >
      {children}
    </BasicText>
  );
};

export type Alignments = "center" | "justify" | "left" | "right";

export type Types =
  | "bodyDefault"
  | "bodyExtraLarge"
  | "bodyExtraSmall"
  | "bodyLarge"
  | "bodySmall"
  | "displayDefault"
  | "headingDefault"
  | "headingLarge"
  | "headingSmall"
  | "labelDefault"
  | "labelSmall";

export const ALIGN_TO_TEXT_STYLE = {
  center: "alignCenter",
  justify: "alignJustify",
  left: "alignLeft",
  right: "alignRight",
} as const;

export interface Props extends TextProps {
  align?: Alignments;
  color?: string;
  style?: StyleProp<TextStyle>;
  type?: Types;
}

export default Text;
