import {
  forwardRef,
  FunctionComponent,
  ReactNode,
  Ref,
  useMemo,
  useRef,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { RefCallBack } from "react-hook-form";
import {
  StyleProp,
  TextInput,
  TextInputProps,
  View,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  TextStyle,
  Platform,
} from "react-native";

import { IconClear } from "..";
import global, { ComponentSize } from "../../styles/global";
import { useTheme } from "../../styles/themes/themeUtils";
import { isServer } from "../../utils";
import Animated from "../Animated";
import Button from "../Button";

import styles from "./styles";

/*
  About the editable and disabled values.
  Right now we need and use both the props because the meanings we give them are different.
  You can think of it in this way: a disabled input is an input which is disabled from a UI and UX perspective
  therefore you'll have things like a grayed out box, with interactions disabled and so on.
  But when we talk about non-editable inputs, we're talking about an input
  with an hidden cursor which doesn't affects colors or anything else
*/

const Input: FunctionComponent<PropsWithChildren<Props>> = forwardRef(
  (
    {
      background = "primary",
      clearable = false,
      disabled = false,
      editable = true,
      error = false,
      focused,
      formRef,
      inputStyle,
      isPassword = false,
      leftEnhancer,
      minWidth,
      onClear,
      rightEnhancer,
      size = "default",
      style: styleProp,
      value,
      ...otherProps
    },
    ref: Ref<View>
  ) => {
    const [textInputRef, setTextInputRef] = useState<TextInput | null>();
    const animatedColor = useRef(new Animated.Value(0)).current;
    const hasClearable: boolean = clearable;
    const hasLeftEnhancer: boolean = !!leftEnhancer;
    const hasRightEnhancer: boolean = !!rightEnhancer;
    const [isFocused, setIsFocused] = useState(false);
    const { style, theme } = useTheme(
      styles,
      useMemo(
        () => ({
          background,
          disabled,
          hasClearable,
          hasLeftEnhancer,
          hasRightEnhancer,
          size,
        }),
        [
          background,
          disabled,
          hasClearable,
          hasLeftEnhancer,
          hasRightEnhancer,
          size,
        ]
      )
    );

    const [isAnimationEnabled, setAnimationEnabled] = useState(false);

    useEffect(() => {
      setAnimationEnabled(Platform.OS === "web" && !isServer());
    }, []);

    const isPrimaryBackground = (): boolean => background === "primary";

    const getOutputRange = (): [string, string] => {
      const outputRange: [string, string] = [
        isPrimaryBackground()
          ? theme.backgroundTertiary
          : theme.backgroundSecondary,
        theme.backgroundPrimaryInverted,
      ];

      if (error) {
        outputRange[0] = theme.systemRed100;

        return outputRange;
      }

      return outputRange;
    };

    const getBorderColor = () => {
      if (disabled) return theme.backgroundSecondary;
      if (isFocused) return theme.backgroundPrimaryInverted;
      if (error) return theme.systemRed100;

      return isPrimaryBackground()
        ? theme.backgroundTertiary
        : theme.backgroundSecondary;
    };

    const getAnimatedBorderColor = () => {
      if (disabled) return theme.backgroundSecondary;
      const config = {
        inputRange: [0, 1],
        outputRange: getOutputRange(),
      };

      return animatedColor.interpolate(config);
    };

    const clear = () => {
      textInputRef?.focus();

      otherProps.onChange?.({
        nativeEvent: {
          eventCount: 0,
          // @ts-ignore
          target: textInputRef,
          text: "",
        },
      });
      otherProps.onChangeText?.("");

      onClear?.({
        nativeEvent: {
          eventCount: 0,
          // @ts-ignore
          target: textInputRef,
          text: "",
        },
      });

      if (value === undefined) {
        textInputRef?.clear();
      }
    };

    const onFocus = () => {
      if (isAnimationEnabled) {
        Animated.timing(animatedColor, {
          duration: global.animatedTimingDuration,
          toValue: 1,
          useNativeDriver: false,
        }).start();
      }

      setIsFocused(true);

      otherProps.onFocus?.();
    };

    const onBlur = () => {
      if (isAnimationEnabled) {
        Animated.timing(animatedColor, {
          duration: global.animatedTimingDuration,
          toValue: 0,
          useNativeDriver: false,
        }).start();
      }

      setIsFocused(false);

      otherProps.onBlur?.();
    };

    useEffect(() => {
      if (focused) {
        textInputRef?.focus?.();
      }
    }, [focused]);

    return (
      <View ref={ref} style={{ minWidth }}>
        <Animated.View
          style={[
            styleProp,
            style.inputContainer,
            {
              borderColor: isAnimationEnabled
                ? getAnimatedBorderColor()
                : getBorderColor(),
            },
          ]}
        >
          {leftEnhancer && (
            <View style={style.leftEnhancerContainer}>{leftEnhancer}</View>
          )}
          <TextInput
            {...otherProps}
            editable={editable === false ? editable : !disabled}
            onBlur={onBlur}
            onFocus={!disabled ? onFocus : () => {}}
            placeholderTextColor={
              disabled ? theme.contentTertiary : theme.contentSecondary
            }
            ref={(input) => {
              setTextInputRef(input);
              formRef?.(input);
              input?.setNativeProps({ tabIndex: 0 });

              if (otherProps.autoFocus) {
                input?.setNativeProps({ autofocus: true });
              }
            }}
            secureTextEntry={isPassword}
            selectionColor={theme.contentSecondary}
            style={[style.input, inputStyle]}
            textContentType={isPassword ? "password" : "none"}
            value={value}
          />
          {clearable && value !== undefined && (
            <View
              // @ts-ignore
              // This is needed because the prop is web-only. It's the react-native-web equivalent of aria-hidden.
              accessibilityHidden={!value?.length}
              style={style.buttonContainer}
            >
              <Button
                Icon={IconClear}
                minTouchTarget={false}
                onPress={value?.length ? clear : undefined}
                size="small"
                style={{
                  opacity: value?.length ? 1 : 0,
                }}
                type="text"
              />
            </View>
          )}

          {rightEnhancer && (
            <View style={style.rightEnhancerContainer}>{rightEnhancer}</View>
          )}
        </Animated.View>
      </View>
    );
  }
);

type Background = "primary" | "secondary";

interface Props
  extends Omit<
    TextInputProps,
    | "placeholderTextColor"
    | "secureTextEntry"
    | "selectionColor"
    | "style"
    | "textContentType"
  > {
  background?: Background;
  clearable?: boolean;
  disabled?: boolean;
  error?: boolean;
  focused?: boolean;
  formRef?: RefCallBack;
  inputStyle?: StyleProp<TextStyle>;
  isPassword?: boolean;
  leftEnhancer?: ReactNode;
  minWidth?: number;
  onBlur?: () => void;
  onClear?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  onFocus?: () => void;
  ref?: Ref<View>;
  rightEnhancer?: ReactNode;
  size?: ComponentSize;
  style?: StyleProp<ViewStyle>;
}

export default Input;
