export const clipString = (
  string: string | undefined,
  maxLength: number,
  shouldClip?: boolean
): string | undefined => {
  if (string === undefined) {
    return undefined;
  }

  if (shouldClip !== false && string.length > maxLength) {
    return string.slice(0, maxLength) + "...";
  } else {
    return string;
  }
};

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
