import { Dimensions, Platform } from "react-native";

import { textStyles, viewStyles } from "../../styles";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";
import {
  bottomSheetPaddingBottom,
  bottomSheetPaddingTop,
} from "../BottomSheet/styles";

const styles = (
  theme: Theme,
  { anchorMeasuresWidth, isSmallScreen }: Options
) => {
  return {
    ...textStyles({
      emptyText: {
        padding: global.spacing * 4,
        textAlign: "center",
      },
    }),
    ...viewStyles({
      dialogCloseButton: {
        marginBottom: 0,
        marginHorizontal: global.spacing * 4,
      },
      inputIcon: { marginRight: global.spacing * 2 },
      list: {
        maxHeight:
          Platform.OS === "web" && isSmallScreen
            ? Dimensions.get("window").height - bottomSheetPadding
            : "auto",
        ...(!isSmallScreen && {
          borderRadius: !isSmallScreen ? global.radius.default : 0,
        }),
        paddingBottom: isSmallScreen ? global.spacing * 6 : global.spacing * 2,
        paddingTop: isSmallScreen ? 0 : global.spacing * 2,
      },
      mobileSearchHeader: {
        marginBottom: global.spacing,
        marginHorizontal: global.spacing * 4,
        marginTop: global.spacing * 2,
      },
      pill: { marginRight: global.spacing * 2, marginTop: global.spacing * 2 },
      pillsContainer: {
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        maxWidth: anchorMeasuresWidth,
      },
      searchHeaderWrapper: {
        backgroundColor: theme.backgroundPrimaryElevated,
      },
    }),
  };
};

export default styles;

const bottomSheetPadding = bottomSheetPaddingBottom + bottomSheetPaddingTop;

interface Options {
  anchorMeasuresWidth?: number;
  isSmallScreen: boolean;
}
