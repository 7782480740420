import { ComponentProps, Ref, useState, PropsWithChildren } from "react";
import * as React from "react";
import {
  NativeSyntheticEvent,
  Platform,
  TargetedEvent,
  TextInputChangeEventData,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";

import { IconChevronDown, Input } from "..";
import { useTheme } from "../..";
import { ComponentSize } from "../../styles/global";

import { ItemValueType } from "./Select";
import styles from "./styles";

const DropdownAnchor = ({
  anchorRef,
  background,
  clearable,
  disabled,
  enhancedOnChange,
  error,
  onBlur,
  onFocus,
  placeholder,
  produceAnchorLabel,
  searchableOnLarge,
  searchTerm,
  setDropOpen,
  setSearchTerm,
  size,
  ...props
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(styles, {});
  const [inputFocused, setInputFocused] = useState(false);

  const onAnchorInputChange = (
    event: NativeSyntheticEvent<TextInputChangeEventData>
  ) => {
    setDropOpen(true);

    if (searchTerm === "") {
      if (Platform.OS === "web") {
        /*
        This runs only on web, so we're sure we can access the delta of the text (which is event.nativEevent.data)
        we need it because otherwise the value of the input component would comprehend the selected option's label too
        */
        // @ts-ignore
        setSearchTerm(event.nativeEvent.data || "");
      } else {
        // a bit cumbersome but on native we can't rely on "nativeEvent.data"
        setSearchTerm(event.nativeEvent.text.replace(produceAnchorLabel(), ""));
      }
    } else {
      setSearchTerm(event.nativeEvent.text);
    }
  };

  const onInputBlur = () => {
    setInputFocused(false);
  };

  return Platform.OS === "web" ? (
    <Input
      // @ts-ignore
      autoComplete="new-password"
      background={background}
      // If a select is NOT searchable on a large screen
      // we need to disable the editing and hide the cursor
      caretHidden={!searchableOnLarge}
      clearable={clearable || (searchableOnLarge && !!searchTerm)}
      disabled={disabled}
      editable={!!searchableOnLarge}
      error={error}
      focusable={searchableOnLarge}
      onBlur={onBlur}
      onChange={searchableOnLarge ? onAnchorInputChange : undefined}
      onClear={() => {
        enhancedOnChange("" as ItemValueType);
      }}
      onFocus={onFocus}
      onPressOut={() => setDropOpen((o) => !o)}
      placeholder={placeholder}
      ref={anchorRef}
      rightEnhancer={
        <IconChevronDown
          color={disabled ? theme.contentTertiary : undefined}
          size={20}
          style={style.inputIcon}
        />
      }
      size={size}
      style={
        {
          caretColor: searchableOnLarge ? "auto" : "transparent",
          cursor: "pointer",
        } as ViewStyle
      }
      value={produceAnchorLabel()}
    />
  ) : (
    <TouchableOpacity
      activeOpacity={1.0}
      disabled={disabled}
      onBlur={onBlur}
      onFocus={onFocus}
      onPress={() => {
        setDropOpen((o) => !o);

        if (searchableOnLarge) {
          setInputFocused(true);
        }
      }}
    >
      <Input
        background={background}
        // If a select is NOT searchable on a large screen
        // we need to disable the editing and hide the cursor
        caretHidden={!searchableOnLarge}
        clearable={clearable || (searchableOnLarge && !!searchTerm)}
        disabled={disabled}
        editable={!!searchableOnLarge}
        error={error}
        focused={inputFocused}
        onBlur={onInputBlur}
        onChange={searchableOnLarge ? onAnchorInputChange : undefined}
        onClear={() => {
          enhancedOnChange("" as ItemValueType);
        }}
        placeholder={placeholder}
        // This makes the onPress work on iOS
        pointerEvents={searchableOnLarge ? "auto" : "none"}
        ref={anchorRef}
        rightEnhancer={
          <IconChevronDown
            color={disabled ? theme.contentTertiary : undefined}
            size={20}
            style={style.inputIcon}
          />
        }
        selection={Platform.OS === "android" ? { start: 0 } : undefined}
        size={size}
        value={produceAnchorLabel()}
      />
    </TouchableOpacity>
  );
};

interface Props {
  anchorRef: (node: View | null) => void;
  background?: ComponentProps<typeof Input>["background"];
  clearable: boolean;
  disabled: boolean;
  enhancedOnChange: (value: ItemValueType) => void;
  error?: boolean;
  onBlur?: (e?: NativeSyntheticEvent<TargetedEvent>) => void;
  onFocus?: (e?: NativeSyntheticEvent<TargetedEvent>) => void;
  placeholder?: string;
  produceAnchorLabel: () => string;
  ref?: Ref<TouchableOpacity>;
  searchableOnLarge?: boolean;
  searchTerm: string;
  setDropOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchTerm: (text: string) => void;
  size?: ComponentSize;
}

export default DropdownAnchor;
