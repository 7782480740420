import { PropsWithChildren } from "react";

import { PaymentContext } from "./PaymentContext";
import { PaymentProviderProps } from "./types";

const noop = () => {};

const PaymentProvider = ({
  children,
}: PropsWithChildren<PaymentProviderProps>) => (
  <PaymentContext.Provider value={{ init: noop }}>
    {children}
  </PaymentContext.Provider>
);

export default PaymentProvider;
