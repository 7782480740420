import { useState, useEffect } from "react";

import { audioPlayer } from ".";

export const useInitializeAudioPlayer = (): {
  playerInitialized: boolean;
  playerError: boolean;
} => {
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (audioPlayer) {
      audioPlayer
        .initialize()
        .then(() => setInitialized(true))
        .catch((err) => {
          setError(true);
          console.error(err);
        });

      return (): void => {
        audioPlayer?.destroy();
      };
    }
  }, []);

  return {
    playerError: error,
    playerInitialized: initialized,
  };
};
