import {
  NavigationContainerRef,
  StackActions,
  useLinkBuilder,
} from "@mxmdev/react-universal-navigation/native";
import deepEqual from "deep-equal";
import { createRef, useEffect, DependencyList, useRef } from "react";

import { RootStackParamList, BackAction, RootRoute } from "./types";

export const navigationRef =
  createRef<NavigationContainerRef<RootStackParamList>>();

export const navigate: NavigationContainerRef<RootStackParamList>["navigate"] =
  (
    ...args: Parameters<NavigationContainerRef<RootStackParamList>["navigate"]>
  ): void => {
    navigationRef.current?.navigate(...args);
  };

export const replace = <K extends keyof RootStackParamList>(
  name: K,
  params?: RootStackParamList[K]
): void => {
  navigationRef.current?.dispatch(StackActions.replace(name, params));
};

export const push = <K extends keyof RootStackParamList>(
  name: K,
  params?: RootStackParamList[K]
): void => {
  navigationRef.current?.dispatch(StackActions.push(name, params));
};

export const canGoBack = (): boolean => {
  return navigationRef.current?.canGoBack() ?? false;
};

export const getBackAction = (): BackAction<RootRoute> | undefined => {
  const state = navigationRef.current?.getRootState();

  if (!state) {
    return undefined;
  }

  if (state.routes.length < 2) {
    return undefined;
  }

  const previousRoute = state.routes[state.routes.length - 2];

  return {
    route: previousRoute.name as RootRoute,
    routeParams: previousRoute.params as RootStackParamList[RootRoute],
  };
};

export const getCurrentRouteName = (): RootRoute | undefined => {
  return navigationRef.current?.getCurrentRoute()?.name as
    | RootRoute
    | undefined;
};

export const getCurrentRouteParams = <T extends RootRoute>():
  | RootStackParamList[T]
  | undefined => {
  return navigationRef.current?.getCurrentRoute()?.params as
    | RootStackParamList[T]
    | undefined;
};

export const getCurrentRouteParamsIfRoute = <T extends RootRoute>(
  route: T
): RootStackParamList[T] | undefined => {
  if (navigationRef.current?.getCurrentRoute()?.name !== route) {
    return undefined;
  }

  return navigationRef.current?.getCurrentRoute()?.params as
    | RootStackParamList[T]
    | undefined;
};

export const navigateBack = (backAction: BackAction<RootRoute>): void => {
  const state = navigationRef.current?.getRootState();

  if (!state) {
    return;
  }

  const previousRoute = state.routes[state.routes.length - 2];

  if (
    previousRoute &&
    previousRoute.name === backAction.route &&
    deepEqual(previousRoute.params, backAction.routeParams)
  ) {
    goBack();
  } else {
    replace(backAction.route, backAction.routeParams);
  }
};

export const goBack = (): void => {
  if (navigationRef.current && navigationRef.current.canGoBack()) {
    navigationRef.current.goBack();
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
export const useRouteChange = (
  onChange: (route: RootRoute, isFirstRoute: boolean) => void,
  deps?: DependencyList
): void => {
  const previousRoute = useRef<RootRoute | undefined>(undefined);

  useEffect(() => {
    const currentRoute = getCurrentRouteName();

    if (currentRoute && previousRoute.current !== currentRoute) {
      if (previousRoute.current !== undefined) {
        onChange(currentRoute, false);
      } else {
        onChange(currentRoute, true);
      }
    }

    previousRoute.current = currentRoute;
  }, deps);
};

// A wrapper around useLinkBuilder to enforce typesafety of routes
export const useSafeLinkBuilder = (): (<K extends keyof RootStackParamList>(
  name: K,
  params?: RootStackParamList[K]
) => string | undefined) => {
  const buildLink = useLinkBuilder();

  return buildLink;
};
