import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import { IconArtworkNext, IconComponent, IconAlign, IconColor } from "../Icon";

import styles from "./styles";

const DialogIcon = ({ Icon, iconAlign, iconColor }: Props) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });

  return (
    <IconArtworkNext
      Icon={Icon}
      iconAlign={iconAlign}
      iconColor={iconColor}
      style={style.iconContainer}
    />
  );
};

interface Props {
  Icon: IconComponent;
  iconAlign?: IconAlign;
  iconColor?: IconColor;
}

export default DialogIcon;
