import * as React from "react";
import { ComponentProps, PropsWithChildren } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { Text } from "..";
import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";

import styles from "./styles";

const DialogText = ({
  description,
  descriptionAlign,
  note,
  noteAlign,
  style: styleProp,
  title,
  titleAlign,
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style, theme } = useTheme(styles, { isSmallScreen });

  return (
    <View style={[style.textContainer, styleProp]}>
      <Text
        align={titleAlign}
        color={theme.contentPrimary}
        type="headingDefault"
      >
        {title}
      </Text>
      {description && (
        <Text
          align={descriptionAlign}
          color={theme.contentPrimary}
          style={style.description}
          type="bodyLarge"
        >
          {description}
        </Text>
      )}
      {note && (
        <Text
          align={noteAlign}
          color={theme.contentSecondary}
          style={style.note}
          type="bodyLarge"
        >
          {note}
        </Text>
      )}
    </View>
  );
};

interface Props {
  description?: string | React.ReactNode;
  descriptionAlign?: ComponentProps<typeof Text>["align"];
  note?: string;
  noteAlign?: ComponentProps<typeof Text>["align"];
  style?: StyleProp<ViewStyle>;
  titleAlign?: ComponentProps<typeof Text>["align"];
  title: string;
}

export default DialogText;
