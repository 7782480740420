import { PropsWithChildren } from "react";

import { global } from "../../styles";
import { ComponentSize } from "../../styles/global";

import { SharedSkeletonProps } from "./EnhancedSkeleton";
import Skeleton from "./Skeleton";

const SkeletonCircle = ({
  size = "default",
  style,
  ...props
}: PropsWithChildren<Props>) => (
  <Skeleton
    height={global.size[size]}
    style={[{ borderRadius: global.size[size] }, style]}
    width={global.size[size]}
    {...props}
  />
);

export default SkeletonCircle;

interface Props extends SharedSkeletonProps {
  size?: ComponentSize;
}
