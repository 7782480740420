import { canUseDOM } from "@mxmdev/react-universal-core";
import { useEffect } from "react";
import { Platform } from "react-native";

// On the web, the slider component sometimes misbehaves when
// rendered in the middle of a screen movement (for example when
// switching desktops on macOS).
// For an example, see: https://musixmatch.atlassian.net/browse/POD-67
// As a workaround, we trigger a resize event when the user focuses
// the page, which causes the Slider to recalculate the coordinates correctly.
const useSliderFix = (): void => {
  useEffect(() => {
    if (Platform.OS !== "web" || !canUseDOM) {
      return;
    }

    const focusListener = (): void => {
      window.dispatchEvent(new Event("resize"));
    };

    window.addEventListener("focus", focusListener);

    return (): void => {
      window.removeEventListener("focus", focusListener);
    };
  }, []);
};

export default useSliderFix;
