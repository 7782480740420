import { User } from "../../../types";
import { canUseDOM, getTimeMilliseconds } from "../../../utils";

const logEvent = (eventName: string, eventParams?: Record<string, any>) => {
  if (!canUseDOM || !window._cio) {
    console.warn(
      `[Analytics] Customer.io event '${eventName}' (params: ${JSON.stringify(
        eventParams
      )}) dropped, because analytics has not been properly initialized.`
    );

    return;
  }

  window._cio.track(eventName, eventParams);
};

const setUser = (user: User, userParams?: Record<string, any>) => {
  if (!canUseDOM || !window._cio) {
    console.warn(
      `[Analytics] Customer.io user with id '${
        user.user_id
      }' (params: ${JSON.stringify(
        userParams
      )}) not set, because analytics has not been properly initialized.`
    );

    return;
  }

  window._cio.identify({
    created_at: getTimeMilliseconds(user.user_creation_date),
    device_language: navigator.language ?? "en",
    email: user.profile.email,
    first_name: user.profile.first_name,
    id: user.user_id,
    last_name: user.profile.last_name,
    name: user.profile.name,
    ...userParams,
  });
};

export { logEvent, setUser };
