import { Animated as RNAnimated, Platform } from "react-native";

import { getResolvedColorVariable } from "../../styles/colorUtils";
import { isServer } from "../../utils";

/**
 * Server-side enabled Animated component.
 * Extends the default behavior of React Native's
 * Animated but doesn't interpolate string values
 * during server-side rendering.
 */
RNAnimated.Value = class extends RNAnimated.Value {
  interpolate(
    config: RNAnimated.InterpolationConfigType
  ): RNAnimated.AnimatedInterpolation {
    if (typeof config.outputRange[0] === "string") {
      if (isServer()) {
        // Select the first value from the `outputRange`
        // based on the value of the first `inputRange`.
        // Ideally it should access a valid value in the `outputRange`,
        // revert to the first available output otherwise.
        const firstIndex = config.inputRange[0];
        const output = config.outputRange[firstIndex] ?? config.outputRange[0];

        // `any` is required here because we just went to return the raw value
        return output as any;
      } else if (
        Platform.OS === "web" &&
        config.outputRange[0].startsWith("var(")
      ) {
        // Resolves CSS variables during client-side rendering to real values
        const resolvedCSSVarsOutput = config.outputRange.map((value) => {
          if (typeof value === "string") {
            return getResolvedColorVariable(value);
          } else {
            return value;
          }
        });

        const newConfig: RNAnimated.InterpolationConfigType = {
          ...config,
          outputRange: resolvedCSSVarsOutput as number[] | string[],
        };

        return super.interpolate(newConfig);
      }
    }

    return super.interpolate(config);
  }
};

export default RNAnimated;
