import { ComponentProps, useEffect, useRef } from "react";

import { Popover } from "../components";

type Props = Pick<ComponentProps<typeof Popover>, "visible" | "onDismiss">;

const usePopStateListener = ({ onDismiss, visible }: Props) => {
  const dismiss = useRef(onDismiss);

  dismiss.current = onDismiss;

  useEffect(() => {
    const handleWindowPopstate = () => {
      dismiss.current?.();
    };

    const attachListeners = () => {
      window.addEventListener("popstate", handleWindowPopstate);
    };

    const removeListeners = () => {
      window.removeEventListener("popstate", handleWindowPopstate);
    };

    if (visible) {
      attachListeners();
    } else {
      removeListeners();
    }

    return () => {
      removeListeners();
    };
  }, [visible]);
};

export default usePopStateListener;
