export const ERROR_CODES = {
  FORBIDDEN_OPERATION: "FORBIDDEN",
  UNEXPECTED: "UNEXPECTED",
} as const;

type ErrorCode = typeof ERROR_CODES[keyof typeof ERROR_CODES];

class PaymentsError extends Error {
  code: ErrorCode;

  constructor(message: string, code: ErrorCode) {
    super(message);

    this.code = code;
  }
}

export default PaymentsError;
