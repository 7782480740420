import { useContext, createContext, PropsWithChildren } from "react";

import { SSRProps } from "../ssr/types";

export type SSRContextData = SSRProps | null;

const SSRContextDefaultValue: SSRContextData = null;

export const SSRContext = createContext<SSRContextData>(SSRContextDefaultValue);

const SSRProvider = ({ children, ssrProps }: PropsWithChildren<Props>) => {
  return <SSRContext.Provider value={ssrProps}>{children}</SSRContext.Provider>;
};

interface Props {
  ssrProps: SSRProps | null;
}

export const useSSRProvider = (): SSRContextData => useContext(SSRContext);

export default SSRProvider;
