import { Theme, viewStyles } from "../../styles/";
import global from "../../styles/global";

interface Props {
  viewBoxHeight: number;
  viewBoxWidth: number;
}

const styles = (_: Theme, { viewBoxHeight, viewBoxWidth }: Props) => {
  return viewStyles({
    // To preserve the logo's proportions, we need to express its height only. However, without an explicit width, the Svg's path disappears on iOS. Using aspectRatio fixes the issue.
    aspectRatio: { aspectRatio: viewBoxWidth / viewBoxHeight },
    logo: {
      flexDirection: "row",
    },
  });
};

export default styles;

export const logoSize = global.size.small;
