import * as React from "react";
import { ComponentProps, ReactNode, useState, PropsWithChildren } from "react";
import { ColorSchemeName, StatusBar } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";

import {
  DialogProvider as RitmoDialogProvider,
  ToastProvider as RitmoToastProvider,
} from "..";
import { SafeAreaProvider as RitmoSafeAreaProvider } from "../../safe-area";
import {
  ServerScreenSizeProvider,
  ThemeProvider as RitmoThemeProvider,
} from "../../styles";
import Portal from "../Portal";
import Toast from "../Toast/Toast";
import { DialogsOutlet } from "../dialogs";

import { getStatusBarStyle } from "./utils";

type Provider = "theme" | "dialog" | "toast" | "safearea";

const PortaledComponents = ({ children }: PropsWithChildren<unknown>) => (
  <Portal.Host>
    <DialogsOutlet />
    <Toast />
    {children}
  </Portal.Host>
);

const RitmoProvider = ({
  children,
  colorScheme,
  exclude,
  PrePortalComponent,
  serverScreenSize,
  themeConfig,
  useLink,
}: PropsWithChildren<Props>) => {
  // This avoids any rerendering due to the strict equality changes of the exclude array
  const [excluded] = useState(() => exclude);

  const ThemeProvider = excluded?.includes("theme")
    ? () => <>{children}</>
    : RitmoThemeProvider;

  const SafeAreaProvider = excluded?.includes("safearea")
    ? () => <>{children}</>
    : RitmoSafeAreaProvider;

  const ToastProvider = excluded?.includes("toast")
    ? () => <>{children}</>
    : RitmoToastProvider;

  const DialogProvider = excluded?.includes("dialog")
    ? () => <>{children}</>
    : RitmoDialogProvider;

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <ServerScreenSizeProvider screenSize={serverScreenSize}>
        <ThemeProvider
          config={themeConfig}
          scheme={colorScheme}
          useLink={useLink}
        >
          <SafeAreaProvider>
            <StatusBar barStyle={getStatusBarStyle(colorScheme)} />
            <ToastProvider>
              <DialogProvider>
                {PrePortalComponent ? (
                  React.cloneElement(PrePortalComponent, {
                    children: (
                      <PortaledComponents>{children}</PortaledComponents>
                    ),
                  })
                ) : (
                  <PortaledComponents>{children}</PortaledComponents>
                )}
              </DialogProvider>
            </ToastProvider>
          </SafeAreaProvider>
        </ThemeProvider>
      </ServerScreenSizeProvider>
    </GestureHandlerRootView>
  );
};

export default RitmoProvider;

interface Props {
  PrePortalComponent?: React.ReactElement;
  children: ReactNode;
  /**
   * Ignore the system color scheme and force a specific one.
   */
  colorScheme?: ColorSchemeName;
  exclude?: Provider[];
  serverScreenSize?: "large" | "small" | "medium";
  themeConfig?: ComponentProps<typeof RitmoThemeProvider>["config"];
  useLink: ComponentProps<typeof RitmoThemeProvider>["useLink"];
}
