import { Episode, MxmSpeaker } from "@mxmdev/podcasts-shared";
import { canUseDOM, useAnalytics } from "@mxmdev/react-universal-core";
import { useCallback } from "react";

import { episodeToAnalytics } from "../analytics/utils";
import { TopicDialogSource } from "../dialog";
import { formatEpisodeAnalytics } from "../types";

export const usePodcastAnalytics = () => {
  const { logEvent } = useAnalytics();

  const logAdsFinderError = useCallback(
    () => logEvent("ads_finder_error"),
    [logEvent]
  );

  const logClaimPodcastClicked = useCallback(
    () => logEvent("claim_podcast_clicked"),
    [logEvent]
  );

  const logEditPodcastClicked = useCallback(
    () => logEvent("edit_clicked"),
    [logEvent]
  );

  const logEntityClicked = useCallback(
    () => logEvent("entity_clicked"),
    [logEvent]
  );

  const logEntityScreenShowed = useCallback(
    (source: TopicDialogSource) => logEvent("entity_screen_showed", { source }),
    [logEvent]
  );

  const logEntitySearchClicked = useCallback(
    () => logEvent("entity_search_clicked"),
    [logEvent]
  );

  const logEntityWikipediaLinkClicked = useCallback(
    () => logEvent("entity_wikipedia_link_clicked"),
    [logEvent]
  );

  const logPlayEpisode = useCallback(
    (
      episode: Episode,
      source: LogPlaySource,
      logPlayType: LogPlayType,
      isSeekingSection?: boolean
    ) => {
      logEvent("play_episode", {
        ...formatEpisodeAnalytics(episodeToAnalytics(episode)),
        play_from: isSeekingSection ? 1 : 0,
        source,
        type: logPlayType,
      });
    },
    [logEvent]
  );

  const logEpisodeSelected = useCallback(
    (source: LogEpisodeSelectedSource) =>
      logEvent("episode_selected", { source }),
    [logEvent]
  );

  const logEpisodeShowed = useCallback(
    (episode: Episode) => {
      const transcriptionStatus =
        episode.transcriptionStatus === "verified"
          ? "curated"
          : episode.transcriptionStatus === "transcribed"
          ? "ai"
          : "not_found";

      logEvent("episode_showed", {
        ...formatEpisodeAnalytics(episodeToAnalytics(episode)),
        ai_content_learnings: episode.aiLearnings ? 1 : 0,
        ai_content_summary: episode.aiSummary ? 1 : 0,
        referrer:
          canUseDOM && document.referrer ? document.referrer : undefined,
        transcription_status: transcriptionStatus,
      });
    },
    [logEvent]
  );

  const logReferralVerifyCompleted = useCallback(
    (episode: Episode, mxmLink: string, referrer?: string) =>
      logEvent("referral_verify_completed", {
        ...formatEpisodeAnalytics(episodeToAnalytics(episode)),
        mxm_link: mxmLink,
        referrer,
      }),
    [logEvent]
  );

  const logSearchUsed = useCallback(() => logEvent("search_used"), [logEvent]);

  const logSpeakerTranscriptionAnchorClicked = useCallback(
    () => logEvent("speaker_transcript_anchor_clicked"),
    [logEvent]
  );

  const logSuggestedPodcastClicked = useCallback(
    (index: number) =>
      logEvent("suggested_podcast_clicked", { position: index + 1 }),
    [logEvent]
  );

  const logTopicClicked = useCallback(
    () => logEvent("topic_clicked"),
    [logEvent]
  );

  const logTopPodcastClicked = useCallback(
    (country: string, index: number) =>
      logEvent("top_podcast_clicked", { country, position: index + 1 }),
    [logEvent]
  );

  const logTrendingTopicClicked = useCallback(
    (country: string, index: number, type: "topic" | "speaker") => {
      logEvent("trending_topic_clicked", {
        country,
        position: index + 1,
        type,
      });
    },
    [logEvent]
  );

  const logUpvoteTranscriptionRequest = useCallback(
    (episode: Episode) => {
      logEvent("upvote_transcription_request", {
        ...formatEpisodeAnalytics(episodeToAnalytics(episode)),
        referrer:
          canUseDOM && document.referrer ? document.referrer : undefined,
      });
    },
    [logEvent]
  );

  const logSpeakerShowed = useCallback(
    (speaker: MxmSpeaker) =>
      logEvent("speaker_showed", {
        has_wikipedia_id: speaker.wikidataId ? 1 : 0,
        speaker_id: speaker.id,
      }),
    [logEvent]
  );

  return {
    logAdsFinderError,
    logClaimPodcastClicked,
    logEditPodcastClicked,
    logEntityClicked,
    logEntityScreenShowed,
    logEntitySearchClicked,
    logEntityWikipediaLinkClicked,
    logEpisodeSelected,
    logEpisodeShowed,
    logPlayEpisode,
    logReferralVerifyCompleted,
    logSearchUsed,
    logSpeakerShowed,
    logSpeakerTranscriptionAnchorClicked,
    logSuggestedPodcastClicked,
    logTopicClicked,
    logTopPodcastClicked,
    logTrendingTopicClicked,
    logUpvoteTranscriptionRequest,
  };
};

export type LogPlayType =
  | "curated"
  | "transcripted"
  | "untranscripted"
  | "null";

export type LogPlaySource =
  | "discover"
  | "podcast"
  | "episode"
  | "search"
  | "topic"
  | "queue"
  | "speaker_appears_in_section"
  | "speaker_speaks_in_section";

export type LogEpisodeSelectedSource = LogPlaySource;
