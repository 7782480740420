import { EntitlementsGetData } from "@mxmdev/react-universal-core";

import { Entitlement } from "../types";

const entitlementsTransformer = (
  data: EntitlementsGetData["entitlementsGet"]["body"]
): Entitlement[] => {
  return (
    data?.entitlements.map((ent) => ({
      displayName: ent.displayName,
      expirationDate: ent.expirationDate,
      id: ent.id,
      internal: {
        platformItemId: ent.internal.platformItemId,
        platformSku: ent.internal.platformSku,
        platformSubscriptionId: ent.internal.platformSubscriptionId,
      },
      purchaseDate: ent.purchaseDate,
    })) ?? []
  );
};

export default entitlementsTransformer;
