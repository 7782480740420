import {
  imageStyles,
  viewStyles,
  global,
  Theme,
} from "@mxmdev/react-universal-components";

interface Props {
  isSmallScreen: boolean;
}

const styles = (theme: Theme, { isSmallScreen }: Props) => {
  const PICTURE_SIZE = isSmallScreen ? 180 : 200;

  return {
    ...imageStyles({
      imageView: {
        marginTop: global.spacing * 4,
      },
      item: {
        aspectRatio: 1,
        backgroundColor: theme.backgroundSecondary,
        borderRadius: 999,
      },
      picture: {
        backgroundColor: theme.backgroundSecondary,
        borderRadius: PICTURE_SIZE / 2,
        height: PICTURE_SIZE,
        marginBottom: global.spacing * 2,
        width: PICTURE_SIZE,
      },
      selected: {
        borderColor: theme.backgroundPrimaryInverted,
        borderWidth: 2,
      },
    }),
    ...viewStyles({
      borderView: {
        borderRadius: 999,
        bottom: -4,
        left: -4,
        position: "absolute",
        right: -4,
        top: -4,
      },
      button: { marginTop: global.spacing * 6 },
      colorsContainer: { flexDirection: "row" },
      container: { alignItems: "center" },
      imagesContainer: {
        flexDirection: "row",
      },
      itemContainer: {
        borderRadius: 999,
        flex: 1,
      },
      skeleton: { height: "100%", width: "100%" },
      text: { marginBottom: global.spacing * 4, marginTop: global.spacing * 6 },
      title: { marginTop: global.spacing * 2 },
    }),
  };
};

export default styles;
