import {
  CheckoutPostData,
  CheckoutPostVars,
  openUrl,
  queries,
  useMutation,
} from "@mxmdev/react-universal-core";
import { useCallback, useState } from "react";

import { CheckoutFn } from "../types";

const usePaymentsCheckout = (): {
  checkout: CheckoutFn;
  loading: boolean;
} => {
  const [loading, setLoading] = useState<boolean>(false);

  const [mutate] = useMutation<CheckoutPostData, CheckoutPostVars>(
    queries.checkoutPost
  );

  const checkout = useCallback<CheckoutFn>(
    async ({ price, product, quantity }, options) => {
      setLoading(true);

      try {
        const res = await mutate({
          variables: {
            body: {
              allowDiscounts: options.allowDiscounts,
              cancelRedirectURL: options.cancelRedirectURL,
              discountCodes: options.discountCodes,
              metadata: options.metadata,
              products: [
                {
                  internal: {
                    paymentType:
                      product.internal.paymentType ?? "recurring-payment",
                    platformItemId: price.internal.platformSku,
                    platformSku: product.internal.platformSku,
                  },
                  quantity,
                },
              ],
              referenceId: options.referenceId,
              successRedirectURL: options.successRedirectURL,
            },
          },
        });

        if (!res.data?.checkoutPost.body) {
          setLoading(false);
          throw new TypeError(
            "[PaymentProvider] checkout returned an empty response body"
          );
        }

        const checkoutURL = res.data.checkoutPost.body.checkoutURL;

        if (options.preventRedirect !== true) {
          openUrl(checkoutURL, "_self");
        }

        return {
          checkoutURL,
          type: "web",
        };
      } finally {
        setLoading(false);
      }
    },
    [mutate]
  );

  return { checkout, loading };
};

export default usePaymentsCheckout;
