import { FeatureFlags } from "./types";

export const FEATURE_FLAGS_DEFAULT_VALUES: FeatureFlags = {
  disableAdsFinder: false,
  disableMainNavigation: false,
  disableNonCurators: true,
  disablePodcastClaim: false,
  disableRelatedPodcasts: false,
  disableSearchBar: false,
  enableAudiogramDownload: false,
  enableMxmAccounts: true,
  enableReferenceTrackFallback: false,
  enableSSR: false,
  enableStudioVideo: false,
  enableText2Sync: false,
};
