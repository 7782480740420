import { forwardRef } from "react";
import { View } from "react-native";

import { Portal } from "..";
import { useTheme } from "../../styles";
import RemoveScroll from "../RemoveScroll";

import styles from "./styles";
import { FullscreenPortalProps } from "./types";

const FullscreenPortal = forwardRef<View, FullscreenPortalProps>(
  ({ children, keepInDOM = false, style: styleProp, visible }, ref) => {
    const { style } = useTheme(styles);

    if (!visible && !keepInDOM) {
      return null;
    }

    return (
      <Portal>
        <RemoveScroll
          allowPinchZoom={false}
          enabled={visible}
          forwardProps
          removeScrollBar={false}
        >
          <View style={{ display: visible ? "flex" : "none", flex: 1 }}>
            <View ref={ref} style={[styleProp, style.container]}>
              {children}
            </View>
          </View>
        </RemoveScroll>
      </Portal>
    );
  }
);

export default FullscreenPortal;
