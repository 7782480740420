import { useContext } from "react";

import { LocalPersistenceContext } from "./LocalPersistenceProvider";

import { PersistenceManager } from ".";

export const useLocalPersistence = (): PersistenceManager => {
  const { persistenceManager } = useContext(LocalPersistenceContext);

  if (persistenceManager === undefined) {
    throw new Error("PersistenceManager not available");
  }

  return persistenceManager;
};
