import {
  useLocalValidatedData,
  useLocalValidatedUpdate,
} from "@mxmdev/podcasts-shared-native";
import { useMediaQuery } from "@mxmdev/react-universal-components";
import { canUseDOM } from "@mxmdev/react-universal-core";
import { useCallback } from "react";
import { TypeOf, z } from "zod";

import useUserPodcastsList from "../../data-fetching/hooks/common/useUserPodcasts";

const BETA_BANNER_KEY = "beta-banner";

const schema = z.object({
  dismissed: z.boolean(),
});

const reader = (input: TypeOf<typeof schema>): boolean => input.dismissed;

const writer = (dismissed: boolean): TypeOf<typeof schema> => ({
  dismissed,
});

export const useBannerBeta = (): {
  dismissBanner: () => Promise<void>;
  height: number;
  error?: unknown;
  isLoading: boolean;
  retry: () => void;
  showBanner: boolean;
} => {
  const { isLargeScreen, isSmallScreen } = useMediaQuery();
  const claimedPodcasts = useUserPodcastsList();

  const {
    data: dismissed,
    error,
    isLoading,
    retry,
  } = useLocalValidatedData(BETA_BANNER_KEY, schema, reader);

  const { update } = useLocalValidatedUpdate(
    BETA_BANNER_KEY,
    schema,
    reader,
    writer
  );

  const dismissBanner = useCallback(async () => {
    return update((): boolean => true);
  }, [update]);

  const hasAlreadyClaimedPodcasts = claimedPodcasts.length > 0;

  const showBanner =
    canUseDOM && !dismissed && !isSmallScreen && !hasAlreadyClaimedPodcasts;

  const height = showBanner ? (isLargeScreen ? 60 : 94) : 0;

  return {
    dismissBanner,
    error,
    height,
    isLoading,
    retry,
    showBanner,
  };
};
