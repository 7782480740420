import { HttpLink } from "@apollo/client";
import { Platform } from "react-native";

import { isServer } from "../../../utils/platformUtils";

import { createProxiedHttpLink } from "./apolloHttpUtils";
import { ApolloClientOptions } from "./types";

const port = parseInt(process.env.PORT ?? "3000", 10);

export const api2Link = createProxiedHttpLink("ws/2.0/content/graphql");

export const fileUploadLink = createProxiedHttpLink(
  "ws/2.0/fileupload/graphql"
);

export const atlantisLink = createProxiedHttpLink("ws/1.1/credits.atlantis");

export const queueSenderLink = createProxiedHttpLink("ws/1.1/queue-sender");

export const datoLink = ({
  datoApiHeaders,
  datoApiToken,
}: ApolloClientOptions) =>
  new HttpLink({
    headers: {
      authorization: `Bearer ${datoApiToken}`,
      ...datoApiHeaders,
    },
    uri: "https://graphql.datocms.com",
  });

const datoNextWebLink = isServer()
  ? ({ datoApiHeaders }: ApolloClientOptions) =>
      new HttpLink({
        headers: datoApiHeaders,
        uri: `http://localhost:${port}/ws/datocms`,
      })
  : (options: ApolloClientOptions) =>
      createProxiedHttpLink("ws/datocms", {
        headers: options.datoApiHeaders,
      })(options);

export const datoNextLink = Platform.OS === "web" ? datoNextWebLink : datoLink;
