import { Platform } from "react-native";

import global from "../../styles/global";

export const isBrowser = Platform.OS === "web";
// Used to decide when the dropdown must:
// 1. go TOP instead of BOTTOM
// 2. go in fullheight
export const minHeight = 140; // Chosen based on the height of two ListItems
export const dropdownOpacityDuration = 100;
export const screenIndent = global.spacing * 4; // Minimum spacing between the edge of the screen and the dropdown
export const anchorDropdownGap = global.spacing * 2;
