import { createContext, useContext } from "react";

import { AnalyticsContextValue } from "./types";

const noClearUser: AnalyticsContextValue["clearUser"] = () => {
  console.warn(
    `[Analytics] User not reset, because analytics context has not been initialized.`
  );
};

const noLogEvent: AnalyticsContextValue["logEvent"] = (
  eventName,
  eventParams
) => {
  console.warn(
    `[Analytics] Event '${eventName}' (params: ${JSON.stringify(
      eventParams
    )}) dropped, because analytics context has not been initialized.`
  );
};

const noSetUser: AnalyticsContextValue["setUser"] = (id, userParams) => {
  console.warn(
    `[Analytics] User with id '${id}' (params: ${JSON.stringify(
      userParams
    )}) not set, because analytics context has not been initialized.`
  );
};

const AnalyticsContext = createContext<AnalyticsContextValue>({
  clearUser: noClearUser,
  logEvent: noLogEvent,
  setUser: noSetUser,
});

const useAnalytics = () => {
  const analyticsContext = useContext(AnalyticsContext);

  return analyticsContext;
};

export { AnalyticsContext, useAnalytics };
