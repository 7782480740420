import { AppOptions, HeaderAccountMenu } from "@mxmdev/account-shared";
import {
  useDeveloperToolsDialog,
  useIsMusixmatcher,
  useUser,
  useUserInfo,
} from "@mxmdev/podcasts-shared-native";
import {
  Button,
  IconCode,
  IconQuoteSquare,
} from "@mxmdev/react-universal-components";
import { getUserType, openUrl } from "@mxmdev/react-universal-core";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import { useCallback, useMemo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { navigate } from "../../navigation/RootNavigation";

const MainHeaderMenu = ({ style: styleProp }: Props): JSX.Element => {
  const { user } = useUser();
  const { userInfo } = useUserInfo();
  const { getLinks, signIn, signOut } = useAuth();
  const { manage: manageAccountURL } = getLinks();
  const isMusixmatcher = useIsMusixmatcher();
  const { openDeveloperToolsDialog } = useDeveloperToolsDialog();

  const appData = useMemo(() => {
    const userRole = userInfo?.curator
      ? "Curator"
      : (user?.authorizations.podcast?.podcastList?.length ?? 0) > 0
      ? "Podcaster"
      : "Listener";

    const appOptions: AppOptions[] = [
      {
        label: "Podcasts Studio",
        onPress: (): void => {
          navigate("Studio", {});
        },
        StartIcon: IconQuoteSquare,
      },
    ];

    if (isMusixmatcher) {
      appOptions.push({
        label: "Developer tools",
        onPress: (): void => {
          openDeveloperToolsDialog();
        },
        StartIcon: IconCode,
      });
    }

    return {
      appInfo: {
        name: "Podcasts",
        userRole,
      },
      appOptions,
    };
  }, [
    isMusixmatcher,
    openDeveloperToolsDialog,
    user?.authorizations.podcast?.podcastList?.length,
    userInfo?.curator,
  ]);

  const manageAccountLink = useCallback(() => {
    if (manageAccountURL) {
      openUrl(manageAccountURL.href, "_blank");
    }
  }, [manageAccountURL]);

  const handleSignIn = useCallback(() => {
    signIn();
  }, [signIn]);

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  if (!user) {
    return (
      <Button
        onPress={handleSignIn}
        size="small"
        style={styleProp}
        text="Sign in"
      />
    );
  }

  return (
    <View style={styleProp}>
      <HeaderAccountMenu
        appData={appData}
        image={user.profile.user_picture}
        manageAccountLink={manageAccountLink}
        onSignOut={handleSignOut}
        service={getUserType(user.profile.user_type) ?? ""}
        userName={user.profile.name}
      />
    </View>
  );
};

type Props = {
  style?: StyleProp<ViewStyle>;
};

export default MainHeaderMenu;
