import { useUser } from "@mxmdev/podcasts-shared-native";
import { useMemo } from "react";

type Props = {
  noCache?: boolean;
};

const useUserPodcastsList = ({ noCache }: Props = {}): string[] => {
  const { user } = useUser({ noCache });
  const podcastList = useMemo(
    () =>
      (user?.authorizations?.podcast?.podcastList ?? []).map(({ id }) =>
        String(id).toUpperCase()
      ),
    [user]
  );

  return podcastList;
};

export default useUserPodcastsList;
