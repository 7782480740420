import { useState, PropsWithChildren } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import global from "../../styles/global";
import Animated from "../Animated";

import { useIsHoverEnabled } from "./HoverUtils";

const Hoverable = ({
  animatedValue,
  children,
  isAnimationDisabled = false,
  onHover = () => {},
  style: styleProp,
  ...props
}: PropsWithChildren<Props>) => {
  const [isHovered, setIsHovered] = useState(false);
  const isHoverEnabled = useIsHoverEnabled();

  const onHoverIn = () => {
    props.onHoverIn?.();

    if (animatedValue) {
      Animated.timing(animatedValue, {
        duration: global.animatedTimingDuration,
        toValue: 1,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          onHover();
        }
      });
    } else onHover();
  };

  const onHoverOut = () => {
    props.onHoverOut?.();

    if (animatedValue) {
      Animated.timing(animatedValue, {
        toValue: 0,
        useNativeDriver: false,
      }).stop();
      Animated.timing(animatedValue, {
        duration: global.animatedTimingDuration,
        toValue: 0,
        useNativeDriver: true,
      }).start();
    }
  };

  const handleMouseEnter = () => {
    if (isHoverEnabled() && !isHovered) {
      if (!isAnimationDisabled) {
        onHoverIn();
      }

      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (isHovered) {
      if (!isAnimationDisabled) {
        onHoverOut();
      }

      setIsHovered(false);
    }
  };

  return (
    <View
      //@ts-ignore: React web only.
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={styleProp ?? undefined}
    >
      {children}
    </View>
  );
};

export interface Props {
  animatedValue?: Animated.Value;
  isAnimationDisabled?: boolean;
  onHover?: () => void;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  style?: StyleProp<ViewStyle>;
}

export default Hoverable;
