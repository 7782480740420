import {
  ComponentProps,
  forwardRef,
  FunctionComponent,
  Ref,
  useRef,
  useState,
  PropsWithChildren,
} from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";

import useAnimatedOverlays from "../../hooks/useAnimatedOverlays";
import useEscapeListener from "../../hooks/useEscapeListener";
import usePopStateListener from "../../hooks/usePopStateListener";
import { useMediaQuery, useLayoutMeasures } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import Animated from "../Animated";
import BottomSheet from "../BottomSheet";
import BottomSheetGestureProvider from "../BottomSheet/BottomSheetGestureProvider";
import FocusLock from "../FocusLock";
import { FullscreenPortal } from "../FullscreenPortal";

import styles from "./styles";

/**
 * Component that renders an animated overlay and bottomsheet or modal window (based on the screen size) on top of it.
 * @prop {react node} children - Content displayed inside.
 * @prop {boolean} dismissable - Allow/prevents to dismiss the component without pressing on the action buttons.
 * @prop {boolean} hasOverlay - Shows/hides the overlay.
 * @prop {function} onDismiss - Action that changes the visibility of the component.
 * @prop {boolean} panEnabled - Enable/Disable the closing pan gesture on mobile.
 * @prop {object} style - Changes style of the container.
 * @prop {boolean} visible - Show/hides the component.
 * @prop {boolean} keepInDOM - maintains the component rendered in the DOM even if it's not visible.
 */
const Modal: FunctionComponent<PropsWithChildren<Props>> = forwardRef(
  (
    {
      children,
      desktopHeight,
      desktopWidth,
      dismissable = true,
      hasOverlay = true,
      keepInDOM = false,
      mobileFullscreen = false,
      onDismiss = () => {},
      onFocusReady,
      panEnabled = true,
      style: styleProp,
      visible,
    },
    ref
  ) => {
    useEscapeListener({
      onDismiss: dismissable ? onDismiss : undefined,
      visible,
    });
    usePopStateListener({
      onDismiss, // When navigating session history the contextual modal should be dismissed
      visible,
    });
    const { isSmallScreen } = useMediaQuery();
    const { style } = useTheme(styles, {
      desktopHeight,
      desktopWidth,
      dismissable,
      isSmallScreen,
      mobileFullscreen,
    });
    const windowOpacity = useRef(new Animated.Value(0)).current;
    const overlayOpacity = useRef(new Animated.Value(0)).current;
    const renderedState = useState(visible);
    const [rendered] = renderedState;
    const [bottomSheetLayout, bottomSheetRef] = useLayoutMeasures();

    const { gestureHandlerProps, scrollViewProps, translateY } =
      useAnimatedOverlays({
        bottomSheetLayout,
        fullscreen: mobileFullscreen,
        // We give always true since positioning for modals is
        // centered and handled by the layout system
        isPositionCalculated: true,
        onDismiss,
        overlayOpacity,
        rendered: renderedState,
        visible,
        windowOpacity,
      });

    return (
      <FullscreenPortal
        keepInDOM={keepInDOM}
        ref={ref}
        style={[
          style.container,
          { justifyContent: isSmallScreen ? "flex-end" : "center" },
        ]}
        visible={rendered}
      >
        <>
          <TouchableWithoutFeedback
            onPress={dismissable ? () => onDismiss?.() : () => {}}
          >
            {hasOverlay ? (
              <Animated.View
                style={[style.overlay, { opacity: overlayOpacity }]}
              />
            ) : (
              <View style={StyleSheet.absoluteFill} />
            )}
          </TouchableWithoutFeedback>
          {isSmallScreen ? (
            <BottomSheetGestureProvider
              value={{
                gestureHandlerProps,
                panEnabled,
                scrollViewProps,
              }}
            >
              <View pointerEvents="box-none" style={{ flex: 1, width: "100%" }}>
                <BottomSheet
                  bottomSheetRef={bottomSheetRef}
                  fullscreen={mobileFullscreen}
                  style={styleProp}
                  translateY={translateY}
                >
                  {children}
                </BottomSheet>
              </View>
            </BottomSheetGestureProvider>
          ) : (
            <FocusLock
              lockProps={{ style: { display: "flex", maxHeight: "100%" } }}
              onActivation={onFocusReady}
            >
              <Animated.View
                style={[style.windowContainer, { opacity: windowOpacity }]}
              >
                <View style={[style.window, styleProp]}>{children}</View>
              </Animated.View>
            </FocusLock>
          )}
        </>
      </FullscreenPortal>
    );
  }
);

export interface Props {
  desktopHeight?: number;
  desktopWidth?: number;
  dismissable?: boolean;
  hasOverlay?: boolean;
  mobileFullscreen?: boolean;
  onFocusReady?: () => void;
  onDismiss?: () => void;
  panEnabled?: boolean;
  ref?: Ref<View>;
  style?: StyleProp<ViewStyle>;
  visible: boolean;
  keepInDOM?: ComponentProps<typeof FullscreenPortal>["keepInDOM"];
}

export default Modal;
