import {
  Animated,
  Hoverable,
  Pressable,
} from "@mxmdev/react-universal-components";
import { Props as HoverableProps } from "@mxmdev/react-universal-components/components/Hoverable/Hoverable";
import { Props as PressableProps } from "@mxmdev/react-universal-components/components/Pressable/Pressable";
import { useRef, PropsWithChildren } from "react";

import Button, { Props as ButtonProps } from "./Button";

/**
 * Component that provides an hoverable and pressable button.
 * @prop {function} onPress - Action triggered when the button is pressed.
 * @prop {function} onHover - Action triggered when the button is hovered.
 * @prop {boolean} isAnimationDisabled - Value that disables the button's animations.
 * @prop {boolean} disabled - Value that disables the button's onPress action and animation and onHover animation.
 * @prop {boolean} selected - Value that disables the button's onHover and onPress animations.
 */
const EnhancedButton = ({
  backgroundColor,
  bordered,
  compact,
  color,
  description,
  disabled,
  dot,
  dotColor,
  Icon,
  iconAlign,
  image,
  imageShape,
  isLoading,
  onPress,
  onHover,
  selected,
  shadowed,
  shape,
  size,
  style,
  text,
  to,
  type,
  minTouchTarget = !!(onPress || to),
  ...otherProps
}: PropsWithChildren<Props>) => {
  const hoverAnimatedValue = useRef(new Animated.Value(0)).current;
  const pressAnimatedValue = useRef(new Animated.Value(0)).current;

  return (
    <Hoverable
      animatedValue={hoverAnimatedValue}
      isAnimationDisabled={disabled}
      onHover={onHover}
      style={style}
    >
      <Pressable
        {...otherProps}
        animatedValue={pressAnimatedValue}
        disabled={disabled || isLoading}
        isAnimationDisabled={disabled || selected || isLoading}
        onPress={onPress}
        to={to}
      >
        <Button
          backgroundColor={backgroundColor}
          bordered={bordered}
          color={color}
          compact={compact}
          description={description}
          disabled={disabled}
          dot={dot}
          dotColor={dotColor}
          hoverAnimatedValue={
            (onHover && !selected) ||
            (onPress && !selected) ||
            (to && !selected)
              ? hoverAnimatedValue
              : undefined
          }
          Icon={Icon}
          iconAlign={iconAlign}
          image={image}
          imageShape={imageShape}
          isLoading={isLoading}
          minTouchTarget={minTouchTarget}
          pressAnimatedValue={onPress || to ? pressAnimatedValue : undefined}
          selected={selected}
          shadowed={shadowed}
          shape={shape}
          size={size}
          text={text}
          type={type}
        />
      </Pressable>
    </Hoverable>
  );
};

export interface Props extends ButtonProps, HoverableProps, PressableProps {}

export default EnhancedButton;
