import { Episode, getEpisodeApiParams } from "@mxmdev/podcasts-shared";
import { useValidatedFetcher } from "@mxmdev/podcasts-shared-native";
import { z, TypeOf } from "zod";

const parser = z.object({
  episode_metadata: z.optional(
    z.object({
      podcast_episode_url: z.string(),
    })
  ),
});

const transformer = (input: TypeOf<typeof parser>): string => {
  const url = input.episode_metadata?.podcast_episode_url;

  if (url === undefined) {
    throw new Error("unable to resolve URL");
  }

  return url;
};

export const useFallbackUrlResolveFetcher = (): {
  fallbackUrlResolve: (episode: Episode) => Promise<string>;
} => {
  const { fetcher } = useValidatedFetcher(
    "podcast.episode.metadata.get",
    {},
    parser,
    transformer
  );

  const scopedFetcher = (episode: Episode): Promise<string> => {
    const params = getEpisodeApiParams(episode.podcastId, episode.id);

    return fetcher(params);
  };

  return {
    fallbackUrlResolve: scopedFetcher,
  };
};

export default useFallbackUrlResolveFetcher;
