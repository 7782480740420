import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Appearance, ColorSchemeName, Platform } from "react-native";

import { ActivityIndicator } from "../..";
import { UseLinkFunction } from "../../types";

import { THEME_LOCAL_STORAGE, ThemeContext } from "./themeUtils";

const ThemeProvider = ({
  children,
  config,
  scheme: schemeProp,
  useLink,
}: Props) => {
  const [scheme, setScheme] = useState<ColorSchemeName>(
    () => schemeProp ?? Appearance.getColorScheme()
  );

  const onColorSchemeChange = useCallback(
    // Ignore `colorScheme`: https://github.com/expo/expo/issues/10815#issuecomment-897219483
    ({ colorScheme: _colorScheme }: { colorScheme: ColorSchemeName }) => {
      setScheme(Appearance.getColorScheme);
    },
    []
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      const initialScheme =
        schemeProp ??
        localStorage.getItem(THEME_LOCAL_STORAGE) ??
        Appearance.getColorScheme();

      setScheme(initialScheme as ColorSchemeName);
    }

    if (!schemeProp) {
      const colorSchemaListener =
        Appearance.addChangeListener(onColorSchemeChange);

      return () => {
        colorSchemaListener.remove();
      };
    }
  }, [onColorSchemeChange, schemeProp]);

  useEffect(() => {
    if (Platform.OS === "web" && scheme) {
      const root = document.documentElement;
      const newTheme = scheme.toString();

      root.setAttribute("data-theme", newTheme);
      localStorage.setItem(THEME_LOCAL_STORAGE, newTheme);
    }
  }, [scheme]);

  return (
    <ThemeContext.Provider
      value={{
        config,
        scheme,
        setScheme: (scheme: ColorSchemeName) => setScheme(scheme),
        useLink,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export type ThemeConfig = {
  activityIndicatorColor?: ComponentProps<typeof ActivityIndicator>["color"];
};

interface Props {
  children: ReactNode;
  config?: ThemeConfig;

  /**
   * Ignore the system color scheme and force a specific one.
   */
  scheme?: ColorSchemeName;
  useLink: UseLinkFunction;
}

export default ThemeProvider;
