import { ComponentProps, FunctionComponent, PropsWithChildren } from "react";

import { useDataFetchingClient } from "../data-fetching";

import { default as CaptchaProvider } from "./CaptchaProvider";

const withCaptchaProvider = <P extends object>(
  Wrapped: (props: P) => JSX.Element | null
): FunctionComponent<
  PropsWithChildren<
    P & Omit<ComponentProps<typeof CaptchaProvider>, "apolloClient">
  >
> => {
  return (props) => {
    const { appId, appScheme, children, loadingComponent, renderError } = props;

    const apolloClient = useDataFetchingClient();

    return (
      <CaptchaProvider
        apolloClient={apolloClient}
        appId={appId}
        appScheme={appScheme}
        loadingComponent={loadingComponent}
        renderError={renderError}
      >
        <Wrapped {...(props as P)}>{children}</Wrapped>
      </CaptchaProvider>
    );
  };
};

export default withCaptchaProvider;
