import {
  imageStyles,
  viewStyles,
  global,
  Theme,
} from "@mxmdev/react-universal-components";

import { AVATAR_SIZE } from "../AccountEditableAvatar/styles";

import { AppData } from "./AccountMenu";

interface Props {
  appData?: AppData;
  isSmallScreen: boolean;
  subscriptionBackgroundColor: string;
}

const menuProfileBoxAvatarSize = 80;

const styles = (theme: Theme, { appData, isSmallScreen }: Props) => {
  return {
    ...imageStyles({
      profile: {
        borderRadius: menuProfileBoxAvatarSize,
        height: menuProfileBoxAvatarSize,
        marginBottom: global.spacing * 2,
        width: menuProfileBoxAvatarSize,
      },
      profilePic: {
        borderRadius: AVATAR_SIZE / 2,
        height: AVATAR_SIZE,
        width: AVATAR_SIZE,
      },
    }),
    ...viewStyles({
      accountButton: {
        marginTop: global.spacing * 2,
      },
      appDataBox: {
        borderTopColor: theme.backgroundSecondary,
        borderTopWidth: 2,
        marginBottom: global.spacing,
        marginTop: global.spacing * 4,
        paddingBottom: global.spacing * 2,
        width: "100%",
      },
      appInfoContainer: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: global.spacing * 3,
        paddingHorizontal: global.spacing * 4,
        paddingTop: global.spacing * 4,
      },
      appInfoRole: {
        paddingTop: global.spacing,
      },
      container: {
        alignItems: "center",
        minWidth: isSmallScreen ? undefined : 360,
        paddingBottom: isSmallScreen ? 0 : global.spacing * 2,
        paddingTop: isSmallScreen ? global.spacing * 2 : global.spacing * 4,
      },
      dropDown: {
        padding: 0,
      },
      item: {
        alignItems: "center",
        height: global.size.default,
      },
      planIndicator: {
        marginLeft: global.spacing * 4,
      },
      signOut: {
        alignItems: "center",
        borderTopColor: theme.backgroundSecondary,
        borderTopWidth: 2,
        justifyContent: "center",
        marginTop: appData ? undefined : global.spacing * 4,
        paddingTop: global.spacing,
        width: "100%",
      },
      username: {
        marginBottom: global.spacing,
      },
      versionInfo: {
        marginBottom: global.spacing,
        marginTop: global.spacing * 3,
        paddingHorizontal: global.spacing * 4,
      },
    }),
  };
};

export default styles;
