import { generateGUID } from "../../../utils";
import { AuthStorage } from "../types";

const GUID_STORAGE_KEY = "guid";

export const getGUID = async (storage: AuthStorage): Promise<string> => {
  const restoredGuid: string | null = await storage.get(GUID_STORAGE_KEY);

  if (!restoredGuid) {
    const newGuid: string = generateGUID();

    await storage.set(GUID_STORAGE_KEY, newGuid);

    return newGuid;
  } else {
    return restoredGuid;
  }
};

export const createGUIDSync = (storage: AuthStorage): string => {
  const guid = generateGUID();

  storage.set(GUID_STORAGE_KEY, guid);

  return guid;
};
