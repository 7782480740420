import { useDialogContext } from "@mxmdev/react-universal-components";
import { useCallback, useState } from "react";

import { DeveloperToolsDialog } from "./DeveloperToolsDialog";

export const useDeveloperToolsDialog = (): {
  isOpen: boolean;
  openDeveloperToolsDialog: () => Promise<void>;
} => {
  const { openDialog } = useDialogContext();
  const [isOpen, setIsOpen] = useState(false);

  const openDeveloperToolsDialog = useCallback(async () => {
    setIsOpen(true);
    await openDialog(DeveloperToolsDialog);
    setIsOpen(false);
  }, [openDialog]);

  return {
    isOpen,
    openDeveloperToolsDialog,
  };
};
