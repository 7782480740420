import { useCallback } from "react";
import { z } from "zod";

import { notifyChangeEvent } from "./localUpdateNotifier";
import { useLocalPersistence } from "./useLocalPersistence";

export declare type TypeOf<T extends z.ZodType<unknown>> = T["_output"];

// This hook should be used to update the data on the local storage
const useLocalValidatedUpdate = <T extends z.ZodRawShape, OutputType>(
  key: string,
  valueSchema: z.ZodObject<T>,
  reader: (input: TypeOf<z.ZodObject<T>>) => OutputType,
  writer: (input: OutputType) => TypeOf<z.ZodObject<T>>
): {
  update: (
    performUpdate: (
      previous: Readonly<OutputType> | undefined
    ) => Readonly<OutputType> | undefined
  ) => Promise<void>;
} => {
  const persistenceManager = useLocalPersistence();

  const update = useCallback(
    async (
      performUpdate: (
        previous: Readonly<OutputType> | undefined
      ) => Readonly<OutputType> | undefined
    ) => {
      const loaded = await persistenceManager.get(key);

      let previousValue: OutputType | undefined = undefined;

      if (loaded !== undefined) {
        try {
          const jsonParsed = JSON.parse(loaded);
          const parsed = valueSchema.parse(jsonParsed);

          previousValue = reader(parsed);
        } catch (parsingError) {
          console.error("error while parsing local key: ", key, parsingError);
        }
      }

      const updated = performUpdate(previousValue);

      if (updated !== undefined) {
        const output = writer(updated);

        await persistenceManager.set(key, JSON.stringify(output));
      } else {
        await persistenceManager.remove(key);
      }

      notifyChangeEvent(key);

      return Promise.resolve();
    },
    [key, reader, writer, persistenceManager, valueSchema]
  );

  return {
    update,
  };
};

export default useLocalValidatedUpdate;
