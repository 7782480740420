import { ColorSchemeName, StatusBarStyle } from "react-native";

export const getStatusBarStyle = (
  colorScheme: ColorSchemeName
): StatusBarStyle => {
  switch (colorScheme) {
    case "light":
      return "dark-content";
    case "dark":
      return "light-content";
    default:
      return "default";
  }
};
