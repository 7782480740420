import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import { ListItem } from "../List";
import { Props as ListItemProps } from "../List/EnhancedListItem";

import styles from "./styles";

const MenuItem = ({
  children,
  description,
  Icon,
  iconContainerStyle,
  label,
  onHover,
  onPress,
  style,
  to,
  webTarget,
  wrapped,
}: Props) => {
  const { isSmallScreen } = useMediaQuery();
  const { style: defaultStyle } = useTheme(styles, { isSmallScreen });

  return (
    <ListItem
      description={description}
      divider={false}
      Icon={Icon}
      iconContainerStyle={iconContainerStyle}
      label={label}
      onHover={onHover}
      onPress={onPress}
      style={style || (!isSmallScreen && defaultStyle.menuItem)}
      to={to}
      webTarget={webTarget}
      wrapped={wrapped}
    >
      {children}
    </ListItem>
  );
};

interface Props
  extends Pick<
    ListItemProps,
    | "children"
    | "iconContainerStyle"
    | "label"
    | "onHover"
    | "onPress"
    | "to"
    | "webTarget"
    | "wrapped"
    | "description"
    | "style"
    | "Icon"
  > {}

export default MenuItem;
