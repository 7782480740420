import { useContext } from "react";

import {
  ConfigurationContext,
  ConfigurationContextData,
} from "./ConfigurationProvider";

export const useConfiguration = (): ConfigurationContextData => {
  return useContext(ConfigurationContext);
};
