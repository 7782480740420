import {
  Animated,
  Text,
  viewStyles,
  global,
  textStyles,
} from "@mxmdev/react-universal-components";
import { useRef } from "react";
import { View } from "react-native";

import { useEffectOnce } from "../../utils/hookUtils";
import { createStyles, useTheme } from "../../utils/themeUtils";

const Quote = (): JSX.Element => {
  const { style } = useTheme(styles);

  const opacity = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(20)).current;

  useEffectOnce(() => {
    const animation = fadeInAnimation(opacity, translateY);

    animation.start();

    return (): void => {
      animation.stop();
    };
  });

  return (
    <Animated.View
      style={{
        opacity,
        transform: [{ translateY }],
      }}
    >
      <View style={style.container}>
        <Text style={style.quote} type="labelDefault">
          “All you need is love”
        </Text>
        <Text align="center" style={style.author} type="bodyDefault">
          John Lennon
        </Text>
      </View>
    </Animated.View>
  );
};

const styles = createStyles(() => ({
  ...textStyles({
    quote: {
      fontStyle: "italic",
    },
  }),
  ...viewStyles({
    author: {
      marginTop: global.spacing,
    },
    container: {
      marginTop: global.spacing * 4,
    },
  }),
}));

const fadeInAnimation = (
  opacity: Animated.Value,
  translateY: Animated.Value
): Animated.CompositeAnimation =>
  Animated.sequence([
    Animated.delay(2500),
    Animated.parallel([
      Animated.timing(opacity, {
        duration: 1000,
        toValue: 1,
        useNativeDriver: true,
      }),
      Animated.timing(translateY, {
        duration: 1000,
        toValue: 0,
        useNativeDriver: true,
      }),
    ]),
  ]);

export default Quote;
