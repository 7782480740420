import { createContext } from "react";

import { User } from "../../types";

import { AccountLinks } from "./types";

export interface ContextType {
  appId: string;
  getLinks: () => AccountLinks;
  isLoggedIn: boolean;
  signIn: (redirectURL?: string) => void;
  signOut: (redirectURL?: string) => void;
  user: User | null;

  /** @deprecated Will be removed in later versions of AuthProvider */
  userToken: string | null;
}

const initialContextValue: ContextType = {
  appId: "unset",
  getLinks: () => ({
    contributionPreference: null,
    manage: null,
    subscriptions: null,
  }),
  isLoggedIn: false,
  signIn: () => {},
  signOut: () => {},
  user: null,
  userToken: null,
};

const AuthContext = createContext<ContextType>(initialContextValue);

export default AuthContext;
