import { EmbedCodeType } from "../types";

import { assertUnreachable } from "./assertUnreachable";

const getScriptEmbedCode = (embedId: string) => `<div
    id="mxm-podcasts-embed"
    data-episode-id="${embedId}"
    data-mode="full-size"
    data-theme="light"
    data-player="true"
    style="width: 100%; height: 600px"
></div>

<script async type="module" src="https://s.mxmcdn.net/podcasts-embed/prod/v1.js"></script>`;

const getIFrameEmbedCode = (embedId: string) => `<iframe
    src="https://podcasts-embed.musixmatch.com?episodeId=${embedId}&mode=full-size&theme=light&player=true"
    frameborder="0"
    width="100%"
    height="600px"
></iframe>`;

export const getEmbedCode = (embedType: EmbedCodeType, embedId: string) => {
  switch (embedType) {
    case "iframe":
      return getIFrameEmbedCode(embedId);
    case "script":
      return getScriptEmbedCode(embedId);
    default:
      return assertUnreachable(embedType);
  }
};
