import {
  getEpisodeApiParams,
  SyncedSectionWithoutTopics,
  SyncedTranscription,
  UnsyncedTranscription,
} from "@mxmdev/podcasts-shared";
import { ApiResponse, useFeatureFlags } from "@mxmdev/podcasts-shared-native";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import { useMemo, useEffect, useState, useCallback } from "react";

import { useApollo } from "../../../../apollo/useApollo";
import { useReferenceTrackUrl } from "../../../../player/hooks/useReferenceTrackUrl";
import { ResolvedEpisode } from "../../../../types";

import { GQL_ADS_FINDER_INIT, GQL_ADS_FINDER_STATUS_UPDATE } from "./gql";
import {
  remapTopicsToSyncedTranscription,
  remapUnsyncedToSyncedSection,
} from "./sectionUtils";
import useAdsFinderAnalysis from "./useAdsFinderAnalysis";

export const useSyncedTranscription = ({
  episode,
  unsyncedTranscription,
}: Props): ApiResponse<SyncedTranscription, "transcription"> => {
  const { disableAdsFinder, enableReferenceTrackFallback } = useFeatureFlags();
  const { activateApollo, apolloClient } = useApollo();
  const { appId, userToken } = useAuth();
  const referenceTrackUrl = useReferenceTrackUrl(episode);

  const [adsFinderId, setAdsFinderId] = useState<string | undefined>(undefined);
  const [sections, setSections] = useState<SyncedSectionWithoutTopics[] | null>(
    null
  );
  const [appSyncError, setAppSyncError] = useState<unknown | null>(null);

  // TODO: implement retry
  const retry = useCallback(() => {}, []);

  const audioTrackUrl = episode?.resolvedAudioTrackUrl;

  const unsyncedSections = unsyncedTranscription?.sections;

  // Lazy load the Apollo module
  useEffect(() => {
    if (!disableAdsFinder && !apolloClient && audioTrackUrl) {
      activateApollo();
    }
  }, [activateApollo, apolloClient, disableAdsFinder, audioTrackUrl]);

  useEffect(() => {
    setSections([]);
    setAppSyncError(null);
    setAdsFinderId(undefined);
  }, [episode?.podcastId, episode?.id]);

  useEffect(() => {
    if (
      !apolloClient ||
      !unsyncedSections ||
      !audioTrackUrl ||
      audioTrackUrl === referenceTrackUrl
    ) {
      return;
    }

    setSections([]);
    setAppSyncError(null);
    setAdsFinderId(undefined);

    const timestamp = btoa(audioTrackUrl + Date.now());

    const subscriber = apolloClient.subscribe({
      query: GQL_ADS_FINDER_STATUS_UPDATE,
      variables: {
        timestamp,
      },
    });

    let timeout: ReturnType<typeof setTimeout>;
    let mutationTimeout: ReturnType<typeof setTimeout>;
    let subscription: ReturnType<typeof subscriber["subscribe"]>;

    const cleanup = (): void => {
      subscription?.unsubscribe();

      if (timeout) {
        clearTimeout(timeout);
      }

      if (mutationTimeout) {
        clearTimeout(timeout);
      }
    };

    subscription = subscriber.subscribe(
      (result) => {
        const status: AdsFinderStatus = result?.data?.onUpdateStatus?.status;
        const message = result?.data?.onUpdateStatus?.message;

        switch (status) {
          case "no-need-to-run":
            setSections(remapUnsyncedToSyncedSection(unsyncedSections));
            cleanup();
            break;

          case "done":
            setAdsFinderId(message);
            cleanup();
            break;

          case "processing":
          case "hashmap-creation":
            if (enableReferenceTrackFallback) {
              setAppSyncError(new Error(message));
              cleanup();
            }

            break;

          case "error":
            setAppSyncError(new Error(message));
            cleanup();
            break;
        }
      },
      (error) => {
        setAppSyncError(error);
        cleanup();
      }
    );

    mutationTimeout = setTimeout(() => {
      apolloClient
        .mutate({
          mutation: GQL_ADS_FINDER_INIT,
          variables: {
            ...getEpisodeApiParams(episode.podcastId, episode.id),
            analysis_id: "musixmatch",
            app_id: appId,
            client_url: audioTrackUrl,
            fromMutation: "initAdsFinder",
            part: "ads_finder_stoplight",
            timestamp,
            usertoken: userToken,
          },
        })
        .catch((error) => {
          setAppSyncError(error);
          cleanup();
        });
    }, 200);

    timeout = setTimeout(
      () => {
        setAppSyncError(new Error("ads finder timeout"));
        cleanup();
      },
      enableReferenceTrackFallback ? 10000 : 30000
    );

    return cleanup;
  }, [
    apolloClient,
    appId,
    audioTrackUrl,
    enableReferenceTrackFallback,
    episode?.id,
    episode?.podcastId,
    referenceTrackUrl,
    unsyncedSections,
    userToken,
  ]);

  const { error: adsFinderError, sections: adsFinderSections } =
    useAdsFinderAnalysis({
      adsFinderId,
      episodeId: episode?.id,
      podcastId: episode?.podcastId,
    });

  const error = appSyncError || adsFinderError;

  const sectionsWithFallback = adsFinderSections || sections;

  const syncedTranscription = useMemo((): SyncedTranscription | undefined => {
    if (
      !sectionsWithFallback ||
      sectionsWithFallback.length === 0 ||
      !unsyncedTranscription
    ) {
      return undefined;
    }

    return {
      ...unsyncedTranscription,
      syncedSections: remapTopicsToSyncedTranscription(
        unsyncedTranscription.sections,
        sectionsWithFallback
      ),
    };
  }, [sectionsWithFallback, unsyncedTranscription]);

  return {
    error,
    isLoading: !syncedTranscription && !appSyncError,
    retry,
    transcription: syncedTranscription,
  };
};

interface Props {
  episode?: ResolvedEpisode;
  unsyncedTranscription?: UnsyncedTranscription;
}

type AdsFinderStatus =
  | "no-need-to-run"
  | "hashmap-creation"
  | "processing"
  | "done"
  | "error";
