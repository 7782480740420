import { assertUnreachable } from "@mxmdev/podcasts-shared";
import { createContext, PropsWithChildren } from "react";

import { Env, FeatureFlags } from "./types";
import { FEATURE_FLAGS_DEFAULT_VALUES } from "./utils";

export type ConfigurationContextData = {
  apiStream: string;
  baseUrl: string;
  buildNumber: string;
  env: Env;
  stripePriceId?: string;
} & FeatureFlags;

const configurationContextDefaultValue: ConfigurationContextData = {
  apiStream: "",
  baseUrl: "",
  buildNumber: "",
  env: "" as Env,
  stripePriceId: "",
  ...FEATURE_FLAGS_DEFAULT_VALUES,
};

export const ConfigurationContext = createContext<ConfigurationContextData>(
  configurationContextDefaultValue
);

const getBaseUrl = (env: Env, prNumber?: number): string => {
  switch (env) {
    case "local":
      return "http://localhost:3000";
    case "pr":
      return `https://pr-${prNumber}-podcasts.musixmatch.dev`;
    case "dev":
      return "https://podcasts.musixmatch.dev";
    case "pp":
      return "https://podcasts-beta.musixmatch.com";
    case "prod":
      return "https://podcasts.musixmatch.com";
    default:
      return assertUnreachable(env);
  }
};

const ConfigurationProvider = ({
  apiStream,
  buildNumber,
  children,
  env,
  featureFlags,
  prNumber,
  stripePriceId,
}: PropsWithChildren<Props>) => {
  const baseUrl = getBaseUrl(env, prNumber);

  return (
    <ConfigurationContext.Provider
      value={{
        apiStream,
        baseUrl,
        buildNumber,
        env,
        stripePriceId,
        ...{
          ...FEATURE_FLAGS_DEFAULT_VALUES,
          ...featureFlags,
        },
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

interface Props {
  apiStream: string;
  buildNumber: string;
  env: Env;
  featureFlags: Partial<FeatureFlags>;
  prNumber?: number;
  stripePriceId?: string;
}

export default ConfigurationProvider;
