import { PropsWithChildren } from "react";
import ReactFocusLock from "react-focus-lock";
import { ReactFocusLockProps } from "react-focus-lock/interfaces";
import { Platform } from "react-native";

import { useMediaQuery } from "../../styles/styleUtils";

// If multiple modals are rendered each one in a different iFrame on the same page
// we don't use the focusLock since it produces weird behaviours even with crossFrame={false}
// that is the case with storybook too
const FocusLock = ({
  children,
  ...props
}: PropsWithChildren<ReactFocusLockProps>) => {
  const { isSmallScreen } = useMediaQuery();

  return Platform.OS === "web" && !isSmallScreen && !window?.frameElement ? (
    <ReactFocusLock returnFocus {...props}>
      {children}
    </ReactFocusLock>
  ) : (
    <>{children}</>
  );
};

export default FocusLock;
