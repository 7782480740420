import * as React from "react";

import PortalConsumer from "./PortalConsumer";
import PortalHost, { PortalContext, PortalMethods } from "./PortalHost";

type Props = {
  children: React.ReactNode;
};

/**
 * Portal allows to render a component at a different place in the parent tree.
 * You can use it to render content which should appear above other elements, similar to `Modal`.
 * It requires a `Portal.Host` component to be rendered somewhere in the parent tree.
 */
class Portal extends React.Component<Props> {
  static Host = PortalHost;

  render() {
    const { children } = this.props;

    return (
      <PortalContext.Consumer>
        {(manager) => (
          <PortalConsumer manager={manager as PortalMethods}>
            {children}
          </PortalConsumer>
        )}
      </PortalContext.Consumer>
    );
  }
}

export default Portal;
