import {
  useState,
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  PropsWithChildren,
  ComponentType,
} from "react";
import {
  PickerProps,
  View,
  StyleProp,
  ViewStyle,
  NativeSyntheticEvent,
  TargetedEvent,
  GestureResponderEvent,
  Platform,
} from "react-native";

import { IconCheck, IconClear, IconComponent, Text } from "..";
import { useLayoutMeasures, useMediaQuery } from "../../styles";
import { ComponentSize } from "../../styles/global";
import { useTheme } from "../../styles/themes/themeUtils";
import BottomSheetFlatList from "../BottomSheet/BottomSheetFlatList";
import Button from "../Button";
import { Input } from "../Input";
import { ListItem } from "../List";
import Popover from "../Popover";
import DialogCloseButton from "../dialogs/DialogCloseButton";

import DropdownAnchor from "./DropdownAnchor";
import MobileSearchHeader from "./MobileSearchHeader";
import styles from "./styles";

const Select = ({
  background,
  clearable = false,
  disabled = false,
  endOptions = [],
  error = false,
  maxDropdownHeight = 375,
  multiselect,
  onBlur,
  onChange = () => {},
  onFocus,
  options,
  placeholder,
  searchable = options.length >= 10,
  size = "default",
  startOptions = [],
  style: styleProp,
  value,
}: PropsWithChildren<Props>) => {
  const outerValue = Array.isArray(value) ? value : value ? [value] : [];
  const [dropOpen, setDropOpen] = useState(false);
  const [anchorMeasures, anchorRef] = useLayoutMeasures();
  const { isSmallScreen } = useMediaQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState<Item["value"][]>([]);

  const isFullscreen = isSmallScreen;
  const searchableOnLarge = searchable && !isSmallScreen;
  const searchableOnSmall = searchable && isSmallScreen;

  const focusedItemIndex = useRef<number | undefined>(undefined);
  const inputRef = useRef<ComponentType<typeof Input> | undefined>(undefined);
  const optionValues = useRef<string[]>([]);
  const listItems = useRef<HTMLElement[]>([]);

  const onDismiss = useCallback(() => {
    setDropOpen(false);
    onBlur?.();
  }, [onBlur]);

  const enhancedOnChange = useCallback(
    (value: ItemValueType) => {
      setSearchTerm("");
      onChange(value);
      onDismiss();

      if (!value) {
        setDropOpen(true);
      }

      if (Platform.OS === "web") {
        (inputRef.current as unknown as HTMLElement).focus();
      }
    },
    [onChange, onDismiss]
  );

  const onItemPress = useCallback(
    (
      item: Omit<Item, "label"> & Omit<EnhancedItem, "label">,
      e: GestureResponderEvent | KeyboardEvent
    ) => {
      if (!item.value && item.onPress) {
        return item.onPress(e as GestureResponderEvent);
      }

      if (multiselect) {
        return setSelectedItems((items) => {
          let newItems;

          if (items.includes(item.value)) {
            newItems = items.filter(
              (selectedItem) => selectedItem !== item.value
            );
          } else {
            newItems = [...items, item.value];
          }

          enhancedOnChange(newItems as ItemValueType);

          return newItems;
        });
      }

      enhancedOnChange(item.value as ItemValueType);
    },
    [enhancedOnChange]
  );

  const setAnchorRef = (ref: any) => {
    anchorRef(ref);

    if (ref !== null) {
      inputRef.current = ref;
    }
  };

  useEffect(() => {
    if (Platform.OS === "web") {
      const optionsList = listItems.current;
      const selectAnchor = inputRef.current as unknown as HTMLElement;

      optionsList.forEach((item) => {
        if (item !== null) {
          item.setAttribute("tabIndex", "0");
        }
      });

      selectAnchor.setAttribute("tabIndex", "0");

      if (disabled) {
        return;
      }

      const handleSelectAnchorKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }

        if (e.code === "Space" && searchTerm.length === 0) {
          setDropOpen(!dropOpen);
        }

        if (e.key === "ArrowDown") {
          setDropOpen(true);

          if (!value || multiselect) {
            focusedItemIndex.current =
              selectAnchor.querySelector("input") === document.activeElement
                ? 0
                : -1;

            if (listItems.current[focusedItemIndex.current] !== undefined) {
              listItems.current[focusedItemIndex.current].focus();
            }
          } else {
            const index = optionValues.current.indexOf(value as string);

            if (listItems.current[index] !== undefined) {
              focusedItemIndex.current = index - 1;
              listItems.current[index].focus();
            }
          }
        }
      };

      const handleOptionsListKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowDown" && focusedItemIndex.current !== undefined) {
          focusedItemIndex.current =
            focusedItemIndex.current === optionsList.length - 1
              ? focusedItemIndex.current
              : focusedItemIndex.current + 1;

          if (optionsList[focusedItemIndex.current] !== undefined) {
            optionsList[focusedItemIndex.current].focus();
          }
        }

        if (e.key === "ArrowUp" && focusedItemIndex.current !== undefined) {
          focusedItemIndex.current =
            focusedItemIndex.current === 0
              ? focusedItemIndex.current
              : focusedItemIndex.current - 1;

          optionsList[focusedItemIndex.current].focus();
        }

        if (e.code === "Space" && focusedItemIndex.current !== undefined) {
          onItemPress(
            {
              value: optionValues.current[focusedItemIndex.current],
            } as Item & EnhancedItem,
            e
          );
          setDropOpen(false);

          if (selectAnchor !== null) {
            selectAnchor.focus();
          }
        }

        if (e.key === "Tab") {
          // Ideally the focus should go to the next interactive element, but the best we can do is to focus back on the anchor
          e.preventDefault();
          setDropOpen(false);

          if (selectAnchor !== null) {
            selectAnchor.focus();
          }
        }

        if (e.key === "Escape") {
          setDropOpen(false);

          if (selectAnchor !== null) {
            selectAnchor.focus();
          }
        }
      };

      const handleAnchorClick = () => {
        setDropOpen(true);
      };

      selectAnchor.addEventListener("keydown", handleSelectAnchorKeyDown);
      selectAnchor.addEventListener("click", handleAnchorClick);

      if (dropOpen) {
        document.addEventListener("keydown", handleOptionsListKeyDown);
      }

      return () => {
        document.removeEventListener("keydown", handleOptionsListKeyDown);
        selectAnchor.removeEventListener("keydown", handleSelectAnchorKeyDown);
        selectAnchor.removeEventListener("click", handleAnchorClick);
      };
    }
  }, [dropOpen, multiselect, onItemPress, searchTerm.length, value]);

  const { style, theme } = useTheme(styles, {
    anchorMeasuresWidth: anchorMeasures?.width,
    isSmallScreen,
  });

  const innerOptions = useMemo(() => {
    if (searchTerm) {
      return options.filter(({ label }) =>
        label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return [...startOptions, ...options, ...endOptions];
  }, [searchTerm, startOptions, options, endOptions]);

  const produceAnchorLabel = () => {
    if (searchableOnLarge && searchTerm) {
      return searchTerm;
    } else if (outerValue.length && !multiselect) {
      return (
        options.find((option) => option.value === outerValue[0])?.label || ""
      );
    }

    if (multiselect && Array.isArray(value)) {
      // It's safe to call setSelectedItems at each render
      // because value is stable as a reference
      // either if used with react-hook-form or useState
      setSelectedItems(value);
    }

    // If multiselection is true, the label is empty
    return "";
  };

  useEffect(() => {
    if (!dropOpen) {
      setSearchTerm("");
    }
  }, [dropOpen]);

  return (
    <>
      <Popover
        anchor={
          <DropdownAnchor
            anchorRef={setAnchorRef}
            background={background}
            clearable={clearable}
            disabled={disabled}
            enhancedOnChange={enhancedOnChange}
            error={error}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            produceAnchorLabel={produceAnchorLabel}
            searchableOnLarge={searchableOnLarge}
            searchTerm={searchTerm}
            setDropOpen={setDropOpen}
            setSearchTerm={setSearchTerm}
            size={size}
          />
        }
        anchorContainerStyle={styleProp}
        maxDropdownHeight={maxDropdownHeight}
        mobileFullscreen={isFullscreen}
        onDismiss={onDismiss}
        style={[
          !isSmallScreen && {
            width: anchorMeasures?.width,
          },
        ]}
        visible={dropOpen}
      >
        {isSmallScreen && (
          <DialogCloseButton
            onPress={onDismiss}
            style={style.dialogCloseButton}
          />
        )}
        <BottomSheetFlatList
          data={innerOptions}
          fullscreen={isFullscreen}
          keyExtractor={(_, index) => index.toString()}
          ListFooterComponent={
            innerOptions?.length === 0 ? (
              <Text style={style.emptyText}>No results found</Text>
            ) : null
          }
          ListHeaderComponent={
            searchableOnSmall ? (
              <MobileSearchHeader
                onChangeText={setSearchTerm}
                value={searchTerm}
              />
            ) : null
          }
          renderItem={({ index, item }) => (
            <ListItem
              description={item.description}
              disabled={item.disabled}
              divider={false}
              Icon={item?.Icon as IconComponent}
              key={index}
              label={item.label}
              onPress={(e) => onItemPress(item as Item & EnhancedItem, e)}
              ref={(ref: any) => {
                if (ref !== null) {
                  listItems.current[index] = ref;
                  optionValues.current[index] = item.value;
                }
              }}
            >
              {outerValue.includes((item as Item).value) && (
                <IconCheck color={theme.systemGreen100} />
              )}
            </ListItem>
          )}
          stickyHeaderIndices={searchableOnSmall ? [0] : []}
          style={style.list}
        />
      </Popover>
      <View style={style.pillsContainer}>
        {selectedItems.map((item) => (
          <Button
            Icon={IconClear}
            iconAlign="right"
            key={item}
            minTouchTarget={false}
            onPress={(e) =>
              onItemPress({ value: item } as Item & EnhancedItem, e)
            }
            size="small"
            style={style.pill}
            text={options.find((option) => option.value === item)?.label}
            type="basic"
          />
        ))}
      </View>
    </>
  );
};

export type ItemValueType = Item["value"] & Item["value"][];

interface BaseItem {
  disabled?: boolean;
  Icon?: IconComponent;
  label: string;
  description?: string;
}

interface Item extends BaseItem {
  value: string;
}

interface EnhancedItem extends BaseItem {
  onPress: (e: GestureResponderEvent) => void;
}

interface MultiselectionProps {
  multiselect: true;
  onChange?: (value: Item["value"][]) => void;
  value?: Item["value"][];
}
interface NonMultiselectionProps {
  multiselect?: false;
  onChange?: (value: Item["value"]) => void;
  value?: Item["value"];
}

interface InnerProps
  extends Omit<PickerProps, "enabled" | "onValueChange" | "NewSelectedValue"> {
  background?: ComponentProps<typeof Input>["background"];
  disabled?: boolean;
  clearable?: boolean;
  endOptions?: EnhancedItem[];
  error?: boolean;
  maxDropdownHeight?: number;
  onBlur?: (e?: NativeSyntheticEvent<TargetedEvent>) => void;
  onFocus?: (e?: NativeSyntheticEvent<TargetedEvent>) => void;
  options: Item[];
  placeholder?: string;
  searchable?: boolean;
  size?: ComponentSize;
  style?: StyleProp<ViewStyle>;
  startOptions?: EnhancedItem[];
}

type Props = InnerProps & (MultiselectionProps | NonMultiselectionProps);

export default Select;

export type SelectOptions = Item[];
