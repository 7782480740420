import { canUseDOM } from "./platformUtils";

if (canUseDOM && window?.crypto?.subtle === undefined) {
  console.warn(
    "[crypto] is available only in secure contexts (HTTPS) and some browsers."
  );
}

const importKey = async (data: string): Promise<any> => {
  if (canUseDOM) {
    return crypto.subtle.importKey(
      "raw",
      new TextEncoder().encode(data),
      {
        hash: { name: "SHA-256" },
        name: "HMAC",
      },
      false,
      ["sign"]
    );
  } else {
    console.warn(`[crypto] 'importKey' is unimplemented on Node.js.`);
  }
};

const sign = async (key: any, data: string): Promise<string> => {
  if (canUseDOM) {
    const signature = await crypto.subtle.sign(
      // Which hash function to use is specified in either 'generateKey' or 'importKey' methods.
      "HMAC",
      key,
      new TextEncoder().encode(data)
    );

    // Applies 'forgiving-base64' encoding,
    // i.e., the base64 algorithm defined in section 4 of RFC 4648,
    // which could return '+' and '/' special characters.
    return btoa(
      String.fromCharCode.apply(
        null,
        // We could wrap Uint8Array into an Array.from, but we choose to cast
        // to avoid changing a battle-tested implementation
        new Uint8Array(signature) as unknown as number[]
      )
    );
  } else {
    console.warn(`[crypto] 'sign' is unimplemented on Node.js.`);

    return "";
  }
};

export { importKey, sign };
