import { createContext, useContext, useMemo } from "react";
import { ColorSchemeName, Platform, StyleProp } from "react-native";

import { UseLinkFunction } from "../../types";

import { Theme } from "./Theme";
import { ThemeConfig } from "./ThemeProvider";

const CUSTOM_PROPERTY_PREFIX = "mxm";
const THEME_LOCAL_STORAGE = "theme";

const ThemeContext = createContext<{
  config?: ThemeConfig;
  scheme: ColorSchemeName;
  setScheme: (scheme?: ColorSchemeName) => void;
  useLink: UseLinkFunction;
}>({
  config: undefined,
  scheme: undefined,
  setScheme: () => {},
  useLink: () => {
    throw new Error("Using context before initialization");
  },
});

const themeKeys = <T extends Theme>(theme: T) =>
  Object.keys(theme) as (keyof T)[];

const createCustomProperty = (name: keyof Theme): string =>
  `--${CUSTOM_PROPERTY_PREFIX}-${name}`;

const themeToCustomProperties = (theme: Theme) =>
  themeKeys(theme).reduce((prev, curr) => {
    prev[curr] = `var(${createCustomProperty(curr)})`;

    return prev;
  }, {} as Theme);

const getTheme = (scheme: ColorSchemeName = undefined): Theme => {
  let theme: Theme;

  switch (scheme) {
    case "dark":
      theme = require("../themes/default-theme.dark").default;
      break;
    case "light":
    case null:
    case undefined:
    default:
      theme = require("../themes/default-theme.light").default;
      break;
  }

  /**
   * Only on web, both server and client rendering
   * (otherwise there are hydration issues)
   */
  if (Platform.OS === "web") {
    theme = themeToCustomProperties(theme);
  }

  return theme;
};

const useTheme = <T>(
  styles?: (theme: Theme, styleProps: any) => StyleProp<T>,
  styleProps?: {
    [key: string]: boolean | null | number | string | undefined | Function;
  }
) => {
  const { config, scheme } = useContext(ThemeContext);
  const theme = useMemo(() => getTheme(scheme), [scheme]);
  const style = useMemo(
    () => (styles ? styles(theme, styleProps) : undefined),
    [styles, theme, styleProps]
  ) as T;

  return { config, scheme, style, theme };
};

export {
  createCustomProperty,
  themeKeys,
  ThemeContext,
  useTheme,
  THEME_LOCAL_STORAGE,
};
