import { ProductsGetData } from "@mxmdev/react-universal-core";

import { ArrayOneOrMore, Product, ProductPrice } from "../types";

const productsTransformer = (
  data: ProductsGetData["productsGet"]["body"]
): Product[] => {
  return (
    data?.products.map((prod) => {
      const prices = prod.prices.map((price) => ({
        currencyCode: price.currencyCode,
        internal: {
          platformSku: price.internal.platformSku,
        },
        value: price.value,
      }));

      if (prices.length === 0) {
        throw new Error("A Product should always have at least one price");
      }

      return {
        description: prod.description,
        displayName: prod.displayName,
        id: prod.id,
        internal: {
          paymentType: prod.internal.paymentType,
          platformSku: prod.internal.platformSku,
        },
        prices: prices as ArrayOneOrMore<ProductPrice>,
      };
    }) ?? []
  );
};

export default productsTransformer;
