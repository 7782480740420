import {
  Children,
  cloneElement,
  ComponentProps,
  isValidElement,
  ReactElement,
  ReactNode,
  PropsWithChildren,
} from "react";
import { View } from "react-native";

import { Text } from "..";
import { useTheme } from "../../styles";
import { ListItem } from "../List";

import styles from "./styles";

const ItemDescription = ({
  align,
  children,
  color,
  endEnhancer,
  numberOfLines,
  startEnhancer,
  type,
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(styles, { align });

  return (
    <View style={[style.container, style.descriptionContainer]}>
      {startEnhancer && (
        <View style={style.startEnhancer}>{startEnhancer}</View>
      )}
      {typeof children === "string" ? (
        <Text
          align={align}
          color={color || theme.contentSecondary}
          numberOfLines={numberOfLines}
          type={type}
        >
          {children}
        </Text>
      ) : (
        <View style={style.children}>
          {Children.map(children, (child) => {
            if (!isValidElement(child)) {
              return null;
            }

            return cloneElement(
              child as ReactElement<ComponentProps<typeof Text>>,
              {
                align,
                color: color || theme.contentSecondary,
                numberOfLines,
                type: "bodyDefault",
              }
            );
          })}
        </View>
      )}
      {endEnhancer && <View style={style.endEnhancer}>{endEnhancer}</View>}
    </View>
  );
};

export interface Props
  extends Pick<
    ComponentProps<typeof Text>,
    "align" | "numberOfLines" | "type"
  > {
  children: ReactNode | ReactNode[];
  color?: string;
  endEnhancer?: ReactNode;
  startEnhancer?: ReactNode;
}

export default ItemDescription;
