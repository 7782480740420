import { MIXPANEL_INGESTION_API_EU } from "./config";
import { MixpanelAnalytics, MixpanelConfig } from "./types";

const VOID_PROVIDER: MixpanelAnalytics = {
  clearUser: () => {},
  logEvent: () => {},
  setUser: () => {},
};

const DISABLED_PROVIDER: MixpanelAnalytics = {
  clearUser: () => {
    console.warn(
      "[Analytics] Mixpanel user not reset. Mixpanel is not available."
    );
  },
  logEvent: (eventName, properties) => {
    console.warn(
      `[Analytics] Mixpanel event '${eventName}' (params: ${JSON.stringify(
        properties
      )}) dropped. Mixpanel is not available.`
    );
  },
  setUser: (user) => {
    console.warn(
      `[Analytics] Mixpanel user with id '${user.user_id}' not set. Mixpanel is not available.`
    );
  },
};

const analytics = (() => {
  let client: MixpanelAnalytics;

  const init = (config?: MixpanelConfig): MixpanelAnalytics => {
    if (__DEV__) {
      return VOID_PROVIDER;
    }

    if (!config || !config.projectToken) {
      console.warn(
        "[Analytics] Mixpanel: Not able to initialize due to missing/incomplete config",
        config
      );

      return DISABLED_PROVIDER;
    }

    try {
      const mixpanel: import("mixpanel-browser").OverridedMixpanel = require("mixpanel-browser");

      const { projectToken } = config;

      mixpanel.init(projectToken, {
        api_host: MIXPANEL_INGESTION_API_EU,
        track_pageview: true,
      });

      return {
        clearUser: () => {
          mixpanel.reset();
        },
        logEvent: (eventName, properties) => {
          mixpanel.track(eventName, properties);
        },
        setUser: (user) => {
          const distinctId = user.user_id;

          mixpanel.identify(distinctId);

          mixpanel.people.set({
            $created: user.user_creation_date,
            $email: user.profile.email,
            $first_name: user.profile.first_name,
            $last_name: user.profile.last_name,
            $name: user.profile.name,
            user_id: distinctId,
          });

          // Remove the legacy properties
          mixpanel.people.unset([
            "created",
            "email",
            "first_name",
            "last_name",
            "name",
          ]);
        },
      };
    } catch {
      console.error(
        "[Analytics] You are trying to use mixpanel-browser but it is not installed."
      );

      return VOID_PROVIDER;
    }
  };

  return {
    get: (config?: MixpanelConfig): MixpanelAnalytics => {
      if (client === undefined) {
        client = init(config);
      }

      return client;
    },
  };
})();

export { analytics };
