import useLocalValidatedData from "../../context/persistence/useLocalValidatedData";
import { LocaleInfo } from "../../locale/types";

import { schema, reader } from "./schema";

import { USER_PREFERRED_LOCALE_KEY } from ".";

const usePreferredLocale = (): {
  locale?: LocaleInfo;
  isLoading: boolean;
  error?: unknown;
  retry: () => void;
} => {
  const {
    data: locale,
    error,
    isLoading,
    retry,
  } = useLocalValidatedData(USER_PREFERRED_LOCALE_KEY, schema, reader);

  return {
    error,
    isLoading,
    locale,
    retry,
  };
};

export default usePreferredLocale;
