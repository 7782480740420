import Clipboard from "@react-native-clipboard/clipboard";

export const copyToClipboard = (value: string): void => {
  return Clipboard.setString(value);
};

export const pasteFromClipboard = (): Promise<string> => {
  return Clipboard.getString();
};

export const useClipboard = () => {
  return [Clipboard.getString, Clipboard.setString];
};
