import { z } from "zod";

export const entitySchema = z.object({
  descriptions: z.optional(
    z
      .object({
        en: z.optional(
          z.object({
            value: z.string(),
          })
        ),
      })
      .catchall(z.object({ value: z.string() }))
  ),
  id: z.string(),
  imageinfo: z.optional(
    z.object({
      extmetadata: z.object({
        Artist: z.optional(z.object({ value: z.string() })),
        AttributionRequired: z.optional(z.object({ value: z.string() })),
        LicenseShortName: z.object({ value: z.string() }),
        LicenseUrl: z.optional(z.object({ value: z.string() })),
      }),
    })
  ),
  labels: z
    .object({
      en: z.object({
        value: z.string(),
      }),
    })
    .catchall(z.object({ value: z.string() })),
  sitelinks: z.optional(
    z
      .object({
        enwiki: z.optional(
          z.object({
            url: z.string(),
          })
        ),
      })
      .catchall(
        z.object({
          url: z.string(),
        })
      )
  ),
  thumbnail: z.optional(z.object({ source: z.string() })),
  wikipedia: z.optional(
    z
      .object({
        enwiki: z.optional(
          z.object({
            articlePage: z.object({
              text: z.string(),
            }),
          })
        ),
      })
      .catchall(
        z.object({
          articlePage: z.object({
            text: z.string(),
          }),
        })
      )
  ),
});
