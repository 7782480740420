import { createRESTMutation, createRESTQuery } from "../utils/rest";

export const entitlementsGet = createRESTQuery({
  name: "entitlementsGet",
  path: "payment-system/v1/consumer/entitlements",
});

export const adminEntitlementsGet = createRESTQuery({
  name: "entitlementsGet",
  parameters: ["userId"],
  path: "admin-payment-system/entitlements",
});

export const productsGet = createRESTQuery({
  name: "productsGet",
  parameters: ["platform"],
  path: "payment-system/v1.1/consumer/products",
});

export const checkoutPost = createRESTMutation({
  name: "checkoutPost",
  path: "payment-system/v1/consumer/checkout",
});

export const consumablesGet = createRESTQuery({
  name: "consumablesGet",
  path: "payment-system/v1/consumer/consumables",
});

export const consumerInfoGet = createRESTQuery({
  name: "consumerInfoGet",
  path: "payment-system/v1/consumer/info",
});

export const linksPost = createRESTMutation({
  name: "linksPost",
  path: "payment-system/links",
});

export const consumableBundlesGet = createRESTQuery({
  name: "consumableBundlesGet",
  parameters: ["productId"],
  path: "payment-system/v1/admin/consumables/bundles",
});

export const consumableServicesGet = createRESTQuery({
  name: "consumableServicesGet",
  parameters: ["productId"],
  path: "payment-system/v1/admin/consumables",
});

export const createConsumableBundlePost = createRESTMutation({
  name: "createConsumableBundlesPost",
  path: "payment-system/v1/admin/consumables/bundles",
});

export const createConsumableServicePost = createRESTMutation({
  name: "createConsumableServicePost",
  path: "payment-system/v1/admin/consumables",
});

export const grantUserConsumablesPost = createRESTMutation({
  name: "grantUserConsumablesPost",
  parameters: ["userId"],
  path: "payment-system/v1/admin/consumables/grant",
});

export const listUserConsumablesGet = createRESTQuery({
  name: "listUserConsumablesGet",
  parameters: ["productId", "userId"],
  path: "admin-payment-system/v1/admin/consumables/user",
});

export const updateConsumableBundlePost = createRESTMutation({
  name: "updateConsumableBundlePost",
  path: "payment-system/v1/admin/consumables/bundles/{args.id}",
  urlParameters: ["id"],
});

export const updateConsumableServicePost = createRESTMutation({
  name: "updateConsumableServicePost",
  path: "payment-system/v1/admin/consumables/{args.id}",
  urlParameters: ["id"],
});
