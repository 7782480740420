import { Platform, ViewStyle } from "react-native";

import { textStyles, viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";
import { Align } from "../Grid/GridItem";

const styles = (_: Theme, { align = "left" }: { align: Align }) => {
  const labelAlignment: Record<
    Align,
    ViewStyle["justifyContent"] & ViewStyle["alignItems"]
  > = {
    center: "center",
    left: "flex-start",
    right: "flex-end",
  };

  return {
    ...textStyles({
      link: {
        textDecorationLine: "none",
      },
    }),
    ...viewStyles({
      children: {
        flex: 1,
        flexDirection: "row",
      },
      container: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: labelAlignment[align],
      },
      descriptionContainer: {
        flex: Platform.OS === "web" ? 1 : undefined,
      },
      endEnhancer: {
        marginLeft: global.spacing * 2,
      },
      outerContainer: {
        flexDirection: "row",
      },
      startEnhancer: {
        marginRight: global.spacing,
      },
      textContainer: {
        flexShrink: 1,
      },
    }),
  };
};

export default styles;
