import { pad } from "../../..";
import { importKey, sign } from "../../../utils";

let signKey: string | undefined = undefined;

export const signURL = async (
  urlString: string,
  clientKey: string
): Promise<string> => {
  const url = new URL(urlString);

  if (!signKey) {
    signKey = await importKey(clientKey);
  }

  const date = new Date();
  const year: string = date.getUTCFullYear().toString();
  const month: string = pad(date.getUTCMonth() + 1);
  const day: string = pad(date.getUTCDate());

  url.searchParams.delete("signature");
  url.searchParams.delete("signature_protocol");

  const signature: string = await sign(
    signKey,
    url.toString() + year + month + day
  );

  url.searchParams.set("signature", signature);
  url.searchParams.set("signature_protocol", "sha256");

  return url.toString();
};

/**
 * Given a URL string,
 * encodes its query string by replacing '%20' with '+'
 * (see 'application/x-www-form-urlencoded' format).
 */
export const encodeURL = (urlString: string): string => {
  const url = new URL(urlString);

  url.search = "?" + url.searchParams.toString();

  return url.toString();
};
