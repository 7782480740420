import { SyncedSectionWithoutTopics, UnsyncedSection } from "../types";

export const collapseAdsSections = <
  T extends UnsyncedSection | SyncedSectionWithoutTopics
>(
  sections: readonly T[]
): T[] => {
  const collapseAdsSections: T[] = [];

  for (let i = 0; i < sections.length; i++) {
    if (sections[i].isAds) {
      let currentSection: T = sections[i];

      while (i < sections.length) {
        if (sections[i + 1]?.isAds) {
          i++;
        } else {
          break;
        }

        const section = sections[i];

        currentSection = {
          ...currentSection,
          endPosition: sections[i].endPosition,
          ...(section.variant === "unsynced"
            ? { referenceEndTime: section.referenceEndTime }
            : { endTime: section.endTime }),
          transcript: currentSection.transcript + " " + sections[i].transcript,
        };
      }

      collapseAdsSections.push(currentSection);
    } else {
      collapseAdsSections.push(sections[i]);
    }
  }

  return collapseAdsSections;
};
