type Handler = () => void;

const handlers: Record<string, Handler[]> = {};

export const subscribeForChangeEvents = (
  key: string,
  handler: Handler
): void => {
  if (handlers[key] !== undefined) {
    handlers[key] = [
      ...handlers[key].filter((_handler) => _handler !== handler),
      handler,
    ];
  } else {
    handlers[key] = [handler];
  }
};

export const unsubscribeForChangeEvents = (
  key: string,
  handler: Handler
): void => {
  if (handlers[key] !== undefined) {
    handlers[key] = handlers[key].filter((_handler) => _handler !== handler);
  }
};

export const notifyChangeEvent = (key: string): void => {
  if (handlers[key] !== undefined) {
    handlers[key].forEach((handler) => handler());
  }
};
