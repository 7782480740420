import { Path } from "react-native-svg";

import createIcon from "../createIcon";

export const IconVerified = createIcon(
  <>
    <Path d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z" />
    <Path
      d="M16.679 9.112a1.048 1.048 0 0 1 0 1.51l-4.65 4.531a1.117 1.117 0 0 1-1.55 0l-2.325-2.264a1.05 1.05 0 0 1 0-1.511 1.117 1.117 0 0 1 1.55 0l1.55 1.51 3.875-3.776a1.119 1.119 0 0 1 1.55 0z"
      fill="#ffffff"
    />
  </>
);
