import { ComponentProps, forwardRef, useRef } from "react";
import { View } from "react-native";

import Animated from "../Animated";
import Hoverable from "../Hoverable";
import { Props as HoverableProps } from "../Hoverable/Hoverable";
import Pressable from "../Pressable";
import { Props as PressableProps } from "../Pressable/Pressable";

import ListItem from "./ListItem";

const EnhancedListItem = forwardRef<View, Props>(
  (
    {
      alignVertical,
      children,
      condensed,
      containerStyle,
      description,
      descriptionColor,
      descriptionEndEnhancer,
      descriptionNumberOfLines,
      descriptionStartEnhancer,
      divider,
      Icon,
      iconColor,
      iconContainerStyle,
      ignoreHorizontalSpacing,
      image,
      imageShape,
      imageSize,
      inverted,
      label,
      labelColor,
      labelEndEnhancer,
      labelExtra,
      labelExtraColor,
      labelExtraNumberOfLines,
      labelNumberOfLines,
      labelStartEnhancer,
      labelType,
      onHover,
      onPress,
      shape,
      style,
      to,
      wrapped,
      ...otherProps
    },
    ref
  ) => {
    const hoverAnimatedValue = useRef(new Animated.Value(0)).current;
    const pressAnimatedValue = useRef(new Animated.Value(0)).current;

    return (
      <Hoverable
        animatedValue={hoverAnimatedValue}
        isAnimationDisabled={!(onHover || onPress || to)}
        onHover={onHover}
      >
        <Pressable
          {...otherProps}
          animatedValue={pressAnimatedValue}
          isAnimationDisabled={!(onPress || to)}
          onPress={onPress}
          ref={ref}
          to={to}
        >
          <ListItem
            alignVertical={alignVertical}
            condensed={condensed}
            containerStyle={containerStyle}
            description={description}
            descriptionColor={descriptionColor}
            descriptionEndEnhancer={descriptionEndEnhancer}
            descriptionNumberOfLines={descriptionNumberOfLines}
            descriptionStartEnhancer={descriptionStartEnhancer}
            divider={divider}
            hoverAnimatedValue={hoverAnimatedValue}
            Icon={Icon}
            iconColor={iconColor}
            iconContainerStyle={iconContainerStyle}
            ignoreHorizontalSpacing={ignoreHorizontalSpacing}
            image={image}
            imageShape={imageShape}
            imageSize={imageSize}
            inverted={inverted}
            label={label}
            labelColor={labelColor}
            labelEndEnhancer={labelEndEnhancer}
            labelExtra={labelExtra}
            labelExtraColor={labelExtraColor}
            labelExtraNumberOfLines={labelExtraNumberOfLines}
            labelNumberOfLines={labelNumberOfLines}
            labelStartEnhancer={labelStartEnhancer}
            labelType={labelType}
            pressAnimatedValue={pressAnimatedValue}
            shape={shape}
            style={style}
            wrapped={wrapped}
          >
            {children}
          </ListItem>
        </Pressable>
      </Hoverable>
    );
  }
);

export interface Props
  extends Omit<ComponentProps<typeof ListItem>, "children">,
    HoverableProps,
    PressableProps {}

export default EnhancedListItem;
