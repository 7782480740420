import { useEffect, useRef, useState, useMemo } from "react";
import {
  Animated,
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
} from "react-native";
import {
  PanGestureHandlerGestureEvent,
  PanGestureHandlerStateChangeEvent,
  State,
} from "react-native-gesture-handler";

import {
  bottomSheetDuration,
  bottomSheetEasing,
} from "../components/BottomSheet/styles";
import { useMediaQuery } from "../styles/index";
import { Measures } from "../styles/styleUtils";

const VELOCITY_THRESHOLD = Platform.OS === "web" ? 1 : 500;

/**
 * This is a particular hook used by the Modal and Popover components in order to animate them and handle their bottomsheet's gestures.
 * @prop {Animated.Value} overlayOpacity - Backdrop opacity in mobile.
 * @prop {Animated.Value} windowOpacity - Backdrop opacity in desktop.
 * @prop {Measures} bottomSheetLayout - Measurement for the bottomsheet node.
 * @prop {boolean} visible
 * @prop {boolean} rendered
 * @prop {boolean} fullscreen
 * @prop {boolean} onDismiss
 */
const useAnimatedOverlays = ({
  overlayOpacity,
  windowOpacity,
  visible,
  rendered: [rendered, setRendered],
  fullscreen,
  onDismiss,
  bottomSheetLayout,
  isPositionCalculated,
}: Props) => {
  const panGestureRef = useRef();
  const scrollRef = useRef(null);
  const [enabled, setEnabled] = useState(true);
  const { isSmallScreen } = useMediaQuery();
  const bottomSheetHeight = useRef(
    fullscreen ? Dimensions.get("window").height : 360
  );
  const translateY = useRef(
    new Animated.Value(Dimensions.get("window").height)
  ).current;

  if (!fullscreen && bottomSheetLayout?.height) {
    bottomSheetHeight.current = bottomSheetLayout.height;
  }

  const DISTANCE_THRESHOLD = useMemo(
    () => (bottomSheetLayout ? bottomSheetLayout.height / 2 : 120),
    [bottomSheetLayout]
  );

  const onScroll = ({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (nativeEvent.contentOffset.y <= 0 && !enabled) {
      setEnabled(true);
    }

    if (nativeEvent.contentOffset.y > 0 && enabled) {
      setEnabled(false);
    }
  };

  const onSwipeDown =
    (alwaysEnabled?: boolean) =>
    ({ nativeEvent }: PanGestureHandlerGestureEvent) => {
      if (!enabled && !alwaysEnabled) return;

      const { translationY } = nativeEvent;

      if (translationY > 0) {
        translateY.setValue(translationY);
        overlayOpacity.setValue(1 - translationY / 1000);
      }
    };

  const onHandlerStateChange = ({
    nativeEvent,
  }: PanGestureHandlerStateChangeEvent) => {
    const {
      state,
      translationY,
      velocityX: xvel,
      velocityY: yvel,
    } = nativeEvent;

    if (state === State.END) {
      if (
        (Math.abs(xvel) < Math.abs(yvel) && yvel > VELOCITY_THRESHOLD) ||
        translationY >= DISTANCE_THRESHOLD
      ) {
        onDismiss?.();
      } else {
        Animated.parallel([
          Animated.timing(overlayOpacity, {
            duration: 100,
            toValue: 1,
            useNativeDriver: Platform.OS !== "web",
          }),
          Animated.timing(translateY, {
            duration: 100,
            toValue: 0,
            useNativeDriver: Platform.OS !== "web",
          }),
        ]).start();
      }
    }
  };

  useEffect(() => {
    let animation: Animated.CompositeAnimation;

    const overlayDuration = isSmallScreen
      ? bottomSheetDuration
      : windowDuration;

    const animateOut = Animated.parallel([
      Animated.timing(overlayOpacity, {
        duration: overlayDuration,
        toValue: 0,
        useNativeDriver: Platform.OS !== "web",
      }),
      Animated.timing(windowOpacity, {
        duration: windowDuration,
        toValue: 0,
        useNativeDriver: Platform.OS !== "web",
      }),
      Animated.timing(translateY, {
        duration: bottomSheetDuration,
        easing: bottomSheetEasing,
        toValue: bottomSheetHeight.current,
        useNativeDriver: Platform.OS !== "web",
      }),
    ]);

    const animateIn = Animated.parallel([
      Animated.timing(overlayOpacity, {
        duration: overlayDuration,
        toValue: 1,
        useNativeDriver: Platform.OS !== "web",
      }),
      Animated.timing(windowOpacity, {
        duration: windowDuration,
        toValue: 1,
        useNativeDriver: Platform.OS !== "web",
      }),
      Animated.timing(translateY, {
        duration: bottomSheetDuration,
        easing: bottomSheetEasing,
        toValue: 0,
        useNativeDriver: Platform.OS !== "web",
      }),
    ]);

    if (visible) {
      if (rendered && isPositionCalculated) {
        animation = animateIn;
        animation.start();
      } else {
        setRendered(true);
      }
    } else {
      if (rendered) {
        animation = animateOut;
        animation.start(() => setRendered(false));
      }
    }

    return () => {
      animation?.stop();
    };
  }, [
    isSmallScreen,
    windowOpacity,
    overlayOpacity,
    rendered,
    visible,
    setRendered,
    fullscreen,
    translateY,
    isPositionCalculated,
  ]);

  return {
    gestureHandlerProps: {
      // See waitFor
      gestureEnabled: Platform.OS === "web" ? false : enabled,
      onHandlerStateChange,
      onSwipeDown,
      panGestureRef,
    },
    scrollViewProps: {
      bounces: false,
      onScroll,
      overScrollMode: "never" as const,
      ref: scrollRef,
      scrollEventThrottle: 16, // If not set, the onScroll handler will fire only once on web
      // On web, swipe down from content doesn't work due to rngh issues
      waitFor:
        Platform.OS === "web" ? undefined : enabled ? panGestureRef : scrollRef,
    },
    translateY,
  };
};

export default useAnimatedOverlays;

export type SheetScrollViewProps = ReturnType<
  typeof useAnimatedOverlays
>["scrollViewProps"];

export type GestureHandlerProps = ReturnType<
  typeof useAnimatedOverlays
>["gestureHandlerProps"];

export const windowDuration = 100;

interface Props {
  overlayOpacity: Animated.Value;
  windowOpacity: Animated.Value;
  visible: boolean;
  rendered: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  bottomSheetLayout: Measures | null;
  fullscreen: boolean;
  onDismiss?: () => void;
  isPositionCalculated: boolean;
}
