import { textStyles } from "../../../styles/styleUtils";
import { Theme } from "../../../styles/themes";
import typography from "../../../styles/typography";

const styles = (theme: Theme) => ({
  ...textAlignStyles,
  ...textTypeStyles,
});

export default styles;

export const textAlignStyles = textStyles({
  alignCenter: { textAlign: "center" },
  alignJustify: { textAlign: "justify" },
  alignLeft: { textAlign: "left" },
  alignRight: { textAlign: "right" },
});

export const textTypeStyles = textStyles({
  bodyDefault: typography.bodyDefault,
  bodyExtraLarge: typography.bodyExtraLarge,
  bodyExtraSmall: typography.bodyExtraSmall,
  bodyLarge: typography.bodyLarge,
  bodySmall: typography.bodySmall,
  displayDefault: typography.displayDefault,
  headingDefault: typography.headingDefault,
  headingLarge: typography.headingLarge,
  headingSmall: typography.headingSmall,
  labelDefault: typography.labelDefault,
  labelSmall: typography.labelSmall,
});
