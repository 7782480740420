import { canUseDOM, userAgentIsBot } from "@mxmdev/react-universal-core";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import { useCallback, useEffect, useMemo } from "react";
import { z } from "zod";

import { AuthError, NotFoundError } from "../../types/errors";
import { redirectTo503 } from "../../utils/redirectTo503";
import useDelayed from "../useDelayed";
import useTransformation from "../useTransformation";

import useMXMSWR from "./useMXMSWR";

export declare type TypeOf<T extends z.ZodType<unknown>> = T["_output"];

type MutateFn<T extends z.ZodRawShape> = (
  data: z.infer<z.ZodObject<T>>,
  revalidate?: boolean
) => Promise<z.infer<z.ZodObject<T>> | undefined>;

/**
 * Wrapper around useSWR to automatically handle:
 * - Fetching
 * - Parsing/Validation (with zod)
 * - Transformation
 * - Authentication
 */
const useValidatedQuery = <T extends z.ZodRawShape, OutputType>(
  api: string | undefined,
  params: Record<string, unknown> | undefined,
  apiSchema: z.ZodObject<T>,
  transform: (input: TypeOf<z.ZodObject<T>>) => OutputType,
  options?: {
    delay?: number;
    requireAuth?: boolean;
    shouldRetryOnError?: boolean;
  }
): {
  available?: number;
  error?: unknown;
  isLoading: boolean;
  isNotFound: boolean;
  data?: Readonly<OutputType>;
  retry: () => void;
  mutate: MutateFn<T>;
} => {
  const { isLoggedIn } = useAuth();

  const { data, error, mutate } = useMXMSWR<z.infer<z.ZodObject<T>>>(
    options?.requireAuth && !isLoggedIn ? undefined : api,
    params,
    {
      shouldRetryOnError: options?.shouldRetryOnError,
    }
  );

  // When an API fails, we don't want our content to be indexed by the search
  // engines, since it would only be partial.
  // Instead, we redirect to a 503 page and it will show as an error in the
  // Google Search Console.
  useEffect(() => {
    if (
      error &&
      !(error instanceof NotFoundError) &&
      canUseDOM &&
      userAgentIsBot(navigator.userAgent)
    ) {
      redirectTo503();
    }
  }, [error]);

  const { data: delayedData } = useDelayed(data, options?.delay ?? 0);

  const context = useMemo(() => {
    return { name: api, params };
  }, [api, params]);

  const { data: transformedData, error: validationError } = useTransformation(
    apiSchema,
    transform,
    delayedData?.body,
    context
  );

  const authenticationError = useMemo(
    () =>
      options?.requireAuth && !isLoggedIn
        ? new AuthError("not authenticated")
        : undefined,
    [isLoggedIn, options?.requireAuth]
  );

  const isNotFound =
    error instanceof NotFoundError || validationError instanceof NotFoundError;

  const mutateWrapper: MutateFn<T> = useCallback(
    (body, revalidate) => {
      return mutate({ available: data?.available, body }, revalidate).then(
        (result) => result?.body
      );
    },
    [data, mutate]
  );

  return {
    available: delayedData?.available,
    data: transformedData,
    // We don't include validationError here because there isn't any point
    // in showing to the user that we messed up the validation. We just log
    // the error on Sentry and return undefined data.
    error: error || authenticationError,
    isLoading: !delayedData && !error,
    isNotFound,
    mutate: mutateWrapper,
    retry: mutate,
  };
};

export default useValidatedQuery;
