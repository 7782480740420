import { viewStyles, useTheme } from "@mxmdev/react-universal-components";
import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  LegacyRef,
  PropsWithChildren,
} from "react";
import { View, ViewStyle, StyleProp } from "react-native";

import { createStyles } from "../../utils";

const ANIMATION_DURATION = 200;

export const ConditionalView = forwardRef<View, PropsWithChildren<Props>>(
  (
    {
      children,
      keepContainer,
      moveToBackWhenHidden = false,
      show,
      slideAmount = 30,
      style: styleProp,
    },
    ref
  ): JSX.Element => {
    const { style } = useTheme(styles, { slideAmount });
    const [shouldShow, setShouldShow] = useState(show);

    const animationTimer = useRef<number | undefined>(undefined);

    // Fade out
    useEffect(() => {
      if (show) {
        return;
      }

      if (animationTimer.current) {
        clearTimeout(animationTimer.current);
      }

      // @ts-ignore
      animationTimer.current = setTimeout((): void => {
        setShouldShow(false);
      }, ANIMATION_DURATION);

      return (): void => {
        clearTimeout(animationTimer.current);
      };
    }, [show]);

    // Fade in
    useEffect(() => {
      if (!show) {
        return;
      }

      setShouldShow(true);
    }, [show]);

    const visibilityStyle = show ? style.show : style.hide;
    // There are times when we want to keep the container (invisible), but we
    // don't want it to block interactions with underlying elements.
    // For that reason, we move it on the back
    const zOrderingStyle =
      !shouldShow && moveToBackWhenHidden ? style.moveBelow : {};

    return (
      <>
        {(shouldShow || keepContainer) && (
          <View
            ref={ref as LegacyRef<View>}
            style={[
              styleProp,
              style.container,
              visibilityStyle,
              zOrderingStyle,
            ]}
          >
            {children}
          </View>
        )}
      </>
    );
  }
);

const styles = createStyles((_, { slideAmount }: { slideAmount: number }) =>
  viewStyles({
    container: {
      // @ts-ignore
      transition: `opacity ${ANIMATION_DURATION}ms ease, transform ${ANIMATION_DURATION}ms ease`,
    },
    hide: {
      opacity: 0,
      transform: [{ translateY: slideAmount }],
    },
    moveBelow: {
      zIndex: -10,
    },
    show: {
      opacity: 1,
      transform: [{ translateY: 0 }],
    },
  })
);

type Props = {
  show: boolean;
  style?: StyleProp<ViewStyle>;
  keepContainer?: boolean;
  moveToBackWhenHidden?: boolean;
  slideAmount?: number;
};
