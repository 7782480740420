import {
  ComponentProps,
  useCallback,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import { FlatList, PanGestureHandler } from "react-native-gesture-handler";

import { useDialogContext, useMediaQuery } from "../..";

import { useBottomSheetGestureContext } from "./BottomSheetGestureProvider";

// TODO due to react-native-gesture-handler v1 limitations
// we can't have "children" or fragments as child of the PanGestureHandler
// for this reason better structures could have been found
// https://github.com/software-mansion/react-native-gesture-handler/issues/711

// This components wraps a FlatList with a PanGestureHandler
// only when is used by a bottomsheet (e.g. only if isSmallScreen)
const BottomSheetFlatListImplementation: ForwardRefRenderFunction<
  FlatList<any>,
  Props
> = (props, ref) => {
  const { isSmallScreen } = useMediaQuery();
  const gestureContext = useBottomSheetGestureContext();
  const { setShards } = useDialogContext();

  const { children } = props;

  const _ref = useCallback(
    (innerRef) => {
      if (innerRef) {
        setShards([innerRef.getNativeScrollRef()]);

        if (ref) {
          if ("current" in ref) {
            ref.current = innerRef;
          } else if (typeof ref === "function") {
            ref(innerRef);
          }
        }
      }
    },
    [ref, setShards]
  );

  if (isSmallScreen && gestureContext !== undefined) {
    const { gestureHandlerProps, panEnabled, scrollViewProps } = gestureContext;
    const { gestureEnabled, onHandlerStateChange, onSwipeDown, panGestureRef } =
      gestureHandlerProps || {};
    const isPanEnabled = panEnabled && !props?.fullscreen;

    return (
      <PanGestureHandler
        activeOffsetY={5}
        enabled={isPanEnabled && gestureEnabled}
        failOffsetY={-5}
        onGestureEvent={onSwipeDown()}
        onHandlerStateChange={onHandlerStateChange}
        ref={panGestureRef}
      >
        <FlatList
          {...props}
          {...scrollViewProps}
          ref={(innerRef) => {
            scrollViewProps.ref.current = innerRef as any;
            _ref(innerRef);
          }}
        >
          {children}
        </FlatList>
      </PanGestureHandler>
    );
  }

  return (
    <FlatList {...props} ref={_ref}>
      {children}
    </FlatList>
  );
};

export const BottomSheetFlatList = forwardRef<FlatList<any>, Props>(
  BottomSheetFlatListImplementation
);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BottomSheetFlatList = FlatList<any>;
export default BottomSheetFlatList;

interface Props extends ComponentProps<typeof FlatList> {
  fullscreen?: boolean;
}
