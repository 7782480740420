// @ts-nocheck
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable */

import { viewStyles, global, Theme } from "@mxmdev/react-universal-components";
import { Platform } from "react-native";

const styles = (
  theme: Theme,
  {
    backgroundColor,
    bordered,
    compact,
    color,
    disabled,
    dotColor,
    image,
    minTouchTarget,
    selected,
    shadowed,
    shape,
    size,
    text,
    type,
  }
) => {
  const basicBackgrounds = {
    black: theme.backgroundSecondary,
    blue: theme.backgroundSecondary,
    brand: theme.backgroundSecondary,
    green: theme.backgroundSecondary,
    grey: theme.backgroundSecondary,
    purple: theme.backgroundSecondary,
    serviceAmazonMusic: theme.backgroundSecondary,
    serviceAppleMusic: theme.backgroundSecondary,
    serviceFacebook: theme.backgroundSecondary,
    serviceInstagram: theme.backgroundSecondary,
    serviceSpotify: theme.backgroundSecondary,
    serviceTwitter: theme.backgroundSecondary,
    systemBlue: theme.backgroundSecondary,
    systemGreen: theme.backgroundSecondary,
    systemMagenta: theme.backgroundSecondary,
    systemRed: theme.backgroundSecondary,
    systemYellow: theme.backgroundSecondary,
    white: theme.backgroundSecondary,
    yellow: theme.backgroundSecondary,
  };
  const dotBorderWidth = 2;
  const dotSizes = {
    // Add this because the border is rendered inside the dot.
    default: 8 + dotBorderWidth * 2,
    extraLarge: 8 + dotBorderWidth * 2,
    extraSmall: 4 + dotBorderWidth * 2,
    large: 8 + dotBorderWidth * 2,
    small: 6 + dotBorderWidth * 2,
  };
  const iconMargin = {
    default: global.spacing * 2,
    extraSmall: global.spacing,
    extraLarge: global.spacing * 2,
    large: global.spacing * 2,
    small: global.spacing * 2,
  };
  const filledBackgrounds = {
    black: theme.backgroundPrimaryInverted,
    blue: theme.paletteBlue100,
    brand: theme.paletteBrand100,
    green: theme.paletteGreen100,
    grey: theme.paletteGrey100,
    purple: theme.palettePurple100,
    serviceAmazonMusic: theme.serviceAmazonMusic,
    serviceAppleMusic: theme.serviceAppleMusic,
    serviceFacebook: theme.serviceFacebook,
    serviceInstagram: theme.serviceInstagram,
    serviceSpotify: theme.serviceSpotify2, // Always use this color for better accessibility
    serviceTwitter: theme.serviceTwitter,
    systemBlue: theme.systemBlue100,
    systemGreen: theme.systemGreen100,
    systemMagenta: theme.systemMagenta100,
    systemRed: theme.systemRed100,
    systemYellow: theme.systemYellow100,
    white: theme.contentPrimaryInvertedStatic,
    yellow: theme.paletteYellow100,
  };
  const hoverBackgrounds = {
    basic: theme.backgroundHover,
    filled: theme.backgroundHoverInverted,
    text: theme.backgroundHover,
  };
  const paddingValues = {
    default: global.spacing * 6,
    extraLarge: global.spacing * 6,
    extraSmall: global.spacing * 3,
    large: global.spacing * 6,
    small: global.spacing * 4,
  };
  const pressBackgrounds = {
    basic: theme.backgroundPress,
    filled: theme.backgroundPressInverted,
    text: theme.backgroundPress,
  };
  const shapes = {
    default: global.radius.default,
    pill: global.radius.large,
    round: null,
  };
  const sizes = {
    default: global.size.default,
    extraLarge: 70,
    extraSmall: global.size.extraSmall,
    large: global.size.large,
    small: global.size.small,
  };
  const backgroundColors = {
    basic: basicBackgrounds[color],
    filled: filledBackgrounds[color],
    text: null,
  };
  const dotBorderColors = {
    // These borders must have the same color defined
    // for the background of the button (`backgroundColors`).
    // If it doesn't exist (e.g. "text" type) we apply a generic
    // primary background.
    basic: basicBackgrounds[color],
    filled: filledBackgrounds[color],
    text: theme.backgroundPrimary,
  };
  const selectedBackgroundColors = {
    basic: filledBackgrounds[color],
    filled: filledBackgrounds[color],
    text: basicBackgrounds[color],
  };
  const borderRadius =
    // NOTE: button height is used to have rounded shape
    shape === "round" ? sizes[size] : shapes[shape];
  const getBackground = (
    color,
    disabled: boolean,
    selected: boolean,
    theme,
    type
  ) => {
    if (disabled) {
      return theme.backgroundSecondary;
    }

    if (selected) {
      return selectedBackgroundColors[type];
    }

    return backgroundColors[type];
  };
  const getDotBorderColors = (
    disabled: boolean,
    selected: boolean,
    theme,
    type
  ) => {
    if (disabled) {
      return theme.backgroundSecondary;
    }

    if (selected) {
      return selectedBackgroundColors[type];
    }

    return dotBorderColors[type];
  };
  const getHoverBackground = (color: string, type: string) => {
    // The hovered backgroundColor for white button
    // doesn't change in relation to its type
    if (color === "white") {
      return theme.backgroundHover;
    }

    return hoverBackgrounds[type];
  };
  const getPressBackground = (color: string, type: string) => {
    // The pressed backgroundColor for white button
    // doesn't change in relation to its type
    if (color === "white") {
      return theme.backgroundPress;
    }

    return pressBackgrounds[type];
  };

  return viewStyles({
    activityIndicator: {
      bottom: 0,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    container: {
      alignItems: "center",
      backgroundColor:
        backgroundColor ??
        getBackground(color, disabled, selected, theme, type),
      borderColor: bordered ? theme.backgroundSecondary : undefined,
      borderWidth: bordered ? 1 : undefined,
      borderRadius,
      flexDirection: "row",
      height: sizes[size],
      justifyContent: "center",
      // Set horizontal touch target
      // NOTE:
      // our assumption here is that `text` strings make the Button longer
      // than the minimum touch target value for most cases. In the future
      // we could make this feature more accurate by calculating the
      // component width dynamically regardless of its content.
      marginHorizontal:
        minTouchTarget && !text && sizes[size] < global.minimumTouchTarget
          ? (global.minimumTouchTarget - sizes[size]) / 2
          : 0,
      // Set vertical touch target
      marginVertical:
        minTouchTarget && sizes[size] < global.minimumTouchTarget
          ? (global.minimumTouchTarget - sizes[size]) / 2
          : 0,
      // NOTE: aspect ratio is 1:1 when there are icons without text
      paddingHorizontal: !text
        ? (sizes[size] - iconSizes[size]) / 2
        : paddingValues[size],

      paddingLeft: image ? (compact ? 1 : global.spacing * 3) : undefined,

      ...(shadowed &&
        Platform.select({
          android: {
            elevation: 40,
          },
          ios: {
            shadowColor: theme.shadow,
            shadowOffset: {
              height: 0,
              width: 0,
            },
            shadowRadius: 60,
          },
          web: {
            shadowColor: theme.shadow,
            shadowOffset: {
              height: 0,
              width: 0,
            },
            shadowRadius: 60,
          },
        })),
    },
    content: {
      alignItems: "center",
      flexDirection: "row",
    },
    dot: {
      backgroundColor: dotColor ? dotColor : theme.systemRed100,
      borderColor: getDotBorderColors(disabled, selected, theme, type),
      borderRadius: dotSizes[size],
      borderWidth: dotBorderWidth,
      height: dotSizes[size],
      position: "absolute",
      right: 0,
      top: -dotBorderWidth,
      width: dotSizes[size],
      zIndex: 1, // Required on native platforms
    },
    hiddenText: {
      opacity: 0,
    },
    hoverOverlay: {
      backgroundColor: getHoverBackground(color, type),
    },
    leftIcon: {
      marginRight: iconMargin[size],
    },
    overlay: {
      borderRadius,
      bottom: 0,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    pressOverlay: {
      backgroundColor: getPressBackground(color, type),
    },
    rightIcon: {
      marginLeft: iconMargin[size],
    },
    rightIconContent: {
      flexDirection: "row-reverse",
    },
  });
};

export default styles;

export const getColor = (
  color,
  disabled: boolean,
  selected: boolean,
  theme: Theme,
  type
): string => {
  const filledColors = {
    black: theme.contentPrimaryInverted,
    blue: theme.contentPrimaryInvertedStatic,
    brand: theme.contentPrimaryInvertedStatic,
    green: theme.contentPrimaryInvertedStatic,
    grey: theme.contentPrimaryInvertedStatic,
    purple: theme.contentPrimaryInvertedStatic,
    serviceAmazonMusic: theme.contentPrimaryInvertedStatic,
    serviceAppleMusic: theme.contentPrimaryInvertedStatic,
    serviceFacebook: theme.contentPrimaryInvertedStatic,
    serviceInstagram: theme.contentPrimaryInvertedStatic,
    serviceSpotify: theme.contentPrimaryInvertedStatic,
    serviceTwitter: theme.contentPrimaryInvertedStatic,
    systemBlue: theme.contentPrimaryInvertedStatic,
    systemGreen: theme.contentPrimaryInvertedStatic,
    systemMagenta: theme.contentPrimaryInvertedStatic,
    systemRed: theme.contentPrimaryInvertedStatic,
    systemYellow: theme.contentPrimaryInvertedStatic,
    white: theme.contentPrimaryStatic,
    yellow: theme.contentPrimaryInvertedStatic,
  };

  const textColors = {
    black: theme.backgroundPrimaryInverted,
    blue: theme.paletteBlue100,
    brand: theme.paletteBrand100,
    green: theme.paletteGreen100,
    grey: theme.paletteGrey100,
    purple: theme.palettePurple100,
    serviceAmazonMusic: theme.serviceAmazonMusic,
    serviceAppleMusic: theme.serviceAppleMusic,
    serviceFacebook: theme.serviceFacebook,
    serviceInstagram: theme.serviceInstagram,
    serviceSpotify: theme.serviceSpotify2, // Always use this color for better accessibility
    serviceTwitter: theme.serviceTwitter,
    systemBlue: theme.systemBlue100,
    systemGreen: theme.systemGreen100,
    systemMagenta: theme.systemMagenta100,
    systemRed: theme.systemRed100,
    systemYellow: theme.systemYellow100,
    white: theme.contentPrimaryInvertedStatic,
    yellow: theme.paletteYellow100,
  };

  const basicColors = {
    black: theme.contentPrimary,
    blue: theme.contentPrimary,
    brand: theme.contentPrimary,
    green: theme.contentPrimary,
    grey: theme.contentPrimary,
    purple: theme.contentPrimary,
    serviceAmazonMusic: theme.contentPrimary,
    serviceAppleMusic: theme.contentPrimary,
    serviceFacebook: theme.contentPrimary,
    serviceInstagram: theme.contentPrimary,
    serviceSpotify: theme.contentPrimary,
    serviceTwitter: theme.contentPrimary,
    systemBlue: theme.contentPrimary,
    systemGreen: theme.contentPrimary,
    systemMagenta: theme.contentPrimary,
    systemRed: theme.contentPrimary,
    systemYellow: theme.contentPrimary,
    white: theme.contentPrimary,
    yellow: theme.contentPrimary,
  };

  const contentColors = {
    basic: basicColors[color],
    filled: filledColors[color],
    text: textColors[color],
  };

  const selectedColors = {
    basic: filledColors[color],
    filled: filledColors[color],
    text: basicColors[color],
  };

  if (disabled) {
    return theme.contentTertiary;
  }

  if (selected) {
    return selectedColors[type];
  }

  return contentColors[type];
};

export const getTextType = (size: string) => {
  if (size === "extraSmall") {
    return "bodySmall";
  }

  if (size === "small") {
    return "labelSmall";
  }

  return "labelDefault";
};

export const getDescriptionTextType = (size: string) => {
  if (size === "extraSmall") {
    return "bodyExtraSmall";
  }

  if (size === "small") {
    return "bodySmall";
  }

  return "bodySmall";
};

export const iconSizes = {
  default: 24,
  extraLarge: 24,
  extraSmall: 16,
  large: 24,
  small: 20,
};

export const imageSizes = {
  default: 24,
  extraLarge: 48,
  extraSmall: 16,
  large: 36,
  small: 20,
};
