import { StyleProp, ViewStyle } from "react-native";
import Svg from "react-native-svg";

import { useTheme } from "../../styles";

const createIcon = (path: JSX.Element) => {
  return ({ color, size = 24, style }: IconProps) => {
    const { theme } = useTheme();
    const iconColor = color || theme.contentPrimary;

    return (
      <Svg
        fill={iconColor}
        height={size}
        style={style}
        viewBox="0 0 24 24"
        width={size}
      >
        {path}
      </Svg>
    );
  };
};

interface IconProps {
  color?: string;
  size?: number | string;
  style?: StyleProp<ViewStyle>;
}

export type IconComponent = (props: IconProps) => JSX.Element;

export default createIcon;
