import { createContext } from "react";

export const SettingsContext = createContext<SettingsContextProps>(null as any);

export interface SettingsContextProps<TSettings = any> {
  removeSettings: (key: keyof TSettings) => void;
  reset: () => void;
  setSettings: (key: keyof TSettings, value: string) => void;
  settings: TSettings;
}
