import { AnalyticsPlatform } from "@mxmdev/react-universal-core/analytics/types";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import { useMemo } from "react";

const usePodcastsAnalyticsPlatforms = (): AnalyticsPlatform[] => {
  const { isLoggedIn } = useAuth();

  const analyticsPlatforms = useMemo((): AnalyticsPlatform[] => {
    if (isLoggedIn) {
      return ["firebase", "customerio"];
    } else {
      return ["firebase"];
    }
  }, [isLoggedIn]);

  return analyticsPlatforms;
};

export default usePodcastsAnalyticsPlatforms;
