import { Path } from "react-native-svg";

import createIcon from "../createIcon";

export const IconBack = createIcon(
  <Path
    d="M21,12c0,0.621-0.504,1.125-1.125,1.125H7.309l5.485,5.495c0.438,0.438,0.437,1.148-0.001,1.586
  c-0.438,0.438-1.149,0.438-1.587,0l-7.499-7.499c-0.391-0.391-0.391-1.024,0-1.414l7.5-7.5c0.438-0.439,1.149-0.44,1.589-0.003
  c0.442,0.439,0.443,1.154,0.003,1.595l-5.49,5.49h12.566C20.496,10.875,21,11.379,21,12z"
  />
);
