import { ComponentProps, Fragment, useMemo, PropsWithChildren } from "react";
import { AutoFocusInside } from "react-focus-lock";
import { Platform } from "react-native";

import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import BottomSheetScrollView from "../BottomSheet/BottomSheetScrollView";
import { IconComponent, IconColor, IconAlign } from "../Icon";
import Modal from "../Modal";
import { Props as ModalProps } from "../Modal/Modal";

import DialogActions from "./DialogActions";
import DialogCloseButton from "./DialogCloseButton";
import DialogIcon from "./DialogIcon";
import DialogText from "./DialogText";
import styles from "./styles";

/**
 * Basic dialog that renders only icon, text and buttons.
 * @prop {string} closeButton - Show/hides the close button.
 * @prop {string} description - Text displayed in the description area.
 * @prop {string} descriptionAlign - Alignment of the description area's text.
 * @prop {string} note - Text displayed in the note area.
 * @prop {string} noteAlign - Alignment of the note area's text.
 * @prop {boolean} dismissable - Allow/prevents to dismiss the dialog without pressing on the action buttons.
 * @prop {string} Icon - Icon Component to render
 * @prop {string} iconAlign - Alignment of the icon.
 * @prop {function} onDismiss - Action that changes the visibility of the dialog.
 * @prop {function} onPressCloseButton - Action triggered when the close action button is pressed.
 * @prop {function} onPressPrimaryButton - Action triggered when the primary action button is pressed.
 * @prop {function} onPressSecondaryButton - Action triggered when the secondary action button is pressed.
 * @prop {boolean} overlay - Shows/hides the overlay.
 * @prop {string} primaryButtonColor - Color of the primary button.
 * @prop {boolean} primaryButtonIsLoading - Show/hides a loading indicator inside the primary action button.
 * @prop {string} primaryButtonLink - Link opened when the primary action button is pressed.
 * @prop {string} primaryButtonText - Text displayed in the primary action button.
 * @prop {boolean} secondaryButtonIsLoading - Show/hides a loading indicator inside the secondary action button.
 * @prop {string} secondaryButtonLink - Link opened when the secondary action button is pressed.
 * @prop {string} secondaryButtonText - Text displayed in the secondary action button.
 * @prop {string} titleAlign - Alignment of the title area's text.
 * @prop {string} title - Text displayed in the title area.
 * @prop {boolean} visible - Show/hides the dialog.
 */
const Dialog = ({
  closeButton: outerCloseButton,
  description,
  descriptionAlign,
  dismissable = true,
  hasOverlay,
  Icon,
  iconAlign,
  iconColor,
  inlineButtons = false,
  note,
  noteAlign,
  onDismiss = () => {},
  onPressCloseButton,
  onPressPrimaryButton,
  onPressSecondaryButton,
  primaryButtonColor,
  PrimaryButtonIcon,
  primaryButtonIsLoading,
  primaryButtonLink,
  primaryButtonText,
  SecondaryButtonIcon,
  secondaryButtonIsLoading,
  secondaryButtonLink,
  secondaryButtonText,
  stackedButtons = false,
  title,
  titleAlign,
  visible,
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });
  const ActionsFocusContainer =
    Platform.OS === "web" ? AutoFocusInside : Fragment;

  const closeButton = useMemo(() => {
    if (outerCloseButton !== undefined) {
      return outerCloseButton;
    }

    if (!isSmallScreen) {
      return !!dismissable;
    }

    return false;
  }, [dismissable, isSmallScreen, outerCloseButton]);

  return (
    <Modal
      dismissable={dismissable}
      hasOverlay={hasOverlay}
      onDismiss={onDismiss}
      panEnabled={!closeButton && !!dismissable}
      visible={visible}
    >
      {/* TODO until we have RNGH v2 swiping from the close button won't work */}
      {closeButton && (
        <DialogCloseButton
          onPress={() =>
            onPressCloseButton ? onPressCloseButton() : onDismiss()
          }
          style={style.modalSideSpacing}
        />
      )}
      <BottomSheetScrollView style={style.modalSideSpacing}>
        {Icon && (
          <DialogIcon Icon={Icon} iconAlign={iconAlign} iconColor={iconColor} />
        )}
        <DialogText
          description={description}
          descriptionAlign={descriptionAlign}
          note={note}
          noteAlign={noteAlign}
          title={title}
          titleAlign={titleAlign}
        />
        <ActionsFocusContainer>
          <DialogActions
            inline={inlineButtons}
            onPressPrimaryButton={() => onPressPrimaryButton?.()}
            onPressSecondaryButton={() => onPressSecondaryButton?.()}
            primaryButtonColor={primaryButtonColor}
            PrimaryButtonIcon={PrimaryButtonIcon}
            primaryButtonIsLoading={primaryButtonIsLoading}
            primaryButtonLink={primaryButtonLink}
            primaryButtonText={primaryButtonText}
            SecondaryButtonIcon={SecondaryButtonIcon}
            secondaryButtonIsLoading={secondaryButtonIsLoading}
            secondaryButtonLink={secondaryButtonLink}
            secondaryButtonText={secondaryButtonText}
            stacked={stackedButtons}
            style={{ marginTop: 0 }}
          />
        </ActionsFocusContainer>
      </BottomSheetScrollView>
    </Modal>
  );
};

interface Props extends Omit<ModalProps, "children" | "style"> {
  closeButton?: boolean;
  description?: string;
  descriptionAlign?: ComponentProps<typeof DialogText>["descriptionAlign"];
  Icon?: IconComponent;
  iconColor?: IconColor;
  iconAlign?: IconAlign;
  inlineButtons?: ComponentProps<typeof DialogActions>["inline"];
  note?: string;
  noteAlign?: ComponentProps<typeof DialogText>["noteAlign"];
  onPressCloseButton?: () => void;
  onPressPrimaryButton: () => void;
  onPressSecondaryButton?: () => void;
  primaryButtonColor?: ComponentProps<
    typeof DialogActions
  >["primaryButtonColor"];
  PrimaryButtonIcon?: ComponentProps<typeof DialogActions>["PrimaryButtonIcon"];
  primaryButtonIsLoading?: boolean;
  primaryButtonLink?: ComponentProps<typeof DialogActions>["primaryButtonLink"];
  primaryButtonText: string;
  SecondaryButtonIcon?: ComponentProps<
    typeof DialogActions
  >["SecondaryButtonIcon"];
  secondaryButtonIsLoading?: boolean;
  secondaryButtonLink?: ComponentProps<
    typeof DialogActions
  >["secondaryButtonLink"];
  secondaryButtonText?: string;
  stackedButtons?: ComponentProps<typeof DialogActions>["stacked"];
  titleAlign?: ComponentProps<typeof DialogText>["titleAlign"];
  title: string;
}

export default Dialog;
