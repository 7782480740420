import { ApolloClient } from "@apollo/client";
import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { Linking } from "react-native";

import { redirectTo } from "../auth-ssr/client/redirects";
import {
  getCaptchaId,
  getRedirectUrl,
  removeCaptchaId,
  setCaptchaId,
} from "../auth-ssr/client/storage";
import { isResponseOk, queries } from "../data-fetching";
import { CaptchaPostData, CaptchaPostVars } from "../types";
import {
  getOrigin,
  isCaptchaURL,
  parseCaptchaResponse,
  storage,
  parseRedirectUrl,
} from "../utils";

import CaptchaContext from "./CaptchaContext";

const CaptchaProvider = ({
  apolloClient,
  appId,
  appScheme,
  children,
  loadingComponent,
  renderError,
}: PropsWithChildren<Props>) => {
  const [captcha, setCaptcha] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>(null);

  const handleCaptcha = useCallback(
    async (url: string) => {
      setLoading(true);

      const { captchaToken, siteKey } = parseCaptchaResponse(url);

      if (!captchaToken) {
        setLoading(false);

        return setError("cannot retrieve captcha response token");
      }

      try {
        const result = await apolloClient.mutate<
          CaptchaPostData,
          CaptchaPostVars
        >({
          mutation: queries.captchaPost,
          variables: {
            app_id: appId,
            body: { response: captchaToken, site_key: siteKey },
          },
        });

        if (!isResponseOk(result.data?.captchaPost.header.status_code)) {
          throw new Error(
            `error white posting captcha token, status: ${result.data?.captchaPost.header.status_code}`
          );
        }

        const captchaId = result.data?.captchaPost.body?.captcha_id;

        if (!captchaId) {
          throw new Error(`captcha id is empty`);
        }

        await setCaptchaId(storage, captchaId);
        setCaptcha(captchaId);
      } catch (ex) {
        setLoading(false);

        return setError(
          (ex instanceof Error ? ex.message : ex) || "could not post captcha id"
        );
      }

      const redirectUrl =
        (await getRedirectUrl(storage)) ?? parseRedirectUrl(url);

      if (redirectUrl) {
        redirectTo(redirectUrl);
      } else {
        redirectTo(getOrigin(appScheme));
      }
    },
    [apolloClient, appId, appScheme]
  );

  const initialize = useCallback(
    async (event?: { url: string }) => {
      const initialURL = event ? event.url : await Linking.getInitialURL();
      const isReturnFromCaptcha = initialURL && isCaptchaURL(initialURL);

      if (isReturnFromCaptcha) {
        handleCaptcha(initialURL);
      } else {
        const storedCaptchaId = await getCaptchaId(storage);

        if (storedCaptchaId) {
          setCaptcha(storedCaptchaId);
        }

        setLoading(false);
      }
    },
    [handleCaptcha]
  );

  const clear = useCallback(() => {
    removeCaptchaId(storage);
    setCaptcha(undefined);
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    Linking.addEventListener("url", initialize);

    return () => {
      Linking.removeEventListener("url", initialize);
    };
  }, [initialize]);

  if (error) {
    return renderError ? renderError(error) : null;
  }

  if (loading) {
    return loadingComponent;
  }

  return (
    <CaptchaContext.Provider value={{ captchaId: captcha, clear }}>
      {children}
    </CaptchaContext.Provider>
  );
};

interface Props {
  apolloClient: ApolloClient<unknown>;
  appId: string;
  appScheme?: string;
  loadingComponent: ReactElement<any, any> | null;
  renderError?: (error: unknown) => ReactElement<any, any> | null;
}

export default CaptchaProvider;
