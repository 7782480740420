export type PodcastsColorWeight = 100 | 25 | 15 | 6;

export type PodcastsColor = Record<PodcastsColorWeight, string>;

export type PodcastsPalette = Record<
  | "blue"
  | "cyan"
  | "teal"
  | "green"
  | "grey"
  | "yellow"
  | "orange"
  | "pink"
  | "purple",
  PodcastsColor
>;

/* eslint-disable local-rules/sort-keys */
export const podcastsLightPalette: PodcastsPalette = {
  blue: {
    100: "#2433B6",
    25: "#D4DDFE",
    15: "#E6EBFF",
    6: "#F5F7FF",
  },
  cyan: {
    100: "#027BD1",
    25: "#C8E4F9",
    15: "#DEF0FB",
    6: "#F1F8FD",
  },
  teal: {
    100: "#109A79",
    25: "#C5EFE5",
    15: "#DCF5EF",
    6: "#F1FBF9",
  },
  green: {
    100: "#359911",
    25: "#D1EFC5",
    15: "#E3F5DC",
    6: "#F4FBF1",
  },
  grey: {
    100: "#000000",
    25: "#E0E0E0",
    15: "#ECECEC",
    6: "#F8F8F8",
  },
  yellow: {
    100: "#D89411",
    25: "#FBEEC3",
    15: "#FDF5DC",
    6: "#FFFBF1",
  },
  orange: {
    100: "#CF6110",
    25: "#FAD6C3",
    15: "#FCE7DC",
    6: "#FFF5F1",
  },
  pink: {
    100: "#931177",
    25: "#F4C7EB",
    15: "#F9DEF3",
    6: "#FCF2FA",
  },
  purple: {
    100: "#4B17E8",
    25: "#DCCAFA",
    15: "#EADFFC",
    6: "#F8F2FF",
  },
};
