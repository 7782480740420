import { Topic } from "@mxmdev/podcasts-shared";

import { SearchAtom } from "../types";

import { RootStackParamList } from "./types";

export const mapSearchParamsToAtom = (
  params: RootStackParamList["Search"] | undefined
): SearchAtom | undefined => {
  if (params?.q === undefined || params.q.trim() === "") {
    return undefined;
  }

  if (params.type === "topic") {
    return {
      id: params.id,
      text: params.q,
      type: "topic",
    };
  }

  return {
    text: params.q,
    type: "raw",
  };
};

export const mapAtomToSearchParams = (
  atom: SearchAtom | undefined
): RootStackParamList["Search"] => {
  if (!atom) {
    return {
      id: undefined,
      q: undefined,
      type: undefined,
    };
  }

  if (atom.type === "topic") {
    return {
      id: atom.id,
      q: atom.text,
      type: "topic",
    };
  }

  return {
    q: atom.text,
    type: "raw",
  };
};

export const mapTopicToSearchParams = (
  topic: Topic | undefined
): RootStackParamList["Search"] => {
  if (!topic) {
    return {
      id: undefined,
      q: undefined,
      type: undefined,
    };
  }

  if (topic.type === "rich") {
    return {
      id: topic.id,
      q: topic.name,
      type: "topic",
    };
  }

  return {
    q: topic.name,
    type: "raw",
  };
};
