import {
  useLocale,
  useLocalePreferenceSetter,
  supportedCountries,
  LocaleInfo,
  useTheme,
  createStyles,
} from "@mxmdev/podcasts-shared-native";
import { countryIcons } from "@mxmdev/podcasts-shared-native/src/locale/countries";
import {
  Button,
  global,
  Menu,
  MenuItem,
  Text,
  viewStyles,
  IconDropdown,
  IconVerified,
  IconPlaceholder,
} from "@mxmdev/react-universal-components";
import { useCallback, useMemo, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";

const CountrySelector = ({ style: styleProp }: Props): JSX.Element => {
  const { style, theme } = useTheme(styles);

  const { localeInfo } = useLocale();
  const { setLocalePreference } = useLocalePreferenceSetter();

  const currentLocaleFlag = useMemo(
    () => (localeInfo?.country ? countryIcons[localeInfo.country] : "🌎"),
    [localeInfo?.country]
  );

  const [isVisible, setIsVisible] = useState(false);

  const showMenu = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hideMenu = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleSelection = useCallback(
    (locale: LocaleInfo) => {
      setLocalePreference(locale);
      hideMenu();
    },
    [hideMenu, setLocalePreference]
  );

  return (
    <Menu
      anchor={
        <Button
          Icon={IconDropdown}
          iconAlign="right"
          onPress={showMenu}
          shape="round"
          size="small"
          style={styleProp}
          text={currentLocaleFlag}
          type="basic"
        />
      }
      onDismiss={hideMenu}
      visible={isVisible}
    >
      {supportedCountries.map((locale) => (
        <MenuItem
          Icon={
            locale.country === localeInfo?.country
              ? IconVerified
              : IconPlaceholder
          }
          key={locale.country}
          label={locale.countryName}
          onPress={(): void => handleSelection(locale)}
        />
      ))}
      <Text color={theme.contentSecondary} style={style.label} type="bodySmall">
        New countries coming soon...
      </Text>
    </Menu>
  );
};

const styles = createStyles(() =>
  viewStyles({
    label: {
      paddingBottom: global.spacing * 2,
      paddingHorizontal: global.spacing * 4,
      paddingTop: global.spacing * 4,
    },
  })
);

type Props = {
  style: StyleProp<ViewStyle>;
};

export default CountrySelector;
