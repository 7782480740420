import {
  useState,
  useRef,
  useCallback,
  createContext,
  ReactNode,
  useContext,
  useMemo,
} from "react";

import { uniqid } from "../../../utils";

import {
  CustomResponseDialogComponent,
  CustomDialogResponse,
  DialogComponent,
  DialogObj,
  DialogResolution,
  DialogResponse,
  EnhancedDialogProps,
  IDialogContext,
  StandardDialogResponse,
  OpenDialogConfig,
} from "./types";

const DialogContext = createContext<IDialogContext | undefined>(undefined);

const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [shards, setShards] = useState<HTMLElement[]>([]);
  const [dialogs, setDialogs] = useState<DialogObj[]>([]);
  const resolveDialogs = useRef<Record<string, DialogResolution | undefined>>(
    {}
  );

  const resolveDialog = useCallback(
    <CustomResponse,>(
      dialogId: string,
      response: DialogResponse<CustomResponse>
    ) => {
      const resolve = resolveDialogs.current?.[dialogId];

      if (resolve) {
        resolve(response);
        delete resolveDialogs.current?.[dialogId];
      }
    },
    []
  );

  const closeDialog = useCallback(
    <CustomResponse,>(
      dialogId?: string,
      response?: DialogResponse<CustomResponse>
    ) => {
      setDialogs((dialogs) => {
        const id =
          dialogId ||
          dialogs.reverse().find((dialog) => dialog.visible)?.dialogId;

        if (!id || !response) {
          return dialogs;
        }

        resolveDialog(id, response);

        const queuedDialogId = dialogs.find(
          (dialog) => dialog.queued
        )?.dialogId;

        const newDialogs = dialogs.map((dialog) => {
          if (dialog.dialogId === id) {
            return { ...dialog, queued: false, visible: false };
          }

          if (queuedDialogId && dialog.dialogId === queuedDialogId) {
            return { ...dialog, queued: false, visible: true };
          }

          return dialog;
        });

        return newDialogs;
      });
    },
    [resolveDialog]
  );

  const openDialog = useCallback(
    <Props extends object | undefined>(
      UserDialog: DialogComponent<Props>,
      ...props: Props extends object
        ? [Props & OpenDialogConfig]
        : [OpenDialogConfig?]
    ) => {
      const dialogId = uniqid();

      const EnhancedDialog = ({ visible }: EnhancedDialogProps) => {
        return (
          <UserDialog
            {...((props?.[0] ?? {}) as any)}
            onDismiss={() => closeDialog(dialogId, { resolution: "dismiss" })}
            onPressCloseButton={() =>
              closeDialog(dialogId, { resolution: "close" })
            }
            onPressPrimaryButton={() =>
              closeDialog(dialogId, { resolution: "primary" })
            }
            onPressSecondaryButton={() =>
              closeDialog(dialogId, { resolution: "secondary" })
            }
            visible={visible}
          />
        );
      };

      setDialogs((dialogs) => {
        const queued =
          dialogs.some((dialog) => dialog.visible) && !props?.[0]?.stackTop;

        const dialogObj: DialogObj = {
          Component: EnhancedDialog,
          dialogId,
          queued,
          visible: !queued,
        };

        return [
          ...dialogs.filter((dialog) => dialog.visible && !dialog.queued),
          dialogObj,
        ];
      });

      return new Promise<StandardDialogResponse>((resolve) => {
        resolveDialogs.current[dialogId] = resolve as DialogResolution;
      });
    },
    [closeDialog]
  );

  const openCustomResponseDialog: IDialogContext["openCustomResponseDialog"] =
    useCallback(
      <CustomResponse, Props extends object | undefined>(
        UserDialog: CustomResponseDialogComponent<CustomResponse, Props>,
        ...props: Props extends object
          ? [Props & OpenDialogConfig]
          : [OpenDialogConfig?]
      ) => {
        const dialogId = uniqid();

        const EnhancedDialog = ({ visible }: EnhancedDialogProps) => {
          return (
            <UserDialog
              onComplete={(response) =>
                closeDialog(dialogId, { resolution: response })
              }
              onDismiss={() => closeDialog(dialogId, { resolution: undefined })}
              visible={visible}
              {...((props?.[0] ?? {}) as any)}
            />
          );
        };

        setDialogs((dialogs) => {
          const queued =
            dialogs.some((dialog) => dialog.visible) && !props?.[0]?.stackTop;

          const dialogObj: DialogObj = {
            Component: EnhancedDialog,
            dialogId,
            queued,
            visible: !queued,
          };

          return [
            ...dialogs.filter((dialog) => dialog.visible && !dialog.queued),
            dialogObj,
          ];
        });

        return new Promise<CustomDialogResponse<CustomResponse>>((resolve) => {
          resolveDialogs.current[dialogId] = resolve as DialogResolution;
        });
      },
      [closeDialog]
    );

  const providerValue = useMemo(() => {
    return {
      closeDialog,
      dialogs,
      openCustomResponseDialog,
      openDialog,
      setShards,
      shards,
    };
  }, [closeDialog, dialogs, openCustomResponseDialog, openDialog, shards]);

  return (
    <DialogContext.Provider value={providerValue}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => {
  const dialogContext = useContext(DialogContext);

  if (!dialogContext) {
    throw new Error(
      "Error: could not find dialog context value; please ensure the component is wrapped in a <DialogProvider>"
    );
  }

  return dialogContext;
};

export default DialogProvider;
