import { z, TypeOf } from "zod";

import { LocaleInfo } from "../../locale/types";

// Make sure to add only optional fields, otherwise it breaks backward compatibility
export const schema = z.object({
  locale: z.object({
    country: z.string(),
    countryName: z.string(),
    languages: z.array(z.string()),
  }),
});

export const reader = (input: TypeOf<typeof schema>): LocaleInfo => ({
  country: input.locale.country,
  countryName: input.locale.countryName,
  languages: input.locale.languages,
});

export const writer = (locale: LocaleInfo): TypeOf<typeof schema> => ({
  locale: {
    country: locale.country,
    countryName: locale.countryName,
    languages: [...locale.languages],
  },
});
