import { LocaleInfo } from "./types";

export const supportedCountries: readonly LocaleInfo[] = [
  {
    country: "ar",
    countryName: "Argentina",
    languages: ["es", "en"],
  },
  {
    country: "au",
    countryName: "Australia",
    languages: ["en"],
  },
  {
    country: "br",
    countryName: "Brazil",
    languages: ["pt", "en"],
  },
  {
    country: "ca",
    countryName: "Canada",
    languages: ["en"],
  },
  {
    country: "fr",
    countryName: "France",
    languages: ["fr", "en"],
  },
  {
    country: "de",
    countryName: "Germany",
    languages: ["de", "en"],
  },
  {
    country: "it",
    countryName: "Italy",
    languages: ["it", "en"],
  },
  {
    country: "mx",
    countryName: "Mexico",
    languages: ["es", "en"],
  },
  {
    country: "es",
    countryName: "Spain",
    languages: ["es", "en"],
  },
  {
    country: "gb",
    countryName: "United Kingdom",
    languages: ["en"],
  },
  {
    country: "us",
    countryName: "United States",
    languages: ["en"],
  },
];

export const countryIcons: Record<string, string> = {
  ar: "🇦🇷",
  au: "🇦🇺",
  br: "🇧🇷",
  ca: "🇨🇦",
  de: "🇩🇪",
  es: "🇪🇸",
  fr: "🇫🇷",
  gb: "🇬🇧",
  it: "🇮🇹",
  mx: "🇲🇽",
  us: "🇺🇸",
};
