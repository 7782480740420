import { useDialogContext } from "./DialogProvider";

const DialogsOutlet = () => {
  const { dialogs } = useDialogContext();

  return (
    <>
      {dialogs.map(({ Component, dialogId, visible }) => (
        <Component key={dialogId} visible={visible} />
      ))}
    </>
  );
};

export default DialogsOutlet;
