import { memo } from "react";
import * as React from "react";
import { RemoveScroll as RRS } from "react-remove-scroll";

import { useDialogContext } from "../dialogs";

export type RemoveScrollProps = React.ComponentProps<typeof RRS>;

const RemoveScroll = memo((props: RemoveScrollProps) => {
  const { shards } = useDialogContext();

  if (!props.children) return null;

  return <RRS {...props} shards={shards} />;
});

export default RemoveScroll;
