import { ComponentProps } from "react";

import { textStyles, viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";

import Checkbox from "./Checkbox";

interface Props {
  labelType: ComponentProps<typeof Checkbox>["labelType"];
}

const styles = (theme: Theme, { labelType }: Props) => ({
  ...textStyles({
    rightLabel: {
      textAlign: "right",
    },
  }),
  ...viewStyles({
    alignHorizontal: {
      flexDirection: "row",
    },
    checkmark: {
      alignItems: "center",
      backgroundColor: theme.backgroundPrimary,
      borderColor: theme.backgroundTertiary,
      borderRadius,
      borderWidth: 2,
      height: checkmarkSize,
      justifyContent: "center",
      margin: 2,
      width: checkmarkSize,
    },
    checkmarkContainer: {
      alignItems: "center",
      height: checkmarkContainerSize,
      justifyContent: "center",
      width: checkmarkContainerSize,
    },
    checkmarkDisabled: {
      borderColor: theme.backgroundSecondary,
    },
    checkmarkError: {
      borderColor: theme.systemRed100,
    },
    checkmarkFilled: {
      backgroundColor: theme.contentPrimary,
      borderRadius,
      height: checkmarkFilledSize,
      width: checkmarkFilledSize,
    },
    checkmarkFilledDisabled: {
      backgroundColor: theme.contentTertiary,
    },
    container: {
      flexDirection: "row",
    },
    hoverOverlay: {
      backgroundColor: theme.backgroundHover,
    },
    label: {
      marginHorizontal: 2,
    },
    labelContainer: {
      // NOTE: this prevents text overflow
      flex: 1,
      marginTop:
        labelType === "bodySmall" ? global.spacing * 4 : global.spacing * 3,
    },
    overlay: {
      borderRadius: overlaySize,
      height: overlaySize,
      left: overlayOffset,
      position: "absolute",
      top: overlayOffset,
      width: overlaySize,
    },
    pressOverlay: {
      backgroundColor: theme.backgroundPress,
    },
    rightLabelContainer: {
      flexDirection: "row-reverse",
    },
  }),
});

export default styles;

const borderRadius = 2;
const checkmarkContainerSize = global.minimumTouchTarget;
const checkmarkSize = 16;
const checkmarkFilledSize = 10;
const overlaySize = global.size.small;
const overlayOffset = (checkmarkContainerSize - overlaySize) / 2;
