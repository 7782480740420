import {
  collapseAdsSections,
  getEpisodeApiParams,
  SyncedSectionWithoutTopics,
} from "@mxmdev/podcasts-shared";
import { useValidatedQuery, ApiResponse } from "@mxmdev/podcasts-shared-native";
import { TypeOf, z } from "zod";

const API_ENDPOINT = "podcast.episode.analysis.get";

const parser = z.object({
  podcast_episode_analysis: z.object({
    musixmatch: z.record(
      z.string(),
      z.array(
        z.object({
          end_char: z.number(),
          end_time: z.number(),
          is_ads: z.optional(z.boolean()),
          is_adsfinder_ads: z.optional(z.boolean()),
          reference_end_time: z.optional(z.number()),
          reference_start_time: z.optional(z.number()),
          speaker_label: z.string(),
          start_char: z.number(),
          start_time: z.number(),
          text: z.string(),
        })
      )
    ),
  }),
});

const transformer =
  (adsFinderId?: string) =>
  (input: TypeOf<typeof parser>): SyncedSectionWithoutTopics[] => {
    if (!adsFinderId) {
      return [];
    }

    if (!input.podcast_episode_analysis.musixmatch[adsFinderId]) {
      throw new Error(`Missing ${adsFinderId} in response`);
    }

    const sections = input.podcast_episode_analysis.musixmatch[adsFinderId].map(
      (sentence): SyncedSectionWithoutTopics => {
        return {
          endPosition: sentence.end_char,
          endTime: sentence.end_time,
          isAds: sentence.is_ads || sentence.is_adsfinder_ads,
          referenceEndTime: sentence.reference_end_time,
          referenceStartTime: sentence.reference_start_time,
          speakerId: sentence.speaker_label,
          startPosition: sentence.start_char,
          startTime: sentence.start_time,
          transcript: sentence.text,
          variant: "synced",
        };
      }
    );

    return collapseAdsSections(sections);
  };

const useAdsFinderAnalysis = ({
  adsFinderId,
  episodeId,
  podcastId,
}: Params): ApiResponse<readonly SyncedSectionWithoutTopics[], "sections"> => {
  const paramsIds = getEpisodeApiParams(podcastId, episodeId);

  const { data, error, isLoading, retry } = useValidatedQuery(
    adsFinderId !== undefined &&
      episodeId !== undefined &&
      podcastId !== undefined
      ? API_ENDPOINT
      : undefined,
    {
      ...paramsIds,
      analysis_id: "musixmatch",
      part: "ads_finder",
      subpart: adsFinderId,
    },
    parser,
    transformer(adsFinderId)
  );

  return {
    error,
    isLoading,
    retry,
    sections: data,
  };
};

type Params = {
  adsFinderId?: string;
  episodeId?: string;
  podcastId?: string;
};

export default useAdsFinderAnalysis;
