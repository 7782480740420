import { Episode, getReferenceTrackUrl } from "@mxmdev/podcasts-shared";
import { useConfiguration } from "@mxmdev/podcasts-shared-native";

export const useReferenceTrackUrl = (episode?: Episode): string | null => {
  const { apiStream } = useConfiguration();

  if (!episode) {
    return null;
  }

  return getReferenceTrackUrl({ apiStream, episodeId: episode.id });
};
