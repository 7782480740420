import { useContext } from "react";

import CaptchaContext from "./CaptchaContext";

const useCaptchaId = (): string | undefined => {
  const { captchaId } = useContext(CaptchaContext);

  return captchaId;
};

export default useCaptchaId;
