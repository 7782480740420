import { Episode } from "@mxmdev/podcasts-shared";
import { useAnalytics } from "@mxmdev/react-universal-core";
import { useCallback } from "react";

import { episodeToAnalytics } from "../analytics/utils";
import { formatEpisodeAnalytics } from "../types";

export const usePodcastsPlayerAnalytics = () => {
  const { logEvent } = useAnalytics();

  const logPlayEnd = useCallback(
    (episode: Episode, seconds: number) => {
      logEvent("play_end", {
        ...formatEpisodeAnalytics(episodeToAnalytics(episode)),
        time_spent: seconds,
      });
    },
    [logEvent]
  );

  const logTranscriptionClicked = useCallback(
    () => logEvent("transcription_clicked"),
    [logEvent]
  );

  const logVelocitySelected = useCallback(
    (velocity: number) => logEvent("velocity_selected", { count: velocity }),
    [logEvent]
  );

  return {
    logPlayEnd,
    logTranscriptionClicked,
    logVelocitySelected,
  };
};
