import { useConfiguration } from "@mxmdev/podcasts-shared-native";
import { useCallback, useMemo } from "react";

import { ResolvedEpisode } from "../../../../types";
import { unsyncedTranscriptionToSynced } from "../../../../util/transcriptionUtils";

import { SyncState, TranscriptionState } from "./types";
import { useSyncedTranscription } from "./useSyncedTranscription";
import useUnsyncedTranscription from "./useUnsyncedTranscription";

export const useTranscription = ({
  episode,
}: Props): {
  state: TranscriptionState;
  retry: () => void;
} => {
  const { disableAdsFinder } = useConfiguration();

  const { error, isLoading, retry, transcription } = useUnsyncedTranscription({
    episodeId: episode?.id,
    podcastId: episode?.podcastId,
    transcriptionStatus: episode?.transcriptionStatus,
  });

  const {
    error: syncedError,
    isLoading: syncedLoading,
    retry: syncedRetry,
    transcription: syncedTranscription,
  } = useSyncedTranscription({
    episode,
    unsyncedTranscription: transcription,
  });

  const state = useMemo((): TranscriptionState => {
    if (isLoading) {
      return {
        status: "loading",
      };
    }

    if (!transcription && !error && !isLoading) {
      return { status: "not_available" };
    }

    if (error) {
      return {
        error,
        status: "error",
      };
    }

    if (!transcription) {
      return { status: "idle" };
    }

    const syncState = ((): SyncState => {
      if (disableAdsFinder) {
        return {
          status: "loaded",
          transcription: unsyncedTranscriptionToSynced(transcription),
        };
      }

      if (syncedLoading) {
        return {
          status: "loading",
        };
      }

      if (syncedError) {
        return {
          error: syncedError,
          status: "error",
        };
      }

      if (syncedTranscription) {
        return {
          status: "loaded",
          transcription: syncedTranscription,
        };
      }

      return {
        status: "not_loaded",
      };
    })();

    return {
      status: "loaded",
      syncState,
      transcription,
    };
  }, [
    disableAdsFinder,
    error,
    isLoading,
    syncedError,
    syncedLoading,
    syncedTranscription,
    transcription,
  ]);

  const retryAll = useCallback((): void => {
    if (!transcription) {
      retry();
    }

    syncedRetry();
  }, [retry, syncedRetry, transcription]);

  return {
    retry: retryAll,
    state,
  };
};

interface Props {
  episode?: ResolvedEpisode;
}
