import { viewStyles } from "../../styles";

const styles = () =>
  viewStyles({
    container: {
      bottom: 0,
      left: 0,
      maxHeight: "100vh",
      //@ts-ignore
      position: "fixed",
      right: 0,
      top: 0,
    },
  });

export default styles;
