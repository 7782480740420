import { viewStyles } from "@mxmdev/react-universal-components";
import { useEffect, useState } from "react";
import { View } from "react-native";

import { createStyles, useTheme } from "../../utils/themeUtils";
import { SwitchView } from "../animations/SwitchView";

import { LoaderAnimation } from "./LoaderAnimation";
import LoaderBar from "./LoaderBar";
import Quote from "./Quote";
import Retry from "./Retry";

const SHOW_ERROR_SCREEN_AFTER = 30000;

const Loader = ({ disableErrorTimeout }: Props): JSX.Element => {
  const { style } = useTheme(styles);

  const [showError, setShowError] = useState(false);

  const onRetry = (): void => {
    location.reload();
  };

  useEffect(() => {
    if (disableErrorTimeout) {
      return;
    }

    const errorTimeout = setTimeout((): void => {
      setShowError(true);
    }, SHOW_ERROR_SCREEN_AFTER);

    return (): void => clearTimeout(errorTimeout);
  }, [disableErrorTimeout]);

  return (
    <View style={style.container}>
      <LoaderBar />
      <SwitchView
        animationDuration={500}
        components={[
          <>
            <LoaderAnimation />
            <Quote />
          </>,
          <Retry onRetry={onRetry} />,
        ]}
        index={showError ? 1 : 0}
        style={style.switch}
      />
    </View>
  );
};

const styles = createStyles(() =>
  viewStyles({
    container: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    switch: {
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

type Props = {
  disableErrorTimeout?: boolean;
};

export default Loader;
