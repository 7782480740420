import { gql } from "@apollo/client";

import { createRESTQuery } from "../utils/rest";

export const crowdScoreGet = gql`
  query crowdScoreGet(
    $app_id: String!
    $captcha_id: String
    $part: String
    $usertoken: String!
    $uaid: String
  ) {
    crowdScoreGet(
      app_id: $app_id
      captcha_id: $captcha_id
      part: $part
      usertoken: $usertoken
      uaid: $uaid
    ) @rest(type: "CrowdScoreGet", path: "crowd.score.get?{args}") {
      body
      header
    }
  }
`;

export const crowdTrackReportPost = gql`
  mutation crowdTrackReportPost(
    $app_id: String!
    $captcha_id: String
    $commontrack_id: String!
    $description: String
    $feedback_type: String!
    $mission_id: String
    $original_artist: String
    $original_title: String
    $play_time: number
    $task_id: String
    $time_spent: Int
    $usertoken: String!
  ) {
    crowdTrackReportPost(
      input: $body
      params: {
        app_id: $app_id
        captcha_id: $captcha_id
        commontrack_id: $commontrack_id
        description: $description
        feedback_type: $feedback_type
        mission_id: $mission_id
        original_artist: $original_artist
        original_title: $original_title
        play_time: $play_time
        task_id: $task_id
        time_spent: $time_spent
        usertoken: $usertoken
      }
    )
      @rest(
        type: "CrowdTrackReportPost"
        path: "crowd.track.report.post?{args.params}"
        method: "POST"
      ) {
      body
      header
    }
  }
`;

export const crowdAiQuestionPost = gql`
  mutation crowdAiQuestionPost(
    $app_id: String!
    $captcha_id: String
    $track_id: String!
    $commontrack_id: String
    $question_id: String!
    $answer_id: String!
    $description: String
    $energy_ratio: Int!
    $mood_ratio: Int!
    $usertoken: String!
  ) {
    crowdAiQuestionPost(
      input: $body
      params: {
        app_id: $app_id
        captcha_id: $captcha_id
        track_id: $track_id
        commontrack_id: $commontrack_id
        question_id: $question_id
        answer_id: $answer_id
        description: $description
        energy_ratio: $energy_ratio
        mood_ratio: $mood_ratio
        usertoken: $usertoken
      }
    )
      @rest(
        type: "CrowdAiQuestionPost"
        path: "crowd.ai.question.post?{args.params}"
        method: "POST"
      ) {
      body
      header
    }
  }
`;

export const crowdTranslationGet = gql`
  query crowdTranslationGet(
    $app_id: String!
    $commontrack_id: String
    $commontrack_vanity_id: String
    $usertoken: String!
    $selected_language: String!
    $translation_fields_set: String!
  ) {
    crowdTranslationGet(
      app_id: $app_id
      commontrack_id: $commontrack_id
      commontrack_vanity_id: $commontrack_vanity_id
      usertoken: $usertoken
      selected_language: $selected_language
      translation_fields_set: $translation_fields_set
    )
      @rest(
        type: "CrowdTranslationGet"
        path: "crowd.track.translations.get?{args}"
      ) {
      body
      header
    }
  }
`;

export const crowdLyricsValidate = gql`
  query crowdLyricsValidate(
    $app_id: String!
    $usertoken: String!
    $format: String!
    $commontrack_id: String!
    $check_spelling: Number!
    $body: any
  ) {
    crowdLyricsValidate(
      input: $body
      params: {
        app_id: $app_id
        usertoken: $usertoken
        format: $format
        check_spelling: $check_spelling
      }
    )
      @rest(
        type: "CrowdLyricsValidate"
        path: "track.lyrics.validate?{args.params}"
        method: "POST"
      ) {
      body
      header
    }
  }
`;

export const crowdTranslationValidate = gql`
  query crowdTranslationValidate(
    $app_id: String!
    $commontrack_id: String!
    $usertoken: String!
    $captcha_id: String!
    $body: any
  ) {
    crowdTranslationValidate(
      input: $body
      params: {
        app_id: $app_id
        commontrack_id: $commontrack_id
        usertoken: $usertoken
        captcha_id: $captcha_id
      }
    )
      @rest(
        type: "CrowdTranslationValidate"
        path: "crowd.track.translations.validation.post?{args.params}"
        method: "POST"
      ) {
      body
      header
    }
  }
`;

export const crowdUserProfilePost = gql`
  mutation crowdUserProfilePost(
    $app_id: String!
    $usertoken: String!
    $body: any
    $formDataSerializer: any
    $remove: Number
    $incremental: Number
  ) {
    crowdUserProfilePost(
      input: $body
      params: {
        app_id: $app_id
        usertoken: $usertoken
        remove: $remove
        incremental: $incremental
      }
    )
      @rest(
        type: "CrowdUserProfilePost"
        path: "crowd.user.profile.post?{args.params}"
        method: "POST"
        bodySerializer: $formDataSerializer
      ) {
      body
      header
    }
  }
`;

export const crowdArtistFeedbackGet = gql`
  query crowdArtistFeedbackGet(
    $app_id: String!
    $artist_id: Number
    $page: Number
    $page_size: Number
    $part: String
    $captcha_id: String
    $usertoken: String!
  ) {
    crowdArtistFeedbackGet(
      input: $body
      params: {
        app_id: $app_id
        artist_id: $artist_id
        page: $page
        page_size: $page_size
        part: $part
        captcha_id: $captcha_id
        usertoken: $usertoken
      }
    )
      @rest(
        type: "CrowdArtistFeedbackGet"
        path: "crowd.artist.feedback.get?{args.params}"
        method: "GET"
      ) {
      body
      header
    }
  }
`;

export const crowdChartUsersGet = gql`
  query crowdChartUsersGet(
    $app_id: String!
    $artist_id: Number
    $chart_name: String
    $page: Number
    $page_size: Number
    $captcha_id: String
    $usertoken: String!
  ) {
    crowdChartUsersGet(
      input: $body
      params: {
        app_id: $app_id
        artist_id: $artist_id
        chart_name: $chart_name
        page: $page
        page_size: $page_size
        captcha_id: $captcha_id
        usertoken: $usertoken
      }
    )
      @rest(
        type: "CrowdChartUsersGet"
        path: "crowd.chart.users.get?{args.params}"
        method: "GET"
      ) {
      body
      header
    }
  }
`;

export const crowdUserBadgesGet = createRESTQuery({
  name: "crowdUserBadgesGet",
  parameters: [
    {
      name: "uaid",
      type: "String",
    },
  ],
  path: "crowd.user.badges.get",
});
export const crowdUserFeedbackGet = createRESTQuery({
  name: "crowdUserFeedbackGet",
  parameters: [
    {
      name: "uaid",
      type: "String",
    },
    {
      name: "page",
      type: "Number",
    },
    {
      name: "page_size",
      type: "Number",
    },
    {
      name: "format",
      type: "String",
    },
    {
      name: "feedback_type",
      type: "String",
    },
    {
      name: "part",
      type: "String",
    },
  ],
  path: "crowd.user.feedback.get",
});

export const crowdArtistsChartGet = createRESTQuery({
  name: "crowdArtistsChartGet",
  parameters: [
    {
      name: "uaid",
      type: "String",
    },
    {
      name: "part",
      type: "String",
    },
    {
      name: "page",
      type: "Number",
    },
    {
      name: "page_size",
      type: "Number",
    },
    {
      name: "format",
      type: "String",
    },
  ],
  path: "crowd.artists.chart.get",
});
