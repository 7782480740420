import { gql } from "@apollo/client";

import { createRESTMutation } from "../utils/rest";

const captchaPost = gql`
  mutation captchaPost($app_id: String!, $response: String!) {
    captchaPost(input: $body, params: { app_id: $app_id })
      @rest(
        type: "CaptchaPost"
        path: "captcha.post?{args.params}"
        method: "POST"
      ) {
      body
      header
    }
  }
`;

const credentialPost = createRESTMutation({
  name: "credentialPost",
  parameters: ["allow_duplicates", "check_missing_info", "email", "password"],
  path: "credential.post",
});

const accountPost = createRESTMutation({
  name: "accountPost",
  parameters: ["allow_duplicates", "check_missing_info", "guid"],
  path: "account.post",
});

const migrateAccountPost = gql`
  mutation migrateAccountPost($app_id: String!, $usertoken: String!) {
    migrateAccountPost(
      input: {}
      params: { app_id: $app_id, usertoken: $usertoken }
    )
      @rest(
        type: "MigrateAccountPost"
        path: "migrate.account.post?{args.params}"
        method: "POST"
      ) {
      body
      header
    }
  }
`;

export const signInPost = createRESTMutation({
  name: "signInPost",
  path: "auth/v1/signin",
});

export const emailSignUpPost = createRESTMutation({
  name: "signUpPost",
  path: "auth/v1/signup",
});

const signOutAccountPost = gql`
  mutation migrateAccountPost($app_id: String!, $usertoken: String!) {
    migrateAccountPost(input: $body, params: { app_id: $app_id })
      @rest(
        type: "SignOutAccountPost"
        path: "signout.account.post?{args.params}"
        method: "POST"
      ) {
      body
      header
    }
  }
`;

const jwtGet = gql`
  query jwtGet($app_id: String!, $captcha_id: String, $usertoken: String!) {
    jwtGet(app_id: $app_id, captcha_id: $captcha_id, usertoken: $usertoken)
      @rest(type: "JwtGet", path: "jwt.get?{args}") {
      body
      header
    }
  }
`;

const tokenGet = gql`
  query tokenGet($app_id: String!, $captcha_id: String, $guid: String!) {
    tokenGet(app_id: $app_id, captcha_id: $captcha_id, guid: $guid)
      @rest(type: "TokenGet", path: "token.get?{args}") {
      body
      header
    }
  }
`;

const userGet = gql`
  query userGet($app_id: String!, $captcha_id: String, $usertoken: String!) {
    userGet(app_id: $app_id, captcha_id: $captcha_id, usertoken: $usertoken)
      @rest(type: "UserGet", path: "user.get?{args}") {
      body
      header
    }
  }
`;

export {
  accountPost,
  captchaPost,
  credentialPost,
  jwtGet,
  migrateAccountPost,
  signOutAccountPost,
  tokenGet,
  userGet,
};
