import { useTheme, createStyles } from "@mxmdev/podcasts-shared-native";
import { viewStyles } from "@mxmdev/react-universal-components";
import { View, ViewStyle } from "react-native";
import { Svg, Defs, LinearGradient, Stop, Circle } from "react-native-svg";

const BannerGradient = ({ style: styleProp }: Props): JSX.Element => {
  const { style } = useTheme(styles);

  return (
    <View style={[style.container, styleProp]}>
      <Svg fill="none" height="807" style={style.svg} width="807">
        <Circle
          cx={807 / 2}
          cy={807 / 2}
          fill="url(#paint0_linear)"
          r={807 / 2}
        />
        <Defs>
          <LinearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear"
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
          >
            <Stop offset="0" stopColor="#FF5353" />
            <Stop offset="1" stopColor="#FF8D7B" stopOpacity={0} />
          </LinearGradient>
        </Defs>
      </Svg>
    </View>
  );
};

interface Props {
  style: ViewStyle;
}

const styles = createStyles(() =>
  viewStyles({
    container: {
      height: "100%",
      overflow: "hidden",
    },
    svg: {
      marginLeft: -48,
      marginTop: -120,
    },
  })
);

export default BannerGradient;
