import { getTimeMilliseconds } from "../../../utils";

import { IntercomAnalytics, IntercomConfig } from "./types";

const VOID_PROVIDER: IntercomAnalytics = {
  clearUser: () => {},
  logEvent: () => {},
  setUser: () => {},
};

const DISABLED_PROVIDER: IntercomAnalytics = {
  clearUser: () => {
    console.warn(
      "[Analytics] Intercom user not reset. Intercom is not available."
    );
  },
  logEvent: (eventName, properties) => {
    console.warn(
      `[Analytics] Intercom event '${eventName}' (params: ${JSON.stringify(
        properties
      )}) dropped. Intercom is not available.`
    );
  },
  setUser: (user) => {
    console.warn(
      `[Analytics] Intercom user with id '${user.user_id}' not set. Intercom is not available.`
    );
  },
};

const analytics = (() => {
  let client: IntercomAnalytics;

  const init = (config?: IntercomConfig): IntercomAnalytics => {
    if (__DEV__) {
      return VOID_PROVIDER;
    }

    if (!config || !config.appId) {
      console.warn(
        "[Analytics] Intercom: Not able to initialize due to missing/incomplete config",
        config
      );

      return DISABLED_PROVIDER;
    }

    try {
      const {
        Intercom,
        shutdown,
        trackEvent,
      } = require("@intercom/messenger-js-sdk");

      const { appId } = config;

      return {
        clearUser: () => {
          shutdown();
        },
        logEvent: (eventName, properties) => {
          trackEvent(eventName, properties);
        },
        setUser: (user) => {
          Intercom({
            app_id: appId,
            created_at: getTimeMilliseconds(user.user_creation_date),
            email: user.profile.email,
            name: user.profile.name,
            region: "eu",
            user_id: user.user_id,
          });
        },
      };
    } catch {
      console.error(
        "[Analytics] You are trying to use @intercom/messenger-js-sdk but it is not installed."
      );

      return VOID_PROVIDER;
    }
  };

  return {
    get: (config?: IntercomConfig): IntercomAnalytics => {
      if (client === undefined) {
        client = init(config);
      }

      return client;
    },
  };
})();

export { analytics };
