import { viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";

const styles = (theme: Theme, { isSmallScreen }: { isSmallScreen: boolean }) =>
  viewStyles({
    container: {
      alignItems: "center",
      backgroundColor: theme.backgroundPrimary,
      flex: 1,
      justifyContent: "center",
      padding: isSmallScreen ? global.spacing * 4 : global.spacing * 6,
    },
    content: {
      alignItems: "center",
    },
    text: {
      marginBottom: global.spacing * 6,
    },
    title: {
      marginBottom: global.spacing * 4,
    },
  });

export default styles;
