// This is a wrapper around the react-universal-core ErrorTrackingProvider
// to reduce coupling with that module

import { useErrorTracking } from "@mxmdev/react-universal-core";
import { ErrorTrackingContextType } from "@mxmdev/react-universal-core/error-tracking/errorTrackingUtils";
import { useCallback } from "react";

const useLogError = (): {
  logError: ErrorTrackingContextType["logError"];
} => {
  const errorTracking = useErrorTracking();

  const logError = useCallback(
    (error, data) => {
      errorTracking.logError(error, data);
    },
    [errorTracking]
  );

  return {
    logError,
  };
};

export default useLogError;
