import { Path } from "react-native-svg";

import createIcon from "../createIcon";

export const IconLink = createIcon(
  <>
    <Path d="M13.665 15.504a5.226 5.226 0 0 1-2.472-.632 5.143 5.143 0 0 1-1.66-1.44 1 1 0 1 1 1.602-1.197c.273.366.616.663 1.019.883a3.2 3.2 0 0 0 1.292.379 3.157 3.157 0 0 0 2.466-.92l2.5-2.5c.568-.589.884-1.38.876-2.215s-.337-1.621-.927-2.212a3.152 3.152 0 0 0-2.212-.927h-.027c-.826 0-1.606.315-2.201.889l-1.422 1.415a1 1 0 1 1-1.41-1.418l1.433-1.425a5.146 5.146 0 0 1 3.601-1.461h.045a5.138 5.138 0 0 1 3.608 1.513 5.147 5.147 0 0 1 1.513 3.609 5.14 5.14 0 0 1-1.449 3.634l-2.513 2.513a5.13 5.13 0 0 1-1.849 1.188 5.266 5.266 0 0 1-1.813.324z" />

    <Path d="M7.883 21.278h-.047a5.13 5.13 0 0 1-3.608-1.513 5.14 5.14 0 0 1-1.513-3.608 5.133 5.133 0 0 1 1.45-3.634l2.512-2.512a5.167 5.167 0 0 1 7.791.558 1 1 0 0 1-1.601 1.199 3.158 3.158 0 0 0-2.311-1.262 3.167 3.167 0 0 0-2.465.919l-2.5 2.5c-.568.588-.884 1.379-.876 2.215s.337 1.621.927 2.212c.591.591 1.376.92 2.211.927a3.12 3.12 0 0 0 2.228-.889l1.413-1.412a.999.999 0 1 1 1.414 1.414l-1.425 1.425a5.154 5.154 0 0 1-3.6 1.461z" />
  </>
);
