import { useCallback, useContext, useMemo } from "react";
import {
  GestureResponderEvent,
  Linking,
  Platform,
  ViewStyle,
} from "react-native";

import { ThemeContext } from "../../styles/themes/themeUtils";

import type { Cursor, Props as PressableProps, Target } from "./Pressable";
import { formatExternalURL } from "./utils";

const useNativePressableProps = ({
  disabled,
  isExternalLink,
  isLink,
  isNavPath,
  isURIFragment,
  onPressProp,
  to = "",
  webTarget,
}: Props):
  | NativePressableProps
  | NativePressableNavPathProps
  | NativePressableExternalLinkProps => {
  const externalFormattedURL = useMemo(
    () =>
      typeof to === "string" && isExternalLink ? formatExternalURL(to) : "",
    [isExternalLink, to]
  );
  const { useLink } = useContext(ThemeContext);

  const { href: linkHref, onPress: onLinkPress } = useLink({ to });

  const onPress = useCallback(
    (event) => {
      // Be it a link or a button
      // In any case we always call the onPressProps if it's passed
      onPressProp?.(event);

      if (isURIFragment) {
        // URI fragments such as `#openMenu` should not open anything but use the default browser behaviour
      } else if (isNavPath) {
        // If it's a nav path, on web we still need the `onLinkPress` to be executed
        // while if it's an external link, on native we navigate with `openURL` and on web with the `href` prop

        onLinkPress(event);
      } else if (isExternalLink && Platform.OS !== "web") {
        Linking.openURL(externalFormattedURL);
      }

      event?.stopPropagation();
    },
    [
      externalFormattedURL,
      isURIFragment,
      isExternalLink,
      isNavPath,
      onPressProp,
      onLinkPress,
    ]
  );

  const htmlProps = useMemo(() => {
    if (Platform.OS === "web" && (isLink || isURIFragment)) {
      const commonHtmlProps = { accessibilityRole: "link" as const };

      if (isNavPath || isURIFragment) {
        return { ...commonHtmlProps, href: linkHref };
      }

      if (isExternalLink) {
        return {
          ...commonHtmlProps,
          href: externalFormattedURL,
          hrefAttrs: { rel: "noopener" as const, target: webTarget },
        };
      }
    }

    return {};
  }, [
    externalFormattedURL,
    isURIFragment,
    isExternalLink,
    isLink,
    isNavPath,
    linkHref,
    webTarget,
  ]);

  const style = useMemo(() => {
    if (Platform.OS === "web") {
      let cursor: Cursor;

      if (isLink || (!isLink && onPressProp)) {
        cursor = "pointer";
      } else if (!isLink && !onPressProp) {
        cursor = "default";
      }

      if (disabled) {
        cursor = "not-allowed";
      }

      return {
        cursor: cursor!,
      } as ViewStyle;
    }

    return {};
  }, [disabled, isLink, onPressProp]);

  return {
    ...htmlProps,
    onPress,
    style,
  };
};

export default useNativePressableProps;

interface NativePressableProps {
  onPress: (
    e?:
      | GestureResponderEvent
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | undefined
  ) => void;
  style?: ViewStyle;
}

interface NativePressableNavPathProps extends Partial<NativePressableProps> {
  href: string;
  accessibilityRole: "link";
}

interface NativePressableExternalLinkProps
  extends Partial<NativePressableProps>,
    NativePressableNavPathProps {
  hrefAttrs: {
    rel: "noopener";
    target: Target;
  };
}

interface Props {
  to: PressableProps["to"];
  disabled: PressableProps["disabled"];
  onPressProp: PressableProps["onPress"];
  isLink: boolean;
  isExternalLink: boolean;
  isNavPath: boolean;
  isURIFragment: boolean;
  webTarget: PressableProps["webTarget"];
}
