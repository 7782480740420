import { useContext } from "react";

import { LocaleContext } from "./LocaleProvider";
import { LocaleInfo } from "./types";

export const useLocalePreferenceSetter = (): {
  setLocalePreference: (locale: LocaleInfo) => void;
} => {
  const { setLocalePreference } = useContext(LocaleContext);

  return { setLocalePreference };
};
