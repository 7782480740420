import { View } from "react-native";

import { Text } from "../..";
import Button from "../../components/Button";
import { useMediaQuery } from "../../styles/styleUtils";
import { useTheme } from "../../styles/themes/themeUtils";
import { forceAppReload } from "../../utils";

import styles from "./styles";

const GenericError = () => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });

  return (
    <View style={style.container}>
      <View style={style.content}>
        <Text align="center" style={style.title} type="headingLarge">
          Something went wrong
        </Text>
        <Text align="center" style={style.text} type="bodyLarge">
          We encountered an unexpected problem, please try again.
        </Text>
        <Button onPress={() => forceAppReload()} text="Retry" />
      </View>
    </View>
  );
};

export default GenericError;

interface Props {}
