// @ts-nocheck
import {
  Animated,
  useTheme,
  Text,
  ActivityIndicator,
  IconComponent,
} from "@mxmdev/react-universal-components";
import { useMemo, PropsWithChildren } from "react";
import { View, Image } from "react-native";

import styles, {
  getColor,
  getTextType,
  getDescriptionTextType,
  iconSizes,
  imageSizes,
} from "./styles";

const Button = ({
  backgroundColor,
  bordered,
  compact,
  color = "black",
  description,
  disabled = false,
  dot = false,
  dotColor,
  Icon,
  iconAlign = "left",
  image,
  imageShape = "round",
  isLoading = false,
  hoverAnimatedValue,
  minTouchTarget = true,
  pressAnimatedValue,
  selected = false,
  size = "default",
  text,
  type = "filled",
  shadowed,

  // These default values resolve most use cases
  shape = text === undefined || size === "extraSmall" || size === "small"
    ? "round"
    : "default",
}: PropsWithChildren<Props>) => {
  const { style, theme } = useTheme(
    styles,
    useMemo(
      () => ({
        backgroundColor,
        bordered,
        color,
        compact,
        disabled,
        dotColor,
        image,
        minTouchTarget,
        selected,
        shadowed,
        shape,
        size,
        text,
        type,
      }),
      [
        backgroundColor,
        bordered,
        compact,
        color,
        disabled,
        dotColor,
        image,
        minTouchTarget,
        selected,
        shadowed,
        shape,
        size,
        text,
        type,
      ]
    )
  );
  const contentColor = getColor(color, disabled, selected, theme, type);
  const hasIconOrImage = Icon !== undefined || image !== undefined;
  const shouldOnlyDisplayActivityIndicator = isLoading && !hasIconOrImage;
  const imageSize = compact ? 32 : imageSizes[size];

  return (
    <View style={style.container}>
      {hoverAnimatedValue && (
        <Animated.View
          style={[
            style.overlay,
            style.hoverOverlay,
            { opacity: hoverAnimatedValue },
          ]}
        />
      )}
      {pressAnimatedValue && (
        <Animated.View
          style={[
            style.overlay,
            style.pressOverlay,
            { opacity: pressAnimatedValue },
          ]}
        />
      )}
      <View
        style={[
          style.content,
          hasIconOrImage && text && iconAlign === "right"
            ? style.rightIconContent
            : undefined,
        ]}
      >
        {shouldOnlyDisplayActivityIndicator && (
          <ActivityIndicator
            colorCustom={contentColor}
            size={iconSizes[size]}
            style={style.activityIndicator}
          />
        )}
        {hasIconOrImage && (
          <View
            style={
              text
                ? iconAlign === "right"
                  ? style.rightIcon
                  : style.leftIcon
                : undefined
            }
          >
            {dot && <View style={style.dot} />}
            {isLoading ? (
              <ActivityIndicator
                colorCustom={contentColor}
                size={iconSizes[size]}
              />
            ) : (
              <>
                {image !== undefined && (
                  <Image
                    source={{ uri: image }}
                    style={{
                      height: imageSize,
                      width: imageSize,
                      ...(imageShape === "round" && {
                        borderRadius: imageSize,
                      }),
                    }}
                  />
                )}
                {Icon !== undefined && image === undefined && (
                  <Icon color={contentColor} size={iconSizes[size]} />
                )}
              </>
            )}
          </View>
        )}
        {text ? (
          <View>
            <Text
              color={contentColor}
              selectable={false}
              style={shouldOnlyDisplayActivityIndicator && style.hiddenText}
              type={getTextType(size)}
            >
              {text}
            </Text>
            {description ? (
              <Text
                color={theme.contentSecondary}
                selectable={false}
                style={shouldOnlyDisplayActivityIndicator && style.hiddenText}
                type={getDescriptionTextType(size)}
              >
                {description}
              </Text>
            ) : null}
          </View>
        ) : null}
      </View>
    </View>
  );
};

type Color =
  | "black"
  | "blue"
  | "brand"
  | "green"
  | "grey"
  | "purple"
  | "serviceAmazonMusic"
  | "serviceAppleMusic"
  | "serviceFacebook"
  | "serviceInstagram"
  | "serviceSpotify"
  | "serviceTwitter"
  | "systemBlue"
  | "systemGreen"
  | "systemMagenta"
  | "systemRed"
  | "systemYellow"
  | "yellow"
  | "white";

type IconAlign = "left" | "right";

type Shape = "default" | "pill" | "round";

type Size = "default" | "extraSmall" | "large" | "small" | "extraLarge";

type Type = "basic" | "filled" | "text";

export interface Props {
  backgroundColor?: string;
  bordered?: boolean;
  compact?: boolean;
  color?: Color;
  description?: string;
  disabled?: boolean;
  dot?: boolean;
  dotColor?: string;
  Icon?: IconComponent;
  iconAlign?: IconAlign;
  image?: string;
  imageShape?: "round" | "raw";
  isLoading?: boolean;
  hoverAnimatedValue?: Animated.Value;
  minTouchTarget?: boolean;
  pressAnimatedValue?: Animated.Value;
  selected?: boolean;
  shadowed?: boolean;
  shape?: Shape;
  size?: Size;
  text?: string;
  type?: Type;
}

export default Button;
