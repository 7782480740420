import {
  ApolloError,
  ProductsGetData,
  ProductsGetVars,
  queries,
  useQuery,
} from "@mxmdev/react-universal-core";
import { useCallback, useMemo, useState } from "react";

import { productsTransformer } from "../transformers";
import { Product } from "../types";

const usePaymentsProducts = (): {
  loading: boolean;
  error: ApolloError | Error | undefined;
  data: Product[] | null;
  refetch: () => Promise<void>;
} => {
  const [error, setError] = useState<ApolloError | Error | undefined>(
    undefined
  );

  const { data, loading, refetch } = useQuery<ProductsGetData, ProductsGetVars>(
    queries.productsGet,
    {
      onError: (error) => {
        setError(error);
      },
      variables: {
        platform: "stripe",
      },
    }
  );

  const transformedData = useMemo(() => {
    if (!data) {
      return null;
    }

    try {
      return productsTransformer(data.productsGet.body);
    } catch (error) {
      setError(error as Error); // `productsTransformer` may throw an Error instance

      return null;
    }
  }, [data]);

  const refetchFn = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return { data: transformedData, error, loading, refetch: refetchFn };
};

export default usePaymentsProducts;
