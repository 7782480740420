import {
  ComponentProps,
  useCallback,
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
} from "react";
import { ScrollView as RNScrollView } from "react-native";
import { PanGestureHandler, ScrollView } from "react-native-gesture-handler";

import { useDialogContext, useMediaQuery } from "../..";

import { useBottomSheetGestureContext } from "./BottomSheetGestureProvider";

// We need to render a RN ScrollView on web due to an issue with the RNGH ScrollView,
// which makes the text it contains unselectable.
const DefaultScrollView = forwardRef<
  RNScrollView,
  ComponentProps<typeof RNScrollView>
>((props, ref) => <RNScrollView ref={ref} {...props} />);

// TODO due to react-native-gesture-handler v1 limitations
// we can't have "children" or fragments as child of the PanGestureHandler
// for this reason better structures could have been found
// https://github.com/software-mansion/react-native-gesture-handler/issues/711

// This components wraps a ScrollView with a PanGestureHandler
// only when is used by a bottomsheet (e.g. only if isSmallScreen)
const BottomSheetScrollViewImplementation: ForwardRefRenderFunction<
  ScrollView,
  Props
> = (props, ref) => {
  const { isSmallScreen } = useMediaQuery();
  const gestureContext = useBottomSheetGestureContext();

  const { setShards } = useDialogContext();

  const { children } = props;

  const _ref = useCallback(
    (innerRef) => {
      if (innerRef) {
        setShards([innerRef]);

        if (ref) {
          if ("current" in ref) {
            ref.current = innerRef;
          } else if (typeof ref === "function") {
            ref(innerRef);
          }
        }
      }
    },
    [setShards, ref]
  );

  if (isSmallScreen && gestureContext !== undefined) {
    const { gestureHandlerProps, panEnabled, scrollViewProps } = gestureContext;
    const { gestureEnabled, onHandlerStateChange, onSwipeDown, panGestureRef } =
      gestureHandlerProps || {};
    const isPanEnabled = panEnabled && !props?.fullscreen;

    return (
      <PanGestureHandler
        activeOffsetY={5}
        enabled={isPanEnabled && gestureEnabled}
        failOffsetY={-5}
        onGestureEvent={onSwipeDown()}
        onHandlerStateChange={onHandlerStateChange}
        ref={panGestureRef}
      >
        <ScrollView
          {...props}
          {...scrollViewProps}
          ref={(innerRef) => {
            scrollViewProps.ref.current = innerRef as any;
            _ref(innerRef);
          }}
        >
          {children}
        </ScrollView>
      </PanGestureHandler>
    );
  }

  return (
    <DefaultScrollView {...props} ref={_ref}>
      {children}
    </DefaultScrollView>
  );
};

export const BottomSheetScrollView = forwardRef<ScrollView, Props>(
  BottomSheetScrollViewImplementation
);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BottomSheetScrollView = ScrollView;
export default BottomSheetScrollView;

interface Props extends ComponentProps<typeof ScrollView> {
  fullscreen?: boolean;
}
