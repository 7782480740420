import { PropsWithChildren } from "react";

import { IconBack } from "..";
import Button from "../Button";
import { Props as ButtonProps } from "../Button/EnhancedButton";

const HeaderBackButton = ({
  disabled,
  onPress,
  style,
}: PropsWithChildren<Props>) => {
  return (
    <Button
      disabled={disabled}
      Icon={IconBack}
      onPress={onPress}
      shape="round"
      style={style}
      type="text"
    />
  );
};

export interface Props
  extends Pick<ButtonProps, "disabled" | "onPress" | "style"> {}

export default HeaderBackButton;
