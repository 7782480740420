import {
  createResponsiveStyles,
  useResponsiveTheme,
} from "@mxmdev/podcasts-shared-native";
import {
  global,
  IconClose,
  Text,
  viewStyles,
} from "@mxmdev/react-universal-components";
import { View } from "react-native";

import { useClaim } from "../../screens/Podcast/hooks/useClaim";
import Button from "../Button";

import BannerGradient from "./BannerGradient";
import { useBannerBeta } from "./useBannerBeta";

const BannerBeta = ({ onHideBanner }: Props): JSX.Element => {
  const { isLargeScreen, style } = useResponsiveTheme(styles);
  const { handleClaim } = useClaim();
  const { height } = useBannerBeta();

  const CloseButton = (
    <Button
      color="white"
      Icon={IconClose}
      onPress={onHideBanner}
      size="small"
      style={style.closeButton}
      type="text"
    />
  );

  return (
    <View style={[style.banner, height !== undefined ? { height } : undefined]}>
      <View style={style.content}>
        <BannerGradient style={style.gradient} />

        <Text color="white" numberOfLines={1} type="labelSmall">
          Are you a podcaster?
        </Text>

        {!isLargeScreen && CloseButton}

        <View style={style.buttonsContainer}>
          <Button
            color="white"
            onPress={handleClaim}
            shape="pill"
            size="small"
            style={style.applyButton}
            text="Claim your podcast"
          />

          {isLargeScreen && CloseButton}
        </View>
      </View>
    </View>
  );
};

const styles = createResponsiveStyles((_, { isLargeScreen, targetMaxWidth }) =>
  viewStyles({
    applyButton: {
      marginRight: isLargeScreen ? global.spacing * 2 : undefined,
      marginTop: isLargeScreen ? undefined : global.spacing,
      width: isLargeScreen ? 218 : "100%",
    },
    banner: {
      backgroundColor: "#FF8D7B",
      bottom: 0,
      flex: 1,
      left: 0,
      overflow: "hidden",
      position: "absolute",
      right: 0,
      top: 0,
    },
    buttonsContainer: {
      alignItems: "center",
      flexDirection: "row",
    },
    closeButton: {
      position: isLargeScreen ? undefined : "absolute",
      right: isLargeScreen ? undefined : 0,
      top: isLargeScreen ? undefined : 0,
    },
    content: {
      alignItems: isLargeScreen ? "center" : undefined,
      alignSelf: isLargeScreen ? "center" : "stretch",
      flex: 1,
      flexDirection: isLargeScreen ? "row" : "column",
      justifyContent: isLargeScreen ? "space-between" : "center",
      paddingHorizontal: isLargeScreen ? undefined : global.spacing * 4,
      width: targetMaxWidth,
    },
    gradient: {
      height: "100%",
      left: isLargeScreen ? 180 : undefined,
      position: "absolute",
      right: isLargeScreen ? undefined : 0,
      width: isLargeScreen ? undefined : 42,
    },
    labelsContainer: {
      alignItems: "center",
      flexDirection: "row",
      height: isLargeScreen ? undefined : 24,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  onHideBanner: () => void;
}

export default BannerBeta;
