/**
 * Duplicated from core package.
 * */
import { Platform } from "react-native";

export const forceAppReload = () => {
  if (Platform.OS === "web") {
    window.location.reload();
  } else {
    console.warn(`[AuthUtils] Screen reload unimplemented on ${Platform.OS}`);
  }
};

/**
 * Applies capitalization only to the first letter
 */
export const upperFirst = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

/**
 * Applies upperFirst to each line of a multiline string
 */
const upperFirstAll = (text: string): string => {
  return text.split("\n").map(upperFirst).join("\n");
};

/**
 * Applies upperFirst to each word of a sentence
 */
export const upperWords = (text: string): string =>
  text.split(" ").map(upperFirst).join(" ");

/**
 * Returns a unique 16-char string based on the current time.
 */
export const uniqid = (): string => {
  const randomNumber = Math.random().toString(36).substring(2);
  const now = Date.now().toString(36);

  return (now + randomNumber).substring(0, 16);
};

/**
 * Returns the text capitalized and the new cursor position
 */
export const capitalizeTextInput = (
  oldText: string,
  newText: string,
  selection: { end: number; start: number }
) => {
  const capitalizeText = upperFirstAll(newText);

  // Calculates the length delta between the old and the new inputs' values
  // we don't use the event's text delta because it's only available on web
  const textLengthDiff = newText.length - oldText.length;

  // This fixes a known React issue that exists only on web
  // link https://github.com/facebook/react/issues/955
  return {
    selection: {
      end: selection.end + textLengthDiff,
      start: selection.end + textLengthDiff,
    },
    text: capitalizeText,
  };
};

export const isServer = (): boolean =>
  Platform.OS === "web" && typeof window === "undefined";
