import { global, Theme, viewStyles } from "@mxmdev/react-universal-components";

export const AVATAR_SIZE = 80;

const styles = (theme: Theme, { isSmallScreen = false }) => {
  const AVATAR_BORDER_WIDTH = 2;

  return {
    ...viewStyles({
      bottomSpacing: { marginBottom: global.spacing * 2 },
      button: {
        borderColor: theme.backgroundPrimaryElevated,
        borderRadius: global.radius.extraLarge,
        borderWidth: AVATAR_BORDER_WIDTH,
        bottom: -AVATAR_BORDER_WIDTH,
        position: "absolute",
        right: -AVATAR_BORDER_WIDTH,
      },
      hoverOverlay: {
        backgroundColor: theme.backgroundHover,
      },
      overlay: {
        borderRadius: AVATAR_SIZE / 2,
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
      },
      pressOverlay: {
        backgroundColor: theme.backgroundPress,
      },
    }),
  };
};

export default styles;
