import { EmbedCodeType } from "@mxmdev/podcasts-shared/src/types";
import { getEmbedCode } from "@mxmdev/podcasts-shared/src/utils";
import {
  global,
  Select,
  Text,
  textStyles,
  viewStyles,
} from "@mxmdev/react-universal-components";
import { useCallback } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { createStyles, useTheme } from "../../utils";

const selectOptions = [
  {
    label: "iFrame",
    value: "iframe" as const,
  },
  {
    label: "Script",
    value: "script" as const,
  },
];

const EmbedCode = ({
  embedId,
  onEmbedCodeChange,
  selectedEmbedType,
  style: styleProp,
}: Props): JSX.Element => {
  const { style } = useTheme(styles);

  const handleSelectChange = useCallback(
    (value: string) => {
      switch (value) {
        case "iframe":
        case "script":
          return onEmbedCodeChange(value);
        default:
          throw new Error("EmbedCode: Unexpected selected value");
      }
    },
    [onEmbedCodeChange]
  );

  return (
    <View style={[style.container, styleProp]}>
      <View style={style.header}>
        <Text style={style.label} type="labelDefault">
          Copy code to your website
        </Text>

        <Select
          onChange={handleSelectChange}
          options={selectOptions}
          value={selectedEmbedType}
        />
      </View>

      <Text style={style.code} type="bodyLarge">
        {getEmbedCode(selectedEmbedType, embedId)}
      </Text>
    </View>
  );
};

const styles = createStyles((theme) => ({
  ...viewStyles({
    container: {
      backgroundColor: theme.backgroundPrimaryInverted,
      borderRadius: global.radius.default,
      padding: global.spacing * 4,
    },
    header: {
      alignItems: "center",
      flexDirection: "row",
    },
  }),
  ...textStyles({
    code: {
      color: theme.contentPrimaryInverted,
    },
    label: {
      color: theme.contentPrimaryInverted,
      flex: 1,
    },
  }),
}));

export type Props = {
  embedId: string;
  onEmbedCodeChange: (type: EmbedCodeType) => void;
  selectedEmbedType: EmbedCodeType;
  style?: StyleProp<ViewStyle>;
};

export default EmbedCode;
