export const isItunesId = (id: string) => {
  return /^\d+$/.test(id);
};

export const getPodcastApiParams = (podcastId?: string) => {
  if (podcastId === undefined) {
    return {};
  }

  return isItunesId(podcastId)
    ? { podcast_itunes_id: podcastId }
    : { podcast_id: podcastId };
};

export const getEpisodeApiParams = (podcastId?: string, episodeId?: string) => {
  const podcastParams = getPodcastApiParams(podcastId);
  const episodeParams = episodeId
    ? isItunesId(episodeId)
      ? {
          podcast_itunes_episode_id: episodeId,
        }
      : {
          podcast_episode_id: episodeId,
        }
    : {};

  return {
    ...podcastParams,
    ...episodeParams,
  };
};

export const getEpisodeListApiParams = (episodeIds?: readonly string[]) => {
  if (episodeIds === undefined) {
    return {};
  }

  const episodesJoined = episodeIds.join(",");

  return episodeIds.every(isItunesId)
    ? {
        podcast_itunes_episode_ids: episodesJoined,
      }
    : {
        podcast_episode_ids: episodesJoined,
      };
};

export const apiParamsToString = (params: object) => {
  return Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");
};
