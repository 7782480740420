import { canUseDOM } from "../../../utils";

export const getRedirectOrigin = (appScheme: string | undefined) => {
  if (canUseDOM) {
    return window.location.href;
  } else {
    if (!appScheme) {
      throw new Error(
        "cannot build native redirect URL, `appScheme` not specified"
      );
    }

    return `${appScheme}://`;
  }
};
