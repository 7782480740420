import { useState, useEffect, useRef } from "react";
import {
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native";

import { Portal, Text } from "..";
import { global, useMediaQuery, useTheme } from "../../styles";
import Animated from "../Animated";
import Button from "../Button";

import { ToastPosition } from "./ToastProvider";
import styles from "./styles";

import { useToast } from ".";

const positionToStyle: Record<
  ToastPosition,
  Record<"toast" | "root", ViewStyle>
> = {
  bottom: { root: { alignItems: "center" }, toast: { bottom: 0 } },
  bottomEnd: { root: { alignItems: "flex-end" }, toast: { bottom: 0 } },
  bottomStart: { root: { alignItems: "flex-start" }, toast: { bottom: 0 } },
  top: { root: { alignItems: "center" }, toast: { top: 0 } },
  topEnd: { root: { alignItems: "flex-end" }, toast: { top: 0 } },
  topStart: { root: { alignItems: "flex-start" }, toast: { top: 0 } },
};

const Toast = () => {
  const { isSmallScreen } = useMediaQuery();
  const { style, theme } = useTheme(styles, { isSmallScreen });
  const { action, hideToast, Icon, position, startEnhancer, text, visible } =
    useToast();
  const [rendered, setRendered] = useState(false);
  const opacity = useRef(new Animated.Value(0)).current;
  const { width: screenWidth } = useWindowDimensions();

  useEffect(() => {
    if (rendered) {
      Animated.timing(opacity, {
        duration: 200,
        toValue: 1,
        useNativeDriver: Platform.OS !== "web",
      }).start();
    }
  }, [opacity, rendered]);

  useEffect(() => {
    if (visible) {
      setRendered(true);
    } else {
      Animated.timing(opacity, {
        duration: 200,
        toValue: 0,
        useNativeDriver: Platform.OS !== "web",
      }).start(() => {
        setRendered(false);
      });
    }
  }, [opacity, visible]);

  if (rendered) {
    return (
      <Portal>
        <View
          pointerEvents="box-none"
          style={[StyleSheet.absoluteFill, positionToStyle[position].root]}
        >
          <Animated.View
            style={[
              style.toast,
              { opacity, width: screenWidth - global.spacing * 4 * 2 },
              positionToStyle[position].toast,
            ]}
          >
            {(startEnhancer || Icon) && (
              <View style={style.startEnhancer}>
                {Icon ? (
                  <Icon color={theme.contentPrimaryInverted} />
                ) : (
                  startEnhancer
                )}
              </View>
            )}

            <Text
              color={theme.contentPrimaryInverted}
              numberOfLines={2}
              style={style.toastText}
            >
              {text}
            </Text>
            {action && (
              <Button
                onPress={() => {
                  hideToast();
                  action.onPress?.();
                }}
                size="small"
                style={style.customActionButton}
                text={action.text}
              />
            )}
          </Animated.View>
        </View>
      </Portal>
    );
  }

  return null;
};

export default Toast;
