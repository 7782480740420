import {
  Text,
  viewStyles,
  Button,
  global,
} from "@mxmdev/react-universal-components";
import { View } from "react-native";

import { createStyles, useTheme } from "../utils/themeUtils";

const ErrorPanel = ({ description, onRetry }: Props): JSX.Element => {
  const { style } = useTheme(styles);

  return (
    <View style={style.container}>
      <Text type="labelDefault">Oops, something went wrong!</Text>
      {description && (
        <Text align="center" style={style.description}>
          {description}
        </Text>
      )}
      {onRetry && (
        <Button
          onPress={onRetry}
          size="small"
          style={style.retry}
          text="Try again"
          type="filled"
        />
      )}
    </View>
  );
};

const styles = createStyles((theme) =>
  viewStyles({
    container: {
      alignItems: "center",
      backgroundColor: theme.systemRed10,
      borderRadius: global.radius.default,
      padding: global.spacing * 4,
    },
    description: {
      marginTop: global.spacing * 2,
    },
    retry: {
      marginTop: global.spacing * 2,
    },
  })
);

interface Props {
  description?: string;
  onRetry?: () => void;
}

export default ErrorPanel;
