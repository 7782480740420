import {
  ReactNode,
  useCallback,
  useState,
  useMemo,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";

import { IconComponent } from "..";

export type ToastPosition =
  | "bottom"
  | "bottomEnd"
  | "bottomStart"
  | "top"
  | "topEnd"
  | "topStart";

interface IShowToastOptions {
  action?: { onPress: () => void; text: string };
  Icon?: IconComponent;
  position?: ToastPosition;
  startEnhancer?: ReactNode;
  timeout?: number;
}

interface IToastContext {
  action?: IShowToastOptions["action"];
  Icon?: IShowToastOptions["Icon"];
  hideToast: () => void;
  position: ToastPosition;
  showToast: (text: string, options?: IShowToastOptions) => void;
  startEnhancer?: ReactNode;
  text?: string;
  visible: boolean;
}

export const ToastContext = createContext<IToastContext | undefined>(undefined);

const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<{
    text?: string;
    action?: IShowToastOptions["action"];
    Icon?: IShowToastOptions["Icon"];
    visible: boolean;
    timeout?: number;
    pressCount: number;
    position: ToastPosition;
  }>({ position: "bottom", pressCount: 0, visible: false });
  const closeTimeout = useRef<ReturnType<typeof setTimeout>>();

  // When closing the Toast we still spread the previous state
  // otherwise we would see a jump in the UI
  // because the text istantly disappears whereas the opacity fades out
  const closeToast = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        pressCount: 0,
        visible: false,
      })),
    []
  );

  // Press count is used to retrigger this effect.
  // When multiple showToasts call are triggered,
  // we need to reset the timer
  useEffect(() => {
    const timeout = state.timeout || 5000;

    // If a 0 timeout is requested
    // the closing must be handled manually
    if (state.visible && state.timeout !== 0) {
      closeTimeout.current = setTimeout(closeToast, timeout);
    }

    return () => {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
    };
  }, [closeToast, state.timeout, state.visible, state.pressCount]);

  const showToast = useCallback((text: string, options?: IShowToastOptions) => {
    setState((prevState) => ({
      action: options?.action,
      Icon: options?.Icon,
      position: options?.position || "bottom",
      pressCount: prevState.pressCount + 1,
      startEnhancer: options?.startEnhancer,
      text,
      timeout: options?.timeout,
      visible: true,
    }));
  }, []);

  const hideToast = useCallback(closeToast, [closeToast]);

  const providerValue = useMemo(() => {
    return { hideToast, showToast, ...state };
  }, [showToast, state, hideToast]);

  return (
    <ToastContext.Provider value={providerValue}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const toastContext = useContext(ToastContext);

  if (!toastContext) {
    throw new Error(
      "Error: could not find toast context value; please ensure the component is wrapped in a <ToastProvider>"
    );
  }

  return toastContext;
};

export default ToastProvider;
