import {
  Episode,
  SyncedSection,
  UnsyncedSection,
} from "@mxmdev/podcasts-shared";
import {
  DialogCustom,
  viewStyles,
  global,
  Button,
  Text,
  DialogComponent,
  ButtonGroup,
  IconLink,
  IconCode,
} from "@mxmdev/react-universal-components";
import { useState } from "react";
import { View } from "react-native";

import { createStyles, useTheme } from "../../utils";

import { EmbedTab } from "./EmbedTab";
import ShareTab from "./ShareTab";

export const ShareSectionDialog: DialogComponent<Props> = (props) => {
  const {
    episode,
    initialTab = "image",
    section,
    withOverlay = true,
    withTabs = false,
  } = props;
  const { style } = useTheme(styles);

  const [activeTab, setActiveTab] = useState(initialTab === "embed" ? 1 : 0);

  const title = !withTabs && initialTab === "embed" ? "Embed" : "Share";

  return (
    <DialogCustom desktopWidth={644} {...props} hasOverlay={withOverlay}>
      <View style={style.container}>
        <Text accessibilityRole="header" numberOfLines={1} type="headingSmall">
          {title}
        </Text>

        {withTabs && (
          <ButtonGroup
            onPress={(_, idx) => setActiveTab(idx)}
            selected={activeTab}
            shape="round"
            size="small"
            style={style.buttonGroup}
            type="basic"
          >
            <Button Icon={IconLink} text="Link & image" />
            <Button Icon={IconCode} text="Embed widget" />
          </ButtonGroup>
        )}

        <View style={style.tab}>
          {activeTab === 0 ? (
            <ShareTab episode={episode} section={section} />
          ) : (
            <EmbedTab episode={episode} />
          )}
        </View>
      </View>
    </DialogCustom>
  );
};

const styles = createStyles(() =>
  viewStyles({
    buttonGroup: {
      marginTop: global.spacing * 6,
    },
    container: {
      alignItems: "stretch",
    },
    tab: {
      paddingTop: global.spacing * 5,
    },
  })
);

type Props = {
  initialTab?: "image" | "embed";
  episode: Pick<
    Episode,
    "id" | "podcastId" | "collection" | "name" | "language"
  >;
  section?: UnsyncedSection | SyncedSection;
  withTabs?: boolean;
  withOverlay?: boolean;
};
