import { useCallback, useState } from "react";
import { TypeOf, z } from "zod";

import useValidatedFetcher from "./useValidatedFetcher";

const DEFAULT_OPTIONS = {
  headers: {
    "Content-Type": "application/json",
  },
  method: "POST",
};

const useValidatedMutation = <T extends z.ZodRawShape, OutputType>(
  api: string,
  params: Record<string, unknown>,
  apiSchema: z.ZodObject<T>,
  transform: (input: TypeOf<z.ZodObject<T>>) => OutputType,
  options: RequestInit = DEFAULT_OPTIONS
) => {
  const [{ data, error, hasMutated, isLoading }, setState] = useState<{
    data: OutputType | undefined;
    error: unknown | undefined;
    hasMutated: boolean;
    isLoading: boolean;
  }>({
    data: undefined,
    error: undefined,
    hasMutated: false,
    isLoading: false,
  });

  const { fetcher } = useValidatedFetcher(
    api,
    params,
    apiSchema,
    transform,
    options
  );

  const mutate = useCallback(
    async (
      queryParams?: Record<string, unknown>,
      body?: Record<string, unknown>
    ) => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));

        const data = await fetcher(queryParams, body);

        setState((prevState) => ({ ...prevState, data }));
      } catch (err) {
        setState((prevState) => ({ ...prevState, error: err }));
      } finally {
        setState((prevState) => ({
          ...prevState,
          hasMutated: true,
          isLoading: false,
        }));
      }
    },
    [fetcher]
  );

  return {
    data,
    error,
    hasMutated,
    isLoading,
    mutate,
  };
};

export default useValidatedMutation;
