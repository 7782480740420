import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export const useEffectOnce = (effect: EffectCallback): void => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(effect, []);
};

export const useEffectOnceCondition = (
  callback: () => void,
  condition: () => boolean,
  dependencies: DependencyList
): void => {
  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    if (condition()) {
      callback();

      calledOnce.current = true;
    }
  }, [callback, condition, dependencies]);
};
