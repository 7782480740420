const generateGUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (match) => {
    const random: number = (Math.random() * 16) | 0;
    const GUID: number = match === "x" ? random : (random & 0x3) | 0x8;

    return GUID.toString(16);
  });
};

export { generateGUID };
