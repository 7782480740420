import {
  Button,
  Text,
  viewStyles,
  global,
  IconReload,
} from "@mxmdev/react-universal-components";
import { View } from "react-native";

import { createStyles, useTheme } from "../../utils/themeUtils";

const Retry = ({ onRetry }: Props): JSX.Element => {
  const { style } = useTheme(styles);

  return (
    <View style={style.container}>
      <Text align="center" style={style.title} type="headingDefault">
        Oops! Something went wrong, we couldn't load the page.
      </Text>
      <Text align="center" style={style.subtitle} type="bodyDefault">
        Please try refreshing it or let us know if the problem persist.
      </Text>
      {onRetry && <Button Icon={IconReload} onPress={onRetry} text="Refresh" />}
    </View>
  );
};

const styles = createStyles(() =>
  viewStyles({
    container: {
      alignItems: "center",
      marginTop: global.spacing * 4,
      padding: global.spacing * 5,
    },
    subtitle: {
      marginBottom: global.spacing * 8,
    },
    title: {
      marginBottom: global.spacing * 3,
    },
  })
);

interface Props {
  onRetry?: () => void;
}

export default Retry;
