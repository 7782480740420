import { useCallback } from "react";
import { z, TypeOf } from "zod";

import { useValidatedMutation } from ".";

type UseEmbedCodeParams = {
  episodeId: string;
  podcastId: string;
};

export type GetEmbedCodeResult = {
  id: string;
};

const parser = z.object({
  id: z.string(),
});

const transformer = (
  input: TypeOf<typeof parser>
): GetEmbedCodeResult | undefined => {
  return input;
};

const params = {};

export const useEmbedCode = ({ episodeId, podcastId }: UseEmbedCodeParams) => {
  const { data, error, isLoading, mutate } = useValidatedMutation(
    "embed.create",
    params,
    parser,
    transformer
  );

  const fetchEmbedCode = useCallback(
    (allowedOrigins: string[]) => {
      mutate(
        {},
        {
          allowedOrigins,
          episodeId,
          podcastId,
        }
      );
    },
    [episodeId, mutate, podcastId]
  );

  return {
    embedCode: data,
    error,
    fetchEmbedCode,
    isLoading,
  };
};
