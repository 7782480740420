import { Text } from "@mxmdev/react-universal-components";
import { PropsWithChildren } from "react";
import { Appearance, StyleProp, ViewStyle } from "react-native";

const CustomWebViewFactory = () => {
  try {
    const { WebView } = require("react-native-webview");

    const CustomWebViewInternal = ({
      handleNavigationStateChanged,
      style: styleProp,
      urlToLoad,
    }: PropsWithChildren<Props>) => {
      const colorScheme = Appearance.getColorScheme();

      return (
        <WebView
          forceDarkOn={colorScheme === "dark"}
          onNavigationStateChange={(
            navigation: import("react-native-webview").WebViewNavigation
          ) => handleNavigationStateChanged?.(navigation)}
          source={{ uri: urlToLoad }}
          startInLoadingState
          style={styleProp}
        />
      );
    };

    return CustomWebViewInternal;
  } catch {
    return () => (
      <Text>Please install "react-native-webview" to use this component</Text>
    );
  }
};

const CustomWebView = CustomWebViewFactory();

interface Props {
  handleNavigationStateChanged?: (
    navigation: import("react-native-webview").WebViewNavigation
  ) => void;
  style?: StyleProp<ViewStyle>;
  urlToLoad: string;
}

export default CustomWebView;
