import { Platform, TextStyle } from "react-native";

import global, { ComponentSize } from "../../styles/global";
import { viewStyles } from "../../styles/styleUtils";
import { Theme } from "../../styles/themes";
import typography from "../../styles/typography";

type Background = "primary" | "secondary";

const sizes = {
  default: global.size.default,
  large: global.size.large,
  small: global.size.small,
};

interface Props {
  background: Background;
  disabled?: boolean;
  hasClearable: boolean;
  hasLeftEnhancer: boolean;
  hasRightEnhancer: boolean;
  size: ComponentSize;
}

const styles = (
  theme: Theme,
  {
    background,
    disabled,
    hasClearable,
    hasLeftEnhancer,
    hasRightEnhancer,
    size,
  }: Props
) => {
  const backgrounds = {
    primary: theme.backgroundPrimary,
    secondary: theme.backgroundSecondary,
  };

  const borderWidth = 1;

  const paddingValues = {
    default: global.spacing * 4,
    large: global.spacing * 4,
    small: global.spacing * 4,
  };

  const textStyles: { [key: string]: TextStyle } = {
    default: typography.bodyLarge,
    large: typography.bodyLarge,
    small: typography.bodyDefault,
  };

  return viewStyles({
    buttonContainer: {
      justifyContent: "center",
      paddingLeft: global.spacing * 2,
      paddingRight: hasRightEnhancer ? 0 : global.spacing * 2,
    },
    input: {
      color: disabled ? theme.contentTertiary : theme.contentPrimary,
      flex: Platform.OS !== "web" ? 1 : undefined,
      flexGrow: 1,
      height: sizes[size] - borderWidth * 2,
      paddingLeft: hasLeftEnhancer ? 0 : paddingValues[size],
      paddingRight: hasRightEnhancer || hasClearable ? 0 : paddingValues[size],
      ...Platform.select({
        android: {
          paddingVertical: 4, // This workaround is needed (only on Android) to see vertically centered text inside Input.
        },
        ios: {
          paddingBottom: 4, // This workaround is needed (only on iOS) to see vertically centered text inside Input.
        },
        web: {
          cursor: disabled ? "not-allowed" : "auto",
          outlineStyle: "none", // Remove outline on focus (web)
          // Browsers apply a fixated width on the input based on the "size" HTML attribute
          // we need this to make the input responsive
          // width: 100% on mobile, instead, would cause the input to completely shrink
        },
      }),
      ...textStyles[size],
    },
    inputContainer: {
      backgroundColor:
        background === "primary" ? undefined : backgrounds[background],
      borderRadius: global.radius.default,
      borderWidth,
      flexDirection: "row",
      ...Platform.select({
        web: {
          cursor: disabled ? "not-allowed" : "auto",
        },
      }),
    },
    leftEnhancerContainer: {
      justifyContent: "center",
      paddingHorizontal: global.spacing * 2,
    },
    rightEnhancerContainer: {
      justifyContent: "center",
      paddingLeft: hasClearable ? 0 : global.spacing * 2,
      paddingRight: global.spacing * 2,
    },
  });
};

export default styles;
