import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";

const defaultBackendCacheEnabled = true;

export const useBackendCache = (): {
  isBackendCacheEnabled: boolean;
  toggleIsBackendCacheEnabled: () => void;
} => {
  const { data, mutate } = useSWRImmutable(
    "local:backend-cache",
    () => defaultBackendCacheEnabled
  );

  const toggleIsBackendCacheEnabled = useCallback(() => {
    mutate(!data, { revalidate: false });
  }, [data, mutate]);

  return {
    isBackendCacheEnabled: data ?? defaultBackendCacheEnabled,
    toggleIsBackendCacheEnabled,
  };
};
