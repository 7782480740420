import { SyncedSection, UnsyncedSection } from "@mxmdev/podcasts-shared";
import {
  Button,
  Checkbox,
  global,
  imageStyles,
  Input,
  Skeleton,
  Text,
  TextLink,
  textStyles,
  viewStyles,
} from "@mxmdev/react-universal-components";
import { useClipboard } from "@mxmdev/react-universal-core";
import { useState, useReducer } from "react";
import { View } from "react-native";

import { ConditionalView } from "../../components";
import { Image } from "../../components/Image";
import { useConfiguration } from "../../context";
import useEpisodePreviewUrl from "../../hooks/useEpisodePreviewUrl";
import { createResponsiveStyles, useResponsiveTheme } from "../../utils";

const ShareTab = ({ episode, section }: Props) => {
  const [copied, setCopied] = useReducer(() => true, false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgError, setImgError] = useState(false);

  const [, copyValue] = useClipboard();
  const { baseUrl, env } = useConfiguration();

  const { style, theme } = useResponsiveTheme(styles);

  const [startFromParagraph, toggleStartFromParagraph] = useReducer(
    (s) => !s,
    true
  );

  const { isLoading, previewUrl } = useEpisodePreviewUrl({
    episodeId: episode.id,
    podcastId: episode.podcastId,
    time: startFromParagraph ? section?.referenceStartTime : undefined,
  });

  const url =
    `${baseUrl}/podcast/${episode.podcastId.toLowerCase()}/episode/${episode.id.toLowerCase()}` +
    (section?.referenceStartTime !== undefined && startFromParagraph
      ? `?time=${section?.referenceStartTime}`
      : ``);

  return (
    <>
      {imgError && env !== "pp" && env !== "prod" ? (
        <TextLink to={previewUrl}>{previewUrl}</TextLink>
      ) : (
        <View style={style.imageContainer}>
          <Image
            onError={(): void => setImgError(true)}
            onLoadEnd={(): void => setImgLoaded(true)}
            src={previewUrl}
            style={style.previewImage}
          />

          {(isLoading || !imgLoaded) && <Skeleton height={630} width={1200} />}
        </View>
      )}

      <Input size="small" style={style.input} value={url} />

      {section && (
        <Checkbox
          checked={startFromParagraph}
          label="Start from the selected paragraph"
          onChange={toggleStartFromParagraph}
          style={style.checkbox}
        />
      )}

      <View style={style.footer}>
        <ConditionalView keepContainer show={copied} slideAmount={5}>
          <Text
            color={theme.contentSecondary}
            numberOfLines={1}
            style={style.confirmText}
            type="labelSmall"
          >
            Link copied to clipboard
          </Text>
        </ConditionalView>

        <Button
          color="brand"
          onPress={(): void => {
            setCopied();
            copyValue(url);
          }}
          style={style.button}
          text="Copy link"
        />
      </View>
    </>
  );
};

interface Props {
  episode: {
    id: string;
    podcastId: string;
  };
  section?: UnsyncedSection | SyncedSection;
}

const styles = createResponsiveStyles((theme, { isSmallScreen }) => ({
  ...viewStyles({
    button: {
      marginTop: global.spacing * 2,
    },
    checkbox: {
      marginTop: global.spacing * 3,
      transform: [{ translateX: -global.spacing * 3 }],
    },
    footer: {
      alignSelf: isSmallScreen ? "stretch" : "flex-end",
      marginTop: global.spacing * 6,
      minWidth: 255,
    },
    imageContainer: {
      aspectRatio: 1.91,
      borderRadius: global.radius.large,
      overflow: "hidden",
      position: "relative",
    },
    input: {
      marginTop: global.spacing * 4,
    },
  }),
  ...imageStyles({
    previewImage: {
      flex: 1,
      resizeMode: "contain",
    },
  }),
  ...textStyles({
    confirmText: {
      textAlign: "center",
    },
  }),
}));

export default ShareTab;
