import { Context, useContext } from "react";

import { SettingsContext, SettingsContextProps } from "./SettingsContext";

const useSettings = <TSettings>() => {
  const settingsContext = useContext(
    SettingsContext as Context<SettingsContextProps<TSettings>>
  );

  if (!settingsContext) {
    throw new Error(
      "Error: could not find settings context value; please ensure the component is wrapped in a <SettingsProvider>"
    );
  }

  return settingsContext;
};

export default useSettings;
