import { canUseDOM, signURL } from "@mxmdev/react-universal-core";
import { createContext, PropsWithChildren } from "react";

import { useBackendCache } from "../hooks/useBackendCache";
import { useUserToken } from "../hooks/useUserToken";

export type DataFetchingContextData = {
  /**
   * Generates a URL string from the given API endpoint and parameters,
   * also injecting the authentication headers if the user is logged in.
   */
  createRequestUrl: (
    api: string,
    params?: Record<string, unknown>
  ) => Promise<string>;

  /**
   * Instead of taking just the API name (eg. podcast.episode.get), this method
   * accepts a path (eg. /the/path), that will be automatically appended to the correct
   * origin. Moreover, the relevant parameters (appID, auth tokens) will be added
   */
  createRawRequestUrl: (
    path: string,
    params?: Record<string, unknown>
  ) => string;

  createRawRequestUrlWithOrigin: (
    origin: string,
    path: string,
    params?: Record<string, unknown>
  ) => string;

  apiOrigin: string;
};

const dataFetchingContextDefaultValue: DataFetchingContextData = {
  apiOrigin: "",
  createRawRequestUrl: () => {
    throw new Error("not initialized");
  },
  createRawRequestUrlWithOrigin: () => {
    throw new Error("not initialized");
  },
  createRequestUrl: () => {
    throw new Error("not initialized");
  },
};

export const DataFetchingContext = createContext<DataFetchingContextData>(
  dataFetchingContextDefaultValue
);

const CROWD_API_PREFIX = "/ws/1.1";
const NEXTJS_API_ROUTES_PREFIX = "/api";

const SPECIAL_API_PREFIXES = new Map([
  ["crowd.score.get", CROWD_API_PREFIX],
  ["podcast.episode.preview.image.get", NEXTJS_API_ROUTES_PREFIX],
  ["user.get", CROWD_API_PREFIX],
  ["queue-sender/v1/send", CROWD_API_PREFIX],
]);

const DataFetchingProvider = ({
  apiOrigin,
  apiURLSignatureEnabled,
  appId,
  children,
  clientKey,
}: PropsWithChildren<Props>) => {
  const { userToken: authenticatedUserToken } = useUserToken();
  const { isBackendCacheEnabled } = useBackendCache();

  const createRawRequestUrlWithOrigin = (
    origin: string,
    path: string,
    params?: Record<string, unknown>
  ): string => {
    const urlParams: Record<string, string | number | boolean> = {
      app_id: appId,
      ...(authenticatedUserToken && { usertoken: authenticatedUserToken }),
      ...params,
    };

    if (!isBackendCacheEnabled) {
      urlParams.nocache = true;
    }

    // We can't use URLSearchParams on React Native, so we need to implement
    // in a custom way
    const escapedUrlParams = Object.entries(urlParams)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const url = `${origin}${path}?${escapedUrlParams}`;

    return url;
  };

  const createRawRequestUrl = (
    path: string,
    params?: Record<string, unknown>
  ): string => {
    const origin = canUseDOM ? window.location.origin : apiOrigin;

    return createRawRequestUrlWithOrigin(origin, path, params);
  };

  const createRequestUrl = async (
    api: string,
    params?: Record<string, unknown>
  ): Promise<string> => {
    const specialPrefix = SPECIAL_API_PREFIXES.get(api);
    const apiPrefix = specialPrefix ?? "/ws/1.0";

    const url = createRawRequestUrl(`${apiPrefix}/${api}`, params);

    const signedUrl = apiURLSignatureEnabled
      ? await signURL(url, clientKey)
      : url;

    return signedUrl;
  };

  const computedApiOrigin = canUseDOM ? window.location.origin : apiOrigin;

  return (
    <DataFetchingContext.Provider
      value={{
        apiOrigin: computedApiOrigin,
        createRawRequestUrl,
        createRawRequestUrlWithOrigin,
        createRequestUrl,
      }}
    >
      {children}
    </DataFetchingContext.Provider>
  );
};

interface Props {
  appId: string;
  apiOrigin: string;
  apiURLSignatureEnabled: boolean;
  clientKey: string;
}

export default DataFetchingProvider;
