import { createContext } from "react";

interface ContextType {
  captchaId: string | undefined;
  clear: () => void;
}

const initialContextValue: ContextType = {
  captchaId: undefined,
  clear: () => {
    console.warn(
      "[CaptchaContext] cannot clear captcha context as it is not initialized"
    );
  },
};

const CaptchaContext = createContext<ContextType>(initialContextValue);

export default CaptchaContext;
