import { PropsWithChildren } from "react";

import ItemDescription, { Props } from "../shared/ItemDescription";

const ListItemDescription = ({
  children,
  color,
  endEnhancer,
  numberOfLines,
  startEnhancer,
  type,
}: PropsWithChildren<Props>) => {
  return (
    <ItemDescription
      color={color}
      endEnhancer={endEnhancer}
      numberOfLines={numberOfLines}
      startEnhancer={startEnhancer}
      type={type}
    >
      {children}
    </ItemDescription>
  );
};

export default ListItemDescription;
