import { createContext, useContext, PropsWithChildren } from "react";

import {
  GestureHandlerProps,
  SheetScrollViewProps,
} from "../../hooks/useAnimatedOverlays";

export const GestureContext = createContext<IGestureContext | undefined>(
  undefined
);

const BottomSheetGestureProvider = ({
  children,
  value,
}: PropsWithChildren<Props>) => {
  return (
    <GestureContext.Provider value={value}>{children}</GestureContext.Provider>
  );
};

export default BottomSheetGestureProvider;

export const useBottomSheetGestureContext = () => {
  const gestureContext = useContext(GestureContext);

  return gestureContext;
};

interface IGestureContext {
  gestureHandlerProps: GestureHandlerProps;
  scrollViewProps: SheetScrollViewProps;
  panEnabled: boolean;
}

interface Props {
  value: IGestureContext | undefined;
}
