import { InvalidRequestError, NotFoundError } from "../../types/errors";

// Normally, we should specify unknown for API results, but
// useSWR does not accept unknown as a result, so we have to resort
// to any. Feel free to suggest better options!

type FetcherResponse = {
  body: any;
  available?: number;
};

export const fetcher = async (
  url: string,
  options?: RequestInit
): Promise<FetcherResponse> => {
  const res = await fetch(url, options);

  const parsed = await res.json();

  if (
    parsed?.message?.header?.status_code === undefined ||
    parsed?.message?.body === undefined
  ) {
    throw new Error("malformed API response");
  }

  const statusCode = parsed.message.header.status_code;
  const error = parsed.message.body.error;

  if (statusCode === 400) {
    throw new InvalidRequestError(error || "Invalid request");
  } else if (statusCode === 401) {
    throw new Error("unauthorized");
  } else if (statusCode === 404) {
    throw new NotFoundError("not found");
  } else if (statusCode !== 200 && statusCode !== 201 && statusCode !== 202) {
    throw new Error(`API returned non-successful response: ${statusCode}`);
  }

  return {
    available: parsed.message.header.available,
    body: parsed.message.body,
  };
};
