import {
  ApolloError,
  ConsumerInfoGetData,
  ConsumerInfoGetVars,
  isResponseOk,
  queries,
  useQuery,
} from "@mxmdev/react-universal-core";
import { useCallback, useMemo, useState } from "react";

import { PaymentsError } from "../errors";
import { ERROR_CODES } from "../errors/PaymentsError";
import {
  consumablesTransformer,
  kycTransformer,
  simpleEntitlementsTransformer,
  subscriptionsTransformer,
} from "../transformers";
import {
  Consumable,
  KycStatus,
  SimpleEntitlement,
  Subscription,
} from "../types";

type hasEntitlementFn = (
  entitlementId: string
) => readonly [boolean, SimpleEntitlement | null];
type hasAvailableConsumablesFn = (
  consumableId: string
) => readonly [boolean, Consumable | null];

const usePaymentsConsumerInfo = (): {
  loading: boolean;
  error: ApolloError | PaymentsError | null;
  entitlements: SimpleEntitlement[] | null;
  consumables: Consumable[] | null;
  subscriptions: Subscription[] | null;
  kyc: KycStatus | null;
  hasEntitlement: hasEntitlementFn;
  hasAvailableConsumable: hasAvailableConsumablesFn;
  refetch: () => Promise<void>;
} => {
  const [error, setError] = useState<ApolloError | PaymentsError | null>(null);

  const { data, loading, refetch } = useQuery<
    ConsumerInfoGetData,
    ConsumerInfoGetVars
  >(queries.consumerInfoGet, {
    onCompleted: (data) => {
      if (!isResponseOk(data?.consumerInfoGet.header.status_code)) {
        setError(
          new PaymentsError(
            data?.consumerInfoGet.header.hint ?? "unknown error",
            ERROR_CODES.UNEXPECTED
          )
        );
      }
    },
    onError: (error) => {
      setError(error);
    },
  });

  const transformedData = useMemo(() => {
    if (!data) {
      return {
        consumables: null,
        entitlements: null,
        kyc: null,
        subscriptions: null,
      };
    }

    return {
      consumables: consumablesTransformer(data.consumerInfoGet.body?.info),
      entitlements: simpleEntitlementsTransformer(data.consumerInfoGet.body),
      kyc: kycTransformer(data.consumerInfoGet.body),
      subscriptions: subscriptionsTransformer(data.consumerInfoGet.body),
    };
  }, [data]);

  const refetchFn = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const hasEntitlement = useCallback<hasEntitlementFn>(
    (entitlementId: string) => {
      const { entitlements } = transformedData;

      const entitlement = entitlements?.find((ent) => ent.id === entitlementId);

      return [Boolean(entitlement), entitlement ?? null] as const;
    },
    [transformedData]
  );

  const hasAvailableConsumable = useCallback<hasAvailableConsumablesFn>(
    (consumableId: string) => {
      const { consumables } = transformedData;

      const consumable = consumables?.find(
        (consumable) =>
          consumable.consumableId === consumableId &&
          consumable.availableTokens > 0
      );

      return [Boolean(consumable), consumable ?? null] as const;
    },
    [transformedData]
  );

  return {
    ...transformedData,
    error,
    hasAvailableConsumable,
    hasEntitlement,
    loading,
    refetch: refetchFn,
  };
};

export default usePaymentsConsumerInfo;
