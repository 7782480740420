import { global, textStyles, viewStyles } from "../../styles/";

const styles = () => ({
  ...textStyles({
    text: {
      fontWeight: "700",
    },
  }),
  ...viewStyles({
    content: {
      alignItems: "center",
      flexDirection: "row",
    },
    icon: {
      marginRight: global.spacing,
    },
    minTouchTarget: {
      paddingVertical: global.spacing * 3,
    },
  }),
});

export default styles;
