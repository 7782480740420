import { Theme } from "./Theme";
import common from "./common-theme-colors";

const theme: Theme = {
  backgroundHover: "rgba(255,255,255,0.05)",
  backgroundHoverInverted: "rgba(0,0,0,0.15)",
  backgroundHoverInvertedStatic: common.backgroundHoverInvertedStatic,
  backgroundHoverStatic: common.backgroundHoverStatic,
  backgroundPress: "rgba(255,255,255,0.05)",
  backgroundPressInverted: "rgba(0,0,0,0.15)",
  backgroundPressInvertedStatic: common.backgroundPressInvertedStatic,
  backgroundPressStatic: common.backgroundPressStatic,
  backgroundPrimary: "#131313",
  backgroundPrimaryElevated: "#1F1F1F",
  backgroundPrimaryInverted: "#FFFFFF",
  backgroundSecondary: "#343434",
  backgroundSecondaryInverted: "#F0F0F0",
  backgroundTertiary: "#757575",
  backgroundTertiaryInverted: "#CACACA",
  contentPrimary: "#FFFFFF",
  contentPrimaryInverted: "#131313",
  contentPrimaryInvertedStatic: common.contentPrimaryInvertedStatic,
  contentPrimaryStatic: common.contentPrimaryStatic,
  contentSecondary: "#BDBDBD",
  contentSecondaryInverted: "#777777",
  contentSecondaryInvertedStatic: common.contentSecondaryInvertedStatic,
  contentSecondaryStatic: common.contentSecondaryStatic,
  contentTertiary: "#828282",
  contentTertiaryInverted: "#BDBDBD",
  contentTertiaryInvertedStatic: common.contentTertiaryInvertedStatic,
  contentTertiaryStatic: common.contentTertiaryStatic,
  paletteBlue10: "#171F30",
  paletteBlue20: "#25325D",
  paletteBlue70: "#415AB8",
  paletteBlue100: common.paletteBlue100,
  paletteBlue200: common.paletteBlue200,
  paletteBlue300: common.paletteBlue300,
  paletteBrand10: "#380B01",
  paletteBrand20: "#661300",
  paletteBrand70: "#A02208",
  paletteBrand100: common.paletteBrand100,
  paletteBrand200: common.paletteBrand200,
  paletteBrand300: common.paletteBrand300,
  paletteGreen10: "#0E2726",
  paletteGreen20: "#00493E",
  paletteGreen70: "#058567",
  paletteGreen100: common.paletteGreen100,
  paletteGreen200: common.paletteGreen200,
  paletteGreen300: common.paletteGreen300,
  paletteGrey10: "#1B2023",
  paletteGrey20: "#323638",
  paletteGrey70: "#545454",
  paletteGrey100: common.paletteGrey100,
  paletteGrey200: "#CCCCCC",
  paletteGrey300: "#F2F2F2",
  palettePurple10: "#1D1530",
  palettePurple20: "#36145E",
  palettePurple70: "#6A14B9",
  palettePurple100: common.palettePurple100,
  palettePurple200: common.palettePurple200,
  palettePurple300: common.palettePurple300,
  paletteYellow10: "#272516",
  paletteYellow20: "#554611",
  paletteYellow70: "#B08707",
  paletteYellow100: common.paletteYellow100,
  paletteYellow200: common.paletteYellow200,
  paletteYellow300: common.paletteYellow300,
  serviceAmazonMusic: common.serviceAmazonMusic,
  serviceAppleMusic: common.serviceAppleMusic,
  serviceFacebook: common.serviceFacebook,
  serviceInstagram: common.serviceInstagram,
  serviceSpotify1: common.serviceSpotify1,
  serviceSpotify2: common.serviceSpotify2,
  serviceTikTok: "#FFFFFF",
  serviceTwitter: "#FFFFFF",
  serviceVimeo: common.serviceVimeo,
  serviceYoutube: common.serviceYoutube,
  shadow: common.shadow,
  systemBlue10: "#17335E",
  systemBlue100: common.systemBlue100,
  systemBlue200: common.systemBlue200,
  systemGreen10: "#1C3424",
  systemGreen100: common.systemGreen100,
  systemGreen200: common.systemGreen200,
  systemMagenta10: "#40142E",
  systemMagenta100: common.systemMagenta100,
  systemMagenta200: common.systemMagenta200,
  systemRed10: "#3E2021",
  systemRed70: "#82302D",
  systemRed100: common.systemRed100,
  systemRed200: common.systemRed200,
  systemYellow10: "#534123",
  systemYellow100: common.systemYellow100,
  systemYellow200: common.systemYellow200,
};

export default theme;
