import {
  PaymentLinksPostData,
  PaymentLinksPostVars,
  queries,
  useMutation,
} from "@mxmdev/react-universal-core";
import { useCallback, useState } from "react";
import { Platform } from "react-native";

import { LinksFn } from "../types";

const usePaymentsLinks = (): {
  links: LinksFn;
  loading: boolean;
} => {
  const [loading, setLoading] = useState<boolean>(false);

  const [mutate] = useMutation<PaymentLinksPostData, PaymentLinksPostVars>(
    queries.linksPost
  );

  const links = useCallback<LinksFn>(
    async (options) => {
      setLoading(true);

      try {
        const res = await mutate({
          variables: {
            body: {
              platform: "stripe", //TODO: handle properly different platforms
              returnRedirectURL: options.returnRedirectURL,
              ...(options.upgrade && {
                upgrade: {
                  completeRedirectURL: options.upgrade.completeRedirectURL,
                  ...("price" in options.upgrade
                    ? {
                        product: {
                          internal: {
                            platformSku:
                              options.upgrade.price.internal.platformSku,
                            platformSubscriptionId:
                              options.upgrade.product.internal
                                .platformSubscriptionId,
                          },
                        },
                      }
                    : {
                        platformSubscriptionId:
                          options.upgrade.platformSubscriptionId,
                      }),
                },
              }),
            },
          },
        });

        if (!res.data?.linksPost.body) {
          setLoading(false);
          throw new TypeError(
            "[PaymentProvider] links returned an empty response body"
          );
        }

        return {
          customerPortal: res.data.linksPost.body.customerPortal,
        };
      } finally {
        setLoading(false);
      }
    },
    [mutate]
  );

  return { links, loading };
};

export default usePaymentsLinks;
