import {
  Button,
  Hoverable,
  IconEdit,
  Pressable,
  SkeletonCircle,
  useMediaQuery,
  useTheme,
} from "@mxmdev/react-universal-components";
import { ComponentProps, useRef, PropsWithChildren } from "react";
import { Animated, View } from "react-native";

import Avatar from "./AccountAvatar";
import styles from "./styles";

const EnhancedAvatar = ({
  isLoading,
  onHover,
  onPress,
  to,
  ...otherProps
}: PropsWithChildren<Props>) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });

  const hoverAnimatedValue = useRef(new Animated.Value(0)).current;
  const pressAnimatedValue = useRef(new Animated.Value(0)).current;

  return isLoading ? (
    <SkeletonCircle style={[otherProps.style, style.bottomSpacing]} />
  ) : (
    <View style={style.bottomSpacing}>
      <Hoverable animatedValue={hoverAnimatedValue} onHover={onHover}>
        <Pressable animatedValue={pressAnimatedValue} onPress={onPress} to={to}>
          <Avatar
            {...otherProps}
            hoverAnimatedValue={hoverAnimatedValue}
            pressAnimatedValue={pressAnimatedValue}
          />
        </Pressable>
      </Hoverable>

      {onPress && !isLoading && (
        <Button
          Icon={IconEdit}
          minTouchTarget={false}
          onPress={onPress}
          size="extraSmall"
          style={style.button}
          type="basic"
        />
      )}
    </View>
  );
};

export interface Props
  extends ComponentProps<typeof Avatar>,
    Pick<ComponentProps<typeof Hoverable>, "onHover">,
    Pick<ComponentProps<typeof Pressable>, "onPress" | "to"> {
  isLoading?: boolean;
}

export default EnhancedAvatar;
