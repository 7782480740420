import { ComponentProps } from "react";
import { Platform } from "react-native";

import { Text } from "..";

const Heading = ({ children, level, type: typeProp, ...otherProps }: Props) => {
  const levels: Record<Level, 1 | 2 | 3 | 4 | 5 | 6> = {
    h1: 1,
    h2: 2,
    h3: 3,
    h4: 4,
    h5: 5,
    h6: 6,
  };
  const types: Record<Level, ComponentProps<typeof Text>["type"]> = {
    h1: "displayDefault",
    h2: "headingLarge",
    h3: "headingDefault",
    h4: "headingSmall",
    h5: "labelDefault",
    h6: "labelSmall",
  };

  const ariaLevel = levels[level];
  const type = typeProp ?? types[level];

  return (
    <Text
      {...otherProps}
      //@ts-ignore
      accessibilityLevel={Platform.OS === "web" ? ariaLevel : undefined}
      accessibilityRole="header"
      type={type}
    >
      {children}
    </Text>
  );
};

type Level = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface Props
  extends Omit<
    ComponentProps<typeof Text>,
    "accessibilityRole" | "aria-level"
  > {
  level: Level;
}

export default Heading;
