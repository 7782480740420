import { useContext, useEffect, useState } from "react";

import { DataFetchingContext } from "../../context";

type Params = {
  api: string | undefined;
  params?: Record<string, unknown>;
};

type UseSignAPI = {
  signedUrl: string | undefined;
};

export const useSignURL = ({ api, params }: Params): UseSignAPI => {
  const { createRequestUrl } = useContext(DataFetchingContext);
  const [signedUrl, setSignedUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const signUrl = async () => {
      if (api !== undefined) {
        const signedUrl = await createRequestUrl(api, params);

        setSignedUrl(signedUrl.toString());
      } else {
        setSignedUrl(undefined);
      }
    };

    signUrl();
  }, [api, createRequestUrl, params]);

  return {
    signedUrl,
  };
};
