import {
  createResponsiveStyles,
  usePodcastsStudioAnalytics,
  useResponsiveTheme,
  episodeToAnalytics,
} from "@mxmdev/podcasts-shared-native";
import {
  viewStyles,
  global,
  HeaderBackButton,
  ButtonGroup,
  Button,
  Header,
  LogoMusixmatchPodcastsStudio,
} from "@mxmdev/react-universal-components";
import { useCallback, useMemo, useState } from "react";

import { useEpisode } from "../../data-fetching/hooks";
import {
  getBackAction,
  getCurrentRouteName,
  getCurrentRouteParams,
  navigateBack,
  replace,
  useRouteChange,
} from "../../navigation/RootNavigation";
import { STUDIO_TABS } from "../../navigation/config";
import { BackAction, RootRoute, StudioRoute } from "../../navigation/types";
import { parseIdFromSlugURLComponent } from "../../util/urlUtils";

import MainHeaderMenu from "./MainHeaderMenu";

const INDEX_TO_ROUTE: Record<number, RootRoute> = {
  0: "StudioEpisodeOverview",
  1: "Editor",
  2: "StudioEpisodeVideo",
  3: "StudioEpisodeAIContent",
};

const ROUTE_TO_INDEX = Object.entries(INDEX_TO_ROUTE).reduce(
  (acc, [index, route]) => ({
    ...acc,
    [route]: Number(index),
  }),
  {}
) as Record<RootRoute, number>;

const StudioHeader = (): JSX.Element => {
  const { isLargeScreen, isSmallScreen, style } = useResponsiveTheme(styles);

  const { logTabAIContentClicked, logTabVideoClicked } =
    usePodcastsStudioAnalytics();

  const routes = getCurrentRouteParams<StudioRoute>();

  const { _slug_episode, _slug_podcast, episode_id, podcast_id } = routes || {};

  const podcastId = podcast_id
    ? parseIdFromSlugURLComponent(podcast_id)
    : undefined;

  const episodeId = episode_id
    ? parseIdFromSlugURLComponent(episode_id)
    : undefined;

  const { episode } = useEpisode({ episodeId, podcastId });

  const backAction: BackAction<RootRoute> | undefined = useMemo(() => {
    if (!episode_id || !podcast_id) {
      return undefined;
    }

    const fallbackBackAction: BackAction<"Episode"> = {
      route: "Episode",
      routeParams: {
        _slug_episode: _slug_episode ?? "",
        _slug_podcast: _slug_podcast ?? "",
        episode_id,
        podcast_id,
      },
    };

    return getBackAction() ?? fallbackBackAction;
  }, [_slug_episode, _slug_podcast, episode_id, podcast_id]);

  // Remove icons in mobile view
  const TABS = useMemo(
    () =>
      STUDIO_TABS.map((tab) => ({
        ...tab,
        Icon: isSmallScreen ? undefined : tab.Icon,
      })),
    [isSmallScreen]
  );

  const [tabIndex, setTabIndex] = useState<number>(() => {
    const route = getCurrentRouteName() as StudioRoute;

    if (!route || !TABS.map(({ value }) => value).includes(route)) {
      return 0;
    }

    return TABS.findIndex(({ value }) => value === route);
  });

  useRouteChange((route): void => {
    setTabIndex(ROUTE_TO_INDEX[route]);
  });

  const navigateToTab = useCallback(
    (index: number) => {
      if (index === tabIndex) {
        return;
      }

      const routes = getCurrentRouteParams<StudioRoute>();

      if (routes) {
        const { _slug_episode, _slug_podcast, episode_id, podcast_id } = routes;

        setTabIndex(index);

        replace(TABS[index].value, {
          _slug_episode,
          _slug_podcast,
          episode_id,
          podcast_id,
        });
      }
    },
    [TABS, tabIndex]
  );

  const onTabChange = useCallback(
    (index: number) => {
      navigateToTab(index);

      const route = INDEX_TO_ROUTE[index];

      if (episode) {
        const analytics = episodeToAnalytics(episode);

        switch (route) {
          case "StudioEpisodeAIContent":
            logTabAIContentClicked(analytics);
            break;
          case "StudioEpisodeVideo":
            logTabVideoClicked(analytics);
            break;
        }
      }
    },
    [episode, logTabAIContentClicked, logTabVideoClicked, navigateToTab]
  );

  const left = useCallback(
    (): JSX.Element => (
      <>
        <HeaderBackButton
          onPress={(): void => {
            if (backAction) {
              navigateBack(backAction);
            }
          }}
          style={style.backButton}
        />

        <LogoMusixmatchPodcastsStudio height={isSmallScreen ? 24 : 28} />
      </>
    ),
    [backAction, style.backButton, isSmallScreen]
  );

  const center = useCallback(
    (): JSX.Element => (
      <ButtonGroup
        align="center"
        horizontalScrollAnimation
        selected={tabIndex}
        shape="round"
        size={isSmallScreen ? "small" : "default"}
        spacing="small"
        style={style.tabsContainer}
        type="text"
      >
        {TABS.map((tab, index) => {
          const isCurrent = index === tabIndex;

          const onPress = (): void => {
            onTabChange(index);
          };

          return (
            <Button
              accessibilityLabel={tab.name}
              accessibilityRole="button"
              accessibilityState={isCurrent ? { selected: true } : {}}
              Icon={tab.Icon}
              key={tab.name}
              onPress={onPress}
              selected={isCurrent}
              text={tab.name}
              webTarget="_self"
            />
          );
        })}
      </ButtonGroup>
    ),
    [TABS, isSmallScreen, onTabChange, style.tabsContainer, tabIndex]
  );

  const right = useCallback((): JSX.Element => <MainHeaderMenu />, []);

  return (
    <Header
      center={center}
      left={left}
      right={right}
      stacked={!isLargeScreen}
    />
  );
};

const styles = createResponsiveStyles((theme, { isSmallScreen }) =>
  viewStyles({
    backButton: { marginRight: global.spacing * 2 },
    tabsContainer: {
      maxWidth: isSmallScreen ? "100%" : undefined,
    },
  })
);

export default StudioHeader;
