import { Episode } from "../types";

import { apiParamsToString, getEpisodeApiParams } from "./api-params";

type GetReferenceTrackUrlParams = {
  apiStream: string;
  episodeId: Episode["id"];
};

export const getReferenceTrackUrl = ({
  apiStream,
  episodeId,
}: GetReferenceTrackUrlParams) => {
  const params = apiParamsToString(getEpisodeApiParams(undefined, episodeId));

  return `${apiStream}/ws/1.0/streams/podcast/${episodeId}?${params}`;
};
