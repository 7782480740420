export class Stopwatch {
  private time = 0;
  /**
   * The timestamp of the moment when the stopwatch started. `null` when it's
   * not playing.
   */
  private playingSince: number | null = null;

  start = (): void => {
    this.playingSince = Date.now();
  };

  pause = (): void => {
    this.updateTime();
    this.playingSince = null;
  };

  stop = (): number => {
    this.updateTime();
    this.playingSince = null;

    const time = this.time;

    this.time = 0;

    return time;
  };

  private updateTime = (): void => {
    const timeToAdd =
      this.playingSince !== null ? Date.now() - this.playingSince : 0;

    this.time += timeToAdd;
  };
}
