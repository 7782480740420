import {
  Episode,
  episodeGetAIParts,
  episodeGetSchema,
  episodeGetTransformer,
  getEpisodeApiParams,
} from "@mxmdev/podcasts-shared";
import { ApiResponse, useValidatedQuery } from "@mxmdev/podcasts-shared-native";

import { useSSRData } from "./useSSRData";

type Params = {
  episodeId?: string;
  podcastId?: string;
  noCache?: boolean;
};

const useEpisode = ({
  episodeId,
  noCache,
  podcastId,
}: Params): ApiResponse<Episode, "episode"> => {
  const ssrEpisode = useSSRData(episodeId, "episode", "episode");

  const params: Record<string, unknown> = {
    ...getEpisodeApiParams(podcastId, episodeId),
    part: episodeGetAIParts,
  };

  if (noCache) {
    params.nocache = true;
  }

  const { data, error, isLoading, isNotFound, retry } = useValidatedQuery(
    episodeId && podcastId && !ssrEpisode ? "podcast.episode.get" : undefined,
    params,
    episodeGetSchema,
    episodeGetTransformer
  );

  return {
    episode: ssrEpisode ? ssrEpisode : data,
    error,
    isLoading: ssrEpisode ? false : isLoading,
    isNotFound,
    retry,
  };
};

export default useEpisode;
