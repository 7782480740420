import { useMemo } from "react";
import { z } from "zod";

import useTransformation from "../../hooks/useTransformation";

import useLocalRawData from "./useLocalRawData";

export declare type TypeOf<T extends z.ZodType<unknown>> = T["_output"];

// This hook should be used to read data on the local storage
const useLocalValidatedData = <T extends z.ZodRawShape, OutputType>(
  key: string | undefined,
  valueSchema: z.ZodObject<T>,
  transform: (input: TypeOf<z.ZodObject<T>>) => OutputType
): {
  error?: unknown;
  isLoading: boolean;
  data?: Readonly<OutputType>;
  retry: () => void;
} => {
  const { data, error, isLoading, retry } = useLocalRawData(key);

  const context = useMemo(() => {
    return { name: `localKey:${key}` };
  }, [key]);

  const { data: transformedData, error: validationError } = useTransformation(
    valueSchema,
    transform,
    data,
    context
  );

  return {
    data: transformedData,
    error: error || validationError,
    isLoading,
    retry,
  };
};

export default useLocalValidatedData;
