import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { storage } from "../utils";

import { SettingsContext } from "./SettingsContext";
import { AppId } from "./types";

const SettingsProvider = ({
  appId,
  children,
  defaultSettings,
}: SettingsProviderProps) => {
  const appSettingsId = useRef(`${appId}-settings`);
  const [appSettings, setAppSettings] = useState<any>(defaultSettings);

  useEffect(() => {
    const getSettings = async () => {
      const settings = await storage.get(appSettingsId.current);

      if (settings) {
        setAppSettings(JSON.parse(settings));
      }
    };

    getSettings();
  }, []);

  const removeSettings = useCallback(async (key: any) => {
    setAppSettings((prevState: any) => {
      const settings = Object.keys(prevState).reduce((object, field) => {
        if (field !== key) {
          (object as any)[field] = prevState[field];
        }

        return object;
      }, {});

      storage.set(appSettingsId.current, JSON.stringify(settings));

      return settings;
    });
  }, []);

  const reset = useCallback(async () => {
    storage.remove(appSettingsId.current);
  }, []);

  const setSettings = useCallback(async (key: any, value: string) => {
    setAppSettings((prevState: any) => {
      const settings = { ...prevState, [key]: value };

      storage.set(appSettingsId.current, JSON.stringify(settings));

      return settings;
    });
  }, []);

  return (
    <SettingsContext.Provider
      children={children}
      value={{
        removeSettings,
        reset,
        setSettings,
        settings: appSettings,
      }}
    />
  );
};

interface SettingsProviderProps<TSettings = any> {
  appId: AppId;
  children: ReactNode;
  defaultSettings: TSettings;
}

export default SettingsProvider;
