import { Platform } from "react-native";

const buildWebURL = (to: string) => {
  const splittedTo = to.split(":");
  const [protocol, url] = splittedTo;

  // If undefined URL is without protocol
  if (url) {
    if (protocol === "http" || protocol === "https") {
      return url;
    } else {
      return `${protocol}:${url}`;
    }
  }

  if (protocol.startsWith("//")) {
    return protocol;
  }

  return `//${protocol}`;
};

const buildNativeURL = (to: string) => {
  const splittedTo = to.split(":");
  const [protocol, url] = splittedTo;

  if (url) {
    return `${protocol}:${url}`;
  }

  // Default fallback to https
  return `https://${protocol.replace("//", "")}`;
};

export const formatExternalURL = (to: string) => {
  return Platform.OS === "web" ? buildWebURL(to) : buildNativeURL(to);
};
