import { AuthStorage } from "../types";

const REDIRECT_STORAGE_KEY = "afterCaptchaRedirectUrl";

export const getRedirectUrl = (storage: AuthStorage) =>
  storage.get(REDIRECT_STORAGE_KEY);

export const setRedirectUrl = (storage: AuthStorage, userToken: string) =>
  storage.set(REDIRECT_STORAGE_KEY, userToken);

export const removeRedirectUrl = (storage: AuthStorage) =>
  storage.remove(REDIRECT_STORAGE_KEY);
