import { LinearGradient } from "expo-linear-gradient";
import { ReactNode } from "react";
import { View } from "react-native";

import { useTheme } from "../../../..";

import styles from "./styles";

const BackgroundWrapper = ({ children, colors }: Props) => {
  const { style } = useTheme(styles, { baseColor: colors[0] });

  if (colors.length === 1)
    return (
      <View style={[style.container, style.backgroundColor, style.radius]}>
        {children}
      </View>
    );

  return (
    <View style={style.radius}>
      <LinearGradient
        colors={colors}
        end={{ x: 1, y: 1 }}
        start={{ x: 0, y: 0 }}
        style={style.container}
      >
        {children}
      </LinearGradient>
    </View>
  );
};

interface Props {
  children: ReactNode;
  colors: string[];
}

export default BackgroundWrapper;
