import { NavigationAction } from "@react-navigation/native";
import { forwardRef, FunctionComponent, Ref, PropsWithChildren } from "react";
import {
  Pressable as NativePressable,
  TouchableWithoutFeedbackProps,
  View,
} from "react-native";

import Animated from "../Animated";

import useNativePressableProps from "./useNativePressableProps";
import usePressableAnimations from "./usePressableAnimations";

/**
 * This component is the base for every Ritmo component that can be pressed.
 * Implementation details https://ritmo.musixmatch.dev/?path=/story/components-pressable--page
 *
 */
const Pressable: FunctionComponent<PropsWithChildren<Props>> = forwardRef(
  (props, ref) => {
    const {
      action,
      animatedValue,
      children,
      disabled = false,
      isAnimationDisabled,
      onPress: onPressProp,
      style: styleProp,
      to = "",
      webTarget = "_blank",
      ...otherProps
    } = props;
    const isLink = !!to;
    const isURIFragment = typeof to === "string" && to.startsWith("#");
    const isNavPath =
      isLink &&
      ((typeof to === "string" && to.startsWith("/") && !to.startsWith("//")) ||
        (typeof to === "object" && "screen" in to));
    const isExternalLink = isLink && !isNavPath && !isURIFragment;

    const { onPressInAnimation, onPressOutAnimation } = usePressableAnimations({
      animatedValue,
      isAnimationDisabled,
    });
    const { style, ...pressableProps } = useNativePressableProps({
      disabled,
      isExternalLink,
      isLink,
      isNavPath,
      isURIFragment,
      onPressProp,
      to,
      webTarget,
    });

    if (isExternalLink && disabled) {
      console.warn("[Pressable] External links should not be disabled!");
    }

    return (
      <NativePressable
        disabled={disabled}
        onPressIn={onPressInAnimation}
        onPressOut={onPressOutAnimation}
        ref={ref}
        style={[styleProp, style]}
        {...otherProps}
        {...pressableProps}
      >
        {children}
      </NativePressable>
    );
  }
);

// Web `target` reference:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
export type Target = "_blank" | "_self" | "_parent" | "_top";

// Web `cursor` reference:
// https://developer.mozilla.org/en-US/docs/Web/CSS/cursor#values
export type Cursor = "not-allowed" | "pointer" | "default";

export interface Props extends TouchableWithoutFeedbackProps {
  action?: NavigationAction;
  animatedValue?: Animated.Value;
  disabled?: boolean;
  isAnimationDisabled?: boolean;
  ref?: Ref<View>;
  to?: string;
  webTarget?: Target;
}

export default Pressable;
