import {
  useMediaQuery,
  useTheme,
  AvatarBox,
  IconComponent,
} from "@mxmdev/react-universal-components";
import {
  useQuery,
  CrowdScoreGetData,
  CrowdScoreGetVars,
  queries,
} from "@mxmdev/react-universal-core";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import {
  ComponentProps,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { BackHandler, GestureResponderEvent, View } from "react-native";

import { USER_EMPTY_PIC_BE, USER_EMPTY_PIC_PROXY } from "../../constants";
import { AccountMenu, Props as AccountMenuProps } from "../AccountMenu";

import styles from "./styles";

const HeaderAccountMenu = ({
  AnchorEnhancer,
  appData,
  hasManageAccountButton,
  image,
  manageAccountLink,
  onPressAnchor: onPressAnchorProp,
  onSignOut,
  service,
  subscriptionColors,
  subscriptionIcon,
  subscriptionName,
  t,
  userName,
}: Props) => {
  const { isSmallScreen } = useMediaQuery();
  const { style } = useTheme(styles, { isSmallScreen });
  const [isMenuVisible, setMenuVisibility] = useState(false);

  const { data, error, loading } = useQuery<
    CrowdScoreGetData,
    CrowdScoreGetVars
  >(queries.crowdScoreGet);

  const auth = useAuth();

  const userType = auth.user?.profile.user_type;

  const isObsoletePlaceholder =
    image?.includes(USER_EMPTY_PIC_PROXY) || image?.includes(USER_EMPTY_PIC_BE);

  const profileImage = isObsoletePlaceholder
    ? ""
    : userType === "mxm"
    ? data?.crowdScoreGet.body?.user?.user_profile_photo ?? ""
    : image;

  useEffect(() => {
    const onBackPress = () => {
      if (isMenuVisible) {
        setMenuVisibility(false);

        return true;
      } else {
        return false;
      }
    };

    BackHandler.addEventListener("hardwareBackPress", onBackPress);

    return () =>
      BackHandler.removeEventListener("hardwareBackPress", onBackPress);
  }, [isMenuVisible, setMenuVisibility]);

  const hideMenu = () => {
    setMenuVisibility(false);
  };
  const showMenu = () => {
    setMenuVisibility(true);
  };

  const onPressAnchor = useCallback(
    (event: GestureResponderEvent) => {
      onPressAnchorProp?.(event);

      showMenu();
    },
    [onPressAnchorProp]
  );

  return (
    <AccountMenu
      anchor={
        <>
          {AnchorEnhancer ? (
            <View style={style.anchorEnhancer}>
              <AnchorEnhancer onPress={onPressAnchor} />
            </View>
          ) : undefined}

          <AvatarBox
            hasPlaceholder={!profileImage || loading || !!error}
            onPress={onPressAnchor}
            size="small"
            source={profileImage ?? ""}
          />
        </>
      }
      anchorContainerStyle={style.accountMenuAnchorContainerStyle}
      appData={appData}
      hasManageAccountButton={hasManageAccountButton}
      image={profileImage}
      isLoading={loading}
      manageAccountLink={manageAccountLink}
      onDismiss={() => hideMenu()}
      onSignOut={onSignOut}
      service={service}
      subscriptionColors={subscriptionColors}
      subscriptionIcon={subscriptionIcon}
      subscriptionName={subscriptionName}
      t={t}
      userName={userName}
      visible={isMenuVisible}
    />
  );
};

interface AnchorEnhancerProps {
  onPress?: ComponentProps<typeof AvatarBox>["onPress"];
}

export interface Props
  extends Omit<
    AccountMenuProps,
    "anchor" | "anchorContainerStyle" | "onDismiss" | "visible"
  > {
  AnchorEnhancer?: FunctionComponent<PropsWithChildren<AnchorEnhancerProps>>;
  onPressAnchor?: ComponentProps<typeof AvatarBox>["onPress"];
  subscriptionIcon?: IconComponent;
  subscriptionColors?: string[];
  subscriptionName?: string;
}

export default HeaderAccountMenu;
