import PaymentsError, { ERROR_CODES } from "./PaymentsError";

class ForbiddenOperationError extends PaymentsError {
  operation: string;

  constructor(operation: string) {
    super(
      `Forbidden Operation '${operation}'`,
      ERROR_CODES.FORBIDDEN_OPERATION
    );

    this.operation = operation;
  }
}

export default ForbiddenOperationError;
