import { createContext } from "react";

export interface PaymentContextData {
  /**
   * Initialization of the PaymentService.
   * An active user session is required.
   */
  init: () => void;
}

export const PaymentContext = createContext<PaymentContextData>({
  init: () => {
    throw new TypeError(
      "[PaymentContext] trying to use context without a provider"
    );
  },
});
