import { viewStyles } from "../../styles/";
import global from "../../styles/global";
import { Theme } from "../../styles/themes";

import { Align, Spacing } from "./ButtonGroup";

const spacingValues = {
  default: global.spacing * 4,
  small: global.spacing * 2,
};

const alignToJustify: Record<
  "center" | "left" | "right",
  "center" | "flex-start" | "flex-end"
> = {
  center: "center",
  left: "flex-start",
  right: "flex-end",
};

interface Props {
  align: Align;
  spacing: Spacing;
}

const styles = (theme: Theme, { align, spacing }: Props) => {
  return viewStyles({
    alignment: {
      flexDirection: "row",
      justifyContent: alignToJustify[align],
    },
    childSpacing: {
      marginLeft: spacingValues[spacing],
    },
    contentContainerStyle: { flexGrow: 1 },
    // flexGrow: 0 allows the horizontal scrollView to not fill the height
    // https://stackoverflow.com/questions/49373417/react-native-scrollview-height-always-stays-static-and-does-not-change
    // flexShrink: 0 avoids the scrollview to be shrinked when there is insufficient remaining space
    scrollViewStyle: { flexGrow: 0, flexShrink: 0 },
  });
};

export default styles;
